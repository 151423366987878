import React from 'react'
import { useNavigate, Outlet } from 'react-router-dom'

import { Layout, Button, Image, Spin } from 'antd'

import { useAuth } from '../../contexts/AuthContextProvider'
import LayoutContextProvider from '../../contexts/LayoutContextProvider'
import Sidebar from './Sidebar'
import Logo from '../../assets/images/logo.png'

const AdminLayout = () => {
  const navigate = useNavigate()
  const { firebaseLogout, loading } = useAuth()

  const handleLogout = async () => {
    await firebaseLogout()
    navigate('/login')
  }

  return (
    <Layout>
      <Layout.Sider
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0
        }}
      >
        <Sidebar />
        <div style={{ zIndex: 9, position: 'absolute', width: '100%', padding: 20, bottom: 0 }}>
          <Image src={Logo} preview={false}></Image>
          <div style={{ textAlign: 'center', marginTop: 20 }}>
            <Button type='primary' danger style={{ borderRadius: '20px', border: 0, width: 100 }} onClick={() => handleLogout()}>Sign out</Button>
          </div>
        </div>
      </Layout.Sider>
      <Layout style={{ marginLeft: 200 }}>
        <Layout.Content style={{ padding: 8, minHeight: '100vh' }}>
          <Spin spinning={loading}>
            <LayoutContextProvider>
              <Outlet />
            </LayoutContextProvider>
          </Spin>
        </Layout.Content>
      </Layout>
    </Layout>
  )
}

export default AdminLayout

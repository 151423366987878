const tickers = {
  binance: {
    spot: [
      {
        tk: "ETHBTC",
        balas: "BTC",
        trdas: "ETH",
        scnt: 1,
        pdec: 5,
        sdec: 4
      },
      {
        tk: "LTCBTC",
        balas: "BTC",
        trdas: "LTC",
        scnt: 1,
        pdec: 6,
        sdec: 3
      },
      {
        tk: "BNBBTC",
        balas: "BTC",
        trdas: "BNB",
        scnt: 1,
        pdec: 6,
        sdec: 3
      },
      {
        tk: "NEOBTC",
        balas: "BTC",
        trdas: "NEO",
        scnt: 1,
        pdec: 7,
        sdec: 2
      },
      {
        tk: "QTUMETH",
        balas: "ETH",
        trdas: "QTUM",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "EOSETH",
        balas: "ETH",
        trdas: "EOS",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "SNTETH",
        balas: "ETH",
        trdas: "SNT",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "BNTETH",
        balas: "ETH",
        trdas: "BNT",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "BCCBTC",
        balas: "BTC",
        trdas: "BCC",
        scnt: 1,
        pdec: 6,
        sdec: 3
      },
      {
        tk: "GASBTC",
        balas: "BTC",
        trdas: "GAS",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "BNBETH",
        balas: "ETH",
        trdas: "BNB",
        scnt: 1,
        pdec: 4,
        sdec: 3
      },
      {
        tk: "BTCUSDT",
        balas: "USDT",
        trdas: "BTC",
        scnt: 1,
        pdec: 2,
        sdec: 5
      },
      {
        tk: "ETHUSDT",
        balas: "USDT",
        trdas: "ETH",
        scnt: 1,
        pdec: 2,
        sdec: 4
      },
      {
        tk: "HSRBTC",
        balas: "BTC",
        trdas: "HSR",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "OAXETH",
        balas: "ETH",
        trdas: "OAX",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "DNTETH",
        balas: "ETH",
        trdas: "DNT",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "MCOETH",
        balas: "ETH",
        trdas: "MCO",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "ICNETH",
        balas: "ETH",
        trdas: "ICN",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "MCOBTC",
        balas: "BTC",
        trdas: "MCO",
        scnt: 1,
        pdec: 7,
        sdec: 2
      },
      {
        tk: "WTCBTC",
        balas: "BTC",
        trdas: "WTC",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "WTCETH",
        balas: "ETH",
        trdas: "WTC",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "LRCBTC",
        balas: "BTC",
        trdas: "LRC",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "LRCETH",
        balas: "ETH",
        trdas: "LRC",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "QTUMBTC",
        balas: "BTC",
        trdas: "QTUM",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "YOYOBTC",
        balas: "BTC",
        trdas: "YOYO",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "OMGBTC",
        balas: "BTC",
        trdas: "OMG",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "OMGETH",
        balas: "ETH",
        trdas: "OMG",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "ZRXBTC",
        balas: "BTC",
        trdas: "ZRX",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "ZRXETH",
        balas: "ETH",
        trdas: "ZRX",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "STRATBTC",
        balas: "BTC",
        trdas: "STRAT",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "STRATETH",
        balas: "ETH",
        trdas: "STRAT",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "SNGLSBTC",
        balas: "BTC",
        trdas: "SNGLS",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "SNGLSETH",
        balas: "ETH",
        trdas: "SNGLS",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "BQXBTC",
        balas: "BTC",
        trdas: "BQX",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "BQXETH",
        balas: "ETH",
        trdas: "BQX",
        scnt: 1,
        pdec: -1,
        sdec: -1
      },
      {
        tk: "KNCBTC",
        balas: "BTC",
        trdas: "KNC",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "KNCETH",
        balas: "ETH",
        trdas: "KNC",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "FUNBTC",
        balas: "BTC",
        trdas: "FUN",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "FUNETH",
        balas: "ETH",
        trdas: "FUN",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "SNMBTC",
        balas: "BTC",
        trdas: "SNM",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "SNMETH",
        balas: "ETH",
        trdas: "SNM",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "NEOETH",
        balas: "ETH",
        trdas: "NEO",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "IOTABTC",
        balas: "BTC",
        trdas: "IOTA",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "IOTAETH",
        balas: "ETH",
        trdas: "IOTA",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "LINKBTC",
        balas: "BTC",
        trdas: "LINK",
        scnt: 1,
        pdec: 7,
        sdec: 2
      },
      {
        tk: "LINKETH",
        balas: "ETH",
        trdas: "LINK",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "XVGBTC",
        balas: "BTC",
        trdas: "XVG",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "XVGETH",
        balas: "ETH",
        trdas: "XVG",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "SALTBTC",
        balas: "BTC",
        trdas: "SALT",
        scnt: 1,
        pdec: 7,
        sdec: 2
      },
      {
        tk: "SALTETH",
        balas: "ETH",
        trdas: "SALT",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "MDABTC",
        balas: "BTC",
        trdas: "MDA",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "MDAETH",
        balas: "ETH",
        trdas: "MDA",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "MTLBTC",
        balas: "BTC",
        trdas: "MTL",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "MTLETH",
        balas: "ETH",
        trdas: "MTL",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "SUBBTC",
        balas: "BTC",
        trdas: "SUB",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "SUBETH",
        balas: "ETH",
        trdas: "SUB",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "EOSBTC",
        balas: "BTC",
        trdas: "EOS",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "SNTBTC",
        balas: "BTC",
        trdas: "SNT",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "ETCETH",
        balas: "ETH",
        trdas: "ETC",
        scnt: 1,
        pdec: 5,
        sdec: 2
      },
      {
        tk: "ETCBTC",
        balas: "BTC",
        trdas: "ETC",
        scnt: 1,
        pdec: 7,
        sdec: 2
      },
      {
        tk: "MTHBTC",
        balas: "BTC",
        trdas: "MTH",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "MTHETH",
        balas: "ETH",
        trdas: "MTH",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "ENGBTC",
        balas: "BTC",
        trdas: "ENG",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "ENGETH",
        balas: "ETH",
        trdas: "ENG",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "DNTBTC",
        balas: "BTC",
        trdas: "DNT",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "ZECBTC",
        balas: "BTC",
        trdas: "ZEC",
        scnt: 1,
        pdec: 7,
        sdec: 3
      },
      {
        tk: "ZECETH",
        balas: "ETH",
        trdas: "ZEC",
        scnt: 1,
        pdec: 5,
        sdec: 3
      },
      {
        tk: "BNTBTC",
        balas: "BTC",
        trdas: "BNT",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "ASTBTC",
        balas: "BTC",
        trdas: "AST",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "ASTETH",
        balas: "ETH",
        trdas: "AST",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "DASHBTC",
        balas: "BTC",
        trdas: "DASH",
        scnt: 1,
        pdec: 7,
        sdec: 3
      },
      {
        tk: "DASHETH",
        balas: "ETH",
        trdas: "DASH",
        scnt: 1,
        pdec: 5,
        sdec: 3
      },
      {
        tk: "OAXBTC",
        balas: "BTC",
        trdas: "OAX",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "ICNBTC",
        balas: "BTC",
        trdas: "ICN",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "BTGBTC",
        balas: "BTC",
        trdas: "BTG",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "BTGETH",
        balas: "ETH",
        trdas: "BTG",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "EVXBTC",
        balas: "BTC",
        trdas: "EVX",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "EVXETH",
        balas: "ETH",
        trdas: "EVX",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "REQBTC",
        balas: "BTC",
        trdas: "REQ",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "REQETH",
        balas: "ETH",
        trdas: "REQ",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "VIBBTC",
        balas: "BTC",
        trdas: "VIB",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "VIBETH",
        balas: "ETH",
        trdas: "VIB",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "HSRETH",
        balas: "ETH",
        trdas: "HSR",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "TRXBTC",
        balas: "BTC",
        trdas: "TRX",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "TRXETH",
        balas: "ETH",
        trdas: "TRX",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "POWRBTC",
        balas: "BTC",
        trdas: "POWR",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "POWRETH",
        balas: "ETH",
        trdas: "POWR",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "ARKBTC",
        balas: "BTC",
        trdas: "ARK",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "ARKETH",
        balas: "ETH",
        trdas: "ARK",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "YOYOETH",
        balas: "ETH",
        trdas: "YOYO",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "XRPBTC",
        balas: "BTC",
        trdas: "XRP",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "XRPETH",
        balas: "ETH",
        trdas: "XRP",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "MODBTC",
        balas: "BTC",
        trdas: "MOD",
        scnt: 1,
        pdec: 7,
        sdec: 2
      },
      {
        tk: "MODETH",
        balas: "ETH",
        trdas: "MOD",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "ENJBTC",
        balas: "BTC",
        trdas: "ENJ",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "ENJETH",
        balas: "ETH",
        trdas: "ENJ",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "STORJBTC",
        balas: "BTC",
        trdas: "STORJ",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "STORJETH",
        balas: "ETH",
        trdas: "STORJ",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "BNBUSDT",
        balas: "USDT",
        trdas: "BNB",
        scnt: 1,
        pdec: 1,
        sdec: 3
      },
      {
        tk: "VENBNB",
        balas: "BNB",
        trdas: "VEN",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "YOYOBNB",
        balas: "BNB",
        trdas: "YOYO",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "POWRBNB",
        balas: "BNB",
        trdas: "POWR",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "VENBTC",
        balas: "BTC",
        trdas: "VEN",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "VENETH",
        balas: "ETH",
        trdas: "VEN",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "KMDBTC",
        balas: "BTC",
        trdas: "KMD",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "KMDETH",
        balas: "ETH",
        trdas: "KMD",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "NULSBNB",
        balas: "BNB",
        trdas: "NULS",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "RCNBTC",
        balas: "BTC",
        trdas: "RCN",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "RCNETH",
        balas: "ETH",
        trdas: "RCN",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "RCNBNB",
        balas: "BNB",
        trdas: "RCN",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "NULSBTC",
        balas: "BTC",
        trdas: "NULS",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "NULSETH",
        balas: "ETH",
        trdas: "NULS",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "RDNBTC",
        balas: "BTC",
        trdas: "RDN",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "RDNETH",
        balas: "ETH",
        trdas: "RDN",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "RDNBNB",
        balas: "BNB",
        trdas: "RDN",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "XMRBTC",
        balas: "BTC",
        trdas: "XMR",
        scnt: 1,
        pdec: 6,
        sdec: 3
      },
      {
        tk: "XMRETH",
        balas: "ETH",
        trdas: "XMR",
        scnt: 1,
        pdec: 5,
        sdec: 3
      },
      {
        tk: "DLTBNB",
        balas: "BNB",
        trdas: "DLT",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "WTCBNB",
        balas: "BNB",
        trdas: "WTC",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "DLTBTC",
        balas: "BTC",
        trdas: "DLT",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "DLTETH",
        balas: "ETH",
        trdas: "DLT",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "AMBBTC",
        balas: "BTC",
        trdas: "AMB",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "AMBETH",
        balas: "ETH",
        trdas: "AMB",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "AMBBNB",
        balas: "BNB",
        trdas: "AMB",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "BCCETH",
        balas: "ETH",
        trdas: "BCC",
        scnt: 1,
        pdec: 5,
        sdec: 3
      },
      {
        tk: "BCCUSDT",
        balas: "USDT",
        trdas: "BCC",
        scnt: 1,
        pdec: 2,
        sdec: 5
      },
      {
        tk: "BCCBNB",
        balas: "BNB",
        trdas: "BCC",
        scnt: 1,
        pdec: 2,
        sdec: 5
      },
      {
        tk: "BATBTC",
        balas: "BTC",
        trdas: "BAT",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "BATETH",
        balas: "ETH",
        trdas: "BAT",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "BATBNB",
        balas: "BNB",
        trdas: "BAT",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "BCPTBTC",
        balas: "BTC",
        trdas: "BCPT",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "BCPTETH",
        balas: "ETH",
        trdas: "BCPT",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "BCPTBNB",
        balas: "BNB",
        trdas: "BCPT",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "ARNBTC",
        balas: "BTC",
        trdas: "ARN",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "ARNETH",
        balas: "ETH",
        trdas: "ARN",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "GVTBTC",
        balas: "BTC",
        trdas: "GVT",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "GVTETH",
        balas: "ETH",
        trdas: "GVT",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "CDTBTC",
        balas: "BTC",
        trdas: "CDT",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "CDTETH",
        balas: "ETH",
        trdas: "CDT",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "GXSBTC",
        balas: "BTC",
        trdas: "GXS",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "GXSETH",
        balas: "ETH",
        trdas: "GXS",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "NEOUSDT",
        balas: "USDT",
        trdas: "NEO",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "NEOBNB",
        balas: "BNB",
        trdas: "NEO",
        scnt: 1,
        pdec: 5,
        sdec: 2
      },
      {
        tk: "POEBTC",
        balas: "BTC",
        trdas: "POE",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "POEETH",
        balas: "ETH",
        trdas: "POE",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "QSPBTC",
        balas: "BTC",
        trdas: "QSP",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "QSPETH",
        balas: "ETH",
        trdas: "QSP",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "QSPBNB",
        balas: "BNB",
        trdas: "QSP",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "BTSBTC",
        balas: "BTC",
        trdas: "BTS",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "BTSETH",
        balas: "ETH",
        trdas: "BTS",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "BTSBNB",
        balas: "BNB",
        trdas: "BTS",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "XZCBTC",
        balas: "BTC",
        trdas: "XZC",
        scnt: 1,
        pdec: 7,
        sdec: 2
      },
      {
        tk: "XZCETH",
        balas: "ETH",
        trdas: "XZC",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "XZCBNB",
        balas: "BNB",
        trdas: "XZC",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "LSKBTC",
        balas: "BTC",
        trdas: "LSK",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "LSKETH",
        balas: "ETH",
        trdas: "LSK",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "LSKBNB",
        balas: "BNB",
        trdas: "LSK",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "TNTBTC",
        balas: "BTC",
        trdas: "TNT",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "TNTETH",
        balas: "ETH",
        trdas: "TNT",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "FUELBTC",
        balas: "BTC",
        trdas: "FUEL",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "FUELETH",
        balas: "ETH",
        trdas: "FUEL",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "MANABTC",
        balas: "BTC",
        trdas: "MANA",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "MANAETH",
        balas: "ETH",
        trdas: "MANA",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "BCDBTC",
        balas: "BTC",
        trdas: "BCD",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "BCDETH",
        balas: "ETH",
        trdas: "BCD",
        scnt: 1,
        pdec: 5,
        sdec: 3
      },
      {
        tk: "DGDBTC",
        balas: "BTC",
        trdas: "DGD",
        scnt: 1,
        pdec: 6,
        sdec: 3
      },
      {
        tk: "DGDETH",
        balas: "ETH",
        trdas: "DGD",
        scnt: 1,
        pdec: 5,
        sdec: 3
      },
      {
        tk: "IOTABNB",
        balas: "BNB",
        trdas: "IOTA",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "ADXBTC",
        balas: "BTC",
        trdas: "ADX",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "ADXETH",
        balas: "ETH",
        trdas: "ADX",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "ADXBNB",
        balas: "BNB",
        trdas: "ADX",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "ADABTC",
        balas: "BTC",
        trdas: "ADA",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "ADAETH",
        balas: "ETH",
        trdas: "ADA",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "PPTBTC",
        balas: "BTC",
        trdas: "PPT",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "PPTETH",
        balas: "ETH",
        trdas: "PPT",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "CMTBTC",
        balas: "BTC",
        trdas: "CMT",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "CMTETH",
        balas: "ETH",
        trdas: "CMT",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "CMTBNB",
        balas: "BNB",
        trdas: "CMT",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "XLMBTC",
        balas: "BTC",
        trdas: "XLM",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "XLMETH",
        balas: "ETH",
        trdas: "XLM",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "XLMBNB",
        balas: "BNB",
        trdas: "XLM",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "CNDBTC",
        balas: "BTC",
        trdas: "CND",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "CNDETH",
        balas: "ETH",
        trdas: "CND",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "CNDBNB",
        balas: "BNB",
        trdas: "CND",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "LENDBTC",
        balas: "BTC",
        trdas: "LEND",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "LENDETH",
        balas: "ETH",
        trdas: "LEND",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "WABIBTC",
        balas: "BTC",
        trdas: "WABI",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "WABIETH",
        balas: "ETH",
        trdas: "WABI",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "WABIBNB",
        balas: "BNB",
        trdas: "WABI",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "LTCETH",
        balas: "ETH",
        trdas: "LTC",
        scnt: 1,
        pdec: 5,
        sdec: 3
      },
      {
        tk: "LTCUSDT",
        balas: "USDT",
        trdas: "LTC",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "LTCBNB",
        balas: "BNB",
        trdas: "LTC",
        scnt: 1,
        pdec: 4,
        sdec: 3
      },
      {
        tk: "TNBBTC",
        balas: "BTC",
        trdas: "TNB",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "TNBETH",
        balas: "ETH",
        trdas: "TNB",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "WAVESBTC",
        balas: "BTC",
        trdas: "WAVES",
        scnt: 1,
        pdec: 8,
        sdec: 2
      },
      {
        tk: "WAVESETH",
        balas: "ETH",
        trdas: "WAVES",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "WAVESBNB",
        balas: "BNB",
        trdas: "WAVES",
        scnt: 1,
        pdec: 5,
        sdec: 2
      },
      {
        tk: "GTOBTC",
        balas: "BTC",
        trdas: "GTO",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "GTOETH",
        balas: "ETH",
        trdas: "GTO",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "GTOBNB",
        balas: "BNB",
        trdas: "GTO",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "ICXBTC",
        balas: "BTC",
        trdas: "ICX",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "ICXETH",
        balas: "ETH",
        trdas: "ICX",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "ICXBNB",
        balas: "BNB",
        trdas: "ICX",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "OSTBTC",
        balas: "BTC",
        trdas: "OST",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "OSTETH",
        balas: "ETH",
        trdas: "OST",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "OSTBNB",
        balas: "BNB",
        trdas: "OST",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "ELFBTC",
        balas: "BTC",
        trdas: "ELF",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "ELFETH",
        balas: "ETH",
        trdas: "ELF",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "AIONBTC",
        balas: "BTC",
        trdas: "AION",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "AIONETH",
        balas: "ETH",
        trdas: "AION",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "AIONBNB",
        balas: "BNB",
        trdas: "AION",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "NEBLBTC",
        balas: "BTC",
        trdas: "NEBL",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "NEBLBNB",
        balas: "BNB",
        trdas: "NEBL",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "BRDBTC",
        balas: "BTC",
        trdas: "BRD",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "BRDETH",
        balas: "ETH",
        trdas: "BRD",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "BRDBNB",
        balas: "BNB",
        trdas: "BRD",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "MCOBNB",
        balas: "BNB",
        trdas: "MCO",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "EDOBTC",
        balas: "BTC",
        trdas: "EDO",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "EDOETH",
        balas: "ETH",
        trdas: "EDO",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "WINGSBTC",
        balas: "BTC",
        trdas: "WINGS",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "WINGSETH",
        balas: "ETH",
        trdas: "WINGS",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "NAVBTC",
        balas: "BTC",
        trdas: "NAV",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "NAVETH",
        balas: "ETH",
        trdas: "NAV",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "NAVBNB",
        balas: "BNB",
        trdas: "NAV",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "LUNBTC",
        balas: "BTC",
        trdas: "LUN",
        scnt: 1,
        pdec: 7,
        sdec: 2
      },
      {
        tk: "LUNETH",
        balas: "ETH",
        trdas: "LUN",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "TRIGBTC",
        balas: "BTC",
        trdas: "TRIG",
        scnt: 1,
        pdec: 7,
        sdec: 2
      },
      {
        tk: "TRIGETH",
        balas: "ETH",
        trdas: "TRIG",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "TRIGBNB",
        balas: "BNB",
        trdas: "TRIG",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "APPCBTC",
        balas: "BTC",
        trdas: "APPC",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "APPCETH",
        balas: "ETH",
        trdas: "APPC",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "APPCBNB",
        balas: "BNB",
        trdas: "APPC",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "VIBEBTC",
        balas: "BTC",
        trdas: "VIBE",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "VIBEETH",
        balas: "ETH",
        trdas: "VIBE",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "RLCBTC",
        balas: "BTC",
        trdas: "RLC",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "RLCETH",
        balas: "ETH",
        trdas: "RLC",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "RLCBNB",
        balas: "BNB",
        trdas: "RLC",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "INSBTC",
        balas: "BTC",
        trdas: "INS",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "INSETH",
        balas: "ETH",
        trdas: "INS",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "PIVXBTC",
        balas: "BTC",
        trdas: "PIVX",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "PIVXBNB",
        balas: "BNB",
        trdas: "PIVX",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "IOSTBTC",
        balas: "BTC",
        trdas: "IOST",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "IOSTETH",
        balas: "ETH",
        trdas: "IOST",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "CHATBTC",
        balas: "BTC",
        trdas: "CHAT",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "CHATETH",
        balas: "ETH",
        trdas: "CHAT",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "STEEMBTC",
        balas: "BTC",
        trdas: "STEEM",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "STEEMETH",
        balas: "ETH",
        trdas: "STEEM",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "STEEMBNB",
        balas: "BNB",
        trdas: "STEEM",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "NANOBTC",
        balas: "BTC",
        trdas: "NANO",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "NANOETH",
        balas: "ETH",
        trdas: "NANO",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "NANOBNB",
        balas: "BNB",
        trdas: "NANO",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "VIABTC",
        balas: "BTC",
        trdas: "VIA",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "VIAETH",
        balas: "ETH",
        trdas: "VIA",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "VIABNB",
        balas: "BNB",
        trdas: "VIA",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "BLZBTC",
        balas: "BTC",
        trdas: "BLZ",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "BLZETH",
        balas: "ETH",
        trdas: "BLZ",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "BLZBNB",
        balas: "BNB",
        trdas: "BLZ",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "AEBTC",
        balas: "BTC",
        trdas: "AE",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "AEETH",
        balas: "ETH",
        trdas: "AE",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "AEBNB",
        balas: "BNB",
        trdas: "AE",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "RPXBTC",
        balas: "BTC",
        trdas: "RPX",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "RPXETH",
        balas: "ETH",
        trdas: "RPX",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "RPXBNB",
        balas: "BNB",
        trdas: "RPX",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "NCASHBTC",
        balas: "BTC",
        trdas: "NCASH",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "NCASHETH",
        balas: "ETH",
        trdas: "NCASH",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "NCASHBNB",
        balas: "BNB",
        trdas: "NCASH",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "POABTC",
        balas: "BTC",
        trdas: "POA",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "POAETH",
        balas: "ETH",
        trdas: "POA",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "POABNB",
        balas: "BNB",
        trdas: "POA",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "ZILBTC",
        balas: "BTC",
        trdas: "ZIL",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "ZILETH",
        balas: "ETH",
        trdas: "ZIL",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "ZILBNB",
        balas: "BNB",
        trdas: "ZIL",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "ONTBTC",
        balas: "BTC",
        trdas: "ONT",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "ONTETH",
        balas: "ETH",
        trdas: "ONT",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "ONTBNB",
        balas: "BNB",
        trdas: "ONT",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "STORMBTC",
        balas: "BTC",
        trdas: "STORM",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "STORMETH",
        balas: "ETH",
        trdas: "STORM",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "STORMBNB",
        balas: "BNB",
        trdas: "STORM",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "QTUMBNB",
        balas: "BNB",
        trdas: "QTUM",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "QTUMUSDT",
        balas: "USDT",
        trdas: "QTUM",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "XEMBTC",
        balas: "BTC",
        trdas: "XEM",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "XEMETH",
        balas: "ETH",
        trdas: "XEM",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "XEMBNB",
        balas: "BNB",
        trdas: "XEM",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "WANBTC",
        balas: "BTC",
        trdas: "WAN",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "WANETH",
        balas: "ETH",
        trdas: "WAN",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "WANBNB",
        balas: "BNB",
        trdas: "WAN",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "WPRBTC",
        balas: "BTC",
        trdas: "WPR",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "WPRETH",
        balas: "ETH",
        trdas: "WPR",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "QLCBTC",
        balas: "BTC",
        trdas: "QLC",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "QLCETH",
        balas: "ETH",
        trdas: "QLC",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "SYSBTC",
        balas: "BTC",
        trdas: "SYS",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "SYSETH",
        balas: "ETH",
        trdas: "SYS",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "SYSBNB",
        balas: "BNB",
        trdas: "SYS",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "QLCBNB",
        balas: "BNB",
        trdas: "QLC",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "GRSBTC",
        balas: "BTC",
        trdas: "GRS",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "GRSETH",
        balas: "ETH",
        trdas: "GRS",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "ADAUSDT",
        balas: "USDT",
        trdas: "ADA",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "ADABNB",
        balas: "BNB",
        trdas: "ADA",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "CLOAKBTC",
        balas: "BTC",
        trdas: "CLOAK",
        scnt: 1,
        pdec: 7,
        sdec: 2
      },
      {
        tk: "CLOAKETH",
        balas: "ETH",
        trdas: "CLOAK",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "GNTBTC",
        balas: "BTC",
        trdas: "GNT",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "GNTETH",
        balas: "ETH",
        trdas: "GNT",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "GNTBNB",
        balas: "BNB",
        trdas: "GNT",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "LOOMBTC",
        balas: "BTC",
        trdas: "LOOM",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "LOOMETH",
        balas: "ETH",
        trdas: "LOOM",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "LOOMBNB",
        balas: "BNB",
        trdas: "LOOM",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "XRPUSDT",
        balas: "USDT",
        trdas: "XRP",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "BCNBTC",
        balas: "BTC",
        trdas: "BCN",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "BCNETH",
        balas: "ETH",
        trdas: "BCN",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "BCNBNB",
        balas: "BNB",
        trdas: "BCN",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "REPBTC",
        balas: "BTC",
        trdas: "REP",
        scnt: 1,
        pdec: 7,
        sdec: 2
      },
      {
        tk: "REPBNB",
        balas: "BNB",
        trdas: "REP",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "BTCTUSD",
        balas: "TUSD",
        trdas: "BTC",
        scnt: 1,
        pdec: 2,
        sdec: 5
      },
      {
        tk: "TUSDBTC",
        balas: "BTC",
        trdas: "TUSD",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "ETHTUSD",
        balas: "TUSD",
        trdas: "ETH",
        scnt: 1,
        pdec: 2,
        sdec: 4
      },
      {
        tk: "TUSDETH",
        balas: "ETH",
        trdas: "TUSD",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "TUSDBNB",
        balas: "BNB",
        trdas: "TUSD",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "ZENBTC",
        balas: "BTC",
        trdas: "ZEN",
        scnt: 1,
        pdec: 7,
        sdec: 2
      },
      {
        tk: "ZENETH",
        balas: "ETH",
        trdas: "ZEN",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "ZENBNB",
        balas: "BNB",
        trdas: "ZEN",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "SKYBTC",
        balas: "BTC",
        trdas: "SKY",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "SKYETH",
        balas: "ETH",
        trdas: "SKY",
        scnt: 1,
        pdec: 5,
        sdec: 3
      },
      {
        tk: "SKYBNB",
        balas: "BNB",
        trdas: "SKY",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "EOSUSDT",
        balas: "USDT",
        trdas: "EOS",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "EOSBNB",
        balas: "BNB",
        trdas: "EOS",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "CVCBTC",
        balas: "BTC",
        trdas: "CVC",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "CVCETH",
        balas: "ETH",
        trdas: "CVC",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "CVCBNB",
        balas: "BNB",
        trdas: "CVC",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "THETABTC",
        balas: "BTC",
        trdas: "THETA",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "THETAETH",
        balas: "ETH",
        trdas: "THETA",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "THETABNB",
        balas: "BNB",
        trdas: "THETA",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "XRPBNB",
        balas: "BNB",
        trdas: "XRP",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "TUSDUSDT",
        balas: "USDT",
        trdas: "TUSD",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "IOTAUSDT",
        balas: "USDT",
        trdas: "IOTA",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "XLMUSDT",
        balas: "USDT",
        trdas: "XLM",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "IOTXBTC",
        balas: "BTC",
        trdas: "IOTX",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "IOTXETH",
        balas: "ETH",
        trdas: "IOTX",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "QKCBTC",
        balas: "BTC",
        trdas: "QKC",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "QKCETH",
        balas: "ETH",
        trdas: "QKC",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "AGIBTC",
        balas: "BTC",
        trdas: "AGI",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "AGIETH",
        balas: "ETH",
        trdas: "AGI",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "AGIBNB",
        balas: "BNB",
        trdas: "AGI",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "NXSBTC",
        balas: "BTC",
        trdas: "NXS",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "NXSETH",
        balas: "ETH",
        trdas: "NXS",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "NXSBNB",
        balas: "BNB",
        trdas: "NXS",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "ENJBNB",
        balas: "BNB",
        trdas: "ENJ",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "DATABTC",
        balas: "BTC",
        trdas: "DATA",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "DATAETH",
        balas: "ETH",
        trdas: "DATA",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "ONTUSDT",
        balas: "USDT",
        trdas: "ONT",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "TRXBNB",
        balas: "BNB",
        trdas: "TRX",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "TRXUSDT",
        balas: "USDT",
        trdas: "TRX",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "ETCUSDT",
        balas: "USDT",
        trdas: "ETC",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "ETCBNB",
        balas: "BNB",
        trdas: "ETC",
        scnt: 1,
        pdec: 5,
        sdec: 2
      },
      {
        tk: "ICXUSDT",
        balas: "USDT",
        trdas: "ICX",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "SCBTC",
        balas: "BTC",
        trdas: "SC",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "SCETH",
        balas: "ETH",
        trdas: "SC",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "NPXSBTC",
        balas: "BTC",
        trdas: "NPXS",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "NPXSETH",
        balas: "ETH",
        trdas: "NPXS",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "VENUSDT",
        balas: "USDT",
        trdas: "VEN",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "KEYBTC",
        balas: "BTC",
        trdas: "KEY",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "KEYETH",
        balas: "ETH",
        trdas: "KEY",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "NASBTC",
        balas: "BTC",
        trdas: "NAS",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "NASETH",
        balas: "ETH",
        trdas: "NAS",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "NASBNB",
        balas: "BNB",
        trdas: "NAS",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "MFTBTC",
        balas: "BTC",
        trdas: "MFT",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "MFTETH",
        balas: "ETH",
        trdas: "MFT",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "MFTBNB",
        balas: "BNB",
        trdas: "MFT",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "DENTBTC",
        balas: "BTC",
        trdas: "DENT",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "DENTETH",
        balas: "ETH",
        trdas: "DENT",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "ARDRBTC",
        balas: "BTC",
        trdas: "ARDR",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "ARDRETH",
        balas: "ETH",
        trdas: "ARDR",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "ARDRBNB",
        balas: "BNB",
        trdas: "ARDR",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "NULSUSDT",
        balas: "USDT",
        trdas: "NULS",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "HOTBTC",
        balas: "BTC",
        trdas: "HOT",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "HOTETH",
        balas: "ETH",
        trdas: "HOT",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "VETBTC",
        balas: "BTC",
        trdas: "VET",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "VETETH",
        balas: "ETH",
        trdas: "VET",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "VETUSDT",
        balas: "USDT",
        trdas: "VET",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "VETBNB",
        balas: "BNB",
        trdas: "VET",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "DOCKBTC",
        balas: "BTC",
        trdas: "DOCK",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "DOCKETH",
        balas: "ETH",
        trdas: "DOCK",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "POLYBTC",
        balas: "BTC",
        trdas: "POLY",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "POLYBNB",
        balas: "BNB",
        trdas: "POLY",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "PHXBTC",
        balas: "BTC",
        trdas: "PHX",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "PHXETH",
        balas: "ETH",
        trdas: "PHX",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "PHXBNB",
        balas: "BNB",
        trdas: "PHX",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "HCBTC",
        balas: "BTC",
        trdas: "HC",
        scnt: 1,
        pdec: 7,
        sdec: 2
      },
      {
        tk: "HCETH",
        balas: "ETH",
        trdas: "HC",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "GOBTC",
        balas: "BTC",
        trdas: "GO",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "GOBNB",
        balas: "BNB",
        trdas: "GO",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "PAXBTC",
        balas: "BTC",
        trdas: "PAX",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "PAXBNB",
        balas: "BNB",
        trdas: "PAX",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "PAXUSDT",
        balas: "USDT",
        trdas: "PAX",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "PAXETH",
        balas: "ETH",
        trdas: "PAX",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "RVNBTC",
        balas: "BTC",
        trdas: "RVN",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "DCRBTC",
        balas: "BTC",
        trdas: "DCR",
        scnt: 1,
        pdec: 7,
        sdec: 3
      },
      {
        tk: "DCRBNB",
        balas: "BNB",
        trdas: "DCR",
        scnt: 1,
        pdec: 3,
        sdec: 3
      },
      {
        tk: "USDCBNB",
        balas: "BNB",
        trdas: "USDC",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "MITHBTC",
        balas: "BTC",
        trdas: "MITH",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "MITHBNB",
        balas: "BNB",
        trdas: "MITH",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "BCHABCBTC",
        balas: "BTC",
        trdas: "BCHABC",
        scnt: 1,
        pdec: 6,
        sdec: 3
      },
      {
        tk: "BCHSVBTC",
        balas: "BTC",
        trdas: "BCHSV",
        scnt: 1,
        pdec: 6,
        sdec: 3
      },
      {
        tk: "BCHABCUSDT",
        balas: "USDT",
        trdas: "BCHABC",
        scnt: 1,
        pdec: 2,
        sdec: 5
      },
      {
        tk: "BCHSVUSDT",
        balas: "USDT",
        trdas: "BCHSV",
        scnt: 1,
        pdec: 2,
        sdec: 5
      },
      {
        tk: "BNBPAX",
        balas: "PAX",
        trdas: "BNB",
        scnt: 1,
        pdec: 1,
        sdec: 3
      },
      {
        tk: "BTCPAX",
        balas: "PAX",
        trdas: "BTC",
        scnt: 1,
        pdec: 2,
        sdec: 5
      },
      {
        tk: "ETHPAX",
        balas: "PAX",
        trdas: "ETH",
        scnt: 1,
        pdec: 2,
        sdec: 4
      },
      {
        tk: "XRPPAX",
        balas: "PAX",
        trdas: "XRP",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "EOSPAX",
        balas: "PAX",
        trdas: "EOS",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "XLMPAX",
        balas: "PAX",
        trdas: "XLM",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "RENBTC",
        balas: "BTC",
        trdas: "REN",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "RENBNB",
        balas: "BNB",
        trdas: "REN",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "BNBTUSD",
        balas: "TUSD",
        trdas: "BNB",
        scnt: 1,
        pdec: 1,
        sdec: 3
      },
      {
        tk: "XRPTUSD",
        balas: "TUSD",
        trdas: "XRP",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "EOSTUSD",
        balas: "TUSD",
        trdas: "EOS",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "XLMTUSD",
        balas: "TUSD",
        trdas: "XLM",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "BNBUSDC",
        balas: "USDC",
        trdas: "BNB",
        scnt: 1,
        pdec: 1,
        sdec: 3
      },
      {
        tk: "BTCUSDC",
        balas: "USDC",
        trdas: "BTC",
        scnt: 1,
        pdec: 2,
        sdec: 5
      },
      {
        tk: "ETHUSDC",
        balas: "USDC",
        trdas: "ETH",
        scnt: 1,
        pdec: 2,
        sdec: 4
      },
      {
        tk: "XRPUSDC",
        balas: "USDC",
        trdas: "XRP",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "EOSUSDC",
        balas: "USDC",
        trdas: "EOS",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "XLMUSDC",
        balas: "USDC",
        trdas: "XLM",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "USDCUSDT",
        balas: "USDT",
        trdas: "USDC",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "ADATUSD",
        balas: "TUSD",
        trdas: "ADA",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "TRXTUSD",
        balas: "TUSD",
        trdas: "TRX",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "NEOTUSD",
        balas: "TUSD",
        trdas: "NEO",
        scnt: 1,
        pdec: 3,
        sdec: 3
      },
      {
        tk: "TRXXRP",
        balas: "XRP",
        trdas: "TRX",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "XZCXRP",
        balas: "XRP",
        trdas: "XZC",
        scnt: 1,
        pdec: 3,
        sdec: 3
      },
      {
        tk: "PAXTUSD",
        balas: "TUSD",
        trdas: "PAX",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "USDCTUSD",
        balas: "TUSD",
        trdas: "USDC",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "USDCPAX",
        balas: "PAX",
        trdas: "USDC",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "LINKUSDT",
        balas: "USDT",
        trdas: "LINK",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "LINKTUSD",
        balas: "TUSD",
        trdas: "LINK",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "LINKPAX",
        balas: "PAX",
        trdas: "LINK",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "LINKUSDC",
        balas: "USDC",
        trdas: "LINK",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "WAVESUSDT",
        balas: "USDT",
        trdas: "WAVES",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "WAVESTUSD",
        balas: "TUSD",
        trdas: "WAVES",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "WAVESPAX",
        balas: "PAX",
        trdas: "WAVES",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "WAVESUSDC",
        balas: "USDC",
        trdas: "WAVES",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "BCHABCTUSD",
        balas: "TUSD",
        trdas: "BCHABC",
        scnt: 1,
        pdec: 2,
        sdec: 5
      },
      {
        tk: "BCHABCPAX",
        balas: "PAX",
        trdas: "BCHABC",
        scnt: 1,
        pdec: 2,
        sdec: 5
      },
      {
        tk: "BCHABCUSDC",
        balas: "USDC",
        trdas: "BCHABC",
        scnt: 1,
        pdec: 2,
        sdec: 5
      },
      {
        tk: "BCHSVTUSD",
        balas: "TUSD",
        trdas: "BCHSV",
        scnt: 1,
        pdec: 2,
        sdec: 5
      },
      {
        tk: "BCHSVPAX",
        balas: "PAX",
        trdas: "BCHSV",
        scnt: 1,
        pdec: 2,
        sdec: 5
      },
      {
        tk: "BCHSVUSDC",
        balas: "USDC",
        trdas: "BCHSV",
        scnt: 1,
        pdec: 2,
        sdec: 5
      },
      {
        tk: "LTCTUSD",
        balas: "TUSD",
        trdas: "LTC",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "LTCPAX",
        balas: "PAX",
        trdas: "LTC",
        scnt: 1,
        pdec: 2,
        sdec: 5
      },
      {
        tk: "LTCUSDC",
        balas: "USDC",
        trdas: "LTC",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "TRXPAX",
        balas: "PAX",
        trdas: "TRX",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "TRXUSDC",
        balas: "USDC",
        trdas: "TRX",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "BTTBTC",
        balas: "BTC",
        trdas: "BTT",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "BTTBNB",
        balas: "BNB",
        trdas: "BTT",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "BTTUSDT",
        balas: "USDT",
        trdas: "BTT",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "BNBUSDS",
        balas: "USDS",
        trdas: "BNB",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "BTCUSDS",
        balas: "USDS",
        trdas: "BTC",
        scnt: 1,
        pdec: 2,
        sdec: 6
      },
      {
        tk: "USDSUSDT",
        balas: "USDT",
        trdas: "USDS",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "USDSPAX",
        balas: "PAX",
        trdas: "USDS",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "USDSTUSD",
        balas: "TUSD",
        trdas: "USDS",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "USDSUSDC",
        balas: "USDC",
        trdas: "USDS",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "BTTPAX",
        balas: "PAX",
        trdas: "BTT",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "BTTTUSD",
        balas: "TUSD",
        trdas: "BTT",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "BTTUSDC",
        balas: "USDC",
        trdas: "BTT",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "ONGBNB",
        balas: "BNB",
        trdas: "ONG",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "ONGBTC",
        balas: "BTC",
        trdas: "ONG",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "ONGUSDT",
        balas: "USDT",
        trdas: "ONG",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "HOTBNB",
        balas: "BNB",
        trdas: "HOT",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "HOTUSDT",
        balas: "USDT",
        trdas: "HOT",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "ZILUSDT",
        balas: "USDT",
        trdas: "ZIL",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "ZRXBNB",
        balas: "BNB",
        trdas: "ZRX",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "ZRXUSDT",
        balas: "USDT",
        trdas: "ZRX",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "FETBNB",
        balas: "BNB",
        trdas: "FET",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "FETBTC",
        balas: "BTC",
        trdas: "FET",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "FETUSDT",
        balas: "USDT",
        trdas: "FET",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "BATUSDT",
        balas: "USDT",
        trdas: "BAT",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "XMRBNB",
        balas: "BNB",
        trdas: "XMR",
        scnt: 1,
        pdec: 4,
        sdec: 3
      },
      {
        tk: "XMRUSDT",
        balas: "USDT",
        trdas: "XMR",
        scnt: 1,
        pdec: 1,
        sdec: 3
      },
      {
        tk: "ZECBNB",
        balas: "BNB",
        trdas: "ZEC",
        scnt: 1,
        pdec: 4,
        sdec: 3
      },
      {
        tk: "ZECUSDT",
        balas: "USDT",
        trdas: "ZEC",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "ZECPAX",
        balas: "PAX",
        trdas: "ZEC",
        scnt: 1,
        pdec: 2,
        sdec: 5
      },
      {
        tk: "ZECTUSD",
        balas: "TUSD",
        trdas: "ZEC",
        scnt: 1,
        pdec: 2,
        sdec: 5
      },
      {
        tk: "ZECUSDC",
        balas: "USDC",
        trdas: "ZEC",
        scnt: 1,
        pdec: 1,
        sdec: 3
      },
      {
        tk: "IOSTUSDT",
        balas: "USDT",
        trdas: "IOST",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "CELRBNB",
        balas: "BNB",
        trdas: "CELR",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "CELRBTC",
        balas: "BTC",
        trdas: "CELR",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "CELRUSDT",
        balas: "USDT",
        trdas: "CELR",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "ADAPAX",
        balas: "PAX",
        trdas: "ADA",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "ADAUSDC",
        balas: "USDC",
        trdas: "ADA",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "NEOPAX",
        balas: "PAX",
        trdas: "NEO",
        scnt: 1,
        pdec: 3,
        sdec: 3
      },
      {
        tk: "NEOUSDC",
        balas: "USDC",
        trdas: "NEO",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "DASHBNB",
        balas: "BNB",
        trdas: "DASH",
        scnt: 1,
        pdec: 4,
        sdec: 3
      },
      {
        tk: "DASHUSDT",
        balas: "USDT",
        trdas: "DASH",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "NANOUSDT",
        balas: "USDT",
        trdas: "NANO",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "OMGBNB",
        balas: "BNB",
        trdas: "OMG",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "OMGUSDT",
        balas: "USDT",
        trdas: "OMG",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "THETAUSDT",
        balas: "USDT",
        trdas: "THETA",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "ENJUSDT",
        balas: "USDT",
        trdas: "ENJ",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "MITHUSDT",
        balas: "USDT",
        trdas: "MITH",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "MATICBNB",
        balas: "BNB",
        trdas: "MATIC",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "MATICBTC",
        balas: "BTC",
        trdas: "MATIC",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "MATICUSDT",
        balas: "USDT",
        trdas: "MATIC",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "ATOMBNB",
        balas: "BNB",
        trdas: "ATOM",
        scnt: 1,
        pdec: 5,
        sdec: 2
      },
      {
        tk: "ATOMBTC",
        balas: "BTC",
        trdas: "ATOM",
        scnt: 1,
        pdec: 7,
        sdec: 2
      },
      {
        tk: "ATOMUSDT",
        balas: "USDT",
        trdas: "ATOM",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "ATOMUSDC",
        balas: "USDC",
        trdas: "ATOM",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "ATOMPAX",
        balas: "PAX",
        trdas: "ATOM",
        scnt: 1,
        pdec: 3,
        sdec: 3
      },
      {
        tk: "ATOMTUSD",
        balas: "TUSD",
        trdas: "ATOM",
        scnt: 1,
        pdec: 3,
        sdec: 3
      },
      {
        tk: "ETCUSDC",
        balas: "USDC",
        trdas: "ETC",
        scnt: 1,
        pdec: 3,
        sdec: 3
      },
      {
        tk: "ETCPAX",
        balas: "PAX",
        trdas: "ETC",
        scnt: 1,
        pdec: 3,
        sdec: 3
      },
      {
        tk: "ETCTUSD",
        balas: "TUSD",
        trdas: "ETC",
        scnt: 1,
        pdec: 3,
        sdec: 3
      },
      {
        tk: "BATUSDC",
        balas: "USDC",
        trdas: "BAT",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "BATPAX",
        balas: "PAX",
        trdas: "BAT",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "BATTUSD",
        balas: "TUSD",
        trdas: "BAT",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "PHBBNB",
        balas: "BNB",
        trdas: "PHB",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "PHBBTC",
        balas: "BTC",
        trdas: "PHB",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "PHBUSDC",
        balas: "USDC",
        trdas: "PHB",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "PHBTUSD",
        balas: "TUSD",
        trdas: "PHB",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "PHBPAX",
        balas: "PAX",
        trdas: "PHB",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "TFUELBNB",
        balas: "BNB",
        trdas: "TFUEL",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "TFUELBTC",
        balas: "BTC",
        trdas: "TFUEL",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "TFUELUSDT",
        balas: "USDT",
        trdas: "TFUEL",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "TFUELUSDC",
        balas: "USDC",
        trdas: "TFUEL",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "TFUELTUSD",
        balas: "TUSD",
        trdas: "TFUEL",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "TFUELPAX",
        balas: "PAX",
        trdas: "TFUEL",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "ONEBNB",
        balas: "BNB",
        trdas: "ONE",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "ONEBTC",
        balas: "BTC",
        trdas: "ONE",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "ONEUSDT",
        balas: "USDT",
        trdas: "ONE",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "ONETUSD",
        balas: "TUSD",
        trdas: "ONE",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "ONEPAX",
        balas: "PAX",
        trdas: "ONE",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "ONEUSDC",
        balas: "USDC",
        trdas: "ONE",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "FTMBNB",
        balas: "BNB",
        trdas: "FTM",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "FTMBTC",
        balas: "BTC",
        trdas: "FTM",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "FTMUSDT",
        balas: "USDT",
        trdas: "FTM",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "FTMTUSD",
        balas: "TUSD",
        trdas: "FTM",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "FTMPAX",
        balas: "PAX",
        trdas: "FTM",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "FTMUSDC",
        balas: "USDC",
        trdas: "FTM",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "BTCBBTC",
        balas: "BTC",
        trdas: "BTCB",
        scnt: 1,
        pdec: 6,
        sdec: 3
      },
      {
        tk: "BCPTTUSD",
        balas: "TUSD",
        trdas: "BCPT",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "BCPTPAX",
        balas: "PAX",
        trdas: "BCPT",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "BCPTUSDC",
        balas: "USDC",
        trdas: "BCPT",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "ALGOBNB",
        balas: "BNB",
        trdas: "ALGO",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "ALGOBTC",
        balas: "BTC",
        trdas: "ALGO",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "ALGOUSDT",
        balas: "USDT",
        trdas: "ALGO",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "ALGOTUSD",
        balas: "TUSD",
        trdas: "ALGO",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "ALGOPAX",
        balas: "PAX",
        trdas: "ALGO",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "ALGOUSDC",
        balas: "USDC",
        trdas: "ALGO",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "USDSBUSDT",
        balas: "USDT",
        trdas: "USDSB",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "USDSBUSDS",
        balas: "USDS",
        trdas: "USDSB",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "GTOUSDT",
        balas: "USDT",
        trdas: "GTO",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "GTOPAX",
        balas: "PAX",
        trdas: "GTO",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "GTOTUSD",
        balas: "TUSD",
        trdas: "GTO",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "GTOUSDC",
        balas: "USDC",
        trdas: "GTO",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "ERDBNB",
        balas: "BNB",
        trdas: "ERD",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "ERDBTC",
        balas: "BTC",
        trdas: "ERD",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "ERDUSDT",
        balas: "USDT",
        trdas: "ERD",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "ERDPAX",
        balas: "PAX",
        trdas: "ERD",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "ERDUSDC",
        balas: "USDC",
        trdas: "ERD",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "DOGEBNB",
        balas: "BNB",
        trdas: "DOGE",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "DOGEBTC",
        balas: "BTC",
        trdas: "DOGE",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "DOGEUSDT",
        balas: "USDT",
        trdas: "DOGE",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "DOGEPAX",
        balas: "PAX",
        trdas: "DOGE",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "DOGEUSDC",
        balas: "USDC",
        trdas: "DOGE",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "DUSKBNB",
        balas: "BNB",
        trdas: "DUSK",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "DUSKBTC",
        balas: "BTC",
        trdas: "DUSK",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "DUSKUSDT",
        balas: "USDT",
        trdas: "DUSK",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "DUSKUSDC",
        balas: "USDC",
        trdas: "DUSK",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "DUSKPAX",
        balas: "PAX",
        trdas: "DUSK",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "BGBPUSDC",
        balas: "USDC",
        trdas: "BGBP",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "ANKRBNB",
        balas: "BNB",
        trdas: "ANKR",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "ANKRBTC",
        balas: "BTC",
        trdas: "ANKR",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "ANKRUSDT",
        balas: "USDT",
        trdas: "ANKR",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "ANKRTUSD",
        balas: "TUSD",
        trdas: "ANKR",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "ANKRPAX",
        balas: "PAX",
        trdas: "ANKR",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "ANKRUSDC",
        balas: "USDC",
        trdas: "ANKR",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "ONTPAX",
        balas: "PAX",
        trdas: "ONT",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "ONTUSDC",
        balas: "USDC",
        trdas: "ONT",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "WINBNB",
        balas: "BNB",
        trdas: "WIN",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "WINBTC",
        balas: "BTC",
        trdas: "WIN",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "WINUSDT",
        balas: "USDT",
        trdas: "WIN",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "WINUSDC",
        balas: "USDC",
        trdas: "WIN",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "COSBNB",
        balas: "BNB",
        trdas: "COS",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "COSBTC",
        balas: "BTC",
        trdas: "COS",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "COSUSDT",
        balas: "USDT",
        trdas: "COS",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "TUSDBTUSD",
        balas: "TUSD",
        trdas: "TUSDB",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "NPXSUSDT",
        balas: "USDT",
        trdas: "NPXS",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "NPXSUSDC",
        balas: "USDC",
        trdas: "NPXS",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "COCOSBNB",
        balas: "BNB",
        trdas: "COCOS",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "COCOSBTC",
        balas: "BTC",
        trdas: "COCOS",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "COCOSUSDT",
        balas: "USDT",
        trdas: "COCOS",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "MTLUSDT",
        balas: "USDT",
        trdas: "MTL",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "TOMOBNB",
        balas: "BNB",
        trdas: "TOMO",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "TOMOBTC",
        balas: "BTC",
        trdas: "TOMO",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "TOMOUSDT",
        balas: "USDT",
        trdas: "TOMO",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "TOMOUSDC",
        balas: "USDC",
        trdas: "TOMO",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "PERLBNB",
        balas: "BNB",
        trdas: "PERL",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "PERLBTC",
        balas: "BTC",
        trdas: "PERL",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "PERLUSDC",
        balas: "USDC",
        trdas: "PERL",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "PERLUSDT",
        balas: "USDT",
        trdas: "PERL",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "DENTUSDT",
        balas: "USDT",
        trdas: "DENT",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "MFTUSDT",
        balas: "USDT",
        trdas: "MFT",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "KEYUSDT",
        balas: "USDT",
        trdas: "KEY",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "STORMUSDT",
        balas: "USDT",
        trdas: "STORM",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "DOCKUSDT",
        balas: "USDT",
        trdas: "DOCK",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "WANUSDT",
        balas: "USDT",
        trdas: "WAN",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "FUNUSDT",
        balas: "USDT",
        trdas: "FUN",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "CVCUSDT",
        balas: "USDT",
        trdas: "CVC",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "BTTTRX",
        balas: "TRX",
        trdas: "BTT",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "WINTRX",
        balas: "TRX",
        trdas: "WIN",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "CHZBNB",
        balas: "BNB",
        trdas: "CHZ",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "CHZBTC",
        balas: "BTC",
        trdas: "CHZ",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "CHZUSDT",
        balas: "USDT",
        trdas: "CHZ",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "BANDBNB",
        balas: "BNB",
        trdas: "BAND",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "BANDBTC",
        balas: "BTC",
        trdas: "BAND",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "BANDUSDT",
        balas: "USDT",
        trdas: "BAND",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "BNBBUSD",
        balas: "BUSD",
        trdas: "BNB",
        scnt: 1,
        pdec: 1,
        sdec: 3
      },
      {
        tk: "BTCBUSD",
        balas: "BUSD",
        trdas: "BTC",
        scnt: 1,
        pdec: 2,
        sdec: 5
      },
      {
        tk: "BUSDUSDT",
        balas: "USDT",
        trdas: "BUSD",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "BEAMBNB",
        balas: "BNB",
        trdas: "BEAM",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "BEAMBTC",
        balas: "BTC",
        trdas: "BEAM",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "BEAMUSDT",
        balas: "USDT",
        trdas: "BEAM",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "XTZBNB",
        balas: "BNB",
        trdas: "XTZ",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "XTZBTC",
        balas: "BTC",
        trdas: "XTZ",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "XTZUSDT",
        balas: "USDT",
        trdas: "XTZ",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "RENUSDT",
        balas: "USDT",
        trdas: "REN",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "RVNUSDT",
        balas: "USDT",
        trdas: "RVN",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "HCUSDT",
        balas: "USDT",
        trdas: "HC",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "HBARBNB",
        balas: "BNB",
        trdas: "HBAR",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "HBARBTC",
        balas: "BTC",
        trdas: "HBAR",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "HBARUSDT",
        balas: "USDT",
        trdas: "HBAR",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "NKNBNB",
        balas: "BNB",
        trdas: "NKN",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "NKNBTC",
        balas: "BTC",
        trdas: "NKN",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "NKNUSDT",
        balas: "USDT",
        trdas: "NKN",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "XRPBUSD",
        balas: "BUSD",
        trdas: "XRP",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "ETHBUSD",
        balas: "BUSD",
        trdas: "ETH",
        scnt: 1,
        pdec: 2,
        sdec: 4
      },
      {
        tk: "BCHABCBUSD",
        balas: "BUSD",
        trdas: "BCHABC",
        scnt: 1,
        pdec: 2,
        sdec: 5
      },
      {
        tk: "LTCBUSD",
        balas: "BUSD",
        trdas: "LTC",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "LINKBUSD",
        balas: "BUSD",
        trdas: "LINK",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "ETCBUSD",
        balas: "BUSD",
        trdas: "ETC",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "STXBNB",
        balas: "BNB",
        trdas: "STX",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "STXBTC",
        balas: "BTC",
        trdas: "STX",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "STXUSDT",
        balas: "USDT",
        trdas: "STX",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "KAVABNB",
        balas: "BNB",
        trdas: "KAVA",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "KAVABTC",
        balas: "BTC",
        trdas: "KAVA",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "KAVAUSDT",
        balas: "USDT",
        trdas: "KAVA",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "BUSDNGN",
        balas: "NGN",
        trdas: "BUSD",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "BNBNGN",
        balas: "NGN",
        trdas: "BNB",
        scnt: 1,
        pdec: 0,
        sdec: 3
      },
      {
        tk: "BTCNGN",
        balas: "NGN",
        trdas: "BTC",
        scnt: 1,
        pdec: 0,
        sdec: 5
      },
      {
        tk: "ARPABNB",
        balas: "BNB",
        trdas: "ARPA",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "ARPABTC",
        balas: "BTC",
        trdas: "ARPA",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "ARPAUSDT",
        balas: "USDT",
        trdas: "ARPA",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "TRXBUSD",
        balas: "BUSD",
        trdas: "TRX",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "EOSBUSD",
        balas: "BUSD",
        trdas: "EOS",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "IOTXUSDT",
        balas: "USDT",
        trdas: "IOTX",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "RLCUSDT",
        balas: "USDT",
        trdas: "RLC",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "MCOUSDT",
        balas: "USDT",
        trdas: "MCO",
        scnt: 1,
        pdec: 3,
        sdec: 3
      },
      {
        tk: "XLMBUSD",
        balas: "BUSD",
        trdas: "XLM",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "ADABUSD",
        balas: "BUSD",
        trdas: "ADA",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "CTXCBNB",
        balas: "BNB",
        trdas: "CTXC",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "CTXCBTC",
        balas: "BTC",
        trdas: "CTXC",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "CTXCUSDT",
        balas: "USDT",
        trdas: "CTXC",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "BCHBNB",
        balas: "BNB",
        trdas: "BCH",
        scnt: 1,
        pdec: 3,
        sdec: 3
      },
      {
        tk: "BCHBTC",
        balas: "BTC",
        trdas: "BCH",
        scnt: 1,
        pdec: 6,
        sdec: 3
      },
      {
        tk: "BCHUSDT",
        balas: "USDT",
        trdas: "BCH",
        scnt: 1,
        pdec: 1,
        sdec: 3
      },
      {
        tk: "BCHUSDC",
        balas: "USDC",
        trdas: "BCH",
        scnt: 1,
        pdec: 1,
        sdec: 3
      },
      {
        tk: "BCHTUSD",
        balas: "TUSD",
        trdas: "BCH",
        scnt: 1,
        pdec: 1,
        sdec: 3
      },
      {
        tk: "BCHPAX",
        balas: "PAX",
        trdas: "BCH",
        scnt: 1,
        pdec: 2,
        sdec: 5
      },
      {
        tk: "BCHBUSD",
        balas: "BUSD",
        trdas: "BCH",
        scnt: 1,
        pdec: 1,
        sdec: 3
      },
      {
        tk: "BTCRUB",
        balas: "RUB",
        trdas: "BTC",
        scnt: 1,
        pdec: 0,
        sdec: 5
      },
      {
        tk: "ETHRUB",
        balas: "RUB",
        trdas: "ETH",
        scnt: 1,
        pdec: 1,
        sdec: 4
      },
      {
        tk: "XRPRUB",
        balas: "RUB",
        trdas: "XRP",
        scnt: 1,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "BNBRUB",
        balas: "RUB",
        trdas: "BNB",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "TROYBNB",
        balas: "BNB",
        trdas: "TROY",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "TROYBTC",
        balas: "BTC",
        trdas: "TROY",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "TROYUSDT",
        balas: "USDT",
        trdas: "TROY",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "BUSDRUB",
        balas: "RUB",
        trdas: "BUSD",
        scnt: 1,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "QTUMBUSD",
        balas: "BUSD",
        trdas: "QTUM",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "VETBUSD",
        balas: "BUSD",
        trdas: "VET",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "VITEBNB",
        balas: "BNB",
        trdas: "VITE",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "VITEBTC",
        balas: "BTC",
        trdas: "VITE",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "VITEUSDT",
        balas: "USDT",
        trdas: "VITE",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "FTTBNB",
        balas: "BNB",
        trdas: "FTT",
        scnt: 1,
        pdec: 5,
        sdec: 2
      },
      {
        tk: "FTTBTC",
        balas: "BTC",
        trdas: "FTT",
        scnt: 1,
        pdec: 7,
        sdec: 2
      },
      {
        tk: "FTTUSDT",
        balas: "USDT",
        trdas: "FTT",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "BTCTRY",
        balas: "TRY",
        trdas: "BTC",
        scnt: 1,
        pdec: 0,
        sdec: 5
      },
      {
        tk: "BNBTRY",
        balas: "TRY",
        trdas: "BNB",
        scnt: 1,
        pdec: 0,
        sdec: 3
      },
      {
        tk: "BUSDTRY",
        balas: "TRY",
        trdas: "BUSD",
        scnt: 1,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "ETHTRY",
        balas: "TRY",
        trdas: "ETH",
        scnt: 1,
        pdec: 0,
        sdec: 4
      },
      {
        tk: "XRPTRY",
        balas: "TRY",
        trdas: "XRP",
        scnt: 1,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "USDTTRY",
        balas: "TRY",
        trdas: "USDT",
        scnt: 1,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "USDTRUB",
        balas: "RUB",
        trdas: "USDT",
        scnt: 1,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "BTCEUR",
        balas: "EUR",
        trdas: "BTC",
        scnt: 1,
        pdec: 2,
        sdec: 5
      },
      {
        tk: "ETHEUR",
        balas: "EUR",
        trdas: "ETH",
        scnt: 1,
        pdec: 2,
        sdec: 4
      },
      {
        tk: "BNBEUR",
        balas: "EUR",
        trdas: "BNB",
        scnt: 1,
        pdec: 1,
        sdec: 3
      },
      {
        tk: "XRPEUR",
        balas: "EUR",
        trdas: "XRP",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "EURBUSD",
        balas: "BUSD",
        trdas: "EUR",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "EURUSDT",
        balas: "USDT",
        trdas: "EUR",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "OGNBNB",
        balas: "BNB",
        trdas: "OGN",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "OGNBTC",
        balas: "BTC",
        trdas: "OGN",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "OGNUSDT",
        balas: "USDT",
        trdas: "OGN",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "DREPBNB",
        balas: "BNB",
        trdas: "DREP",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "DREPBTC",
        balas: "BTC",
        trdas: "DREP",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "DREPUSDT",
        balas: "USDT",
        trdas: "DREP",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "BULLUSDT",
        balas: "USDT",
        trdas: "BULL",
        scnt: 1,
        pdec: 2,
        sdec: 6
      },
      {
        tk: "BULLBUSD",
        balas: "BUSD",
        trdas: "BULL",
        scnt: 1,
        pdec: 2,
        sdec: 6
      },
      {
        tk: "BEARUSDT",
        balas: "USDT",
        trdas: "BEAR",
        scnt: 1,
        pdec: 2,
        sdec: 5
      },
      {
        tk: "BEARBUSD",
        balas: "BUSD",
        trdas: "BEAR",
        scnt: 1,
        pdec: 2,
        sdec: 5
      },
      {
        tk: "ETHBULLUSDT",
        balas: "USDT",
        trdas: "ETHBULL",
        scnt: 1,
        pdec: 2,
        sdec: 6
      },
      {
        tk: "ETHBULLBUSD",
        balas: "BUSD",
        trdas: "ETHBULL",
        scnt: 1,
        pdec: 2,
        sdec: 6
      },
      {
        tk: "ETHBEARUSDT",
        balas: "USDT",
        trdas: "ETHBEAR",
        scnt: 1,
        pdec: 2,
        sdec: 5
      },
      {
        tk: "ETHBEARBUSD",
        balas: "BUSD",
        trdas: "ETHBEAR",
        scnt: 1,
        pdec: 2,
        sdec: 5
      },
      {
        tk: "TCTBNB",
        balas: "BNB",
        trdas: "TCT",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "TCTBTC",
        balas: "BTC",
        trdas: "TCT",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "TCTUSDT",
        balas: "USDT",
        trdas: "TCT",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "WRXBNB",
        balas: "BNB",
        trdas: "WRX",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "WRXBTC",
        balas: "BTC",
        trdas: "WRX",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "WRXUSDT",
        balas: "USDT",
        trdas: "WRX",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "ICXBUSD",
        balas: "BUSD",
        trdas: "ICX",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "BTSUSDT",
        balas: "USDT",
        trdas: "BTS",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "BTSBUSD",
        balas: "BUSD",
        trdas: "BTS",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "LSKUSDT",
        balas: "USDT",
        trdas: "LSK",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "BNTUSDT",
        balas: "USDT",
        trdas: "BNT",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "BNTBUSD",
        balas: "BUSD",
        trdas: "BNT",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "LTOBNB",
        balas: "BNB",
        trdas: "LTO",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "LTOBTC",
        balas: "BTC",
        trdas: "LTO",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "LTOUSDT",
        balas: "USDT",
        trdas: "LTO",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "ATOMBUSD",
        balas: "BUSD",
        trdas: "ATOM",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "DASHBUSD",
        balas: "BUSD",
        trdas: "DASH",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "NEOBUSD",
        balas: "BUSD",
        trdas: "NEO",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "WAVESBUSD",
        balas: "BUSD",
        trdas: "WAVES",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "XTZBUSD",
        balas: "BUSD",
        trdas: "XTZ",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "EOSBULLUSDT",
        balas: "USDT",
        trdas: "EOSBULL",
        scnt: 1,
        pdec: 2,
        sdec: 5
      },
      {
        tk: "EOSBULLBUSD",
        balas: "BUSD",
        trdas: "EOSBULL",
        scnt: 1,
        pdec: 2,
        sdec: 5
      },
      {
        tk: "EOSBEARUSDT",
        balas: "USDT",
        trdas: "EOSBEAR",
        scnt: 1,
        pdec: 2,
        sdec: 5
      },
      {
        tk: "EOSBEARBUSD",
        balas: "BUSD",
        trdas: "EOSBEAR",
        scnt: 1,
        pdec: 2,
        sdec: 5
      },
      {
        tk: "XRPBULLUSDT",
        balas: "USDT",
        trdas: "XRPBULL",
        scnt: 1,
        pdec: 2,
        sdec: 5
      },
      {
        tk: "XRPBULLBUSD",
        balas: "BUSD",
        trdas: "XRPBULL",
        scnt: 1,
        pdec: 2,
        sdec: 5
      },
      {
        tk: "XRPBEARUSDT",
        balas: "USDT",
        trdas: "XRPBEAR",
        scnt: 1,
        pdec: 2,
        sdec: 5
      },
      {
        tk: "XRPBEARBUSD",
        balas: "BUSD",
        trdas: "XRPBEAR",
        scnt: 1,
        pdec: 2,
        sdec: 5
      },
      {
        tk: "BATBUSD",
        balas: "BUSD",
        trdas: "BAT",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "ENJBUSD",
        balas: "BUSD",
        trdas: "ENJ",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "NANOBUSD",
        balas: "BUSD",
        trdas: "NANO",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "ONTBUSD",
        balas: "BUSD",
        trdas: "ONT",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "RVNBUSD",
        balas: "BUSD",
        trdas: "RVN",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "STRATBUSD",
        balas: "BUSD",
        trdas: "STRAT",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "STRATBNB",
        balas: "BNB",
        trdas: "STRAT",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "STRATUSDT",
        balas: "USDT",
        trdas: "STRAT",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "AIONBUSD",
        balas: "BUSD",
        trdas: "AION",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "AIONUSDT",
        balas: "USDT",
        trdas: "AION",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "MBLBNB",
        balas: "BNB",
        trdas: "MBL",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "MBLBTC",
        balas: "BTC",
        trdas: "MBL",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "MBLUSDT",
        balas: "USDT",
        trdas: "MBL",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "COTIBNB",
        balas: "BNB",
        trdas: "COTI",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "COTIBTC",
        balas: "BTC",
        trdas: "COTI",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "COTIUSDT",
        balas: "USDT",
        trdas: "COTI",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "ALGOBUSD",
        balas: "BUSD",
        trdas: "ALGO",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "BTTBUSD",
        balas: "BUSD",
        trdas: "BTT",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "TOMOBUSD",
        balas: "BUSD",
        trdas: "TOMO",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "XMRBUSD",
        balas: "BUSD",
        trdas: "XMR",
        scnt: 1,
        pdec: 1,
        sdec: 3
      },
      {
        tk: "ZECBUSD",
        balas: "BUSD",
        trdas: "ZEC",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "BNBBULLUSDT",
        balas: "USDT",
        trdas: "BNBBULL",
        scnt: 1,
        pdec: 2,
        sdec: 5
      },
      {
        tk: "BNBBULLBUSD",
        balas: "BUSD",
        trdas: "BNBBULL",
        scnt: 1,
        pdec: 2,
        sdec: 5
      },
      {
        tk: "BNBBEARUSDT",
        balas: "USDT",
        trdas: "BNBBEAR",
        scnt: 1,
        pdec: 2,
        sdec: 5
      },
      {
        tk: "BNBBEARBUSD",
        balas: "BUSD",
        trdas: "BNBBEAR",
        scnt: 1,
        pdec: 2,
        sdec: 5
      },
      {
        tk: "STPTBNB",
        balas: "BNB",
        trdas: "STPT",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "STPTBTC",
        balas: "BTC",
        trdas: "STPT",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "STPTUSDT",
        balas: "USDT",
        trdas: "STPT",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "BTCZAR",
        balas: "ZAR",
        trdas: "BTC",
        scnt: 1,
        pdec: 0,
        sdec: 5
      },
      {
        tk: "ETHZAR",
        balas: "ZAR",
        trdas: "ETH",
        scnt: 1,
        pdec: 0,
        sdec: 4
      },
      {
        tk: "BNBZAR",
        balas: "ZAR",
        trdas: "BNB",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "USDTZAR",
        balas: "ZAR",
        trdas: "USDT",
        scnt: 1,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "BUSDZAR",
        balas: "ZAR",
        trdas: "BUSD",
        scnt: 1,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "BTCBKRW",
        balas: "BKRW",
        trdas: "BTC",
        scnt: 1,
        pdec: 0,
        sdec: 6
      },
      {
        tk: "ETHBKRW",
        balas: "BKRW",
        trdas: "ETH",
        scnt: 1,
        pdec: 0,
        sdec: 5
      },
      {
        tk: "BNBBKRW",
        balas: "BKRW",
        trdas: "BNB",
        scnt: 1,
        pdec: 0,
        sdec: 3
      },
      {
        tk: "WTCUSDT",
        balas: "USDT",
        trdas: "WTC",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "DATABUSD",
        balas: "BUSD",
        trdas: "DATA",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "DATAUSDT",
        balas: "USDT",
        trdas: "DATA",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "XZCUSDT",
        balas: "USDT",
        trdas: "XZC",
        scnt: 1,
        pdec: 3,
        sdec: 3
      },
      {
        tk: "SOLBNB",
        balas: "BNB",
        trdas: "SOL",
        scnt: 1,
        pdec: 4,
        sdec: 3
      },
      {
        tk: "SOLBTC",
        balas: "BTC",
        trdas: "SOL",
        scnt: 1,
        pdec: 7,
        sdec: 3
      },
      {
        tk: "SOLUSDT",
        balas: "USDT",
        trdas: "SOL",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "SOLBUSD",
        balas: "BUSD",
        trdas: "SOL",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "BTCIDRT",
        balas: "IDRT",
        trdas: "BTC",
        scnt: 1,
        pdec: 0,
        sdec: 5
      },
      {
        tk: "BNBIDRT",
        balas: "IDRT",
        trdas: "BNB",
        scnt: 1,
        pdec: 0,
        sdec: 3
      },
      {
        tk: "USDTIDRT",
        balas: "IDRT",
        trdas: "USDT",
        scnt: 1,
        pdec: 0,
        sdec: 0
      },
      {
        tk: "BUSDIDRT",
        balas: "IDRT",
        trdas: "BUSD",
        scnt: 1,
        pdec: 0,
        sdec: 2
      },
      {
        tk: "CTSIBTC",
        balas: "BTC",
        trdas: "CTSI",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "CTSIUSDT",
        balas: "USDT",
        trdas: "CTSI",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "CTSIBNB",
        balas: "BNB",
        trdas: "CTSI",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "CTSIBUSD",
        balas: "BUSD",
        trdas: "CTSI",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "HIVEBNB",
        balas: "BNB",
        trdas: "HIVE",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "HIVEBTC",
        balas: "BTC",
        trdas: "HIVE",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "HIVEUSDT",
        balas: "USDT",
        trdas: "HIVE",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "CHRBNB",
        balas: "BNB",
        trdas: "CHR",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "CHRBTC",
        balas: "BTC",
        trdas: "CHR",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "CHRUSDT",
        balas: "USDT",
        trdas: "CHR",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "BTCUPUSDT",
        balas: "USDT",
        trdas: "BTCUP",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "BTCDOWNUSDT",
        balas: "USDT",
        trdas: "BTCDOWN",
        scnt: 1,
        pdec: 7,
        sdec: 2
      },
      {
        tk: "GXSUSDT",
        balas: "USDT",
        trdas: "GXS",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "ARDRUSDT",
        balas: "USDT",
        trdas: "ARDR",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "ERDBUSD",
        balas: "BUSD",
        trdas: "ERD",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "LENDUSDT",
        balas: "USDT",
        trdas: "LEND",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "HBARBUSD",
        balas: "BUSD",
        trdas: "HBAR",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "MATICBUSD",
        balas: "BUSD",
        trdas: "MATIC",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "WRXBUSD",
        balas: "BUSD",
        trdas: "WRX",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "ZILBUSD",
        balas: "BUSD",
        trdas: "ZIL",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "MDTBNB",
        balas: "BNB",
        trdas: "MDT",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "MDTBTC",
        balas: "BTC",
        trdas: "MDT",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "MDTUSDT",
        balas: "USDT",
        trdas: "MDT",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "STMXBTC",
        balas: "BTC",
        trdas: "STMX",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "STMXETH",
        balas: "ETH",
        trdas: "STMX",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "STMXUSDT",
        balas: "USDT",
        trdas: "STMX",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "KNCBUSD",
        balas: "BUSD",
        trdas: "KNC",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "KNCUSDT",
        balas: "USDT",
        trdas: "KNC",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "REPBUSD",
        balas: "BUSD",
        trdas: "REP",
        scnt: 1,
        pdec: 3,
        sdec: 3
      },
      {
        tk: "REPUSDT",
        balas: "USDT",
        trdas: "REP",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "LRCBUSD",
        balas: "BUSD",
        trdas: "LRC",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "LRCUSDT",
        balas: "USDT",
        trdas: "LRC",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "IQBNB",
        balas: "BNB",
        trdas: "IQ",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "IQBUSD",
        balas: "BUSD",
        trdas: "IQ",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "PNTBTC",
        balas: "BTC",
        trdas: "PNT",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "PNTUSDT",
        balas: "USDT",
        trdas: "PNT",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "BTCGBP",
        balas: "GBP",
        trdas: "BTC",
        scnt: 1,
        pdec: 2,
        sdec: 5
      },
      {
        tk: "ETHGBP",
        balas: "GBP",
        trdas: "ETH",
        scnt: 1,
        pdec: 2,
        sdec: 4
      },
      {
        tk: "XRPGBP",
        balas: "GBP",
        trdas: "XRP",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "BNBGBP",
        balas: "GBP",
        trdas: "BNB",
        scnt: 1,
        pdec: 1,
        sdec: 3
      },
      {
        tk: "GBPBUSD",
        balas: "BUSD",
        trdas: "GBP",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "DGBBTC",
        balas: "BTC",
        trdas: "DGB",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "DGBBUSD",
        balas: "BUSD",
        trdas: "DGB",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "BTCUAH",
        balas: "UAH",
        trdas: "BTC",
        scnt: 1,
        pdec: 0,
        sdec: 5
      },
      {
        tk: "USDTUAH",
        balas: "UAH",
        trdas: "USDT",
        scnt: 1,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "COMPBTC",
        balas: "BTC",
        trdas: "COMP",
        scnt: 1,
        pdec: 6,
        sdec: 3
      },
      {
        tk: "COMPBNB",
        balas: "BNB",
        trdas: "COMP",
        scnt: 1,
        pdec: 3,
        sdec: 3
      },
      {
        tk: "COMPBUSD",
        balas: "BUSD",
        trdas: "COMP",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "COMPUSDT",
        balas: "USDT",
        trdas: "COMP",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "BTCBIDR",
        balas: "BIDR",
        trdas: "BTC",
        scnt: 1,
        pdec: 0,
        sdec: 5
      },
      {
        tk: "ETHBIDR",
        balas: "BIDR",
        trdas: "ETH",
        scnt: 1,
        pdec: 0,
        sdec: 4
      },
      {
        tk: "BNBBIDR",
        balas: "BIDR",
        trdas: "BNB",
        scnt: 1,
        pdec: 0,
        sdec: 3
      },
      {
        tk: "BUSDBIDR",
        balas: "BIDR",
        trdas: "BUSD",
        scnt: 1,
        pdec: 0,
        sdec: 1
      },
      {
        tk: "USDTBIDR",
        balas: "BIDR",
        trdas: "USDT",
        scnt: 1,
        pdec: 0,
        sdec: 1
      },
      {
        tk: "BKRWUSDT",
        balas: "USDT",
        trdas: "BKRW",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "BKRWBUSD",
        balas: "BUSD",
        trdas: "BKRW",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "SCUSDT",
        balas: "USDT",
        trdas: "SC",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "ZENUSDT",
        balas: "USDT",
        trdas: "ZEN",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "SXPBTC",
        balas: "BTC",
        trdas: "SXP",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "SXPBNB",
        balas: "BNB",
        trdas: "SXP",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "SXPBUSD",
        balas: "BUSD",
        trdas: "SXP",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "SNXBTC",
        balas: "BTC",
        trdas: "SNX",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "SNXBNB",
        balas: "BNB",
        trdas: "SNX",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "SNXBUSD",
        balas: "BUSD",
        trdas: "SNX",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "SNXUSDT",
        balas: "USDT",
        trdas: "SNX",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "ETHUPUSDT",
        balas: "USDT",
        trdas: "ETHUP",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "ETHDOWNUSDT",
        balas: "USDT",
        trdas: "ETHDOWN",
        scnt: 1,
        pdec: 5,
        sdec: 2
      },
      {
        tk: "ADAUPUSDT",
        balas: "USDT",
        trdas: "ADAUP",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "ADADOWNUSDT",
        balas: "USDT",
        trdas: "ADADOWN",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "LINKUPUSDT",
        balas: "USDT",
        trdas: "LINKUP",
        scnt: 1,
        pdec: 5,
        sdec: 2
      },
      {
        tk: "LINKDOWNUSDT",
        balas: "USDT",
        trdas: "LINKDOWN",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "VTHOBNB",
        balas: "BNB",
        trdas: "VTHO",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "VTHOBUSD",
        balas: "BUSD",
        trdas: "VTHO",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "VTHOUSDT",
        balas: "USDT",
        trdas: "VTHO",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "DCRBUSD",
        balas: "BUSD",
        trdas: "DCR",
        scnt: 1,
        pdec: 3,
        sdec: 3
      },
      {
        tk: "DGBUSDT",
        balas: "USDT",
        trdas: "DGB",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "GBPUSDT",
        balas: "USDT",
        trdas: "GBP",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "STORJBUSD",
        balas: "BUSD",
        trdas: "STORJ",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "SXPUSDT",
        balas: "USDT",
        trdas: "SXP",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "IRISBNB",
        balas: "BNB",
        trdas: "IRIS",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "IRISBTC",
        balas: "BTC",
        trdas: "IRIS",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "IRISBUSD",
        balas: "BUSD",
        trdas: "IRIS",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "MKRBNB",
        balas: "BNB",
        trdas: "MKR",
        scnt: 1,
        pdec: 3,
        sdec: 4
      },
      {
        tk: "MKRBTC",
        balas: "BTC",
        trdas: "MKR",
        scnt: 1,
        pdec: 5,
        sdec: 4
      },
      {
        tk: "MKRUSDT",
        balas: "USDT",
        trdas: "MKR",
        scnt: 1,
        pdec: 0,
        sdec: 4
      },
      {
        tk: "MKRBUSD",
        balas: "BUSD",
        trdas: "MKR",
        scnt: 1,
        pdec: 0,
        sdec: 4
      },
      {
        tk: "DAIBNB",
        balas: "BNB",
        trdas: "DAI",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "DAIBTC",
        balas: "BTC",
        trdas: "DAI",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "DAIUSDT",
        balas: "USDT",
        trdas: "DAI",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "DAIBUSD",
        balas: "BUSD",
        trdas: "DAI",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "RUNEBNB",
        balas: "BNB",
        trdas: "RUNE",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "RUNEBTC",
        balas: "BTC",
        trdas: "RUNE",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "RUNEBUSD",
        balas: "BUSD",
        trdas: "RUNE",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "MANABUSD",
        balas: "BUSD",
        trdas: "MANA",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "DOGEBUSD",
        balas: "BUSD",
        trdas: "DOGE",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "LENDBUSD",
        balas: "BUSD",
        trdas: "LEND",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "ZRXBUSD",
        balas: "BUSD",
        trdas: "ZRX",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "DCRUSDT",
        balas: "USDT",
        trdas: "DCR",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "STORJUSDT",
        balas: "USDT",
        trdas: "STORJ",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "XRPBKRW",
        balas: "BKRW",
        trdas: "XRP",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "ADABKRW",
        balas: "BKRW",
        trdas: "ADA",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "BTCAUD",
        balas: "AUD",
        trdas: "BTC",
        scnt: 1,
        pdec: 2,
        sdec: 5
      },
      {
        tk: "ETHAUD",
        balas: "AUD",
        trdas: "ETH",
        scnt: 1,
        pdec: 2,
        sdec: 4
      },
      {
        tk: "AUDBUSD",
        balas: "BUSD",
        trdas: "AUD",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "FIOBNB",
        balas: "BNB",
        trdas: "FIO",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "FIOBTC",
        balas: "BTC",
        trdas: "FIO",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "FIOBUSD",
        balas: "BUSD",
        trdas: "FIO",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "BNBUPUSDT",
        balas: "USDT",
        trdas: "BNBUP",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "BNBDOWNUSDT",
        balas: "USDT",
        trdas: "BNBDOWN",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "XTZUPUSDT",
        balas: "USDT",
        trdas: "XTZUP",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "XTZDOWNUSDT",
        balas: "USDT",
        trdas: "XTZDOWN",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "AVABNB",
        balas: "BNB",
        trdas: "AVA",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "AVABTC",
        balas: "BTC",
        trdas: "AVA",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "AVABUSD",
        balas: "BUSD",
        trdas: "AVA",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "USDTBKRW",
        balas: "BKRW",
        trdas: "USDT",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "BUSDBKRW",
        balas: "BKRW",
        trdas: "BUSD",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "IOTABUSD",
        balas: "BUSD",
        trdas: "IOTA",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "MANAUSDT",
        balas: "USDT",
        trdas: "MANA",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "XRPAUD",
        balas: "AUD",
        trdas: "XRP",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "BNBAUD",
        balas: "AUD",
        trdas: "BNB",
        scnt: 1,
        pdec: 1,
        sdec: 3
      },
      {
        tk: "AUDUSDT",
        balas: "USDT",
        trdas: "AUD",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "BALBNB",
        balas: "BNB",
        trdas: "BAL",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "BALBTC",
        balas: "BTC",
        trdas: "BAL",
        scnt: 1,
        pdec: 7,
        sdec: 2
      },
      {
        tk: "BALBUSD",
        balas: "BUSD",
        trdas: "BAL",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "YFIBNB",
        balas: "BNB",
        trdas: "YFI",
        scnt: 1,
        pdec: 2,
        sdec: 5
      },
      {
        tk: "YFIBTC",
        balas: "BTC",
        trdas: "YFI",
        scnt: 1,
        pdec: 4,
        sdec: 5
      },
      {
        tk: "YFIBUSD",
        balas: "BUSD",
        trdas: "YFI",
        scnt: 1,
        pdec: 0,
        sdec: 5
      },
      {
        tk: "YFIUSDT",
        balas: "USDT",
        trdas: "YFI",
        scnt: 1,
        pdec: 0,
        sdec: 5
      },
      {
        tk: "BLZBUSD",
        balas: "BUSD",
        trdas: "BLZ",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "KMDBUSD",
        balas: "BUSD",
        trdas: "KMD",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "BALUSDT",
        balas: "USDT",
        trdas: "BAL",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "BLZUSDT",
        balas: "USDT",
        trdas: "BLZ",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "IRISUSDT",
        balas: "USDT",
        trdas: "IRIS",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "KMDUSDT",
        balas: "USDT",
        trdas: "KMD",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "BTCDAI",
        balas: "DAI",
        trdas: "BTC",
        scnt: 1,
        pdec: 2,
        sdec: 5
      },
      {
        tk: "ETHDAI",
        balas: "DAI",
        trdas: "ETH",
        scnt: 1,
        pdec: 2,
        sdec: 4
      },
      {
        tk: "BNBDAI",
        balas: "DAI",
        trdas: "BNB",
        scnt: 1,
        pdec: 1,
        sdec: 3
      },
      {
        tk: "USDTDAI",
        balas: "DAI",
        trdas: "USDT",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "BUSDDAI",
        balas: "DAI",
        trdas: "BUSD",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "JSTBNB",
        balas: "BNB",
        trdas: "JST",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "JSTBTC",
        balas: "BTC",
        trdas: "JST",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "JSTBUSD",
        balas: "BUSD",
        trdas: "JST",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "JSTUSDT",
        balas: "USDT",
        trdas: "JST",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "SRMBNB",
        balas: "BNB",
        trdas: "SRM",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "SRMBTC",
        balas: "BTC",
        trdas: "SRM",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "SRMBUSD",
        balas: "BUSD",
        trdas: "SRM",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "SRMUSDT",
        balas: "USDT",
        trdas: "SRM",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "ANTBNB",
        balas: "BNB",
        trdas: "ANT",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "ANTBTC",
        balas: "BTC",
        trdas: "ANT",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "ANTBUSD",
        balas: "BUSD",
        trdas: "ANT",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "ANTUSDT",
        balas: "USDT",
        trdas: "ANT",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "CRVBNB",
        balas: "BNB",
        trdas: "CRV",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "CRVBTC",
        balas: "BTC",
        trdas: "CRV",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "CRVBUSD",
        balas: "BUSD",
        trdas: "CRV",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "CRVUSDT",
        balas: "USDT",
        trdas: "CRV",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "SANDBNB",
        balas: "BNB",
        trdas: "SAND",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "SANDBTC",
        balas: "BTC",
        trdas: "SAND",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "SANDUSDT",
        balas: "USDT",
        trdas: "SAND",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "SANDBUSD",
        balas: "BUSD",
        trdas: "SAND",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "OCEANBNB",
        balas: "BNB",
        trdas: "OCEAN",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "OCEANBTC",
        balas: "BTC",
        trdas: "OCEAN",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "OCEANBUSD",
        balas: "BUSD",
        trdas: "OCEAN",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "OCEANUSDT",
        balas: "USDT",
        trdas: "OCEAN",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "NMRBTC",
        balas: "BTC",
        trdas: "NMR",
        scnt: 1,
        pdec: 7,
        sdec: 2
      },
      {
        tk: "NMRBUSD",
        balas: "BUSD",
        trdas: "NMR",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "NMRUSDT",
        balas: "USDT",
        trdas: "NMR",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "DOTBNB",
        balas: "BNB",
        trdas: "DOT",
        scnt: 1,
        pdec: 5,
        sdec: 2
      },
      {
        tk: "DOTBTC",
        balas: "BTC",
        trdas: "DOT",
        scnt: 1,
        pdec: 7,
        sdec: 2
      },
      {
        tk: "DOTBUSD",
        balas: "BUSD",
        trdas: "DOT",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "DOTUSDT",
        balas: "USDT",
        trdas: "DOT",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "LUNABNB",
        balas: "BNB",
        trdas: "LUNA",
        scnt: 1,
        pdec: 8,
        sdec: 2
      },
      {
        tk: "LUNABTC",
        balas: "BTC",
        trdas: "LUNA",
        scnt: 1,
        pdec: 8,
        sdec: 2
      },
      {
        tk: "LUNABUSD",
        balas: "BUSD",
        trdas: "LUNA",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "LUNAUSDT",
        balas: "USDT",
        trdas: "LUNA",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "IDEXBTC",
        balas: "BTC",
        trdas: "IDEX",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "IDEXBUSD",
        balas: "BUSD",
        trdas: "IDEX",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "RSRBNB",
        balas: "BNB",
        trdas: "RSR",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "RSRBTC",
        balas: "BTC",
        trdas: "RSR",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "RSRBUSD",
        balas: "BUSD",
        trdas: "RSR",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "RSRUSDT",
        balas: "USDT",
        trdas: "RSR",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "PAXGBNB",
        balas: "BNB",
        trdas: "PAXG",
        scnt: 1,
        pdec: 3,
        sdec: 4
      },
      {
        tk: "PAXGBTC",
        balas: "BTC",
        trdas: "PAXG",
        scnt: 1,
        pdec: 5,
        sdec: 4
      },
      {
        tk: "PAXGBUSD",
        balas: "BUSD",
        trdas: "PAXG",
        scnt: 1,
        pdec: 0,
        sdec: 4
      },
      {
        tk: "PAXGUSDT",
        balas: "USDT",
        trdas: "PAXG",
        scnt: 1,
        pdec: 0,
        sdec: 4
      },
      {
        tk: "WNXMBNB",
        balas: "BNB",
        trdas: "WNXM",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "WNXMBTC",
        balas: "BTC",
        trdas: "WNXM",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "WNXMBUSD",
        balas: "BUSD",
        trdas: "WNXM",
        scnt: 1,
        pdec: 3,
        sdec: 3
      },
      {
        tk: "WNXMUSDT",
        balas: "USDT",
        trdas: "WNXM",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "TRBBNB",
        balas: "BNB",
        trdas: "TRB",
        scnt: 1,
        pdec: 3,
        sdec: 3
      },
      {
        tk: "TRBBTC",
        balas: "BTC",
        trdas: "TRB",
        scnt: 1,
        pdec: 6,
        sdec: 3
      },
      {
        tk: "TRBBUSD",
        balas: "BUSD",
        trdas: "TRB",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "TRBUSDT",
        balas: "USDT",
        trdas: "TRB",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "ETHNGN",
        balas: "NGN",
        trdas: "ETH",
        scnt: 1,
        pdec: 0,
        sdec: 5
      },
      {
        tk: "DOTBIDR",
        balas: "BIDR",
        trdas: "DOT",
        scnt: 1,
        pdec: 0,
        sdec: 2
      },
      {
        tk: "LINKAUD",
        balas: "AUD",
        trdas: "LINK",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "SXPAUD",
        balas: "AUD",
        trdas: "SXP",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "BZRXBNB",
        balas: "BNB",
        trdas: "BZRX",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "BZRXBTC",
        balas: "BTC",
        trdas: "BZRX",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "BZRXBUSD",
        balas: "BUSD",
        trdas: "BZRX",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "BZRXUSDT",
        balas: "USDT",
        trdas: "BZRX",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "WBTCBTC",
        balas: "BTC",
        trdas: "WBTC",
        scnt: 1,
        pdec: 4,
        sdec: 5
      },
      {
        tk: "WBTCETH",
        balas: "ETH",
        trdas: "WBTC",
        scnt: 1,
        pdec: 2,
        sdec: 5
      },
      {
        tk: "SUSHIBNB",
        balas: "BNB",
        trdas: "SUSHI",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "SUSHIBTC",
        balas: "BTC",
        trdas: "SUSHI",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "SUSHIBUSD",
        balas: "BUSD",
        trdas: "SUSHI",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "SUSHIUSDT",
        balas: "USDT",
        trdas: "SUSHI",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "YFIIBNB",
        balas: "BNB",
        trdas: "YFII",
        scnt: 1,
        pdec: 2,
        sdec: 4
      },
      {
        tk: "YFIIBTC",
        balas: "BTC",
        trdas: "YFII",
        scnt: 1,
        pdec: 5,
        sdec: 4
      },
      {
        tk: "YFIIBUSD",
        balas: "BUSD",
        trdas: "YFII",
        scnt: 1,
        pdec: 0,
        sdec: 4
      },
      {
        tk: "YFIIUSDT",
        balas: "USDT",
        trdas: "YFII",
        scnt: 1,
        pdec: 1,
        sdec: 4
      },
      {
        tk: "KSMBNB",
        balas: "BNB",
        trdas: "KSM",
        scnt: 1,
        pdec: 4,
        sdec: 3
      },
      {
        tk: "KSMBTC",
        balas: "BTC",
        trdas: "KSM",
        scnt: 1,
        pdec: 6,
        sdec: 3
      },
      {
        tk: "KSMBUSD",
        balas: "BUSD",
        trdas: "KSM",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "KSMUSDT",
        balas: "USDT",
        trdas: "KSM",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "EGLDBNB",
        balas: "BNB",
        trdas: "EGLD",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "EGLDBTC",
        balas: "BTC",
        trdas: "EGLD",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "EGLDBUSD",
        balas: "BUSD",
        trdas: "EGLD",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "EGLDUSDT",
        balas: "USDT",
        trdas: "EGLD",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "DIABNB",
        balas: "BNB",
        trdas: "DIA",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "DIABTC",
        balas: "BTC",
        trdas: "DIA",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "DIABUSD",
        balas: "BUSD",
        trdas: "DIA",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "DIAUSDT",
        balas: "USDT",
        trdas: "DIA",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "RUNEUSDT",
        balas: "USDT",
        trdas: "RUNE",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "FIOUSDT",
        balas: "USDT",
        trdas: "FIO",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "UMABTC",
        balas: "BTC",
        trdas: "UMA",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "UMAUSDT",
        balas: "USDT",
        trdas: "UMA",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "EOSUPUSDT",
        balas: "USDT",
        trdas: "EOSUP",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "EOSDOWNUSDT",
        balas: "USDT",
        trdas: "EOSDOWN",
        scnt: 1,
        pdec: 7,
        sdec: 2
      },
      {
        tk: "TRXUPUSDT",
        balas: "USDT",
        trdas: "TRXUP",
        scnt: 1,
        pdec: 5,
        sdec: 2
      },
      {
        tk: "TRXDOWNUSDT",
        balas: "USDT",
        trdas: "TRXDOWN",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "XRPUPUSDT",
        balas: "USDT",
        trdas: "XRPUP",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "XRPDOWNUSDT",
        balas: "USDT",
        trdas: "XRPDOWN",
        scnt: 1,
        pdec: 8,
        sdec: 2
      },
      {
        tk: "DOTUPUSDT",
        balas: "USDT",
        trdas: "DOTUP",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "DOTDOWNUSDT",
        balas: "USDT",
        trdas: "DOTDOWN",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "SRMBIDR",
        balas: "BIDR",
        trdas: "SRM",
        scnt: 1,
        pdec: 0,
        sdec: 3
      },
      {
        tk: "ONEBIDR",
        balas: "BIDR",
        trdas: "ONE",
        scnt: 1,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "LINKTRY",
        balas: "TRY",
        trdas: "LINK",
        scnt: 1,
        pdec: 1,
        sdec: 2
      },
      {
        tk: "USDTNGN",
        balas: "NGN",
        trdas: "USDT",
        scnt: 1,
        pdec: 1,
        sdec: 1
      },
      {
        tk: "BELBNB",
        balas: "BNB",
        trdas: "BEL",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "BELBTC",
        balas: "BTC",
        trdas: "BEL",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "BELBUSD",
        balas: "BUSD",
        trdas: "BEL",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "BELUSDT",
        balas: "USDT",
        trdas: "BEL",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "WINGBNB",
        balas: "BNB",
        trdas: "WING",
        scnt: 1,
        pdec: 5,
        sdec: 2
      },
      {
        tk: "WINGBTC",
        balas: "BTC",
        trdas: "WING",
        scnt: 1,
        pdec: 7,
        sdec: 2
      },
      {
        tk: "SWRVBNB",
        balas: "BNB",
        trdas: "SWRV",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "SWRVBUSD",
        balas: "BUSD",
        trdas: "SWRV",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "WINGBUSD",
        balas: "BUSD",
        trdas: "WING",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "WINGUSDT",
        balas: "USDT",
        trdas: "WING",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "LTCUPUSDT",
        balas: "USDT",
        trdas: "LTCUP",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "LTCDOWNUSDT",
        balas: "USDT",
        trdas: "LTCDOWN",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "LENDBKRW",
        balas: "BKRW",
        trdas: "LEND",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "SXPEUR",
        balas: "EUR",
        trdas: "SXP",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "CREAMBNB",
        balas: "BNB",
        trdas: "CREAM",
        scnt: 1,
        pdec: 4,
        sdec: 3
      },
      {
        tk: "CREAMBUSD",
        balas: "BUSD",
        trdas: "CREAM",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "UNIBNB",
        balas: "BNB",
        trdas: "UNI",
        scnt: 1,
        pdec: 5,
        sdec: 2
      },
      {
        tk: "UNIBTC",
        balas: "BTC",
        trdas: "UNI",
        scnt: 1,
        pdec: 7,
        sdec: 2
      },
      {
        tk: "UNIBUSD",
        balas: "BUSD",
        trdas: "UNI",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "UNIUSDT",
        balas: "USDT",
        trdas: "UNI",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "NBSBTC",
        balas: "BTC",
        trdas: "NBS",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "NBSUSDT",
        balas: "USDT",
        trdas: "NBS",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "OXTBTC",
        balas: "BTC",
        trdas: "OXT",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "OXTUSDT",
        balas: "USDT",
        trdas: "OXT",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "SUNBTC",
        balas: "BTC",
        trdas: "SUN",
        scnt: 1,
        pdec: 6,
        sdec: 3
      },
      {
        tk: "SUNUSDT",
        balas: "USDT",
        trdas: "SUN",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "AVAXBNB",
        balas: "BNB",
        trdas: "AVAX",
        scnt: 1,
        pdec: 5,
        sdec: 2
      },
      {
        tk: "AVAXBTC",
        balas: "BTC",
        trdas: "AVAX",
        scnt: 1,
        pdec: 7,
        sdec: 2
      },
      {
        tk: "AVAXBUSD",
        balas: "BUSD",
        trdas: "AVAX",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "AVAXUSDT",
        balas: "USDT",
        trdas: "AVAX",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "HNTBTC",
        balas: "BTC",
        trdas: "HNT",
        scnt: 1,
        pdec: 7,
        sdec: 2
      },
      {
        tk: "HNTUSDT",
        balas: "USDT",
        trdas: "HNT",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "BAKEBNB",
        balas: "BNB",
        trdas: "BAKE",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "BURGERBNB",
        balas: "BNB",
        trdas: "BURGER",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "SXPBIDR",
        balas: "BIDR",
        trdas: "SXP",
        scnt: 1,
        pdec: 0,
        sdec: 1
      },
      {
        tk: "LINKBKRW",
        balas: "BKRW",
        trdas: "LINK",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "FLMBNB",
        balas: "BNB",
        trdas: "FLM",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "FLMBTC",
        balas: "BTC",
        trdas: "FLM",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "FLMBUSD",
        balas: "BUSD",
        trdas: "FLM",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "FLMUSDT",
        balas: "USDT",
        trdas: "FLM",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "SCRTBTC",
        balas: "BTC",
        trdas: "SCRT",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "SCRTETH",
        balas: "ETH",
        trdas: "SCRT",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "CAKEBNB",
        balas: "BNB",
        trdas: "CAKE",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "CAKEBUSD",
        balas: "BUSD",
        trdas: "CAKE",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "SPARTABNB",
        balas: "BNB",
        trdas: "SPARTA",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "UNIUPUSDT",
        balas: "USDT",
        trdas: "UNIUP",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "UNIDOWNUSDT",
        balas: "USDT",
        trdas: "UNIDOWN",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "ORNBTC",
        balas: "BTC",
        trdas: "ORN",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "ORNUSDT",
        balas: "USDT",
        trdas: "ORN",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "TRXNGN",
        balas: "NGN",
        trdas: "TRX",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "SXPTRY",
        balas: "TRY",
        trdas: "SXP",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "UTKBTC",
        balas: "BTC",
        trdas: "UTK",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "UTKUSDT",
        balas: "USDT",
        trdas: "UTK",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "XVSBNB",
        balas: "BNB",
        trdas: "XVS",
        scnt: 1,
        pdec: 5,
        sdec: 2
      },
      {
        tk: "XVSBTC",
        balas: "BTC",
        trdas: "XVS",
        scnt: 1,
        pdec: 7,
        sdec: 2
      },
      {
        tk: "XVSBUSD",
        balas: "BUSD",
        trdas: "XVS",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "XVSUSDT",
        balas: "USDT",
        trdas: "XVS",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "ALPHABNB",
        balas: "BNB",
        trdas: "ALPHA",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "ALPHABTC",
        balas: "BTC",
        trdas: "ALPHA",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "ALPHABUSD",
        balas: "BUSD",
        trdas: "ALPHA",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "ALPHAUSDT",
        balas: "USDT",
        trdas: "ALPHA",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "VIDTBTC",
        balas: "BTC",
        trdas: "VIDT",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "VIDTBUSD",
        balas: "BUSD",
        trdas: "VIDT",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "AAVEBNB",
        balas: "BNB",
        trdas: "AAVE",
        scnt: 1,
        pdec: 4,
        sdec: 3
      },
      {
        tk: "BTCBRL",
        balas: "BRL",
        trdas: "BTC",
        scnt: 1,
        pdec: 0,
        sdec: 5
      },
      {
        tk: "USDTBRL",
        balas: "BRL",
        trdas: "USDT",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "AAVEBTC",
        balas: "BTC",
        trdas: "AAVE",
        scnt: 1,
        pdec: 6,
        sdec: 3
      },
      {
        tk: "AAVEETH",
        balas: "ETH",
        trdas: "AAVE",
        scnt: 1,
        pdec: 5,
        sdec: 3
      },
      {
        tk: "AAVEBUSD",
        balas: "BUSD",
        trdas: "AAVE",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "AAVEUSDT",
        balas: "USDT",
        trdas: "AAVE",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "AAVEBKRW",
        balas: "BKRW",
        trdas: "AAVE",
        scnt: 1,
        pdec: 0,
        sdec: 3
      },
      {
        tk: "NEARBNB",
        balas: "BNB",
        trdas: "NEAR",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "NEARBTC",
        balas: "BTC",
        trdas: "NEAR",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "NEARBUSD",
        balas: "BUSD",
        trdas: "NEAR",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "NEARUSDT",
        balas: "USDT",
        trdas: "NEAR",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "SXPUPUSDT",
        balas: "USDT",
        trdas: "SXPUP",
        scnt: 1,
        pdec: 5,
        sdec: 2
      },
      {
        tk: "SXPDOWNUSDT",
        balas: "USDT",
        trdas: "SXPDOWN",
        scnt: 1,
        pdec: 5,
        sdec: 2
      },
      {
        tk: "DOTBKRW",
        balas: "BKRW",
        trdas: "DOT",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "SXPGBP",
        balas: "GBP",
        trdas: "SXP",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "FILBNB",
        balas: "BNB",
        trdas: "FIL",
        scnt: 1,
        pdec: 5,
        sdec: 2
      },
      {
        tk: "FILBTC",
        balas: "BTC",
        trdas: "FIL",
        scnt: 1,
        pdec: 7,
        sdec: 2
      },
      {
        tk: "FILBUSD",
        balas: "BUSD",
        trdas: "FIL",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "FILUSDT",
        balas: "USDT",
        trdas: "FIL",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "FILUPUSDT",
        balas: "USDT",
        trdas: "FILUP",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "FILDOWNUSDT",
        balas: "USDT",
        trdas: "FILDOWN",
        scnt: 1,
        pdec: 7,
        sdec: 2
      },
      {
        tk: "YFIUPUSDT",
        balas: "USDT",
        trdas: "YFIUP",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "YFIDOWNUSDT",
        balas: "USDT",
        trdas: "YFIDOWN",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "INJBNB",
        balas: "BNB",
        trdas: "INJ",
        scnt: 1,
        pdec: 5,
        sdec: 2
      },
      {
        tk: "INJBTC",
        balas: "BTC",
        trdas: "INJ",
        scnt: 1,
        pdec: 7,
        sdec: 2
      },
      {
        tk: "INJBUSD",
        balas: "BUSD",
        trdas: "INJ",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "INJUSDT",
        balas: "USDT",
        trdas: "INJ",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "AERGOBTC",
        balas: "BTC",
        trdas: "AERGO",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "AERGOBUSD",
        balas: "BUSD",
        trdas: "AERGO",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "LINKEUR",
        balas: "EUR",
        trdas: "LINK",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "ONEBUSD",
        balas: "BUSD",
        trdas: "ONE",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "EASYETH",
        balas: "ETH",
        trdas: "EASY",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "AUDIOBTC",
        balas: "BTC",
        trdas: "AUDIO",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "AUDIOBUSD",
        balas: "BUSD",
        trdas: "AUDIO",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "AUDIOUSDT",
        balas: "USDT",
        trdas: "AUDIO",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "CTKBNB",
        balas: "BNB",
        trdas: "CTK",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "CTKBTC",
        balas: "BTC",
        trdas: "CTK",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "CTKBUSD",
        balas: "BUSD",
        trdas: "CTK",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "CTKUSDT",
        balas: "USDT",
        trdas: "CTK",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "BCHUPUSDT",
        balas: "USDT",
        trdas: "BCHUP",
        scnt: 1,
        pdec: 5,
        sdec: 2
      },
      {
        tk: "BCHDOWNUSDT",
        balas: "USDT",
        trdas: "BCHDOWN",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "BOTBTC",
        balas: "BTC",
        trdas: "BOT",
        scnt: 1,
        pdec: 5,
        sdec: 4
      },
      {
        tk: "BOTBUSD",
        balas: "BUSD",
        trdas: "BOT",
        scnt: 1,
        pdec: 2,
        sdec: 5
      },
      {
        tk: "ETHBRL",
        balas: "BRL",
        trdas: "ETH",
        scnt: 1,
        pdec: 2,
        sdec: 4
      },
      {
        tk: "DOTEUR",
        balas: "EUR",
        trdas: "DOT",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "AKROBTC",
        balas: "BTC",
        trdas: "AKRO",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "AKROUSDT",
        balas: "USDT",
        trdas: "AKRO",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "KP3RBNB",
        balas: "BNB",
        trdas: "KP3R",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "KP3RBUSD",
        balas: "BUSD",
        trdas: "KP3R",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "AXSBNB",
        balas: "BNB",
        trdas: "AXS",
        scnt: 1,
        pdec: 5,
        sdec: 2
      },
      {
        tk: "AXSBTC",
        balas: "BTC",
        trdas: "AXS",
        scnt: 1,
        pdec: 7,
        sdec: 2
      },
      {
        tk: "AXSBUSD",
        balas: "BUSD",
        trdas: "AXS",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "AXSUSDT",
        balas: "USDT",
        trdas: "AXS",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "HARDBNB",
        balas: "BNB",
        trdas: "HARD",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "HARDBTC",
        balas: "BTC",
        trdas: "HARD",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "HARDBUSD",
        balas: "BUSD",
        trdas: "HARD",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "HARDUSDT",
        balas: "USDT",
        trdas: "HARD",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "BNBBRL",
        balas: "BRL",
        trdas: "BNB",
        scnt: 1,
        pdec: 0,
        sdec: 3
      },
      {
        tk: "LTCEUR",
        balas: "EUR",
        trdas: "LTC",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "RENBTCBTC",
        balas: "BTC",
        trdas: "RENBTC",
        scnt: 1,
        pdec: 4,
        sdec: 5
      },
      {
        tk: "RENBTCETH",
        balas: "ETH",
        trdas: "RENBTC",
        scnt: 1,
        pdec: 3,
        sdec: 5
      },
      {
        tk: "DNTBUSD",
        balas: "BUSD",
        trdas: "DNT",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "DNTUSDT",
        balas: "USDT",
        trdas: "DNT",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "SLPETH",
        balas: "ETH",
        trdas: "SLP",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "ADAEUR",
        balas: "EUR",
        trdas: "ADA",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "LTCNGN",
        balas: "NGN",
        trdas: "LTC",
        scnt: 1,
        pdec: 0,
        sdec: 5
      },
      {
        tk: "CVPETH",
        balas: "ETH",
        trdas: "CVP",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "CVPBUSD",
        balas: "BUSD",
        trdas: "CVP",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "STRAXBTC",
        balas: "BTC",
        trdas: "STRAX",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "STRAXETH",
        balas: "ETH",
        trdas: "STRAX",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "STRAXBUSD",
        balas: "BUSD",
        trdas: "STRAX",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "STRAXUSDT",
        balas: "USDT",
        trdas: "STRAX",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "FORBTC",
        balas: "BTC",
        trdas: "FOR",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "FORBUSD",
        balas: "BUSD",
        trdas: "FOR",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "UNFIBNB",
        balas: "BNB",
        trdas: "UNFI",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "UNFIBTC",
        balas: "BTC",
        trdas: "UNFI",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "UNFIBUSD",
        balas: "BUSD",
        trdas: "UNFI",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "UNFIUSDT",
        balas: "USDT",
        trdas: "UNFI",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "FRONTETH",
        balas: "ETH",
        trdas: "FRONT",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "FRONTBUSD",
        balas: "BUSD",
        trdas: "FRONT",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "BCHABUSD",
        balas: "BUSD",
        trdas: "BCHA",
        scnt: 1,
        pdec: 3,
        sdec: 3
      },
      {
        tk: "ROSEBTC",
        balas: "BTC",
        trdas: "ROSE",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "ROSEBUSD",
        balas: "BUSD",
        trdas: "ROSE",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "ROSEUSDT",
        balas: "USDT",
        trdas: "ROSE",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "AVAXTRY",
        balas: "TRY",
        trdas: "AVAX",
        scnt: 1,
        pdec: 1,
        sdec: 2
      },
      {
        tk: "BUSDBRL",
        balas: "BRL",
        trdas: "BUSD",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "AVAUSDT",
        balas: "USDT",
        trdas: "AVA",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "SYSBUSD",
        balas: "BUSD",
        trdas: "SYS",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "XEMUSDT",
        balas: "USDT",
        trdas: "XEM",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "HEGICETH",
        balas: "ETH",
        trdas: "HEGIC",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "HEGICBUSD",
        balas: "BUSD",
        trdas: "HEGIC",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "AAVEUPUSDT",
        balas: "USDT",
        trdas: "AAVEUP",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "AAVEDOWNUSDT",
        balas: "USDT",
        trdas: "AAVEDOWN",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "PROMBNB",
        balas: "BNB",
        trdas: "PROM",
        scnt: 1,
        pdec: 5,
        sdec: 2
      },
      {
        tk: "PROMBUSD",
        balas: "BUSD",
        trdas: "PROM",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "XRPBRL",
        balas: "BRL",
        trdas: "XRP",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "XRPNGN",
        balas: "NGN",
        trdas: "XRP",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "SKLBTC",
        balas: "BTC",
        trdas: "SKL",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "SKLBUSD",
        balas: "BUSD",
        trdas: "SKL",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "SKLUSDT",
        balas: "USDT",
        trdas: "SKL",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "BCHEUR",
        balas: "EUR",
        trdas: "BCH",
        scnt: 1,
        pdec: 1,
        sdec: 3
      },
      {
        tk: "YFIEUR",
        balas: "EUR",
        trdas: "YFI",
        scnt: 1,
        pdec: 0,
        sdec: 5
      },
      {
        tk: "ZILBIDR",
        balas: "BIDR",
        trdas: "ZIL",
        scnt: 1,
        pdec: 1,
        sdec: 0
      },
      {
        tk: "SUSDBTC",
        balas: "BTC",
        trdas: "SUSD",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "SUSDETH",
        balas: "ETH",
        trdas: "SUSD",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "SUSDUSDT",
        balas: "USDT",
        trdas: "SUSD",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "COVERETH",
        balas: "ETH",
        trdas: "COVER",
        scnt: 1,
        pdec: 5,
        sdec: 3
      },
      {
        tk: "COVERBUSD",
        balas: "BUSD",
        trdas: "COVER",
        scnt: 1,
        pdec: 1,
        sdec: 3
      },
      {
        tk: "GLMBTC",
        balas: "BTC",
        trdas: "GLM",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "GLMETH",
        balas: "ETH",
        trdas: "GLM",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "GHSTETH",
        balas: "ETH",
        trdas: "GHST",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "GHSTBUSD",
        balas: "BUSD",
        trdas: "GHST",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "SUSHIUPUSDT",
        balas: "USDT",
        trdas: "SUSHIUP",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "SUSHIDOWNUSDT",
        balas: "USDT",
        trdas: "SUSHIDOWN",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "XLMUPUSDT",
        balas: "USDT",
        trdas: "XLMUP",
        scnt: 1,
        pdec: 5,
        sdec: 2
      },
      {
        tk: "XLMDOWNUSDT",
        balas: "USDT",
        trdas: "XLMDOWN",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "LINKBRL",
        balas: "BRL",
        trdas: "LINK",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "LINKNGN",
        balas: "NGN",
        trdas: "LINK",
        scnt: 1,
        pdec: 0,
        sdec: 5
      },
      {
        tk: "LTCRUB",
        balas: "RUB",
        trdas: "LTC",
        scnt: 1,
        pdec: 1,
        sdec: 3
      },
      {
        tk: "TRXTRY",
        balas: "TRY",
        trdas: "TRX",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "XLMEUR",
        balas: "EUR",
        trdas: "XLM",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "DFETH",
        balas: "ETH",
        trdas: "DF",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "DFBUSD",
        balas: "BUSD",
        trdas: "DF",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "GRTBTC",
        balas: "BTC",
        trdas: "GRT",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "GRTETH",
        balas: "ETH",
        trdas: "GRT",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "GRTUSDT",
        balas: "USDT",
        trdas: "GRT",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "JUVBTC",
        balas: "BTC",
        trdas: "JUV",
        scnt: 1,
        pdec: 7,
        sdec: 2
      },
      {
        tk: "JUVBUSD",
        balas: "BUSD",
        trdas: "JUV",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "JUVUSDT",
        balas: "USDT",
        trdas: "JUV",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "PSGBTC",
        balas: "BTC",
        trdas: "PSG",
        scnt: 1,
        pdec: 7,
        sdec: 2
      },
      {
        tk: "PSGBUSD",
        balas: "BUSD",
        trdas: "PSG",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "PSGUSDT",
        balas: "USDT",
        trdas: "PSG",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "BUSDBVND",
        balas: "BVND",
        trdas: "BUSD",
        scnt: 1,
        pdec: 0,
        sdec: 0
      },
      {
        tk: "USDTBVND",
        balas: "BVND",
        trdas: "USDT",
        scnt: 1,
        pdec: 0,
        sdec: 0
      },
      {
        tk: "1INCHBTC",
        balas: "BTC",
        trdas: "1INCH",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "1INCHUSDT",
        balas: "USDT",
        trdas: "1INCH",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "REEFBTC",
        balas: "BTC",
        trdas: "REEF",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "REEFUSDT",
        balas: "USDT",
        trdas: "REEF",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "OGBTC",
        balas: "BTC",
        trdas: "OG",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "OGUSDT",
        balas: "USDT",
        trdas: "OG",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "ATMBTC",
        balas: "BTC",
        trdas: "ATM",
        scnt: 1,
        pdec: 7,
        sdec: 2
      },
      {
        tk: "ATMUSDT",
        balas: "USDT",
        trdas: "ATM",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "ASRBTC",
        balas: "BTC",
        trdas: "ASR",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "ASRUSDT",
        balas: "USDT",
        trdas: "ASR",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "CELOBTC",
        balas: "BTC",
        trdas: "CELO",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "CELOUSDT",
        balas: "USDT",
        trdas: "CELO",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "RIFBTC",
        balas: "BTC",
        trdas: "RIF",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "RIFUSDT",
        balas: "USDT",
        trdas: "RIF",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "CHZTRY",
        balas: "TRY",
        trdas: "CHZ",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "XLMTRY",
        balas: "TRY",
        trdas: "XLM",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "LINKGBP",
        balas: "GBP",
        trdas: "LINK",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "GRTEUR",
        balas: "EUR",
        trdas: "GRT",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "BTCSTBTC",
        balas: "BTC",
        trdas: "BTCST",
        scnt: 1,
        pdec: 7,
        sdec: 2
      },
      {
        tk: "BTCSTBUSD",
        balas: "BUSD",
        trdas: "BTCST",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "BTCSTUSDT",
        balas: "USDT",
        trdas: "BTCST",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "TRUBTC",
        balas: "BTC",
        trdas: "TRU",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "TRUBUSD",
        balas: "BUSD",
        trdas: "TRU",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "TRUUSDT",
        balas: "USDT",
        trdas: "TRU",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "DEXEETH",
        balas: "ETH",
        trdas: "DEXE",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "DEXEBUSD",
        balas: "BUSD",
        trdas: "DEXE",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "EOSEUR",
        balas: "EUR",
        trdas: "EOS",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "LTCBRL",
        balas: "BRL",
        trdas: "LTC",
        scnt: 1,
        pdec: 1,
        sdec: 3
      },
      {
        tk: "USDCBUSD",
        balas: "BUSD",
        trdas: "USDC",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "TUSDBUSD",
        balas: "BUSD",
        trdas: "TUSD",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "PAXBUSD",
        balas: "BUSD",
        trdas: "PAX",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "CKBBTC",
        balas: "BTC",
        trdas: "CKB",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "CKBBUSD",
        balas: "BUSD",
        trdas: "CKB",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "CKBUSDT",
        balas: "USDT",
        trdas: "CKB",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "TWTBTC",
        balas: "BTC",
        trdas: "TWT",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "TWTBUSD",
        balas: "BUSD",
        trdas: "TWT",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "TWTUSDT",
        balas: "USDT",
        trdas: "TWT",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "FIROBTC",
        balas: "BTC",
        trdas: "FIRO",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "FIROETH",
        balas: "ETH",
        trdas: "FIRO",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "FIROUSDT",
        balas: "USDT",
        trdas: "FIRO",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "BETHETH",
        balas: "ETH",
        trdas: "BETH",
        scnt: 1,
        pdec: 4,
        sdec: 4
      },
      {
        tk: "DOGEEUR",
        balas: "EUR",
        trdas: "DOGE",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "DOGETRY",
        balas: "TRY",
        trdas: "DOGE",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "DOGEAUD",
        balas: "AUD",
        trdas: "DOGE",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "DOGEBRL",
        balas: "BRL",
        trdas: "DOGE",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "DOTNGN",
        balas: "NGN",
        trdas: "DOT",
        scnt: 1,
        pdec: 0,
        sdec: 5
      },
      {
        tk: "PROSETH",
        balas: "ETH",
        trdas: "PROS",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "LITBTC",
        balas: "BTC",
        trdas: "LIT",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "LITBUSD",
        balas: "BUSD",
        trdas: "LIT",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "LITUSDT",
        balas: "USDT",
        trdas: "LIT",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "BTCVAI",
        balas: "VAI",
        trdas: "BTC",
        scnt: 1,
        pdec: 2,
        sdec: 5
      },
      {
        tk: "BUSDVAI",
        balas: "VAI",
        trdas: "BUSD",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "SFPBTC",
        balas: "BTC",
        trdas: "SFP",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "SFPBUSD",
        balas: "BUSD",
        trdas: "SFP",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "SFPUSDT",
        balas: "USDT",
        trdas: "SFP",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "DOGEGBP",
        balas: "GBP",
        trdas: "DOGE",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "DOTTRY",
        balas: "TRY",
        trdas: "DOT",
        scnt: 1,
        pdec: 1,
        sdec: 2
      },
      {
        tk: "FXSBTC",
        balas: "BTC",
        trdas: "FXS",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "FXSBUSD",
        balas: "BUSD",
        trdas: "FXS",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "DODOBTC",
        balas: "BTC",
        trdas: "DODO",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "DODOBUSD",
        balas: "BUSD",
        trdas: "DODO",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "DODOUSDT",
        balas: "USDT",
        trdas: "DODO",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "FRONTBTC",
        balas: "BTC",
        trdas: "FRONT",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "EASYBTC",
        balas: "BTC",
        trdas: "EASY",
        scnt: 1,
        pdec: 7,
        sdec: 2
      },
      {
        tk: "CAKEBTC",
        balas: "BTC",
        trdas: "CAKE",
        scnt: 1,
        pdec: 8,
        sdec: 2
      },
      {
        tk: "CAKEUSDT",
        balas: "USDT",
        trdas: "CAKE",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "BAKEBUSD",
        balas: "BUSD",
        trdas: "BAKE",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "UFTETH",
        balas: "ETH",
        trdas: "UFT",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "UFTBUSD",
        balas: "BUSD",
        trdas: "UFT",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "1INCHBUSD",
        balas: "BUSD",
        trdas: "1INCH",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "BANDBUSD",
        balas: "BUSD",
        trdas: "BAND",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "GRTBUSD",
        balas: "BUSD",
        trdas: "GRT",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "IOSTBUSD",
        balas: "BUSD",
        trdas: "IOST",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "OMGBUSD",
        balas: "BUSD",
        trdas: "OMG",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "REEFBUSD",
        balas: "BUSD",
        trdas: "REEF",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "ACMBTC",
        balas: "BTC",
        trdas: "ACM",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "ACMBUSD",
        balas: "BUSD",
        trdas: "ACM",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "ACMUSDT",
        balas: "USDT",
        trdas: "ACM",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "AUCTIONBTC",
        balas: "BTC",
        trdas: "AUCTION",
        scnt: 1,
        pdec: 7,
        sdec: 2
      },
      {
        tk: "AUCTIONBUSD",
        balas: "BUSD",
        trdas: "AUCTION",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "PHABTC",
        balas: "BTC",
        trdas: "PHA",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "PHABUSD",
        balas: "BUSD",
        trdas: "PHA",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "DOTGBP",
        balas: "GBP",
        trdas: "DOT",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "ADATRY",
        balas: "TRY",
        trdas: "ADA",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "ADABRL",
        balas: "BRL",
        trdas: "ADA",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "ADAGBP",
        balas: "GBP",
        trdas: "ADA",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "TVKBTC",
        balas: "BTC",
        trdas: "TVK",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "TVKBUSD",
        balas: "BUSD",
        trdas: "TVK",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "BADGERBTC",
        balas: "BTC",
        trdas: "BADGER",
        scnt: 1,
        pdec: 8,
        sdec: 2
      },
      {
        tk: "BADGERBUSD",
        balas: "BUSD",
        trdas: "BADGER",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "BADGERUSDT",
        balas: "USDT",
        trdas: "BADGER",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "FISBTC",
        balas: "BTC",
        trdas: "FIS",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "FISBUSD",
        balas: "BUSD",
        trdas: "FIS",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "FISUSDT",
        balas: "USDT",
        trdas: "FIS",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "DOTBRL",
        balas: "BRL",
        trdas: "DOT",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "ADAAUD",
        balas: "AUD",
        trdas: "ADA",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "HOTTRY",
        balas: "TRY",
        trdas: "HOT",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "EGLDEUR",
        balas: "EUR",
        trdas: "EGLD",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "OMBTC",
        balas: "BTC",
        trdas: "OM",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "OMBUSD",
        balas: "BUSD",
        trdas: "OM",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "OMUSDT",
        balas: "USDT",
        trdas: "OM",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "PONDBTC",
        balas: "BTC",
        trdas: "POND",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "PONDBUSD",
        balas: "BUSD",
        trdas: "POND",
        scnt: 1,
        pdec: 5,
        sdec: 2
      },
      {
        tk: "PONDUSDT",
        balas: "USDT",
        trdas: "POND",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "DEGOBTC",
        balas: "BTC",
        trdas: "DEGO",
        scnt: 1,
        pdec: 8,
        sdec: 2
      },
      {
        tk: "DEGOBUSD",
        balas: "BUSD",
        trdas: "DEGO",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "DEGOUSDT",
        balas: "USDT",
        trdas: "DEGO",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "AVAXEUR",
        balas: "EUR",
        trdas: "AVAX",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "BTTTRY",
        balas: "TRY",
        trdas: "BTT",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "CHZBRL",
        balas: "BRL",
        trdas: "CHZ",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "UNIEUR",
        balas: "EUR",
        trdas: "UNI",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "ALICEBTC",
        balas: "BTC",
        trdas: "ALICE",
        scnt: 1,
        pdec: 8,
        sdec: 2
      },
      {
        tk: "ALICEBUSD",
        balas: "BUSD",
        trdas: "ALICE",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "ALICEUSDT",
        balas: "USDT",
        trdas: "ALICE",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "CHZBUSD",
        balas: "BUSD",
        trdas: "CHZ",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "CHZEUR",
        balas: "EUR",
        trdas: "CHZ",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "CHZGBP",
        balas: "GBP",
        trdas: "CHZ",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "BIFIBNB",
        balas: "BNB",
        trdas: "BIFI",
        scnt: 1,
        pdec: 3,
        sdec: 3
      },
      {
        tk: "BIFIBUSD",
        balas: "BUSD",
        trdas: "BIFI",
        scnt: 1,
        pdec: 1,
        sdec: 3
      },
      {
        tk: "LINABTC",
        balas: "BTC",
        trdas: "LINA",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "LINABUSD",
        balas: "BUSD",
        trdas: "LINA",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "LINAUSDT",
        balas: "USDT",
        trdas: "LINA",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "ADARUB",
        balas: "RUB",
        trdas: "ADA",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "ENJBRL",
        balas: "BRL",
        trdas: "ENJ",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "ENJEUR",
        balas: "EUR",
        trdas: "ENJ",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "MATICEUR",
        balas: "EUR",
        trdas: "MATIC",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "NEOTRY",
        balas: "TRY",
        trdas: "NEO",
        scnt: 1,
        pdec: 1,
        sdec: 2
      },
      {
        tk: "PERPBTC",
        balas: "BTC",
        trdas: "PERP",
        scnt: 1,
        pdec: 8,
        sdec: 2
      },
      {
        tk: "PERPBUSD",
        balas: "BUSD",
        trdas: "PERP",
        scnt: 1,
        pdec: 5,
        sdec: 2
      },
      {
        tk: "PERPUSDT",
        balas: "USDT",
        trdas: "PERP",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "RAMPBTC",
        balas: "BTC",
        trdas: "RAMP",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "RAMPBUSD",
        balas: "BUSD",
        trdas: "RAMP",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "RAMPUSDT",
        balas: "USDT",
        trdas: "RAMP",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "SUPERBTC",
        balas: "BTC",
        trdas: "SUPER",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "SUPERBUSD",
        balas: "BUSD",
        trdas: "SUPER",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "SUPERUSDT",
        balas: "USDT",
        trdas: "SUPER",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "CFXBTC",
        balas: "BTC",
        trdas: "CFX",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "CFXBUSD",
        balas: "BUSD",
        trdas: "CFX",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "CFXUSDT",
        balas: "USDT",
        trdas: "CFX",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "ENJGBP",
        balas: "GBP",
        trdas: "ENJ",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "EOSTRY",
        balas: "TRY",
        trdas: "EOS",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "LTCGBP",
        balas: "GBP",
        trdas: "LTC",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "LUNAEUR",
        balas: "EUR",
        trdas: "LUNA",
        scnt: 1,
        pdec: 5,
        sdec: 2
      },
      {
        tk: "RVNTRY",
        balas: "TRY",
        trdas: "RVN",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "THETAEUR",
        balas: "EUR",
        trdas: "THETA",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "XVGBUSD",
        balas: "BUSD",
        trdas: "XVG",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "EPSBTC",
        balas: "BTC",
        trdas: "EPS",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "EPSBUSD",
        balas: "BUSD",
        trdas: "EPS",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "EPSUSDT",
        balas: "USDT",
        trdas: "EPS",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "AUTOBTC",
        balas: "BTC",
        trdas: "AUTO",
        scnt: 1,
        pdec: 5,
        sdec: 3
      },
      {
        tk: "AUTOBUSD",
        balas: "BUSD",
        trdas: "AUTO",
        scnt: 1,
        pdec: 1,
        sdec: 3
      },
      {
        tk: "AUTOUSDT",
        balas: "USDT",
        trdas: "AUTO",
        scnt: 1,
        pdec: 1,
        sdec: 3
      },
      {
        tk: "TKOBTC",
        balas: "BTC",
        trdas: "TKO",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "TKOBIDR",
        balas: "BIDR",
        trdas: "TKO",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "TKOBUSD",
        balas: "BUSD",
        trdas: "TKO",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "TKOUSDT",
        balas: "USDT",
        trdas: "TKO",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "PUNDIXETH",
        balas: "ETH",
        trdas: "PUNDIX",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "PUNDIXUSDT",
        balas: "USDT",
        trdas: "PUNDIX",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "BTTBRL",
        balas: "BRL",
        trdas: "BTT",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "BTTEUR",
        balas: "EUR",
        trdas: "BTT",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "HOTEUR",
        balas: "EUR",
        trdas: "HOT",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "WINEUR",
        balas: "EUR",
        trdas: "WIN",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "TLMBTC",
        balas: "BTC",
        trdas: "TLM",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "TLMBUSD",
        balas: "BUSD",
        trdas: "TLM",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "TLMUSDT",
        balas: "USDT",
        trdas: "TLM",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "1INCHUPUSDT",
        balas: "USDT",
        trdas: "1INCHUP",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "1INCHDOWNUSDT",
        balas: "USDT",
        trdas: "1INCHDOWN",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "BTGBUSD",
        balas: "BUSD",
        trdas: "BTG",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "BTGUSDT",
        balas: "USDT",
        trdas: "BTG",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "HOTBUSD",
        balas: "BUSD",
        trdas: "HOT",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "BNBUAH",
        balas: "UAH",
        trdas: "BNB",
        scnt: 1,
        pdec: 0,
        sdec: 3
      },
      {
        tk: "ONTTRY",
        balas: "TRY",
        trdas: "ONT",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "VETEUR",
        balas: "EUR",
        trdas: "VET",
        scnt: 1,
        pdec: 5,
        sdec: 2
      },
      {
        tk: "VETGBP",
        balas: "GBP",
        trdas: "VET",
        scnt: 1,
        pdec: 5,
        sdec: 2
      },
      {
        tk: "WINBRL",
        balas: "BRL",
        trdas: "WIN",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "MIRBTC",
        balas: "BTC",
        trdas: "MIR",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "MIRBUSD",
        balas: "BUSD",
        trdas: "MIR",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "MIRUSDT",
        balas: "USDT",
        trdas: "MIR",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "BARBTC",
        balas: "BTC",
        trdas: "BAR",
        scnt: 1,
        pdec: 7,
        sdec: 2
      },
      {
        tk: "BARBUSD",
        balas: "BUSD",
        trdas: "BAR",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "BARUSDT",
        balas: "USDT",
        trdas: "BAR",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "FORTHBTC",
        balas: "BTC",
        trdas: "FORTH",
        scnt: 1,
        pdec: 8,
        sdec: 2
      },
      {
        tk: "FORTHBUSD",
        balas: "BUSD",
        trdas: "FORTH",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "FORTHUSDT",
        balas: "USDT",
        trdas: "FORTH",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "CAKEGBP",
        balas: "GBP",
        trdas: "CAKE",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "DOGERUB",
        balas: "RUB",
        trdas: "DOGE",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "HOTBRL",
        balas: "BRL",
        trdas: "HOT",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "WRXEUR",
        balas: "EUR",
        trdas: "WRX",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "EZBTC",
        balas: "BTC",
        trdas: "EZ",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "EZETH",
        balas: "ETH",
        trdas: "EZ",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "BAKEUSDT",
        balas: "USDT",
        trdas: "BAKE",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "BURGERBUSD",
        balas: "BUSD",
        trdas: "BURGER",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "BURGERUSDT",
        balas: "USDT",
        trdas: "BURGER",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "SLPBUSD",
        balas: "BUSD",
        trdas: "SLP",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "SLPUSDT",
        balas: "USDT",
        trdas: "SLP",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "TRXAUD",
        balas: "AUD",
        trdas: "TRX",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "TRXEUR",
        balas: "EUR",
        trdas: "TRX",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "VETTRY",
        balas: "TRY",
        trdas: "VET",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "SHIBUSDT",
        balas: "USDT",
        trdas: "SHIB",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "SHIBBUSD",
        balas: "BUSD",
        trdas: "SHIB",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "ICPBTC",
        balas: "BTC",
        trdas: "ICP",
        scnt: 1,
        pdec: 7,
        sdec: 2
      },
      {
        tk: "ICPBNB",
        balas: "BNB",
        trdas: "ICP",
        scnt: 1,
        pdec: 5,
        sdec: 2
      },
      {
        tk: "ICPBUSD",
        balas: "BUSD",
        trdas: "ICP",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "ICPUSDT",
        balas: "USDT",
        trdas: "ICP",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "SHIBEUR",
        balas: "EUR",
        trdas: "SHIB",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "SHIBRUB",
        balas: "RUB",
        trdas: "SHIB",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "ETCEUR",
        balas: "EUR",
        trdas: "ETC",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "ETCBRL",
        balas: "BRL",
        trdas: "ETC",
        scnt: 1,
        pdec: 1,
        sdec: 2
      },
      {
        tk: "DOGEBIDR",
        balas: "BIDR",
        trdas: "DOGE",
        scnt: 1,
        pdec: 0,
        sdec: 0
      },
      {
        tk: "ARBTC",
        balas: "BTC",
        trdas: "AR",
        scnt: 1,
        pdec: 7,
        sdec: 2
      },
      {
        tk: "ARBNB",
        balas: "BNB",
        trdas: "AR",
        scnt: 1,
        pdec: 5,
        sdec: 2
      },
      {
        tk: "ARBUSD",
        balas: "BUSD",
        trdas: "AR",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "ARUSDT",
        balas: "USDT",
        trdas: "AR",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "POLSBTC",
        balas: "BTC",
        trdas: "POLS",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "POLSBNB",
        balas: "BNB",
        trdas: "POLS",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "POLSBUSD",
        balas: "BUSD",
        trdas: "POLS",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "POLSUSDT",
        balas: "USDT",
        trdas: "POLS",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "MDXBTC",
        balas: "BTC",
        trdas: "MDX",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "MDXBNB",
        balas: "BNB",
        trdas: "MDX",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "MDXBUSD",
        balas: "BUSD",
        trdas: "MDX",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "MDXUSDT",
        balas: "USDT",
        trdas: "MDX",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "MASKBNB",
        balas: "BNB",
        trdas: "MASK",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "MASKBUSD",
        balas: "BUSD",
        trdas: "MASK",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "MASKUSDT",
        balas: "USDT",
        trdas: "MASK",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "LPTBTC",
        balas: "BTC",
        trdas: "LPT",
        scnt: 1,
        pdec: 7,
        sdec: 2
      },
      {
        tk: "LPTBNB",
        balas: "BNB",
        trdas: "LPT",
        scnt: 1,
        pdec: 5,
        sdec: 2
      },
      {
        tk: "LPTBUSD",
        balas: "BUSD",
        trdas: "LPT",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "LPTUSDT",
        balas: "USDT",
        trdas: "LPT",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "ETHUAH",
        balas: "UAH",
        trdas: "ETH",
        scnt: 1,
        pdec: 0,
        sdec: 4
      },
      {
        tk: "MATICBRL",
        balas: "BRL",
        trdas: "MATIC",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "SOLEUR",
        balas: "EUR",
        trdas: "SOL",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "SHIBBRL",
        balas: "BRL",
        trdas: "SHIB",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "AGIXBTC",
        balas: "BTC",
        trdas: "AGIX",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "ICPEUR",
        balas: "EUR",
        trdas: "ICP",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "MATICGBP",
        balas: "GBP",
        trdas: "MATIC",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "SHIBTRY",
        balas: "TRY",
        trdas: "SHIB",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "MATICBIDR",
        balas: "BIDR",
        trdas: "MATIC",
        scnt: 1,
        pdec: 0,
        sdec: 1
      },
      {
        tk: "MATICRUB",
        balas: "RUB",
        trdas: "MATIC",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "NUBTC",
        balas: "BTC",
        trdas: "NU",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "NUBNB",
        balas: "BNB",
        trdas: "NU",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "NUBUSD",
        balas: "BUSD",
        trdas: "NU",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "NUUSDT",
        balas: "USDT",
        trdas: "NU",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "XVGUSDT",
        balas: "USDT",
        trdas: "XVG",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "RLCBUSD",
        balas: "BUSD",
        trdas: "RLC",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "CELRBUSD",
        balas: "BUSD",
        trdas: "CELR",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "ATMBUSD",
        balas: "BUSD",
        trdas: "ATM",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "ZENBUSD",
        balas: "BUSD",
        trdas: "ZEN",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "FTMBUSD",
        balas: "BUSD",
        trdas: "FTM",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "THETABUSD",
        balas: "BUSD",
        trdas: "THETA",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "WINBUSD",
        balas: "BUSD",
        trdas: "WIN",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "KAVABUSD",
        balas: "BUSD",
        trdas: "KAVA",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "XEMBUSD",
        balas: "BUSD",
        trdas: "XEM",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "ATABTC",
        balas: "BTC",
        trdas: "ATA",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "ATABNB",
        balas: "BNB",
        trdas: "ATA",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "ATABUSD",
        balas: "BUSD",
        trdas: "ATA",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "ATAUSDT",
        balas: "USDT",
        trdas: "ATA",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "GTCBTC",
        balas: "BTC",
        trdas: "GTC",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "GTCBNB",
        balas: "BNB",
        trdas: "GTC",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "GTCBUSD",
        balas: "BUSD",
        trdas: "GTC",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "GTCUSDT",
        balas: "USDT",
        trdas: "GTC",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "TORNBTC",
        balas: "BTC",
        trdas: "TORN",
        scnt: 1,
        pdec: 7,
        sdec: 2
      },
      {
        tk: "TORNBNB",
        balas: "BNB",
        trdas: "TORN",
        scnt: 1,
        pdec: 5,
        sdec: 2
      },
      {
        tk: "TORNBUSD",
        balas: "BUSD",
        trdas: "TORN",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "TORNUSDT",
        balas: "USDT",
        trdas: "TORN",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "MATICTRY",
        balas: "TRY",
        trdas: "MATIC",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "ETCGBP",
        balas: "GBP",
        trdas: "ETC",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "SOLGBP",
        balas: "GBP",
        trdas: "SOL",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "BAKEBTC",
        balas: "BTC",
        trdas: "BAKE",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "COTIBUSD",
        balas: "BUSD",
        trdas: "COTI",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "KEEPBTC",
        balas: "BTC",
        trdas: "KEEP",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "KEEPBNB",
        balas: "BNB",
        trdas: "KEEP",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "KEEPBUSD",
        balas: "BUSD",
        trdas: "KEEP",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "KEEPUSDT",
        balas: "USDT",
        trdas: "KEEP",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "SOLTRY",
        balas: "TRY",
        trdas: "SOL",
        scnt: 1,
        pdec: 1,
        sdec: 3
      },
      {
        tk: "RUNEGBP",
        balas: "GBP",
        trdas: "RUNE",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "SOLBRL",
        balas: "BRL",
        trdas: "SOL",
        scnt: 1,
        pdec: 1,
        sdec: 3
      },
      {
        tk: "SCBUSD",
        balas: "BUSD",
        trdas: "SC",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "CHRBUSD",
        balas: "BUSD",
        trdas: "CHR",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "STMXBUSD",
        balas: "BUSD",
        trdas: "STMX",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "HNTBUSD",
        balas: "BUSD",
        trdas: "HNT",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "FTTBUSD",
        balas: "BUSD",
        trdas: "FTT",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "DOCKBUSD",
        balas: "BUSD",
        trdas: "DOCK",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "ADABIDR",
        balas: "BIDR",
        trdas: "ADA",
        scnt: 1,
        pdec: 0,
        sdec: 1
      },
      {
        tk: "ERNBNB",
        balas: "BNB",
        trdas: "ERN",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "ERNBUSD",
        balas: "BUSD",
        trdas: "ERN",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "ERNUSDT",
        balas: "USDT",
        trdas: "ERN",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "KLAYBTC",
        balas: "BTC",
        trdas: "KLAY",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "KLAYBNB",
        balas: "BNB",
        trdas: "KLAY",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "KLAYBUSD",
        balas: "BUSD",
        trdas: "KLAY",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "KLAYUSDT",
        balas: "USDT",
        trdas: "KLAY",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "RUNEEUR",
        balas: "EUR",
        trdas: "RUNE",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "MATICAUD",
        balas: "AUD",
        trdas: "MATIC",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "DOTRUB",
        balas: "RUB",
        trdas: "DOT",
        scnt: 1,
        pdec: 1,
        sdec: 2
      },
      {
        tk: "UTKBUSD",
        balas: "BUSD",
        trdas: "UTK",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "IOTXBUSD",
        balas: "BUSD",
        trdas: "IOTX",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "PHAUSDT",
        balas: "USDT",
        trdas: "PHA",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "SOLRUB",
        balas: "RUB",
        trdas: "SOL",
        scnt: 1,
        pdec: 0,
        sdec: 2
      },
      {
        tk: "RUNEAUD",
        balas: "AUD",
        trdas: "RUNE",
        scnt: 1,
        pdec: 3,
        sdec: 6
      },
      {
        tk: "BUSDUAH",
        balas: "UAH",
        trdas: "BUSD",
        scnt: 1,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "BONDBTC",
        balas: "BTC",
        trdas: "BOND",
        scnt: 1,
        pdec: 8,
        sdec: 2
      },
      {
        tk: "BONDBNB",
        balas: "BNB",
        trdas: "BOND",
        scnt: 1,
        pdec: 5,
        sdec: 2
      },
      {
        tk: "BONDBUSD",
        balas: "BUSD",
        trdas: "BOND",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "BONDUSDT",
        balas: "USDT",
        trdas: "BOND",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "MLNBTC",
        balas: "BTC",
        trdas: "MLN",
        scnt: 1,
        pdec: 7,
        sdec: 3
      },
      {
        tk: "MLNBNB",
        balas: "BNB",
        trdas: "MLN",
        scnt: 1,
        pdec: 4,
        sdec: 3
      },
      {
        tk: "MLNBUSD",
        balas: "BUSD",
        trdas: "MLN",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "MLNUSDT",
        balas: "USDT",
        trdas: "MLN",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "GRTTRY",
        balas: "TRY",
        trdas: "GRT",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "CAKEBRL",
        balas: "BRL",
        trdas: "CAKE",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "ICPRUB",
        balas: "RUB",
        trdas: "ICP",
        scnt: 1,
        pdec: 0,
        sdec: 2
      },
      {
        tk: "DOTAUD",
        balas: "AUD",
        trdas: "DOT",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "AAVEBRL",
        balas: "BRL",
        trdas: "AAVE",
        scnt: 1,
        pdec: 0,
        sdec: 3
      },
      {
        tk: "EOSAUD",
        balas: "AUD",
        trdas: "EOS",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "DEXEUSDT",
        balas: "USDT",
        trdas: "DEXE",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "LTOBUSD",
        balas: "BUSD",
        trdas: "LTO",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "ADXBUSD",
        balas: "BUSD",
        trdas: "ADX",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "QUICKBTC",
        balas: "BTC",
        trdas: "QUICK",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "QUICKBNB",
        balas: "BNB",
        trdas: "QUICK",
        scnt: 1,
        pdec: 3,
        sdec: 3
      },
      {
        tk: "QUICKBUSD",
        balas: "BUSD",
        trdas: "QUICK",
        scnt: 1,
        pdec: 1,
        sdec: 3
      },
      {
        tk: "C98USDT",
        balas: "USDT",
        trdas: "C98",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "C98BUSD",
        balas: "BUSD",
        trdas: "C98",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "C98BNB",
        balas: "BNB",
        trdas: "C98",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "C98BTC",
        balas: "BTC",
        trdas: "C98",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "CLVBTC",
        balas: "BTC",
        trdas: "CLV",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "CLVBNB",
        balas: "BNB",
        trdas: "CLV",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "CLVBUSD",
        balas: "BUSD",
        trdas: "CLV",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "CLVUSDT",
        balas: "USDT",
        trdas: "CLV",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "QNTBTC",
        balas: "BTC",
        trdas: "QNT",
        scnt: 1,
        pdec: 6,
        sdec: 3
      },
      {
        tk: "QNTBNB",
        balas: "BNB",
        trdas: "QNT",
        scnt: 1,
        pdec: 4,
        sdec: 3
      },
      {
        tk: "QNTBUSD",
        balas: "BUSD",
        trdas: "QNT",
        scnt: 1,
        pdec: 1,
        sdec: 3
      },
      {
        tk: "QNTUSDT",
        balas: "USDT",
        trdas: "QNT",
        scnt: 1,
        pdec: 1,
        sdec: 3
      },
      {
        tk: "FLOWBTC",
        balas: "BTC",
        trdas: "FLOW",
        scnt: 1,
        pdec: 8,
        sdec: 2
      },
      {
        tk: "FLOWBNB",
        balas: "BNB",
        trdas: "FLOW",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "FLOWBUSD",
        balas: "BUSD",
        trdas: "FLOW",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "FLOWUSDT",
        balas: "USDT",
        trdas: "FLOW",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "XECBUSD",
        balas: "BUSD",
        trdas: "XEC",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "AXSBRL",
        balas: "BRL",
        trdas: "AXS",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "AXSAUD",
        balas: "AUD",
        trdas: "AXS",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "TVKUSDT",
        balas: "USDT",
        trdas: "TVK",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "MINABTC",
        balas: "BTC",
        trdas: "MINA",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "MINABNB",
        balas: "BNB",
        trdas: "MINA",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "MINABUSD",
        balas: "BUSD",
        trdas: "MINA",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "MINAUSDT",
        balas: "USDT",
        trdas: "MINA",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "RAYBNB",
        balas: "BNB",
        trdas: "RAY",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "RAYBUSD",
        balas: "BUSD",
        trdas: "RAY",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "RAYUSDT",
        balas: "USDT",
        trdas: "RAY",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "FARMBTC",
        balas: "BTC",
        trdas: "FARM",
        scnt: 1,
        pdec: 6,
        sdec: 3
      },
      {
        tk: "FARMBNB",
        balas: "BNB",
        trdas: "FARM",
        scnt: 1,
        pdec: 4,
        sdec: 3
      },
      {
        tk: "FARMBUSD",
        balas: "BUSD",
        trdas: "FARM",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "FARMUSDT",
        balas: "USDT",
        trdas: "FARM",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "ALPACABTC",
        balas: "BTC",
        trdas: "ALPACA",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "ALPACABNB",
        balas: "BNB",
        trdas: "ALPACA",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "ALPACABUSD",
        balas: "BUSD",
        trdas: "ALPACA",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "ALPACAUSDT",
        balas: "USDT",
        trdas: "ALPACA",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "TLMTRY",
        balas: "TRY",
        trdas: "TLM",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "QUICKUSDT",
        balas: "USDT",
        trdas: "QUICK",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "ORNBUSD",
        balas: "BUSD",
        trdas: "ORN",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "MBOXBTC",
        balas: "BTC",
        trdas: "MBOX",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "MBOXBNB",
        balas: "BNB",
        trdas: "MBOX",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "MBOXBUSD",
        balas: "BUSD",
        trdas: "MBOX",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "MBOXUSDT",
        balas: "USDT",
        trdas: "MBOX",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "VGXBTC",
        balas: "BTC",
        trdas: "VGX",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "VGXETH",
        balas: "ETH",
        trdas: "VGX",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "FORUSDT",
        balas: "USDT",
        trdas: "FOR",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "REQUSDT",
        balas: "USDT",
        trdas: "REQ",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "GHSTUSDT",
        balas: "USDT",
        trdas: "GHST",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "TRURUB",
        balas: "RUB",
        trdas: "TRU",
        scnt: 1,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "FISBRL",
        balas: "BRL",
        trdas: "FIS",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "WAXPUSDT",
        balas: "USDT",
        trdas: "WAXP",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "WAXPBUSD",
        balas: "BUSD",
        trdas: "WAXP",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "WAXPBNB",
        balas: "BNB",
        trdas: "WAXP",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "WAXPBTC",
        balas: "BTC",
        trdas: "WAXP",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "TRIBEBTC",
        balas: "BTC",
        trdas: "TRIBE",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "TRIBEBNB",
        balas: "BNB",
        trdas: "TRIBE",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "TRIBEBUSD",
        balas: "BUSD",
        trdas: "TRIBE",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "TRIBEUSDT",
        balas: "USDT",
        trdas: "TRIBE",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "GNOUSDT",
        balas: "USDT",
        trdas: "GNO",
        scnt: 1,
        pdec: 1,
        sdec: 3
      },
      {
        tk: "GNOBUSD",
        balas: "BUSD",
        trdas: "GNO",
        scnt: 1,
        pdec: 1,
        sdec: 3
      },
      {
        tk: "GNOBNB",
        balas: "BNB",
        trdas: "GNO",
        scnt: 1,
        pdec: 4,
        sdec: 3
      },
      {
        tk: "GNOBTC",
        balas: "BTC",
        trdas: "GNO",
        scnt: 1,
        pdec: 6,
        sdec: 3
      },
      {
        tk: "ARPATRY",
        balas: "TRY",
        trdas: "ARPA",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "PROMBTC",
        balas: "BTC",
        trdas: "PROM",
        scnt: 1,
        pdec: 7,
        sdec: 2
      },
      {
        tk: "MTLBUSD",
        balas: "BUSD",
        trdas: "MTL",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "OGNBUSD",
        balas: "BUSD",
        trdas: "OGN",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "XECUSDT",
        balas: "USDT",
        trdas: "XEC",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "C98BRL",
        balas: "BRL",
        trdas: "C98",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "SOLAUD",
        balas: "AUD",
        trdas: "SOL",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "XRPBIDR",
        balas: "BIDR",
        trdas: "XRP",
        scnt: 1,
        pdec: 0,
        sdec: 1
      },
      {
        tk: "POLYBUSD",
        balas: "BUSD",
        trdas: "POLY",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "ELFUSDT",
        balas: "USDT",
        trdas: "ELF",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "DYDXUSDT",
        balas: "USDT",
        trdas: "DYDX",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "DYDXBUSD",
        balas: "BUSD",
        trdas: "DYDX",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "DYDXBNB",
        balas: "BNB",
        trdas: "DYDX",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "DYDXBTC",
        balas: "BTC",
        trdas: "DYDX",
        scnt: 1,
        pdec: 8,
        sdec: 2
      },
      {
        tk: "ELFBUSD",
        balas: "BUSD",
        trdas: "ELF",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "POLYUSDT",
        balas: "USDT",
        trdas: "POLY",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "IDEXUSDT",
        balas: "USDT",
        trdas: "IDEX",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "VIDTUSDT",
        balas: "USDT",
        trdas: "VIDT",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "SOLBIDR",
        balas: "BIDR",
        trdas: "SOL",
        scnt: 1,
        pdec: 0,
        sdec: 4
      },
      {
        tk: "BTCUSDP",
        balas: "USDP",
        trdas: "BTC",
        scnt: 1,
        pdec: 2,
        sdec: 5
      },
      {
        tk: "ETHUSDP",
        balas: "USDP",
        trdas: "ETH",
        scnt: 1,
        pdec: 2,
        sdec: 4
      },
      {
        tk: "BNBUSDP",
        balas: "USDP",
        trdas: "BNB",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "USDPBUSD",
        balas: "BUSD",
        trdas: "USDP",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "USDPUSDT",
        balas: "USDT",
        trdas: "USDP",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "GALAUSDT",
        balas: "USDT",
        trdas: "GALA",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "GALABUSD",
        balas: "BUSD",
        trdas: "GALA",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "GALABNB",
        balas: "BNB",
        trdas: "GALA",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "GALABTC",
        balas: "BTC",
        trdas: "GALA",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "FTMBIDR",
        balas: "BIDR",
        trdas: "FTM",
        scnt: 1,
        pdec: 0,
        sdec: 1
      },
      {
        tk: "ALGOBIDR",
        balas: "BIDR",
        trdas: "ALGO",
        scnt: 1,
        pdec: 0,
        sdec: 2
      },
      {
        tk: "CAKEAUD",
        balas: "AUD",
        trdas: "CAKE",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "KSMAUD",
        balas: "AUD",
        trdas: "KSM",
        scnt: 1,
        pdec: 1,
        sdec: 3
      },
      {
        tk: "WAVESRUB",
        balas: "RUB",
        trdas: "WAVES",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "SUNBUSD",
        balas: "BUSD",
        trdas: "SUN",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "ILVUSDT",
        balas: "USDT",
        trdas: "ILV",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "ILVBUSD",
        balas: "BUSD",
        trdas: "ILV",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "ILVBNB",
        balas: "BNB",
        trdas: "ILV",
        scnt: 1,
        pdec: 4,
        sdec: 3
      },
      {
        tk: "ILVBTC",
        balas: "BTC",
        trdas: "ILV",
        scnt: 1,
        pdec: 6,
        sdec: 3
      },
      {
        tk: "RENBUSD",
        balas: "BUSD",
        trdas: "REN",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "YGGUSDT",
        balas: "USDT",
        trdas: "YGG",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "YGGBUSD",
        balas: "BUSD",
        trdas: "YGG",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "YGGBNB",
        balas: "BNB",
        trdas: "YGG",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "YGGBTC",
        balas: "BTC",
        trdas: "YGG",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "STXBUSD",
        balas: "BUSD",
        trdas: "STX",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "SYSUSDT",
        balas: "USDT",
        trdas: "SYS",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "DFUSDT",
        balas: "USDT",
        trdas: "DF",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "SOLUSDC",
        balas: "USDC",
        trdas: "SOL",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "ARPARUB",
        balas: "RUB",
        trdas: "ARPA",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "LTCUAH",
        balas: "UAH",
        trdas: "LTC",
        scnt: 1,
        pdec: 0,
        sdec: 3
      },
      {
        tk: "FETBUSD",
        balas: "BUSD",
        trdas: "FET",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "ARPABUSD",
        balas: "BUSD",
        trdas: "ARPA",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "LSKBUSD",
        balas: "BUSD",
        trdas: "LSK",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "AVAXBIDR",
        balas: "BIDR",
        trdas: "AVAX",
        scnt: 1,
        pdec: 0,
        sdec: 2
      },
      {
        tk: "ALICEBIDR",
        balas: "BIDR",
        trdas: "ALICE",
        scnt: 1,
        pdec: 0,
        sdec: 2
      },
      {
        tk: "FIDAUSDT",
        balas: "USDT",
        trdas: "FIDA",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "FIDABUSD",
        balas: "BUSD",
        trdas: "FIDA",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "FIDABNB",
        balas: "BNB",
        trdas: "FIDA",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "FIDABTC",
        balas: "BTC",
        trdas: "FIDA",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "DENTBUSD",
        balas: "BUSD",
        trdas: "DENT",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "FRONTUSDT",
        balas: "USDT",
        trdas: "FRONT",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "CVPUSDT",
        balas: "USDT",
        trdas: "CVP",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "AGLDBTC",
        balas: "BTC",
        trdas: "AGLD",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "AGLDBNB",
        balas: "BNB",
        trdas: "AGLD",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "AGLDBUSD",
        balas: "BUSD",
        trdas: "AGLD",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "AGLDUSDT",
        balas: "USDT",
        trdas: "AGLD",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "RADBTC",
        balas: "BTC",
        trdas: "RAD",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "RADBNB",
        balas: "BNB",
        trdas: "RAD",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "RADBUSD",
        balas: "BUSD",
        trdas: "RAD",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "RADUSDT",
        balas: "USDT",
        trdas: "RAD",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "UNIAUD",
        balas: "AUD",
        trdas: "UNI",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "HIVEBUSD",
        balas: "BUSD",
        trdas: "HIVE",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "STPTBUSD",
        balas: "BUSD",
        trdas: "STPT",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "BETABTC",
        balas: "BTC",
        trdas: "BETA",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "BETABNB",
        balas: "BNB",
        trdas: "BETA",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "BETABUSD",
        balas: "BUSD",
        trdas: "BETA",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "BETAUSDT",
        balas: "USDT",
        trdas: "BETA",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "SHIBAUD",
        balas: "AUD",
        trdas: "SHIB",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "RAREBTC",
        balas: "BTC",
        trdas: "RARE",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "RAREBNB",
        balas: "BNB",
        trdas: "RARE",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "RAREBUSD",
        balas: "BUSD",
        trdas: "RARE",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "RAREUSDT",
        balas: "USDT",
        trdas: "RARE",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "AVAXBRL",
        balas: "BRL",
        trdas: "AVAX",
        scnt: 1,
        pdec: 1,
        sdec: 2
      },
      {
        tk: "AVAXAUD",
        balas: "AUD",
        trdas: "AVAX",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "LUNAAUD",
        balas: "AUD",
        trdas: "LUNA",
        scnt: 1,
        pdec: 5,
        sdec: 2
      },
      {
        tk: "TROYBUSD",
        balas: "BUSD",
        trdas: "TROY",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "AXSETH",
        balas: "ETH",
        trdas: "AXS",
        scnt: 1,
        pdec: 6,
        sdec: 3
      },
      {
        tk: "FTMETH",
        balas: "ETH",
        trdas: "FTM",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "SOLETH",
        balas: "ETH",
        trdas: "SOL",
        scnt: 1,
        pdec: 5,
        sdec: 3
      },
      {
        tk: "SSVBTC",
        balas: "BTC",
        trdas: "SSV",
        scnt: 1,
        pdec: 7,
        sdec: 2
      },
      {
        tk: "SSVETH",
        balas: "ETH",
        trdas: "SSV",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "LAZIOTRY",
        balas: "TRY",
        trdas: "LAZIO",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "LAZIOEUR",
        balas: "EUR",
        trdas: "LAZIO",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "LAZIOBTC",
        balas: "BTC",
        trdas: "LAZIO",
        scnt: 1,
        pdec: 8,
        sdec: 2
      },
      {
        tk: "LAZIOUSDT",
        balas: "USDT",
        trdas: "LAZIO",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "CHESSBTC",
        balas: "BTC",
        trdas: "CHESS",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "CHESSBNB",
        balas: "BNB",
        trdas: "CHESS",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "CHESSBUSD",
        balas: "BUSD",
        trdas: "CHESS",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "CHESSUSDT",
        balas: "USDT",
        trdas: "CHESS",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "FTMAUD",
        balas: "AUD",
        trdas: "FTM",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "FTMBRL",
        balas: "BRL",
        trdas: "FTM",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "SCRTBUSD",
        balas: "BUSD",
        trdas: "SCRT",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "ADXUSDT",
        balas: "USDT",
        trdas: "ADX",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "AUCTIONUSDT",
        balas: "USDT",
        trdas: "AUCTION",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "CELOBUSD",
        balas: "BUSD",
        trdas: "CELO",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "FTMRUB",
        balas: "RUB",
        trdas: "FTM",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "NUAUD",
        balas: "AUD",
        trdas: "NU",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "NURUB",
        balas: "RUB",
        trdas: "NU",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "REEFTRY",
        balas: "TRY",
        trdas: "REEF",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "REEFBIDR",
        balas: "BIDR",
        trdas: "REEF",
        scnt: 1,
        pdec: 1,
        sdec: 0
      },
      {
        tk: "SHIBDOGE",
        balas: "DOGE",
        trdas: "SHIB",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "DARUSDT",
        balas: "USDT",
        trdas: "DAR",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "DARBUSD",
        balas: "BUSD",
        trdas: "DAR",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "DARBNB",
        balas: "BNB",
        trdas: "DAR",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "DARBTC",
        balas: "BTC",
        trdas: "DAR",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "BNXBTC",
        balas: "BTC",
        trdas: "BNX",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "BNXBNB",
        balas: "BNB",
        trdas: "BNX",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "BNXBUSD",
        balas: "BUSD",
        trdas: "BNX",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "BNXUSDT",
        balas: "USDT",
        trdas: "BNX",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "RGTUSDT",
        balas: "USDT",
        trdas: "RGT",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "RGTBTC",
        balas: "BTC",
        trdas: "RGT",
        scnt: 1,
        pdec: 7,
        sdec: 2
      },
      {
        tk: "RGTBUSD",
        balas: "BUSD",
        trdas: "RGT",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "RGTBNB",
        balas: "BNB",
        trdas: "RGT",
        scnt: 1,
        pdec: 5,
        sdec: 2
      },
      {
        tk: "LAZIOBUSD",
        balas: "BUSD",
        trdas: "LAZIO",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "OXTBUSD",
        balas: "BUSD",
        trdas: "OXT",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "MANATRY",
        balas: "TRY",
        trdas: "MANA",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "ALGORUB",
        balas: "RUB",
        trdas: "ALGO",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "SHIBUAH",
        balas: "UAH",
        trdas: "SHIB",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "LUNABIDR",
        balas: "BIDR",
        trdas: "LUNA",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "AUDUSDC",
        balas: "USDC",
        trdas: "AUD",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "MOVRBTC",
        balas: "BTC",
        trdas: "MOVR",
        scnt: 1,
        pdec: 7,
        sdec: 3
      },
      {
        tk: "MOVRBNB",
        balas: "BNB",
        trdas: "MOVR",
        scnt: 1,
        pdec: 4,
        sdec: 3
      },
      {
        tk: "MOVRBUSD",
        balas: "BUSD",
        trdas: "MOVR",
        scnt: 1,
        pdec: 3,
        sdec: 3
      },
      {
        tk: "MOVRUSDT",
        balas: "USDT",
        trdas: "MOVR",
        scnt: 1,
        pdec: 3,
        sdec: 3
      },
      {
        tk: "CITYBTC",
        balas: "BTC",
        trdas: "CITY",
        scnt: 1,
        pdec: 7,
        sdec: 2
      },
      {
        tk: "CITYBNB",
        balas: "BNB",
        trdas: "CITY",
        scnt: 1,
        pdec: 5,
        sdec: 2
      },
      {
        tk: "CITYBUSD",
        balas: "BUSD",
        trdas: "CITY",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "CITYUSDT",
        balas: "USDT",
        trdas: "CITY",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "ENSBTC",
        balas: "BTC",
        trdas: "ENS",
        scnt: 1,
        pdec: 7,
        sdec: 2
      },
      {
        tk: "ENSBNB",
        balas: "BNB",
        trdas: "ENS",
        scnt: 1,
        pdec: 5,
        sdec: 2
      },
      {
        tk: "ENSBUSD",
        balas: "BUSD",
        trdas: "ENS",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "ENSUSDT",
        balas: "USDT",
        trdas: "ENS",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "SANDETH",
        balas: "ETH",
        trdas: "SAND",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "DOTETH",
        balas: "ETH",
        trdas: "DOT",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "MATICETH",
        balas: "ETH",
        trdas: "MATIC",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "ANKRBUSD",
        balas: "BUSD",
        trdas: "ANKR",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "SANDTRY",
        balas: "TRY",
        trdas: "SAND",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "MANABRL",
        balas: "BRL",
        trdas: "MANA",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "KP3RUSDT",
        balas: "USDT",
        trdas: "KP3R",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "QIUSDT",
        balas: "USDT",
        trdas: "QI",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "QIBUSD",
        balas: "BUSD",
        trdas: "QI",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "QIBNB",
        balas: "BNB",
        trdas: "QI",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "QIBTC",
        balas: "BTC",
        trdas: "QI",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "PORTOBTC",
        balas: "BTC",
        trdas: "PORTO",
        scnt: 1,
        pdec: 8,
        sdec: 2
      },
      {
        tk: "PORTOUSDT",
        balas: "USDT",
        trdas: "PORTO",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "PORTOTRY",
        balas: "TRY",
        trdas: "PORTO",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "PORTOEUR",
        balas: "EUR",
        trdas: "PORTO",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "POWRUSDT",
        balas: "USDT",
        trdas: "POWR",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "POWRBUSD",
        balas: "BUSD",
        trdas: "POWR",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "AVAXETH",
        balas: "ETH",
        trdas: "AVAX",
        scnt: 1,
        pdec: 5,
        sdec: 2
      },
      {
        tk: "SLPTRY",
        balas: "TRY",
        trdas: "SLP",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "FISTRY",
        balas: "TRY",
        trdas: "FIS",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "LRCTRY",
        balas: "TRY",
        trdas: "LRC",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "CHRETH",
        balas: "ETH",
        trdas: "CHR",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "FISBIDR",
        balas: "BIDR",
        trdas: "FIS",
        scnt: 1,
        pdec: 0,
        sdec: 1
      },
      {
        tk: "VGXUSDT",
        balas: "USDT",
        trdas: "VGX",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "GALAETH",
        balas: "ETH",
        trdas: "GALA",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "JASMYUSDT",
        balas: "USDT",
        trdas: "JASMY",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "JASMYBUSD",
        balas: "BUSD",
        trdas: "JASMY",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "JASMYBNB",
        balas: "BNB",
        trdas: "JASMY",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "JASMYBTC",
        balas: "BTC",
        trdas: "JASMY",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "AMPBTC",
        balas: "BTC",
        trdas: "AMP",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "AMPBNB",
        balas: "BNB",
        trdas: "AMP",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "AMPBUSD",
        balas: "BUSD",
        trdas: "AMP",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "AMPUSDT",
        balas: "USDT",
        trdas: "AMP",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "PLABTC",
        balas: "BTC",
        trdas: "PLA",
        scnt: 1,
        pdec: 8,
        sdec: 2
      },
      {
        tk: "PLABNB",
        balas: "BNB",
        trdas: "PLA",
        scnt: 1,
        pdec: 7,
        sdec: 2
      },
      {
        tk: "PLABUSD",
        balas: "BUSD",
        trdas: "PLA",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "PLAUSDT",
        balas: "USDT",
        trdas: "PLA",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "PYRBTC",
        balas: "BTC",
        trdas: "PYR",
        scnt: 1,
        pdec: 7,
        sdec: 3
      },
      {
        tk: "PYRBUSD",
        balas: "BUSD",
        trdas: "PYR",
        scnt: 1,
        pdec: 3,
        sdec: 3
      },
      {
        tk: "PYRUSDT",
        balas: "USDT",
        trdas: "PYR",
        scnt: 1,
        pdec: 3,
        sdec: 3
      },
      {
        tk: "RNDRBTC",
        balas: "BTC",
        trdas: "RNDR",
        scnt: 1,
        pdec: 7,
        sdec: 2
      },
      {
        tk: "RNDRUSDT",
        balas: "USDT",
        trdas: "RNDR",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "RNDRBUSD",
        balas: "BUSD",
        trdas: "RNDR",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "ALCXBTC",
        balas: "BTC",
        trdas: "ALCX",
        scnt: 1,
        pdec: 7,
        sdec: 4
      },
      {
        tk: "ALCXBUSD",
        balas: "BUSD",
        trdas: "ALCX",
        scnt: 1,
        pdec: 2,
        sdec: 4
      },
      {
        tk: "ALCXUSDT",
        balas: "USDT",
        trdas: "ALCX",
        scnt: 1,
        pdec: 2,
        sdec: 4
      },
      {
        tk: "SANTOSBTC",
        balas: "BTC",
        trdas: "SANTOS",
        scnt: 1,
        pdec: 8,
        sdec: 2
      },
      {
        tk: "SANTOSUSDT",
        balas: "USDT",
        trdas: "SANTOS",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "SANTOSBRL",
        balas: "BRL",
        trdas: "SANTOS",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "SANTOSTRY",
        balas: "TRY",
        trdas: "SANTOS",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "MCBTC",
        balas: "BTC",
        trdas: "MC",
        scnt: 1,
        pdec: 8,
        sdec: 2
      },
      {
        tk: "MCBUSD",
        balas: "BUSD",
        trdas: "MC",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "MCUSDT",
        balas: "USDT",
        trdas: "MC",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "BELTRY",
        balas: "TRY",
        trdas: "BEL",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "COCOSBUSD",
        balas: "BUSD",
        trdas: "COCOS",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "DENTTRY",
        balas: "TRY",
        trdas: "DENT",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "ENJTRY",
        balas: "TRY",
        trdas: "ENJ",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "NEORUB",
        balas: "RUB",
        trdas: "NEO",
        scnt: 1,
        pdec: 0,
        sdec: 3
      },
      {
        tk: "SANDAUD",
        balas: "AUD",
        trdas: "SAND",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "SLPBIDR",
        balas: "BIDR",
        trdas: "SLP",
        scnt: 1,
        pdec: 1,
        sdec: 0
      },
      {
        tk: "ANYBTC",
        balas: "BTC",
        trdas: "ANY",
        scnt: 1,
        pdec: 7,
        sdec: 2
      },
      {
        tk: "ANYBUSD",
        balas: "BUSD",
        trdas: "ANY",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "ANYUSDT",
        balas: "USDT",
        trdas: "ANY",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "BICOBTC",
        balas: "BTC",
        trdas: "BICO",
        scnt: 1,
        pdec: 8,
        sdec: 2
      },
      {
        tk: "BICOBUSD",
        balas: "BUSD",
        trdas: "BICO",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "BICOUSDT",
        balas: "USDT",
        trdas: "BICO",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "FLUXBTC",
        balas: "BTC",
        trdas: "FLUX",
        scnt: 1,
        pdec: 8,
        sdec: 2
      },
      {
        tk: "FLUXBUSD",
        balas: "BUSD",
        trdas: "FLUX",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "FLUXUSDT",
        balas: "USDT",
        trdas: "FLUX",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "ALICETRY",
        balas: "TRY",
        trdas: "ALICE",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "FXSUSDT",
        balas: "USDT",
        trdas: "FXS",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "GALABRL",
        balas: "BRL",
        trdas: "GALA",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "GALATRY",
        balas: "TRY",
        trdas: "GALA",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "LUNATRY",
        balas: "TRY",
        trdas: "LUNA",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "REQBUSD",
        balas: "BUSD",
        trdas: "REQ",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "SANDBRL",
        balas: "BRL",
        trdas: "SAND",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "MANABIDR",
        balas: "BIDR",
        trdas: "MANA",
        scnt: 1,
        pdec: 0,
        sdec: 2
      },
      {
        tk: "SANDBIDR",
        balas: "BIDR",
        trdas: "SAND",
        scnt: 1,
        pdec: 0,
        sdec: 2
      },
      {
        tk: "VOXELBTC",
        balas: "BTC",
        trdas: "VOXEL",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "VOXELBNB",
        balas: "BNB",
        trdas: "VOXEL",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "VOXELBUSD",
        balas: "BUSD",
        trdas: "VOXEL",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "VOXELUSDT",
        balas: "USDT",
        trdas: "VOXEL",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "COSBUSD",
        balas: "BUSD",
        trdas: "COS",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "CTXCBUSD",
        balas: "BUSD",
        trdas: "CTXC",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "FTMTRY",
        balas: "TRY",
        trdas: "FTM",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "MANABNB",
        balas: "BNB",
        trdas: "MANA",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "MINATRY",
        balas: "TRY",
        trdas: "MINA",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "XTZTRY",
        balas: "TRY",
        trdas: "XTZ",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "HIGHBTC",
        balas: "BTC",
        trdas: "HIGH",
        scnt: 1,
        pdec: 8,
        sdec: 3
      },
      {
        tk: "HIGHBUSD",
        balas: "BUSD",
        trdas: "HIGH",
        scnt: 1,
        pdec: 3,
        sdec: 3
      },
      {
        tk: "HIGHUSDT",
        balas: "USDT",
        trdas: "HIGH",
        scnt: 1,
        pdec: 3,
        sdec: 3
      },
      {
        tk: "CVXBTC",
        balas: "BTC",
        trdas: "CVX",
        scnt: 1,
        pdec: 7,
        sdec: 3
      },
      {
        tk: "CVXBUSD",
        balas: "BUSD",
        trdas: "CVX",
        scnt: 1,
        pdec: 3,
        sdec: 3
      },
      {
        tk: "CVXUSDT",
        balas: "USDT",
        trdas: "CVX",
        scnt: 1,
        pdec: 3,
        sdec: 3
      },
      {
        tk: "PEOPLEBTC",
        balas: "BTC",
        trdas: "PEOPLE",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "PEOPLEBUSD",
        balas: "BUSD",
        trdas: "PEOPLE",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "PEOPLEUSDT",
        balas: "USDT",
        trdas: "PEOPLE",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "OOKIBUSD",
        balas: "BUSD",
        trdas: "OOKI",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "OOKIUSDT",
        balas: "USDT",
        trdas: "OOKI",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "COCOSTRY",
        balas: "TRY",
        trdas: "COCOS",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "GXSBNB",
        balas: "BNB",
        trdas: "GXS",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "LINKBNB",
        balas: "BNB",
        trdas: "LINK",
        scnt: 1,
        pdec: 5,
        sdec: 3
      },
      {
        tk: "LUNAETH",
        balas: "ETH",
        trdas: "LUNA",
        scnt: 1,
        pdec: 8,
        sdec: 3
      },
      {
        tk: "MDTBUSD",
        balas: "BUSD",
        trdas: "MDT",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "NULSBUSD",
        balas: "BUSD",
        trdas: "NULS",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "SPELLBTC",
        balas: "BTC",
        trdas: "SPELL",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "SPELLUSDT",
        balas: "USDT",
        trdas: "SPELL",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "SPELLBUSD",
        balas: "BUSD",
        trdas: "SPELL",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "USTBTC",
        balas: "BTC",
        trdas: "UST",
        scnt: 1,
        pdec: 8,
        sdec: 2
      },
      {
        tk: "USTBUSD",
        balas: "BUSD",
        trdas: "UST",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "USTUSDT",
        balas: "USDT",
        trdas: "UST",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "JOEBTC",
        balas: "BTC",
        trdas: "JOE",
        scnt: 1,
        pdec: 8,
        sdec: 2
      },
      {
        tk: "JOEBUSD",
        balas: "BUSD",
        trdas: "JOE",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "JOEUSDT",
        balas: "USDT",
        trdas: "JOE",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "ATOMETH",
        balas: "ETH",
        trdas: "ATOM",
        scnt: 1,
        pdec: 6,
        sdec: 3
      },
      {
        tk: "DUSKBUSD",
        balas: "BUSD",
        trdas: "DUSK",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "EGLDETH",
        balas: "ETH",
        trdas: "EGLD",
        scnt: 1,
        pdec: 5,
        sdec: 4
      },
      {
        tk: "ICPETH",
        balas: "ETH",
        trdas: "ICP",
        scnt: 1,
        pdec: 6,
        sdec: 3
      },
      {
        tk: "LUNABRL",
        balas: "BRL",
        trdas: "LUNA",
        scnt: 1,
        pdec: 4,
        sdec: 3
      },
      {
        tk: "LUNAUST",
        balas: "UST",
        trdas: "LUNA",
        scnt: 1,
        pdec: 7,
        sdec: 2
      },
      {
        tk: "NEARETH",
        balas: "ETH",
        trdas: "NEAR",
        scnt: 1,
        pdec: 6,
        sdec: 3
      },
      {
        tk: "ROSEBNB",
        balas: "BNB",
        trdas: "ROSE",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "VOXELETH",
        balas: "ETH",
        trdas: "VOXEL",
        scnt: 1,
        pdec: 7,
        sdec: 2
      },
      {
        tk: "ALICEBNB",
        balas: "BNB",
        trdas: "ALICE",
        scnt: 1,
        pdec: 6,
        sdec: 3
      },
      {
        tk: "ATOMTRY",
        balas: "TRY",
        trdas: "ATOM",
        scnt: 1,
        pdec: 1,
        sdec: 3
      },
      {
        tk: "ETHUST",
        balas: "UST",
        trdas: "ETH",
        scnt: 1,
        pdec: 2,
        sdec: 4
      },
      {
        tk: "GALAAUD",
        balas: "AUD",
        trdas: "GALA",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "LRCBNB",
        balas: "BNB",
        trdas: "LRC",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "ONEETH",
        balas: "ETH",
        trdas: "ONE",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "OOKIBNB",
        balas: "BNB",
        trdas: "OOKI",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "ACHBTC",
        balas: "BTC",
        trdas: "ACH",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "ACHBUSD",
        balas: "BUSD",
        trdas: "ACH",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "ACHUSDT",
        balas: "USDT",
        trdas: "ACH",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "IMXBTC",
        balas: "BTC",
        trdas: "IMX",
        scnt: 1,
        pdec: 8,
        sdec: 2
      },
      {
        tk: "IMXBUSD",
        balas: "BUSD",
        trdas: "IMX",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "IMXUSDT",
        balas: "USDT",
        trdas: "IMX",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "GLMRBTC",
        balas: "BTC",
        trdas: "GLMR",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "GLMRBUSD",
        balas: "BUSD",
        trdas: "GLMR",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "GLMRUSDT",
        balas: "USDT",
        trdas: "GLMR",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "ATOMBIDR",
        balas: "BIDR",
        trdas: "ATOM",
        scnt: 1,
        pdec: 0,
        sdec: 3
      },
      {
        tk: "DYDXETH",
        balas: "ETH",
        trdas: "DYDX",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "FARMETH",
        balas: "ETH",
        trdas: "FARM",
        scnt: 1,
        pdec: 5,
        sdec: 4
      },
      {
        tk: "FORBNB",
        balas: "BNB",
        trdas: "FOR",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "ICPTRY",
        balas: "TRY",
        trdas: "ICP",
        scnt: 1,
        pdec: 1,
        sdec: 3
      },
      {
        tk: "JASMYETH",
        balas: "ETH",
        trdas: "JASMY",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "LINABNB",
        balas: "BNB",
        trdas: "LINA",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "OOKIETH",
        balas: "ETH",
        trdas: "OOKI",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "ROSEETH",
        balas: "ETH",
        trdas: "ROSE",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "UMABUSD",
        balas: "BUSD",
        trdas: "UMA",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "UNIETH",
        balas: "ETH",
        trdas: "UNI",
        scnt: 1,
        pdec: 6,
        sdec: 3
      },
      {
        tk: "XTZETH",
        balas: "ETH",
        trdas: "XTZ",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "LOKABTC",
        balas: "BTC",
        trdas: "LOKA",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "LOKABNB",
        balas: "BNB",
        trdas: "LOKA",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "LOKABUSD",
        balas: "BUSD",
        trdas: "LOKA",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "LOKAUSDT",
        balas: "USDT",
        trdas: "LOKA",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "ATOMBRL",
        balas: "BRL",
        trdas: "ATOM",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "BNBUST",
        balas: "UST",
        trdas: "BNB",
        scnt: 1,
        pdec: 1,
        sdec: 3
      },
      {
        tk: "CRVETH",
        balas: "ETH",
        trdas: "CRV",
        scnt: 1,
        pdec: 7,
        sdec: 2
      },
      {
        tk: "HIGHBNB",
        balas: "BNB",
        trdas: "HIGH",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "NEARRUB",
        balas: "RUB",
        trdas: "NEAR",
        scnt: 1,
        pdec: 1,
        sdec: 3
      },
      {
        tk: "ROSETRY",
        balas: "TRY",
        trdas: "ROSE",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "SCRTUSDT",
        balas: "USDT",
        trdas: "SCRT",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "API3BTC",
        balas: "BTC",
        trdas: "API3",
        scnt: 1,
        pdec: 8,
        sdec: 2
      },
      {
        tk: "API3BUSD",
        balas: "BUSD",
        trdas: "API3",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "API3USDT",
        balas: "USDT",
        trdas: "API3",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "BTTCUSDT",
        balas: "USDT",
        trdas: "BTTC",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "BTTCUSDC",
        balas: "USDC",
        trdas: "BTTC",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "BTTCTRY",
        balas: "TRY",
        trdas: "BTTC",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "ACABTC",
        balas: "BTC",
        trdas: "ACA",
        scnt: 1,
        pdec: 8,
        sdec: 2
      },
      {
        tk: "ACABUSD",
        balas: "BUSD",
        trdas: "ACA",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "ACAUSDT",
        balas: "USDT",
        trdas: "ACA",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "ANCBTC",
        balas: "BTC",
        trdas: "ANC",
        scnt: 1,
        pdec: 8,
        sdec: 2
      },
      {
        tk: "ANCBUSD",
        balas: "BUSD",
        trdas: "ANC",
        scnt: 1,
        pdec: 5,
        sdec: 2
      },
      {
        tk: "ANCUSDT",
        balas: "USDT",
        trdas: "ANC",
        scnt: 1,
        pdec: 5,
        sdec: 2
      },
      {
        tk: "BDOTDOT",
        balas: "DOT",
        trdas: "BDOT",
        scnt: 1,
        pdec: 4,
        sdec: 3
      },
      {
        tk: "XNOBTC",
        balas: "BTC",
        trdas: "XNO",
        scnt: 1,
        pdec: 8,
        sdec: 2
      },
      {
        tk: "XNOETH",
        balas: "ETH",
        trdas: "XNO",
        scnt: 1,
        pdec: 7,
        sdec: 2
      },
      {
        tk: "XNOBUSD",
        balas: "BUSD",
        trdas: "XNO",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "XNOUSDT",
        balas: "USDT",
        trdas: "XNO",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "COSTRY",
        balas: "TRY",
        trdas: "COS",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "KAVAETH",
        balas: "ETH",
        trdas: "KAVA",
        scnt: 1,
        pdec: 7,
        sdec: 2
      },
      {
        tk: "MCBNB",
        balas: "BNB",
        trdas: "MC",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "ONETRY",
        balas: "TRY",
        trdas: "ONE",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "WOOBTC",
        balas: "BTC",
        trdas: "WOO",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "WOOBNB",
        balas: "BNB",
        trdas: "WOO",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "WOOBUSD",
        balas: "BUSD",
        trdas: "WOO",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "WOOUSDT",
        balas: "USDT",
        trdas: "WOO",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "CELRETH",
        balas: "ETH",
        trdas: "CELR",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "PEOPLEBNB",
        balas: "BNB",
        trdas: "PEOPLE",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "SLPBNB",
        balas: "BNB",
        trdas: "SLP",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "SPELLBNB",
        balas: "BNB",
        trdas: "SPELL",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "SPELLTRY",
        balas: "TRY",
        trdas: "SPELL",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "TFUELBUSD",
        balas: "BUSD",
        trdas: "TFUEL",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "AXSTRY",
        balas: "TRY",
        trdas: "AXS",
        scnt: 1,
        pdec: 1,
        sdec: 3
      },
      {
        tk: "DARTRY",
        balas: "TRY",
        trdas: "DAR",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "NEARTRY",
        balas: "TRY",
        trdas: "NEAR",
        scnt: 1,
        pdec: 1,
        sdec: 3
      },
      {
        tk: "IDEXBNB",
        balas: "BNB",
        trdas: "IDEX",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "ALPINEEUR",
        balas: "EUR",
        trdas: "ALPINE",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "ALPINETRY",
        balas: "TRY",
        trdas: "ALPINE",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "ALPINEUSDT",
        balas: "USDT",
        trdas: "ALPINE",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "ALPINEBTC",
        balas: "BTC",
        trdas: "ALPINE",
        scnt: 1,
        pdec: 8,
        sdec: 2
      },
      {
        tk: "TUSDT",
        balas: "USDT",
        trdas: "T",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "TBUSD",
        balas: "BUSD",
        trdas: "T",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "API3BNB",
        balas: "BNB",
        trdas: "API3",
        scnt: 1,
        pdec: 5,
        sdec: 2
      },
      {
        tk: "BETAETH",
        balas: "ETH",
        trdas: "BETA",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "INJTRY",
        balas: "TRY",
        trdas: "INJ",
        scnt: 1,
        pdec: 1,
        sdec: 2
      },
      {
        tk: "TLMBNB",
        balas: "BNB",
        trdas: "TLM",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "ASTRBUSD",
        balas: "BUSD",
        trdas: "ASTR",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "ASTRUSDT",
        balas: "USDT",
        trdas: "ASTR",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "API3TRY",
        balas: "TRY",
        trdas: "API3",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "GLMRBNB",
        balas: "BNB",
        trdas: "GLMR",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "MBOXTRY",
        balas: "TRY",
        trdas: "MBOX",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "GMTBTC",
        balas: "BTC",
        trdas: "GMT",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "GMTBNB",
        balas: "BNB",
        trdas: "GMT",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "GMTBUSD",
        balas: "BUSD",
        trdas: "GMT",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "GMTUSDT",
        balas: "USDT",
        trdas: "GMT",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "ANCBNB",
        balas: "BNB",
        trdas: "ANC",
        scnt: 1,
        pdec: 7,
        sdec: 2
      },
      {
        tk: "ATOMEUR",
        balas: "EUR",
        trdas: "ATOM",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "GALAEUR",
        balas: "EUR",
        trdas: "GALA",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "KSMETH",
        balas: "ETH",
        trdas: "KSM",
        scnt: 1,
        pdec: 5,
        sdec: 4
      },
      {
        tk: "UMATRY",
        balas: "TRY",
        trdas: "UMA",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "KDABTC",
        balas: "BTC",
        trdas: "KDA",
        scnt: 1,
        pdec: 8,
        sdec: 2
      },
      {
        tk: "KDABUSD",
        balas: "BUSD",
        trdas: "KDA",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "KDAUSDT",
        balas: "USDT",
        trdas: "KDA",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "APEUSDT",
        balas: "USDT",
        trdas: "APE",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "APEBUSD",
        balas: "BUSD",
        trdas: "APE",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "APEBTC",
        balas: "BTC",
        trdas: "APE",
        scnt: 1,
        pdec: 8,
        sdec: 2
      },
      {
        tk: "ALPINEBUSD",
        balas: "BUSD",
        trdas: "ALPINE",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "LUNAGBP",
        balas: "GBP",
        trdas: "LUNA",
        scnt: 1,
        pdec: 5,
        sdec: 2
      },
      {
        tk: "NEAREUR",
        balas: "EUR",
        trdas: "NEAR",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "TWTTRY",
        balas: "TRY",
        trdas: "TWT",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "WAVESEUR",
        balas: "EUR",
        trdas: "WAVES",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "APEEUR",
        balas: "EUR",
        trdas: "APE",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "APEGBP",
        balas: "GBP",
        trdas: "APE",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "APETRY",
        balas: "TRY",
        trdas: "APE",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "BSWUSDT",
        balas: "USDT",
        trdas: "BSW",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "BSWBUSD",
        balas: "BUSD",
        trdas: "BSW",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "BSWBNB",
        balas: "BNB",
        trdas: "BSW",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "APEBNB",
        balas: "BNB",
        trdas: "APE",
        scnt: 1,
        pdec: 6,
        sdec: 3
      },
      {
        tk: "GMTBRL",
        balas: "BRL",
        trdas: "GMT",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "GMTETH",
        balas: "ETH",
        trdas: "GMT",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "JASMYTRY",
        balas: "TRY",
        trdas: "JASMY",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "SANTOSBUSD",
        balas: "BUSD",
        trdas: "SANTOS",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "APEAUD",
        balas: "AUD",
        trdas: "APE",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "BIFIUSDT",
        balas: "USDT",
        trdas: "BIFI",
        scnt: 1,
        pdec: 1,
        sdec: 3
      },
      {
        tk: "GMTEUR",
        balas: "EUR",
        trdas: "GMT",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "IMXBNB",
        balas: "BNB",
        trdas: "IMX",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "RUNEETH",
        balas: "ETH",
        trdas: "RUNE",
        scnt: 1,
        pdec: 7,
        sdec: 3
      },
      {
        tk: "AVAXGBP",
        balas: "GBP",
        trdas: "AVAX",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "MULTIBTC",
        balas: "BTC",
        trdas: "MULTI",
        scnt: 1,
        pdec: 8,
        sdec: 3
      },
      {
        tk: "MULTIBUSD",
        balas: "BUSD",
        trdas: "MULTI",
        scnt: 1,
        pdec: 3,
        sdec: 3
      },
      {
        tk: "MULTIUSDT",
        balas: "USDT",
        trdas: "MULTI",
        scnt: 1,
        pdec: 3,
        sdec: 3
      },
      {
        tk: "APEETH",
        balas: "ETH",
        trdas: "APE",
        scnt: 1,
        pdec: 7,
        sdec: 3
      },
      {
        tk: "BSWETH",
        balas: "ETH",
        trdas: "BSW",
        scnt: 1,
        pdec: 7,
        sdec: 2
      },
      {
        tk: "FILTRY",
        balas: "TRY",
        trdas: "FIL",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "FTMEUR",
        balas: "EUR",
        trdas: "FTM",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "GMTGBP",
        balas: "GBP",
        trdas: "GMT",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "ZILTRY",
        balas: "TRY",
        trdas: "ZIL",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "GMTTRY",
        balas: "TRY",
        trdas: "GMT",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "WAVESTRY",
        balas: "TRY",
        trdas: "WAVES",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "BTCUST",
        balas: "UST",
        trdas: "BTC",
        scnt: 1,
        pdec: 2,
        sdec: 5
      },
      {
        tk: "ASTRBTC",
        balas: "BTC",
        trdas: "ASTR",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "ASTRETH",
        balas: "ETH",
        trdas: "ASTR",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "BSWTRY",
        balas: "TRY",
        trdas: "BSW",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "FTTETH",
        balas: "ETH",
        trdas: "FTT",
        scnt: 1,
        pdec: 6,
        sdec: 3
      },
      {
        tk: "FUNBNB",
        balas: "BNB",
        trdas: "FUN",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "PORTOBUSD",
        balas: "BUSD",
        trdas: "PORTO",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "STEEMUSDT",
        balas: "USDT",
        trdas: "STEEM",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "ZILEUR",
        balas: "EUR",
        trdas: "ZIL",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "APEBRL",
        balas: "BRL",
        trdas: "APE",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "AUDIOTRY",
        balas: "TRY",
        trdas: "AUDIO",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "BTTCBUSD",
        balas: "BUSD",
        trdas: "BTTC",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "GMTAUD",
        balas: "AUD",
        trdas: "GMT",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "MBLBUSD",
        balas: "BUSD",
        trdas: "MBL",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "MOBUSDT",
        balas: "USDT",
        trdas: "MOB",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "MOBBUSD",
        balas: "BUSD",
        trdas: "MOB",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "MOBBTC",
        balas: "BTC",
        trdas: "MOB",
        scnt: 1,
        pdec: 8,
        sdec: 2
      },
      {
        tk: "NEXOUSDT",
        balas: "USDT",
        trdas: "NEXO",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "NEXOBUSD",
        balas: "BUSD",
        trdas: "NEXO",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "NEXOBTC",
        balas: "BTC",
        trdas: "NEXO",
        scnt: 1,
        pdec: 8,
        sdec: 2
      },
      {
        tk: "REIUSDT",
        balas: "USDT",
        trdas: "REI",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "REIBNB",
        balas: "BNB",
        trdas: "REI",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "REIETH",
        balas: "ETH",
        trdas: "REI",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "GALUSDT",
        balas: "USDT",
        trdas: "GAL",
        scnt: 1,
        pdec: 3,
        sdec: 3
      },
      {
        tk: "GALBUSD",
        balas: "BUSD",
        trdas: "GAL",
        scnt: 1,
        pdec: 3,
        sdec: 3
      },
      {
        tk: "GALBNB",
        balas: "BNB",
        trdas: "GAL",
        scnt: 1,
        pdec: 6,
        sdec: 3
      },
      {
        tk: "GALBTC",
        balas: "BTC",
        trdas: "GAL",
        scnt: 1,
        pdec: 8,
        sdec: 3
      },
      {
        tk: "JASMYEUR",
        balas: "EUR",
        trdas: "JASMY",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "KNCBNB",
        balas: "BNB",
        trdas: "KNC",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "SHIBGBP",
        balas: "GBP",
        trdas: "SHIB",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "GALEUR",
        balas: "EUR",
        trdas: "GAL",
        scnt: 1,
        pdec: 3,
        sdec: 3
      },
      {
        tk: "GALTRY",
        balas: "TRY",
        trdas: "GAL",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "LDOBUSD",
        balas: "BUSD",
        trdas: "LDO",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "LDOUSDT",
        balas: "USDT",
        trdas: "LDO",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "LDOBTC",
        balas: "BTC",
        trdas: "LDO",
        scnt: 1,
        pdec: 8,
        sdec: 2
      },
      {
        tk: "ENSTRY",
        balas: "TRY",
        trdas: "ENS",
        scnt: 1,
        pdec: 1,
        sdec: 3
      },
      {
        tk: "DAREUR",
        balas: "EUR",
        trdas: "DAR",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "DARETH",
        balas: "ETH",
        trdas: "DAR",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "ALGOETH",
        balas: "ETH",
        trdas: "ALGO",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "ALGOTRY",
        balas: "TRY",
        trdas: "ALGO",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "GALETH",
        balas: "ETH",
        trdas: "GAL",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "EPXUSDT",
        balas: "USDT",
        trdas: "EPX",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "EPXBUSD",
        balas: "BUSD",
        trdas: "EPX",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "RUNETRY",
        balas: "TRY",
        trdas: "RUNE",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "GALBRL",
        balas: "BRL",
        trdas: "GAL",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "STEEMBUSD",
        balas: "BUSD",
        trdas: "STEEM",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "CVCBUSD",
        balas: "BUSD",
        trdas: "CVC",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "REIBUSD",
        balas: "BUSD",
        trdas: "REI",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "DREPBUSD",
        balas: "BUSD",
        trdas: "DREP",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "AKROBUSD",
        balas: "BUSD",
        trdas: "AKRO",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "PUNDIXBUSD",
        balas: "BUSD",
        trdas: "PUNDIX",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "LUNCBUSD",
        balas: "BUSD",
        trdas: "LUNC",
        scnt: 1,
        pdec: 8,
        sdec: 2
      },
      {
        tk: "USTCBUSD",
        balas: "BUSD",
        trdas: "USTC",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "OPBTC",
        balas: "BTC",
        trdas: "OP",
        scnt: 1,
        pdec: 8,
        sdec: 2
      },
      {
        tk: "OPBUSD",
        balas: "BUSD",
        trdas: "OP",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "OPUSDT",
        balas: "USDT",
        trdas: "OP",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "OGBUSD",
        balas: "BUSD",
        trdas: "OG",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "KEYBUSD",
        balas: "BUSD",
        trdas: "KEY",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "ASRBUSD",
        balas: "BUSD",
        trdas: "ASR",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "FIROBUSD",
        balas: "BUSD",
        trdas: "FIRO",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "NKNBUSD",
        balas: "BUSD",
        trdas: "NKN",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "OPBNB",
        balas: "BNB",
        trdas: "OP",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "OPEUR",
        balas: "EUR",
        trdas: "OP",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "GTOBUSD",
        balas: "BUSD",
        trdas: "GTO",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "SNXETH",
        balas: "ETH",
        trdas: "SNX",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "WBTCBUSD",
        balas: "BUSD",
        trdas: "WBTC",
        scnt: 1,
        pdec: 2,
        sdec: 5
      },
      {
        tk: "BELETH",
        balas: "ETH",
        trdas: "BEL",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "LITETH",
        balas: "ETH",
        trdas: "LIT",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "LEVERUSDT",
        balas: "USDT",
        trdas: "LEVER",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "LEVERBUSD",
        balas: "BUSD",
        trdas: "LEVER",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "BURGERETH",
        balas: "ETH",
        trdas: "BURGER",
        scnt: 1,
        pdec: 7,
        sdec: 2
      },
      {
        tk: "PEOPLEETH",
        balas: "ETH",
        trdas: "PEOPLE",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "UNFIETH",
        balas: "ETH",
        trdas: "UNFI",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "BONDETH",
        balas: "ETH",
        trdas: "BOND",
        scnt: 1,
        pdec: 6,
        sdec: 3
      },
      {
        tk: "STORJTRY",
        balas: "TRY",
        trdas: "STORJ",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "OPETH",
        balas: "ETH",
        trdas: "OP",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "ETCTRY",
        balas: "TRY",
        trdas: "ETC",
        scnt: 1,
        pdec: 1,
        sdec: 3
      },
      {
        tk: "WINGETH",
        balas: "ETH",
        trdas: "WING",
        scnt: 1,
        pdec: 6,
        sdec: 3
      },
      {
        tk: "FILETH",
        balas: "ETH",
        trdas: "FIL",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "GLMBUSD",
        balas: "BUSD",
        trdas: "GLM",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "SSVBUSD",
        balas: "BUSD",
        trdas: "SSV",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "STGBTC",
        balas: "BTC",
        trdas: "STG",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "STGBUSD",
        balas: "BUSD",
        trdas: "STG",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "STGUSDT",
        balas: "USDT",
        trdas: "STG",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "ANKRTRY",
        balas: "TRY",
        trdas: "ANKR",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "ARKBUSD",
        balas: "BUSD",
        trdas: "ARK",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "BETHBUSD",
        balas: "BUSD",
        trdas: "BETH",
        scnt: 1,
        pdec: 2,
        sdec: 4
      },
      {
        tk: "LOOMBUSD",
        balas: "BUSD",
        trdas: "LOOM",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "SNMBUSD",
        balas: "BUSD",
        trdas: "SNM",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "AMBBUSD",
        balas: "BUSD",
        trdas: "AMB",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "LUNCUSDT",
        balas: "USDT",
        trdas: "LUNC",
        scnt: 1,
        pdec: 8,
        sdec: 2
      },
      {
        tk: "PHBBUSD",
        balas: "BUSD",
        trdas: "PHB",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "GASBUSD",
        balas: "BUSD",
        trdas: "GAS",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "NEBLBUSD",
        balas: "BUSD",
        trdas: "NEBL",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "PROSBUSD",
        balas: "BUSD",
        trdas: "PROS",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "VIBBUSD",
        balas: "BUSD",
        trdas: "VIB",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "GMXBTC",
        balas: "BTC",
        trdas: "GMX",
        scnt: 1,
        pdec: 6,
        sdec: 3
      },
      {
        tk: "GMXBUSD",
        balas: "BUSD",
        trdas: "GMX",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "GMXUSDT",
        balas: "USDT",
        trdas: "GMX",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "AGIXBUSD",
        balas: "BUSD",
        trdas: "AGIX",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "NEBLUSDT",
        balas: "USDT",
        trdas: "NEBL",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "SNTBUSD",
        balas: "BUSD",
        trdas: "SNT",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "POLYXBTC",
        balas: "BTC",
        trdas: "POLYX",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "POLYXBUSD",
        balas: "BUSD",
        trdas: "POLYX",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "POLYXUSDT",
        balas: "USDT",
        trdas: "POLYX",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "APTBTC",
        balas: "BTC",
        trdas: "APT",
        scnt: 1,
        pdec: 7,
        sdec: 2
      },
      {
        tk: "APTUSDT",
        balas: "USDT",
        trdas: "APT",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "APTBUSD",
        balas: "BUSD",
        trdas: "APT",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "BTCPLN",
        balas: "PLN",
        trdas: "BTC",
        scnt: 1,
        pdec: 0,
        sdec: 5
      },
      {
        tk: "ETHPLN",
        balas: "PLN",
        trdas: "ETH",
        scnt: 1,
        pdec: 0,
        sdec: 4
      },
      {
        tk: "BUSDPLN",
        balas: "PLN",
        trdas: "BUSD",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "APTEUR",
        balas: "EUR",
        trdas: "APT",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "APTTRY",
        balas: "TRY",
        trdas: "APT",
        scnt: 1,
        pdec: 1,
        sdec: 2
      },
      {
        tk: "APTBRL",
        balas: "BRL",
        trdas: "APT",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "QKCBUSD",
        balas: "BUSD",
        trdas: "QKC",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "OSMOBTC",
        balas: "BTC",
        trdas: "OSMO",
        scnt: 1,
        pdec: 8,
        sdec: 2
      },
      {
        tk: "OSMOUSDT",
        balas: "USDT",
        trdas: "OSMO",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "OSMOBUSD",
        balas: "BUSD",
        trdas: "OSMO",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "HFTBTC",
        balas: "BTC",
        trdas: "HFT",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "HFTBUSD",
        balas: "BUSD",
        trdas: "HFT",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "HFTUSDT",
        balas: "USDT",
        trdas: "HFT",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "ARPAETH",
        balas: "ETH",
        trdas: "ARPA",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "PHBUSDT",
        balas: "USDT",
        trdas: "PHB",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "VITEBUSD",
        balas: "BUSD",
        trdas: "VITE",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "HOOKBTC",
        balas: "BTC",
        trdas: "HOOK",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "HOOKUSDT",
        balas: "USDT",
        trdas: "HOOK",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "HOOKBUSD",
        balas: "BUSD",
        trdas: "HOOK",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "HOOKBNB",
        balas: "BNB",
        trdas: "HOOK",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "MAGICBTC",
        balas: "BTC",
        trdas: "MAGIC",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "MAGICBUSD",
        balas: "BUSD",
        trdas: "MAGIC",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "MAGICUSDT",
        balas: "USDT",
        trdas: "MAGIC",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "BUSDRON",
        balas: "RON",
        trdas: "BUSD",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "HIFIETH",
        balas: "ETH",
        trdas: "HIFI",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "HIFIUSDT",
        balas: "USDT",
        trdas: "HIFI",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "RPLBTC",
        balas: "BTC",
        trdas: "RPL",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "RPLBUSD",
        balas: "BUSD",
        trdas: "RPL",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "RPLUSDT",
        balas: "USDT",
        trdas: "RPL",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "PROSUSDT",
        balas: "USDT",
        trdas: "PROS",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "FETTRY",
        balas: "TRY",
        trdas: "FET",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "GFTBUSD",
        balas: "BUSD",
        trdas: "GFT",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "AGIXUSDT",
        balas: "USDT",
        trdas: "AGIX",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "APTETH",
        balas: "ETH",
        trdas: "APT",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "BTCRON",
        balas: "RON",
        trdas: "BTC",
        scnt: 1,
        pdec: 0,
        sdec: 5
      },
      {
        tk: "GNSUSDT",
        balas: "USDT",
        trdas: "GNS",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "GNSBTC",
        balas: "BTC",
        trdas: "GNS",
        scnt: 1,
        pdec: 7,
        sdec: 2
      },
      {
        tk: "SYNBTC",
        balas: "BTC",
        trdas: "SYN",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "SYNUSDT",
        balas: "USDT",
        trdas: "SYN",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "VIBUSDT",
        balas: "USDT",
        trdas: "VIB",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "SSVUSDT",
        balas: "USDT",
        trdas: "SSV",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "LQTYUSDT",
        balas: "USDT",
        trdas: "LQTY",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "LQTYBTC",
        balas: "BTC",
        trdas: "LQTY",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "AMBUSDT",
        balas: "USDT",
        trdas: "AMB",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "BETHUSDT",
        balas: "USDT",
        trdas: "BETH",
        scnt: 1,
        pdec: 2,
        sdec: 4
      },
      {
        tk: "CFXTRY",
        balas: "TRY",
        trdas: "CFX",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "STXTRY",
        balas: "TRY",
        trdas: "STX",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "USTCUSDT",
        balas: "USDT",
        trdas: "USTC",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "GASUSDT",
        balas: "USDT",
        trdas: "GAS",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "GLMUSDT",
        balas: "USDT",
        trdas: "GLM",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "PROMUSDT",
        balas: "USDT",
        trdas: "PROM",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "QKCUSDT",
        balas: "USDT",
        trdas: "QKC",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "UFTUSDT",
        balas: "USDT",
        trdas: "UFT",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "IDBTC",
        balas: "BTC",
        trdas: "ID",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "IDBNB",
        balas: "BNB",
        trdas: "ID",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "IDUSDT",
        balas: "USDT",
        trdas: "ID",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "ARBBTC",
        balas: "BTC",
        trdas: "ARB",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "ARBUSDT",
        balas: "USDT",
        trdas: "ARB",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "AGIXTRY",
        balas: "TRY",
        trdas: "AGIX",
        scnt: 1,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "LOOMUSDT",
        balas: "USDT",
        trdas: "LOOM",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "OAXUSDT",
        balas: "USDT",
        trdas: "OAX",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "ARBTUSD",
        balas: "TUSD",
        trdas: "ARB",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "ARBTRY",
        balas: "TRY",
        trdas: "ARB",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "ARBEUR",
        balas: "EUR",
        trdas: "ARB",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "IDTUSD",
        balas: "TUSD",
        trdas: "ID",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "IDTRY",
        balas: "TRY",
        trdas: "ID",
        scnt: 1,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "IDEUR",
        balas: "EUR",
        trdas: "ID",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "LDOTUSD",
        balas: "TUSD",
        trdas: "LDO",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "MATICTUSD",
        balas: "TUSD",
        trdas: "MATIC",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "OPTUSD",
        balas: "TUSD",
        trdas: "OP",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "SOLTUSD",
        balas: "TUSD",
        trdas: "SOL",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "SSVTUSD",
        balas: "TUSD",
        trdas: "SSV",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "RDNTBTC",
        balas: "BTC",
        trdas: "RDNT",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "RDNTUSDT",
        balas: "USDT",
        trdas: "RDNT",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "RDNTTUSD",
        balas: "TUSD",
        trdas: "RDNT",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "ARBRUB",
        balas: "RUB",
        trdas: "ARB",
        scnt: 1,
        pdec: 1,
        sdec: 1
      },
      {
        tk: "JOETRY",
        balas: "TRY",
        trdas: "JOE",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "MAGICTRY",
        balas: "TRY",
        trdas: "MAGIC",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "USDTPLN",
        balas: "PLN",
        trdas: "USDT",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "ACHTRY",
        balas: "TRY",
        trdas: "ACH",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "XVSTRY",
        balas: "TRY",
        trdas: "XVS",
        scnt: 1,
        pdec: 1,
        sdec: 2
      },
      {
        tk: "EGLDRON",
        balas: "RON",
        trdas: "EGLD",
        scnt: 1,
        pdec: 1,
        sdec: 2
      },
      {
        tk: "USDTRON",
        balas: "RON",
        trdas: "USDT",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "USDTARS",
        balas: "ARS",
        trdas: "USDT",
        scnt: 1,
        pdec: 0,
        sdec: 0
      },
      {
        tk: "DOGETUSD",
        balas: "TUSD",
        trdas: "DOGE",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "WBTCUSDT",
        balas: "USDT",
        trdas: "WBTC",
        scnt: 1,
        pdec: 2,
        sdec: 5
      },
      {
        tk: "EDUUSDT",
        balas: "USDT",
        trdas: "EDU",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "EDUTUSD",
        balas: "TUSD",
        trdas: "EDU",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "EDUBNB",
        balas: "BNB",
        trdas: "EDU",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "EDUBTC",
        balas: "BTC",
        trdas: "EDU",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "EDUEUR",
        balas: "EUR",
        trdas: "EDU",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "EDUTRY",
        balas: "TRY",
        trdas: "EDU",
        scnt: 1,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "SUIUSDT",
        balas: "USDT",
        trdas: "SUI",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "SUITUSD",
        balas: "TUSD",
        trdas: "SUI",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "SUIBTC",
        balas: "BTC",
        trdas: "SUI",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "SUIBNB",
        balas: "BNB",
        trdas: "SUI",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "SUIEUR",
        balas: "EUR",
        trdas: "SUI",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "SUITRY",
        balas: "TRY",
        trdas: "SUI",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "AERGOUSDT",
        balas: "USDT",
        trdas: "AERGO",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "RNDRTRY",
        balas: "TRY",
        trdas: "RNDR",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "PEPEUSDT",
        balas: "USDT",
        trdas: "PEPE",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "PEPETUSD",
        balas: "TUSD",
        trdas: "PEPE",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "FLOKIUSDT",
        balas: "USDT",
        trdas: "FLOKI",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "FLOKITUSD",
        balas: "TUSD",
        trdas: "FLOKI",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "OGTRY",
        balas: "TRY",
        trdas: "OG",
        scnt: 1,
        pdec: 1,
        sdec: 2
      },
      {
        tk: "PEPETRY",
        balas: "TRY",
        trdas: "PEPE",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "WBETHETH",
        balas: "ETH",
        trdas: "WBETH",
        scnt: 1,
        pdec: 4,
        sdec: 4
      },
      {
        tk: "ASTUSDT",
        balas: "USDT",
        trdas: "AST",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "SNTUSDT",
        balas: "USDT",
        trdas: "SNT",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "FLOKITRY",
        balas: "TRY",
        trdas: "FLOKI",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "CITYTRY",
        balas: "TRY",
        trdas: "CITY",
        scnt: 1,
        pdec: 1,
        sdec: 2
      },
      {
        tk: "COMBOUSDT",
        balas: "USDT",
        trdas: "COMBO",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "COMBOBNB",
        balas: "BNB",
        trdas: "COMBO",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "COMBOTRY",
        balas: "TRY",
        trdas: "COMBO",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "LTCTRY",
        balas: "TRY",
        trdas: "LTC",
        scnt: 1,
        pdec: 0,
        sdec: 3
      },
      {
        tk: "RADTRY",
        balas: "TRY",
        trdas: "RAD",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "BTCARS",
        balas: "ARS",
        trdas: "BTC",
        scnt: 1,
        pdec: 0,
        sdec: 5
      },
      {
        tk: "OPTRY",
        balas: "TRY",
        trdas: "OP",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "PAXGTRY",
        balas: "TRY",
        trdas: "PAXG",
        scnt: 1,
        pdec: 0,
        sdec: 4
      },
      {
        tk: "MAVBTC",
        balas: "BTC",
        trdas: "MAV",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "MAVUSDT",
        balas: "USDT",
        trdas: "MAV",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "MAVTUSD",
        balas: "TUSD",
        trdas: "MAV",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "CFXTUSD",
        balas: "TUSD",
        trdas: "CFX",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "PENDLEBTC",
        balas: "BTC",
        trdas: "PENDLE",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "PENDLEUSDT",
        balas: "USDT",
        trdas: "PENDLE",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "PENDLETUSD",
        balas: "TUSD",
        trdas: "PENDLE",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "MAVTRY",
        balas: "TRY",
        trdas: "MAV",
        scnt: 1,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "OCEANTRY",
        balas: "TRY",
        trdas: "OCEAN",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "TUSDTRY",
        balas: "TRY",
        trdas: "TUSD",
        scnt: 1,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "ARBETH",
        balas: "ETH",
        trdas: "ARB",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "BCHTRY",
        balas: "TRY",
        trdas: "BCH",
        scnt: 1,
        pdec: 0,
        sdec: 3
      },
      {
        tk: "XVGTRY",
        balas: "TRY",
        trdas: "XVG",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "XVGTUSD",
        balas: "TUSD",
        trdas: "XVG",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "ARKMUSDT",
        balas: "USDT",
        trdas: "ARKM",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "ARKMTUSD",
        balas: "TUSD",
        trdas: "ARKM",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "ARKMTRY",
        balas: "TRY",
        trdas: "ARKM",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "ARKMBNB",
        balas: "BNB",
        trdas: "ARKM",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "ARKMBTC",
        balas: "BTC",
        trdas: "ARKM",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "WBETHUSDT",
        balas: "USDT",
        trdas: "WBETH",
        scnt: 1,
        pdec: 2,
        sdec: 4
      },
      {
        tk: "ACATRY",
        balas: "TRY",
        trdas: "ACA",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "AVAXTUSD",
        balas: "TUSD",
        trdas: "AVAX",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "COMPTUSD",
        balas: "TUSD",
        trdas: "COMP",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "COMPTRY",
        balas: "TRY",
        trdas: "COMP",
        scnt: 1,
        pdec: 0,
        sdec: 3
      },
      {
        tk: "XECTRY",
        balas: "TRY",
        trdas: "XEC",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "QUICKTUSD",
        balas: "TUSD",
        trdas: "QUICK",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "WLDUSDT",
        balas: "USDT",
        trdas: "WLD",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "WLDBTC",
        balas: "BTC",
        trdas: "WLD",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "BNBFDUSD",
        balas: "FDUSD",
        trdas: "BNB",
        scnt: 1,
        pdec: 1,
        sdec: 3
      },
      {
        tk: "FDUSDBUSD",
        balas: "BUSD",
        trdas: "FDUSD",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "FDUSDUSDT",
        balas: "USDT",
        trdas: "FDUSD",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "ARKMRUB",
        balas: "RUB",
        trdas: "ARKM",
        scnt: 1,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "WLDTRY",
        balas: "TRY",
        trdas: "WLD",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "WLDRUB",
        balas: "RUB",
        trdas: "WLD",
        scnt: 1,
        pdec: 1,
        sdec: 1
      },
      {
        tk: "AMPTRY",
        balas: "TRY",
        trdas: "AMP",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "OGNTRY",
        balas: "TRY",
        trdas: "OGN",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "BTCFDUSD",
        balas: "FDUSD",
        trdas: "BTC",
        scnt: 1,
        pdec: 2,
        sdec: 5
      },
      {
        tk: "ETHFDUSD",
        balas: "FDUSD",
        trdas: "ETH",
        scnt: 1,
        pdec: 2,
        sdec: 4
      },
      {
        tk: "ASRTRY",
        balas: "TRY",
        trdas: "ASR",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "ATMTRY",
        balas: "TRY",
        trdas: "ATM",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "ACMTRY",
        balas: "TRY",
        trdas: "ACM",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "BARTRY",
        balas: "TRY",
        trdas: "BAR",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "JUVTRY",
        balas: "TRY",
        trdas: "JUV",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "PSGTRY",
        balas: "TRY",
        trdas: "PSG",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "SEIBNB",
        balas: "BNB",
        trdas: "SEI",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "SEIBTC",
        balas: "BTC",
        trdas: "SEI",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "SEIFDUSD",
        balas: "FDUSD",
        trdas: "SEI",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "SEITRY",
        balas: "TRY",
        trdas: "SEI",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "SEIUSDT",
        balas: "USDT",
        trdas: "SEI",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "CYBERBNB",
        balas: "BNB",
        trdas: "CYBER",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "CYBERBTC",
        balas: "BTC",
        trdas: "CYBER",
        scnt: 1,
        pdec: 8,
        sdec: 2
      },
      {
        tk: "CYBERFDUSD",
        balas: "FDUSD",
        trdas: "CYBER",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "CYBERTRY",
        balas: "TRY",
        trdas: "CYBER",
        scnt: 1,
        pdec: 1,
        sdec: 2
      },
      {
        tk: "CYBERUSDT",
        balas: "USDT",
        trdas: "CYBER",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "CYBERTUSD",
        balas: "TUSD",
        trdas: "CYBER",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "SEITUSD",
        balas: "TUSD",
        trdas: "SEI",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "LPTTRY",
        balas: "TRY",
        trdas: "LPT",
        scnt: 1,
        pdec: 1,
        sdec: 2
      },
      {
        tk: "UNITRY",
        balas: "TRY",
        trdas: "UNI",
        scnt: 1,
        pdec: 1,
        sdec: 2
      },
      {
        tk: "SOLFDUSD",
        balas: "FDUSD",
        trdas: "SOL",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "TOMOTRY",
        balas: "TRY",
        trdas: "TOMO",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "UNFITRY",
        balas: "TRY",
        trdas: "UNFI",
        scnt: 1,
        pdec: 1,
        sdec: 2
      },
      {
        tk: "XRPFDUSD",
        balas: "FDUSD",
        trdas: "XRP",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "DOGEFDUSD",
        balas: "FDUSD",
        trdas: "DOGE",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "CYBERETH",
        balas: "ETH",
        trdas: "CYBER",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "MTLTRY",
        balas: "TRY",
        trdas: "MTL",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "ARKUSDT",
        balas: "USDT",
        trdas: "ARK",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "CREAMUSDT",
        balas: "USDT",
        trdas: "CREAM",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "GFTUSDT",
        balas: "USDT",
        trdas: "GFT",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "IQUSDT",
        balas: "USDT",
        trdas: "IQ",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "USDTVAI",
        balas: "VAI",
        trdas: "USDT",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "ARBFDUSD",
        balas: "FDUSD",
        trdas: "ARB",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "FDUSDTRY",
        balas: "TRY",
        trdas: "FDUSD",
        scnt: 1,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "FRONTTRY",
        balas: "TRY",
        trdas: "FRONT",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "SUIFDUSD",
        balas: "FDUSD",
        trdas: "SUI",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "NTRNBTC",
        balas: "BTC",
        trdas: "NTRN",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "NTRNUSDT",
        balas: "USDT",
        trdas: "NTRN",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "NTRNBNB",
        balas: "BNB",
        trdas: "NTRN",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "FILFDUSD",
        balas: "FDUSD",
        trdas: "FIL",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "FRONTTUSD",
        balas: "TUSD",
        trdas: "FRONT",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "LEVERTRY",
        balas: "TRY",
        trdas: "LEVER",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "LTCFDUSD",
        balas: "FDUSD",
        trdas: "LTC",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "ADAFDUSD",
        balas: "FDUSD",
        trdas: "ADA",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "RUNETUSD",
        balas: "TUSD",
        trdas: "RUNE",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "TRBTRY",
        balas: "TRY",
        trdas: "TRB",
        scnt: 1,
        pdec: 0,
        sdec: 3
      },
      {
        tk: "ATOMFDUSD",
        balas: "FDUSD",
        trdas: "ATOM",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "AVAXFDUSD",
        balas: "FDUSD",
        trdas: "AVAX",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "BANDTRY",
        balas: "TRY",
        trdas: "BAND",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "BCHFDUSD",
        balas: "FDUSD",
        trdas: "BCH",
        scnt: 1,
        pdec: 1,
        sdec: 3
      },
      {
        tk: "LOOMTRY",
        balas: "TRY",
        trdas: "LOOM",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "MATICFDUSD",
        balas: "FDUSD",
        trdas: "MATIC",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "ALGOFDUSD",
        balas: "FDUSD",
        trdas: "ALGO",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "DOTFDUSD",
        balas: "FDUSD",
        trdas: "DOT",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "FTMFDUSD",
        balas: "FDUSD",
        trdas: "FTM",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "LINKFDUSD",
        balas: "FDUSD",
        trdas: "LINK",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "NEARFDUSD",
        balas: "FDUSD",
        trdas: "NEAR",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "STRAXTRY",
        balas: "TRY",
        trdas: "STRAX",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "TIABTC",
        balas: "BTC",
        trdas: "TIA",
        scnt: 1,
        pdec: 7,
        sdec: 2
      },
      {
        tk: "TIAUSDT",
        balas: "USDT",
        trdas: "TIA",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "TIATRY",
        balas: "TRY",
        trdas: "TIA",
        scnt: 1,
        pdec: 1,
        sdec: 2
      },
      {
        tk: "MEMEBNB",
        balas: "BNB",
        trdas: "MEME",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "MEMEUSDT",
        balas: "USDT",
        trdas: "MEME",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "MEMEFDUSD",
        balas: "FDUSD",
        trdas: "MEME",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "MEMETUSD",
        balas: "TUSD",
        trdas: "MEME",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "MEMETRY",
        balas: "TRY",
        trdas: "MEME",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "ORDIBTC",
        balas: "BTC",
        trdas: "ORDI",
        scnt: 1,
        pdec: 7,
        sdec: 2
      },
      {
        tk: "ORDIUSDT",
        balas: "USDT",
        trdas: "ORDI",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "ORDITRY",
        balas: "TRY",
        trdas: "ORDI",
        scnt: 1,
        pdec: 0,
        sdec: 2
      },
      {
        tk: "EGLDFDUSD",
        balas: "FDUSD",
        trdas: "EGLD",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "FETFDUSD",
        balas: "FDUSD",
        trdas: "FET",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "GASFDUSD",
        balas: "FDUSD",
        trdas: "GAS",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "INJETH",
        balas: "ETH",
        trdas: "INJ",
        scnt: 1,
        pdec: 5,
        sdec: 2
      },
      {
        tk: "INJTUSD",
        balas: "TUSD",
        trdas: "INJ",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "OPFDUSD",
        balas: "FDUSD",
        trdas: "OP",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "ORDIFDUSD",
        balas: "FDUSD",
        trdas: "ORDI",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "ORDITUSD",
        balas: "TUSD",
        trdas: "ORDI",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "RNDRFDUSD",
        balas: "FDUSD",
        trdas: "RNDR",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "SHIBTUSD",
        balas: "TUSD",
        trdas: "SHIB",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "BEAMXUSDT",
        balas: "USDT",
        trdas: "BEAMX",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "ARKTRY",
        balas: "TRY",
        trdas: "ARK",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "BEAMXTRY",
        balas: "TRY",
        trdas: "BEAMX",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "CAKETRY",
        balas: "TRY",
        trdas: "CAKE",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "CAKETUSD",
        balas: "TUSD",
        trdas: "CAKE",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "DYDXFDUSD",
        balas: "FDUSD",
        trdas: "DYDX",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "PIVXUSDT",
        balas: "USDT",
        trdas: "PIVX",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "RUNEFDUSD",
        balas: "FDUSD",
        trdas: "RUNE",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "TIATUSD",
        balas: "TUSD",
        trdas: "TIA",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "DOTTUSD",
        balas: "TUSD",
        trdas: "DOT",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "GALAFDUSD",
        balas: "FDUSD",
        trdas: "GALA",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "WLDFDUSD",
        balas: "FDUSD",
        trdas: "WLD",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "GASTRY",
        balas: "TRY",
        trdas: "GAS",
        scnt: 1,
        pdec: 1,
        sdec: 2
      },
      {
        tk: "NTRNTRY",
        balas: "TRY",
        trdas: "NTRN",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "VICBTC",
        balas: "BTC",
        trdas: "VIC",
        scnt: 1,
        pdec: 8,
        sdec: 2
      },
      {
        tk: "VICUSDT",
        balas: "USDT",
        trdas: "VIC",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "VICTRY",
        balas: "TRY",
        trdas: "VIC",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "BLURBTC",
        balas: "BTC",
        trdas: "BLUR",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "BLURUSDT",
        balas: "USDT",
        trdas: "BLUR",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "BLURTRY",
        balas: "TRY",
        trdas: "BLUR",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "BLURFDUSD",
        balas: "FDUSD",
        trdas: "BLUR",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "SUPERFDUSD",
        balas: "FDUSD",
        trdas: "SUPER",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "USTCFDUSD",
        balas: "FDUSD",
        trdas: "USTC",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "USTCTRY",
        balas: "TRY",
        trdas: "USTC",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "DYDXTRY",
        balas: "TRY",
        trdas: "DYDX",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "VANRYUSDT",
        balas: "USDT",
        trdas: "VANRY",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "VANRYBTC",
        balas: "BTC",
        trdas: "VANRY",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "BTCAEUR",
        balas: "AEUR",
        trdas: "BTC",
        scnt: 1,
        pdec: 2,
        sdec: 5
      },
      {
        tk: "AEURUSDT",
        balas: "USDT",
        trdas: "AEUR",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "ETHAEUR",
        balas: "AEUR",
        trdas: "ETH",
        scnt: 1,
        pdec: 2,
        sdec: 4
      },
      {
        tk: "EURAEUR",
        balas: "AEUR",
        trdas: "EUR",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "AUCTIONFDUSD",
        balas: "FDUSD",
        trdas: "AUCTION",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "IOTAFDUSD",
        balas: "FDUSD",
        trdas: "IOTA",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "LUNCTRY",
        balas: "TRY",
        trdas: "LUNC",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "SUPERTRY",
        balas: "TRY",
        trdas: "SUPER",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "JTOUSDT",
        balas: "USDT",
        trdas: "JTO",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "JTOFDUSD",
        balas: "FDUSD",
        trdas: "JTO",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "JTOTRY",
        balas: "TRY",
        trdas: "JTO",
        scnt: 1,
        pdec: 1,
        sdec: 1
      },
      {
        tk: "1000SATSUSDT",
        balas: "USDT",
        trdas: "1000SATS",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "1000SATSFDUSD",
        balas: "FDUSD",
        trdas: "1000SATS",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "1000SATSTRY",
        balas: "TRY",
        trdas: "1000SATS",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "SHIBFDUSD",
        balas: "FDUSD",
        trdas: "SHIB",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "SANDFDUSD",
        balas: "FDUSD",
        trdas: "SAND",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "MEMEETH",
        balas: "ETH",
        trdas: "MEME",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "IOTATRY",
        balas: "TRY",
        trdas: "IOTA",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "INJFDUSD",
        balas: "FDUSD",
        trdas: "INJ",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "FIDATRY",
        balas: "TRY",
        trdas: "FIDA",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "BONKUSDT",
        balas: "USDT",
        trdas: "BONK",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "BONKFDUSD",
        balas: "FDUSD",
        trdas: "BONK",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "BONKTRY",
        balas: "TRY",
        trdas: "BONK",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "ACEFDUSD",
        balas: "FDUSD",
        trdas: "ACE",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "ACEUSDT",
        balas: "USDT",
        trdas: "ACE",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "ACEBNB",
        balas: "BNB",
        trdas: "ACE",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "ACEBTC",
        balas: "BTC",
        trdas: "ACE",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "ACETRY",
        balas: "TRY",
        trdas: "ACE",
        scnt: 1,
        pdec: 1,
        sdec: 1
      },
      {
        tk: "BLZFDUSD",
        balas: "FDUSD",
        trdas: "BLZ",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "RARETRY",
        balas: "TRY",
        trdas: "RARE",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "VANRYTRY",
        balas: "TRY",
        trdas: "VANRY",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "NFPBTC",
        balas: "BTC",
        trdas: "NFP",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "NFPUSDT",
        balas: "USDT",
        trdas: "NFP",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "NFPBNB",
        balas: "BNB",
        trdas: "NFP",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "NFPFDUSD",
        balas: "FDUSD",
        trdas: "NFP",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "NFPTUSD",
        balas: "TUSD",
        trdas: "NFP",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "NFPTRY",
        balas: "TRY",
        trdas: "NFP",
        scnt: 1,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "ARBUSDC",
        balas: "USDC",
        trdas: "ARB",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "AVAXUSDC",
        balas: "USDC",
        trdas: "AVAX",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "DOTUSDC",
        balas: "USDC",
        trdas: "DOT",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "INJUSDC",
        balas: "USDC",
        trdas: "INJ",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "MATICUSDC",
        balas: "USDC",
        trdas: "MATIC",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "OPUSDC",
        balas: "USDC",
        trdas: "OP",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "ORDIUSDC",
        balas: "USDC",
        trdas: "ORDI",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "AIBTC",
        balas: "BTC",
        trdas: "AI",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "AIUSDT",
        balas: "USDT",
        trdas: "AI",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "AIBNB",
        balas: "BNB",
        trdas: "AI",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "AIFDUSD",
        balas: "FDUSD",
        trdas: "AI",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "AITUSD",
        balas: "TUSD",
        trdas: "AI",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "AITRY",
        balas: "TRY",
        trdas: "AI",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "ICPFDUSD",
        balas: "FDUSD",
        trdas: "ICP",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "LDOFDUSD",
        balas: "FDUSD",
        trdas: "LDO",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "MOVRTRY",
        balas: "TRY",
        trdas: "MOVR",
        scnt: 1,
        pdec: 1,
        sdec: 3
      },
      {
        tk: "XAIBTC",
        balas: "BTC",
        trdas: "XAI",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "XAIUSDT",
        balas: "USDT",
        trdas: "XAI",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "XAIBNB",
        balas: "BNB",
        trdas: "XAI",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "XAIFDUSD",
        balas: "FDUSD",
        trdas: "XAI",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "XAITUSD",
        balas: "TUSD",
        trdas: "XAI",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "XAITRY",
        balas: "TRY",
        trdas: "XAI",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "SKLTRY",
        balas: "TRY",
        trdas: "SKL",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "STXFDUSD",
        balas: "FDUSD",
        trdas: "STX",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "TIAFDUSD",
        balas: "FDUSD",
        trdas: "TIA",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "MANTABTC",
        balas: "BTC",
        trdas: "MANTA",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "MANTAUSDT",
        balas: "USDT",
        trdas: "MANTA",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "MANTABNB",
        balas: "BNB",
        trdas: "MANTA",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "MANTAFDUSD",
        balas: "FDUSD",
        trdas: "MANTA",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "MANTATRY",
        balas: "TRY",
        trdas: "MANTA",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "ENSFDUSD",
        balas: "FDUSD",
        trdas: "ENS",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "ETCFDUSD",
        balas: "FDUSD",
        trdas: "ETC",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "SUIUSDC",
        balas: "USDC",
        trdas: "SUI",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "TIAUSDC",
        balas: "USDC",
        trdas: "TIA",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "CHZFDUSD",
        balas: "FDUSD",
        trdas: "CHZ",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "MANTAUSDC",
        balas: "USDC",
        trdas: "MANTA",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "ALTBTC",
        balas: "BTC",
        trdas: "ALT",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "ALTUSDT",
        balas: "USDT",
        trdas: "ALT",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "ALTBNB",
        balas: "BNB",
        trdas: "ALT",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "ALTFDUSD",
        balas: "FDUSD",
        trdas: "ALT",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "ALTTRY",
        balas: "TRY",
        trdas: "ALT",
        scnt: 1,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "APTFDUSD",
        balas: "FDUSD",
        trdas: "APT",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "BLURUSDC",
        balas: "USDC",
        trdas: "BLUR",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "JUPUSDT",
        balas: "USDT",
        trdas: "JUP",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "JUPFDUSD",
        balas: "FDUSD",
        trdas: "JUP",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "JUPTRY",
        balas: "TRY",
        trdas: "JUP",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "ALTUSDC",
        balas: "USDC",
        trdas: "ALT",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "MAGICFDUSD",
        balas: "FDUSD",
        trdas: "MAGIC",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "SEIUSDC",
        balas: "USDC",
        trdas: "SEI",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "PYTHBTC",
        balas: "BTC",
        trdas: "PYTH",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "PYTHUSDT",
        balas: "USDT",
        trdas: "PYTH",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "PYTHFDUSD",
        balas: "FDUSD",
        trdas: "PYTH",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "PYTHTRY",
        balas: "TRY",
        trdas: "PYTH",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "RONINBTC",
        balas: "BTC",
        trdas: "RONIN",
        scnt: 1,
        pdec: 8,
        sdec: 2
      },
      {
        tk: "RONINUSDT",
        balas: "USDT",
        trdas: "RONIN",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "RONINFDUSD",
        balas: "FDUSD",
        trdas: "RONIN",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "RONINTRY",
        balas: "TRY",
        trdas: "RONIN",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "DYMBTC",
        balas: "BTC",
        trdas: "DYM",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "DYMUSDT",
        balas: "USDT",
        trdas: "DYM",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "DYMFDUSD",
        balas: "FDUSD",
        trdas: "DYM",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "DYMTRY",
        balas: "TRY",
        trdas: "DYM",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "JUPUSDC",
        balas: "USDC",
        trdas: "JUP",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "PENDLEFDUSD",
        balas: "FDUSD",
        trdas: "PENDLE",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "PIXELBTC",
        balas: "BTC",
        trdas: "PIXEL",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "PIXELBNB",
        balas: "BNB",
        trdas: "PIXEL",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "PIXELUSDT",
        balas: "USDT",
        trdas: "PIXEL",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "PIXELFDUSD",
        balas: "FDUSD",
        trdas: "PIXEL",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "PIXELTRY",
        balas: "TRY",
        trdas: "PIXEL",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "STRKBTC",
        balas: "BTC",
        trdas: "STRK",
        scnt: 1,
        pdec: 8,
        sdec: 2
      },
      {
        tk: "STRKUSDT",
        balas: "USDT",
        trdas: "STRK",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "STRKFDUSD",
        balas: "FDUSD",
        trdas: "STRK",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "STRKTRY",
        balas: "TRY",
        trdas: "STRK",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "FILUSDC",
        balas: "USDC",
        trdas: "FIL",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "HBARTRY",
        balas: "TRY",
        trdas: "HBAR",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "PENDLETRY",
        balas: "TRY",
        trdas: "PENDLE",
        scnt: 1,
        pdec: 1,
        sdec: 1
      },
      {
        tk: "WLDUSDC",
        balas: "USDC",
        trdas: "WLD",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "CKBTRY",
        balas: "TRY",
        trdas: "CKB",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "COTITRY",
        balas: "TRY",
        trdas: "COTI",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "LDOTRY",
        balas: "TRY",
        trdas: "LDO",
        scnt: 1,
        pdec: 1,
        sdec: 2
      },
      {
        tk: "UNIUSDC",
        balas: "USDC",
        trdas: "UNI",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "PORTALBTC",
        balas: "BTC",
        trdas: "PORTAL",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "PORTALUSDT",
        balas: "USDT",
        trdas: "PORTAL",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "PORTALBNB",
        balas: "BNB",
        trdas: "PORTAL",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "PORTALFDUSD",
        balas: "FDUSD",
        trdas: "PORTAL",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "PORTALTRY",
        balas: "TRY",
        trdas: "PORTAL",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "PDABTC",
        balas: "BTC",
        trdas: "PDA",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "PDAUSDT",
        balas: "USDT",
        trdas: "PDA",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "AXLBTC",
        balas: "BTC",
        trdas: "AXL",
        scnt: 1,
        pdec: 8,
        sdec: 2
      },
      {
        tk: "AXLUSDT",
        balas: "USDT",
        trdas: "AXL",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "AXLFDUSD",
        balas: "FDUSD",
        trdas: "AXL",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "AXLTRY",
        balas: "TRY",
        trdas: "AXL",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "PEPEFDUSD",
        balas: "FDUSD",
        trdas: "PEPE",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "PIXELUSDC",
        balas: "USDC",
        trdas: "PIXEL",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "STRKUSDC",
        balas: "USDC",
        trdas: "STRK",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "UNIFDUSD",
        balas: "FDUSD",
        trdas: "UNI",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "OMTRY",
        balas: "TRY",
        trdas: "OM",
        scnt: 1,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "THETATRY",
        balas: "TRY",
        trdas: "THETA",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "WIFBTC",
        balas: "BTC",
        trdas: "WIF",
        scnt: 1,
        pdec: 8,
        sdec: 2
      },
      {
        tk: "WIFUSDT",
        balas: "USDT",
        trdas: "WIF",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "WIFFDUSD",
        balas: "FDUSD",
        trdas: "WIF",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "WIFTRY",
        balas: "TRY",
        trdas: "WIF",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "AGIXFDUSD",
        balas: "FDUSD",
        trdas: "AGIX",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "PEPEUSDC",
        balas: "USDC",
        trdas: "PEPE",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "SHIBUSDC",
        balas: "USDC",
        trdas: "SHIB",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "THETAFDUSD",
        balas: "FDUSD",
        trdas: "THETA",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "ARTRY",
        balas: "TRY",
        trdas: "AR",
        scnt: 1,
        pdec: 1,
        sdec: 2
      },
      {
        tk: "METISBTC",
        balas: "BTC",
        trdas: "METIS",
        scnt: 1,
        pdec: 6,
        sdec: 3
      },
      {
        tk: "METISUSDT",
        balas: "USDT",
        trdas: "METIS",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "METISFDUSD",
        balas: "FDUSD",
        trdas: "METIS",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "METISTRY",
        balas: "TRY",
        trdas: "METIS",
        scnt: 1,
        pdec: 0,
        sdec: 3
      },
      {
        tk: "BNBJPY",
        balas: "JPY",
        trdas: "BNB",
        scnt: 1,
        pdec: 0,
        sdec: 4
      },
      {
        tk: "BTCJPY",
        balas: "JPY",
        trdas: "BTC",
        scnt: 1,
        pdec: 0,
        sdec: 6
      },
      {
        tk: "ETHJPY",
        balas: "JPY",
        trdas: "ETH",
        scnt: 1,
        pdec: 0,
        sdec: 5
      },
      {
        tk: "FLOKIFDUSD",
        balas: "FDUSD",
        trdas: "FLOKI",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "GRTFDUSD",
        balas: "FDUSD",
        trdas: "GRT",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "NEARUSDC",
        balas: "USDC",
        trdas: "NEAR",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "SNXTRY",
        balas: "TRY",
        trdas: "SNX",
        scnt: 1,
        pdec: 1,
        sdec: 1
      },
      {
        tk: "AEVOBTC",
        balas: "BTC",
        trdas: "AEVO",
        scnt: 1,
        pdec: 8,
        sdec: 2
      },
      {
        tk: "AEVOUSDT",
        balas: "USDT",
        trdas: "AEVO",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "AEVOBNB",
        balas: "BNB",
        trdas: "AEVO",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "AEVOFDUSD",
        balas: "FDUSD",
        trdas: "AEVO",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "AEVOTRY",
        balas: "TRY",
        trdas: "AEVO",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "FETUSDC",
        balas: "USDC",
        trdas: "FET",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "IMXTRY",
        balas: "TRY",
        trdas: "IMX",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "EURUSDC",
        balas: "USDC",
        trdas: "EUR",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "BOMETRY",
        balas: "TRY",
        trdas: "BOME",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "BOMEBTC",
        balas: "BTC",
        trdas: "BOME",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "BOMEUSDT",
        balas: "USDT",
        trdas: "BOME",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "BOMEFDUSD",
        balas: "FDUSD",
        trdas: "BOME",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "ETHFIBTC",
        balas: "BTC",
        trdas: "ETHFI",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "ETHFIUSDT",
        balas: "USDT",
        trdas: "ETHFI",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "ETHFIBNB",
        balas: "BNB",
        trdas: "ETHFI",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "ETHFIFDUSD",
        balas: "FDUSD",
        trdas: "ETHFI",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "ETHFITRY",
        balas: "TRY",
        trdas: "ETHFI",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "AAVETRY",
        balas: "TRY",
        trdas: "AAVE",
        scnt: 1,
        pdec: 0,
        sdec: 3
      },
      {
        tk: "ARKMFDUSD",
        balas: "FDUSD",
        trdas: "ARKM",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "CRVTRY",
        balas: "TRY",
        trdas: "CRV",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "FETBRL",
        balas: "BRL",
        trdas: "FET",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "RAYFDUSD",
        balas: "FDUSD",
        trdas: "RAY",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "RNDREUR",
        balas: "EUR",
        trdas: "RNDR",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "BONKUSDC",
        balas: "USDC",
        trdas: "BONK",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "FLOKIUSDC",
        balas: "USDC",
        trdas: "FLOKI",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "MKRTRY",
        balas: "TRY",
        trdas: "MKR",
        scnt: 1,
        pdec: 0,
        sdec: 4
      },
      {
        tk: "RAYTRY",
        balas: "TRY",
        trdas: "RAY",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "RNDRBRL",
        balas: "BRL",
        trdas: "RNDR",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "ENABTC",
        balas: "BTC",
        trdas: "ENA",
        scnt: 1,
        pdec: 8,
        sdec: 2
      },
      {
        tk: "ENAUSDT",
        balas: "USDT",
        trdas: "ENA",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "ENABNB",
        balas: "BNB",
        trdas: "ENA",
        scnt: 1,
        pdec: 7,
        sdec: 2
      },
      {
        tk: "ENAFDUSD",
        balas: "FDUSD",
        trdas: "ENA",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "ENATRY",
        balas: "TRY",
        trdas: "ENA",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "LQTYFDUSD",
        balas: "FDUSD",
        trdas: "LQTY",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "MASKTRY",
        balas: "TRY",
        trdas: "MASK",
        scnt: 1,
        pdec: 1,
        sdec: 1
      },
      {
        tk: "PENDLEUSDC",
        balas: "USDC",
        trdas: "PENDLE",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "RDNTTRY",
        balas: "TRY",
        trdas: "RDNT",
        scnt: 1,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "WBTC",
        balas: "BTC",
        trdas: "W",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "WUSDT",
        balas: "USDT",
        trdas: "W",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "WFDUSD",
        balas: "FDUSD",
        trdas: "W",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "WTRY",
        balas: "TRY",
        trdas: "W",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "BOMEUSDC",
        balas: "USDC",
        trdas: "BOME",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "JTOUSDC",
        balas: "USDC",
        trdas: "JTO",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "WIFUSDC",
        balas: "USDC",
        trdas: "WIF",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "TNSRBTC",
        balas: "BTC",
        trdas: "TNSR",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "TNSRUSDT",
        balas: "USDT",
        trdas: "TNSR",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "TNSRFDUSD",
        balas: "FDUSD",
        trdas: "TNSR",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "TNSRTRY",
        balas: "TRY",
        trdas: "TNSR",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "SAGABTC",
        balas: "BTC",
        trdas: "SAGA",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "SAGAUSDT",
        balas: "USDT",
        trdas: "SAGA",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "SAGABNB",
        balas: "BNB",
        trdas: "SAGA",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "SAGAFDUSD",
        balas: "FDUSD",
        trdas: "SAGA",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "SAGATRY",
        balas: "TRY",
        trdas: "SAGA",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "USDTMXN",
        balas: "MXN",
        trdas: "USDT",
        scnt: 1,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "CKBUSDC",
        balas: "USDC",
        trdas: "CKB",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "ENAUSDC",
        balas: "USDC",
        trdas: "ENA",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "ETHFIUSDC",
        balas: "USDC",
        trdas: "ETHFI",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "YGGUSDC",
        balas: "USDC",
        trdas: "YGG",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "USDTCZK",
        balas: "CZK",
        trdas: "USDT",
        scnt: 1,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "TAOBTC",
        balas: "BTC",
        trdas: "TAO",
        scnt: 1,
        pdec: 6,
        sdec: 4
      },
      {
        tk: "TAOUSDT",
        balas: "USDT",
        trdas: "TAO",
        scnt: 1,
        pdec: 1,
        sdec: 4
      },
      {
        tk: "TAOFDUSD",
        balas: "FDUSD",
        trdas: "TAO",
        scnt: 1,
        pdec: 1,
        sdec: 4
      },
      {
        tk: "TAOTRY",
        balas: "TRY",
        trdas: "TAO",
        scnt: 1,
        pdec: 0,
        sdec: 4
      },
      {
        tk: "CFXUSDC",
        balas: "USDC",
        trdas: "CFX",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "RNDRUSDC",
        balas: "USDC",
        trdas: "RNDR",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "RUNEUSDC",
        balas: "USDC",
        trdas: "RUNE",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "SAGAUSDC",
        balas: "USDC",
        trdas: "SAGA",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "POLYXTRY",
        balas: "TRY",
        trdas: "POLYX",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "OMNIBTC",
        balas: "BTC",
        trdas: "OMNI",
        scnt: 1,
        pdec: 7,
        sdec: 2
      },
      {
        tk: "OMNIUSDT",
        balas: "USDT",
        trdas: "OMNI",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "OMNIBNB",
        balas: "BNB",
        trdas: "OMNI",
        scnt: 1,
        pdec: 5,
        sdec: 2
      },
      {
        tk: "OMNIFDUSD",
        balas: "FDUSD",
        trdas: "OMNI",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "OMNITRY",
        balas: "TRY",
        trdas: "OMNI",
        scnt: 1,
        pdec: 1,
        sdec: 2
      },
      {
        tk: "APTUSDC",
        balas: "USDC",
        trdas: "APT",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "GALAUSDC",
        balas: "USDC",
        trdas: "GALA",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "OMNIBRL",
        balas: "BRL",
        trdas: "OMNI",
        scnt: 1,
        pdec: 1,
        sdec: 2
      },
      {
        tk: "STXUSDC",
        balas: "USDC",
        trdas: "STX",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "ICPUSDC",
        balas: "USDC",
        trdas: "ICP",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "OMNIUSDC",
        balas: "USDC",
        trdas: "OMNI",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "PEPEBRL",
        balas: "BRL",
        trdas: "PEPE",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "YGGTRY",
        balas: "TRY",
        trdas: "YGG",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "ADAJPY",
        balas: "JPY",
        trdas: "ADA",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "SHIBJPY",
        balas: "JPY",
        trdas: "SHIB",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "SOLJPY",
        balas: "JPY",
        trdas: "SOL",
        scnt: 1,
        pdec: 0,
        sdec: 3
      },
      {
        tk: "XRPJPY",
        balas: "JPY",
        trdas: "XRP",
        scnt: 1,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "REZBTC",
        balas: "BTC",
        trdas: "REZ",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "REZUSDT",
        balas: "USDT",
        trdas: "REZ",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "REZBNB",
        balas: "BNB",
        trdas: "REZ",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "REZFDUSD",
        balas: "FDUSD",
        trdas: "REZ",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "REZTRY",
        balas: "TRY",
        trdas: "REZ",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "EGLDTRY",
        balas: "TRY",
        trdas: "EGLD",
        scnt: 1,
        pdec: 0,
        sdec: 2
      },
      {
        tk: "PHBTRY",
        balas: "TRY",
        trdas: "PHB",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "RSRTRY",
        balas: "TRY",
        trdas: "RSR",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "BBBTC",
        balas: "BTC",
        trdas: "BB",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "BBUSDT",
        balas: "USDT",
        trdas: "BB",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "BBBNB",
        balas: "BNB",
        trdas: "BB",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "BBFDUSD",
        balas: "FDUSD",
        trdas: "BB",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "BBTRY",
        balas: "TRY",
        trdas: "BB",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "FRONTUSDC",
        balas: "USDC",
        trdas: "FRONT",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "PEOPLETRY",
        balas: "TRY",
        trdas: "PEOPLE",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "TRBUSDC",
        balas: "USDC",
        trdas: "TRB",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "NOTUSDT",
        balas: "USDT",
        trdas: "NOT",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "NOTBNB",
        balas: "BNB",
        trdas: "NOT",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "NOTFDUSD",
        balas: "FDUSD",
        trdas: "NOT",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "NOTTRY",
        balas: "TRY",
        trdas: "NOT",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "ARKMUSDC",
        balas: "USDC",
        trdas: "ARKM",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "ARUSDC",
        balas: "USDC",
        trdas: "AR",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "BBUSDC",
        balas: "USDC",
        trdas: "BB",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "CRVUSDC",
        balas: "USDC",
        trdas: "CRV",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "PEOPLEUSDC",
        balas: "USDC",
        trdas: "PEOPLE",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "ARFDUSD",
        balas: "FDUSD",
        trdas: "AR",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "ENAEUR",
        balas: "EUR",
        trdas: "ENA",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "PEPEEUR",
        balas: "EUR",
        trdas: "PEPE",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "REZUSDC",
        balas: "USDC",
        trdas: "REZ",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "TRBFDUSD",
        balas: "FDUSD",
        trdas: "TRB",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "USDCTRY",
        balas: "TRY",
        trdas: "USDC",
        scnt: 1,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "BTCMXN",
        balas: "MXN",
        trdas: "BTC",
        scnt: 1,
        pdec: 0,
        sdec: 6
      },
      {
        tk: "XRPMXN",
        balas: "MXN",
        trdas: "XRP",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "ENSUSDC",
        balas: "USDC",
        trdas: "ENS",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "LDOUSDC",
        balas: "USDC",
        trdas: "LDO",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "NOTUSDC",
        balas: "USDC",
        trdas: "NOT",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "NEARBRL",
        balas: "BRL",
        trdas: "NEAR",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "HIGHTRY",
        balas: "TRY",
        trdas: "HIGH",
        scnt: 1,
        pdec: 1,
        sdec: 2
      },
      {
        tk: "PEOPLEFDUSD",
        balas: "FDUSD",
        trdas: "PEOPLE",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "TNSRUSDC",
        balas: "USDC",
        trdas: "TNSR",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "USDTCOP",
        balas: "COP",
        trdas: "USDT",
        scnt: 1,
        pdec: 0,
        sdec: 0
      },
      {
        tk: "IOBTC",
        balas: "BTC",
        trdas: "IO",
        scnt: 1,
        pdec: 8,
        sdec: 2
      },
      {
        tk: "IOUSDT",
        balas: "USDT",
        trdas: "IO",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "IOBNB",
        balas: "BNB",
        trdas: "IO",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "IOFDUSD",
        balas: "FDUSD",
        trdas: "IO",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "IOTRY",
        balas: "TRY",
        trdas: "IO",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "NOTBRL",
        balas: "BRL",
        trdas: "NOT",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "TRUTRY",
        balas: "TRY",
        trdas: "TRU",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "WIFEUR",
        balas: "EUR",
        trdas: "WIF",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "ZKBTC",
        balas: "BTC",
        trdas: "ZK",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "ZKUSDT",
        balas: "USDT",
        trdas: "ZK",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "ZKFDUSD",
        balas: "FDUSD",
        trdas: "ZK",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "ZKTRY",
        balas: "TRY",
        trdas: "ZK",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "LISTAUSDT",
        balas: "USDT",
        trdas: "LISTA",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "LISTABNB",
        balas: "BNB",
        trdas: "LISTA",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "LISTAFDUSD",
        balas: "FDUSD",
        trdas: "LISTA",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "LISTATRY",
        balas: "TRY",
        trdas: "LISTA",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "ZROBTC",
        balas: "BTC",
        trdas: "ZRO",
        scnt: 1,
        pdec: 8,
        sdec: 2
      },
      {
        tk: "ZROUSDT",
        balas: "USDT",
        trdas: "ZRO",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "ZROFDUSD",
        balas: "FDUSD",
        trdas: "ZRO",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "ZROTRY",
        balas: "TRY",
        trdas: "ZRO",
        scnt: 1,
        pdec: 1,
        sdec: 2
      },
      {
        tk: "LISTABRL",
        balas: "BRL",
        trdas: "LISTA",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "BAKETRY",
        balas: "TRY",
        trdas: "BAKE",
        scnt: 1,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "WIFBRL",
        balas: "BRL",
        trdas: "WIF",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "ZKUSDC",
        balas: "USDC",
        trdas: "ZK",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "ZROUSDC",
        balas: "USDC",
        trdas: "ZRO",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "IOUSDC",
        balas: "USDC",
        trdas: "IO",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "1000SATSUSDC",
        balas: "USDC",
        trdas: "1000SATS",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "BNXTRY",
        balas: "TRY",
        trdas: "BNX",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "ETHARS",
        balas: "ARS",
        trdas: "ETH",
        scnt: 1,
        pdec: 0,
        sdec: 5
      },
      {
        tk: "GUSDT",
        balas: "USDT",
        trdas: "G",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "GTRY",
        balas: "TRY",
        trdas: "G",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "BANANABTC",
        balas: "BTC",
        trdas: "BANANA",
        scnt: 1,
        pdec: 7,
        sdec: 3
      },
      {
        tk: "BANANAUSDT",
        balas: "USDT",
        trdas: "BANANA",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "BANANABNB",
        balas: "BNB",
        trdas: "BANANA",
        scnt: 1,
        pdec: 5,
        sdec: 3
      },
      {
        tk: "BANANAFDUSD",
        balas: "FDUSD",
        trdas: "BANANA",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "BANANATRY",
        balas: "TRY",
        trdas: "BANANA",
        scnt: 1,
        pdec: 0,
        sdec: 3
      },
      {
        tk: "RENDERBTC",
        balas: "BTC",
        trdas: "RENDER",
        scnt: 1,
        pdec: 8,
        sdec: 2
      },
      {
        tk: "RENDERUSDT",
        balas: "USDT",
        trdas: "RENDER",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "RENDERFDUSD",
        balas: "FDUSD",
        trdas: "RENDER",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "RENDERUSDC",
        balas: "USDC",
        trdas: "RENDER",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "RENDERTRY",
        balas: "TRY",
        trdas: "RENDER",
        scnt: 1,
        pdec: 1,
        sdec: 2
      },
      {
        tk: "RENDEREUR",
        balas: "EUR",
        trdas: "RENDER",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "RENDERBRL",
        balas: "BRL",
        trdas: "RENDER",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "TONBTC",
        balas: "BTC",
        trdas: "TON",
        scnt: 1,
        pdec: 8,
        sdec: 2
      },
      {
        tk: "TONUSDT",
        balas: "USDT",
        trdas: "TON",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "TONFDUSD",
        balas: "FDUSD",
        trdas: "TON",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "TONTRY",
        balas: "TRY",
        trdas: "TON",
        scnt: 1,
        pdec: 1,
        sdec: 2
      },
      {
        tk: "BONKBRL",
        balas: "BRL",
        trdas: "BONK",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "NOTEUR",
        balas: "EUR",
        trdas: "NOT",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "DOGEJPY",
        balas: "JPY",
        trdas: "DOGE",
        scnt: 1,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "MATICJPY",
        balas: "JPY",
        trdas: "MATIC",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "NEARJPY",
        balas: "JPY",
        trdas: "NEAR",
        scnt: 1,
        pdec: 1,
        sdec: 1
      },
      {
        tk: "TONUSDC",
        balas: "USDC",
        trdas: "TON",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "AAVEFDUSD",
        balas: "FDUSD",
        trdas: "AAVE",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "DOGSUSDT",
        balas: "USDT",
        trdas: "DOGS",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "DOGSBNB",
        balas: "BNB",
        trdas: "DOGS",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "DOGSFDUSD",
        balas: "FDUSD",
        trdas: "DOGS",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "DOGSTRY",
        balas: "TRY",
        trdas: "DOGS",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "EUREURI",
        balas: "EURI",
        trdas: "EUR",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "EURIUSDT",
        balas: "USDT",
        trdas: "EURI",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "DOGSBRL",
        balas: "BRL",
        trdas: "DOGS",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "DOGSUSDC",
        balas: "USDC",
        trdas: "DOGS",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "RAREBRL",
        balas: "BRL",
        trdas: "RARE",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "RAREUSDC",
        balas: "USDC",
        trdas: "RARE",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "SLFBTC",
        balas: "BTC",
        trdas: "SLF",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "SLFTRY",
        balas: "TRY",
        trdas: "SLF",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "SLFUSDC",
        balas: "USDC",
        trdas: "SLF",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "SLFUSDT",
        balas: "USDT",
        trdas: "SLF",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "AAVEUSDC",
        balas: "USDC",
        trdas: "AAVE",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "SUNTRY",
        balas: "TRY",
        trdas: "SUN",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "STMXTRY",
        balas: "TRY",
        trdas: "STMX",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "POLBNB",
        balas: "BNB",
        trdas: "POL",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "POLBRL",
        balas: "BRL",
        trdas: "POL",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "POLBTC",
        balas: "BTC",
        trdas: "POL",
        scnt: 1,
        pdec: 8,
        sdec: 1
      },
      {
        tk: "POLETH",
        balas: "ETH",
        trdas: "POL",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "POLEUR",
        balas: "EUR",
        trdas: "POL",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "POLFDUSD",
        balas: "FDUSD",
        trdas: "POL",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "POLJPY",
        balas: "JPY",
        trdas: "POL",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "POLTRY",
        balas: "TRY",
        trdas: "POL",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "POLUSDC",
        balas: "USDC",
        trdas: "POL",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "POLUSDT",
        balas: "USDT",
        trdas: "POL",
        scnt: 1,
        pdec: 4,
        sdec: 1
      }
    ],
    futures: [
      {
        tk: "BTCUSDT",
        balas: "USDT",
        trdas: "BTC",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "ETHUSDT",
        balas: "USDT",
        trdas: "ETH",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "BCHUSDT",
        balas: "USDT",
        trdas: "BCH",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "XRPUSDT",
        balas: "USDT",
        trdas: "XRP",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "EOSUSDT",
        balas: "USDT",
        trdas: "EOS",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "LTCUSDT",
        balas: "USDT",
        trdas: "LTC",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "TRXUSDT",
        balas: "USDT",
        trdas: "TRX",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "ETCUSDT",
        balas: "USDT",
        trdas: "ETC",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "LINKUSDT",
        balas: "USDT",
        trdas: "LINK",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "XLMUSDT",
        balas: "USDT",
        trdas: "XLM",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "ADAUSDT",
        balas: "USDT",
        trdas: "ADA",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "XMRUSDT",
        balas: "USDT",
        trdas: "XMR",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "DASHUSDT",
        balas: "USDT",
        trdas: "DASH",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "ZECUSDT",
        balas: "USDT",
        trdas: "ZEC",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "XTZUSDT",
        balas: "USDT",
        trdas: "XTZ",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "BNBUSDT",
        balas: "USDT",
        trdas: "BNB",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "ATOMUSDT",
        balas: "USDT",
        trdas: "ATOM",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "ONTUSDT",
        balas: "USDT",
        trdas: "ONT",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "IOTAUSDT",
        balas: "USDT",
        trdas: "IOTA",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "BATUSDT",
        balas: "USDT",
        trdas: "BAT",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "VETUSDT",
        balas: "USDT",
        trdas: "VET",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "NEOUSDT",
        balas: "USDT",
        trdas: "NEO",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "QTUMUSDT",
        balas: "USDT",
        trdas: "QTUM",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "IOSTUSDT",
        balas: "USDT",
        trdas: "IOST",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "THETAUSDT",
        balas: "USDT",
        trdas: "THETA",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "ALGOUSDT",
        balas: "USDT",
        trdas: "ALGO",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "ZILUSDT",
        balas: "USDT",
        trdas: "ZIL",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "KNCUSDT",
        balas: "USDT",
        trdas: "KNC",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "ZRXUSDT",
        balas: "USDT",
        trdas: "ZRX",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "COMPUSDT",
        balas: "USDT",
        trdas: "COMP",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "OMGUSDT",
        balas: "USDT",
        trdas: "OMG",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "DOGEUSDT",
        balas: "USDT",
        trdas: "DOGE",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "SXPUSDT",
        balas: "USDT",
        trdas: "SXP",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "KAVAUSDT",
        balas: "USDT",
        trdas: "KAVA",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "BANDUSDT",
        balas: "USDT",
        trdas: "BAND",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "RLCUSDT",
        balas: "USDT",
        trdas: "RLC",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "WAVESUSDT",
        balas: "USDT",
        trdas: "WAVES",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "MKRUSDT",
        balas: "USDT",
        trdas: "MKR",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "SNXUSDT",
        balas: "USDT",
        trdas: "SNX",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "DOTUSDT",
        balas: "USDT",
        trdas: "DOT",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "DEFIUSDT",
        balas: "USDT",
        trdas: "DEFI",
        scnt: 1,
        pdec: 1,
        sdec: 3
      },
      {
        tk: "YFIUSDT",
        balas: "USDT",
        trdas: "YFI",
        scnt: 1,
        pdec: 1,
        sdec: 3
      },
      {
        tk: "BALUSDT",
        balas: "USDT",
        trdas: "BAL",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "CRVUSDT",
        balas: "USDT",
        trdas: "CRV",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "TRBUSDT",
        balas: "USDT",
        trdas: "TRB",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "RUNEUSDT",
        balas: "USDT",
        trdas: "RUNE",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "SUSHIUSDT",
        balas: "USDT",
        trdas: "SUSHI",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "EGLDUSDT",
        balas: "USDT",
        trdas: "EGLD",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "SOLUSDT",
        balas: "USDT",
        trdas: "SOL",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "ICXUSDT",
        balas: "USDT",
        trdas: "ICX",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "STORJUSDT",
        balas: "USDT",
        trdas: "STORJ",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "BLZUSDT",
        balas: "USDT",
        trdas: "BLZ",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "UNIUSDT",
        balas: "USDT",
        trdas: "UNI",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "AVAXUSDT",
        balas: "USDT",
        trdas: "AVAX",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "FTMUSDT",
        balas: "USDT",
        trdas: "FTM",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "ENJUSDT",
        balas: "USDT",
        trdas: "ENJ",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "FLMUSDT",
        balas: "USDT",
        trdas: "FLM",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "RENUSDT",
        balas: "USDT",
        trdas: "REN",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "KSMUSDT",
        balas: "USDT",
        trdas: "KSM",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "NEARUSDT",
        balas: "USDT",
        trdas: "NEAR",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "AAVEUSDT",
        balas: "USDT",
        trdas: "AAVE",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "FILUSDT",
        balas: "USDT",
        trdas: "FIL",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "RSRUSDT",
        balas: "USDT",
        trdas: "RSR",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "LRCUSDT",
        balas: "USDT",
        trdas: "LRC",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "OCEANUSDT",
        balas: "USDT",
        trdas: "OCEAN",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "CVCUSDT",
        balas: "USDT",
        trdas: "CVC",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "BELUSDT",
        balas: "USDT",
        trdas: "BEL",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "CTKUSDT",
        balas: "USDT",
        trdas: "CTK",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "AXSUSDT",
        balas: "USDT",
        trdas: "AXS",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "ALPHAUSDT",
        balas: "USDT",
        trdas: "ALPHA",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "ZENUSDT",
        balas: "USDT",
        trdas: "ZEN",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "SKLUSDT",
        balas: "USDT",
        trdas: "SKL",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "GRTUSDT",
        balas: "USDT",
        trdas: "GRT",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "1INCHUSDT",
        balas: "USDT",
        trdas: "1INCH",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "CHZUSDT",
        balas: "USDT",
        trdas: "CHZ",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "SANDUSDT",
        balas: "USDT",
        trdas: "SAND",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "ANKRUSDT",
        balas: "USDT",
        trdas: "ANKR",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "LITUSDT",
        balas: "USDT",
        trdas: "LIT",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "UNFIUSDT",
        balas: "USDT",
        trdas: "UNFI",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "REEFUSDT",
        balas: "USDT",
        trdas: "REEF",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "RVNUSDT",
        balas: "USDT",
        trdas: "RVN",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "SFPUSDT",
        balas: "USDT",
        trdas: "SFP",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "XEMUSDT",
        balas: "USDT",
        trdas: "XEM",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "BTCSTUSDT",
        balas: "USDT",
        trdas: "BTCST",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "COTIUSDT",
        balas: "USDT",
        trdas: "COTI",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "CHRUSDT",
        balas: "USDT",
        trdas: "CHR",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "MANAUSDT",
        balas: "USDT",
        trdas: "MANA",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "ALICEUSDT",
        balas: "USDT",
        trdas: "ALICE",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "HBARUSDT",
        balas: "USDT",
        trdas: "HBAR",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "ONEUSDT",
        balas: "USDT",
        trdas: "ONE",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "LINAUSDT",
        balas: "USDT",
        trdas: "LINA",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "STMXUSDT",
        balas: "USDT",
        trdas: "STMX",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "DENTUSDT",
        balas: "USDT",
        trdas: "DENT",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "CELRUSDT",
        balas: "USDT",
        trdas: "CELR",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "HOTUSDT",
        balas: "USDT",
        trdas: "HOT",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "MTLUSDT",
        balas: "USDT",
        trdas: "MTL",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "OGNUSDT",
        balas: "USDT",
        trdas: "OGN",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "NKNUSDT",
        balas: "USDT",
        trdas: "NKN",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "SCUSDT",
        balas: "USDT",
        trdas: "SC",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "DGBUSDT",
        balas: "USDT",
        trdas: "DGB",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "1000SHIBUSDT",
        balas: "USDT",
        trdas: "1000SHIB",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "BAKEUSDT",
        balas: "USDT",
        trdas: "BAKE",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "GTCUSDT",
        balas: "USDT",
        trdas: "GTC",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "BTCDOMUSDT",
        balas: "USDT",
        trdas: "BTCDOM",
        scnt: 1,
        pdec: 1,
        sdec: 3
      },
      {
        tk: "IOTXUSDT",
        balas: "USDT",
        trdas: "IOTX",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "RAYUSDT",
        balas: "USDT",
        trdas: "RAY",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "C98USDT",
        balas: "USDT",
        trdas: "C98",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "MASKUSDT",
        balas: "USDT",
        trdas: "MASK",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "ATAUSDT",
        balas: "USDT",
        trdas: "ATA",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "DYDXUSDT",
        balas: "USDT",
        trdas: "DYDX",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "1000XECUSDT",
        balas: "USDT",
        trdas: "1000XEC",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "GALAUSDT",
        balas: "USDT",
        trdas: "GALA",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "CELOUSDT",
        balas: "USDT",
        trdas: "CELO",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "ARUSDT",
        balas: "USDT",
        trdas: "AR",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "KLAYUSDT",
        balas: "USDT",
        trdas: "KLAY",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "ARPAUSDT",
        balas: "USDT",
        trdas: "ARPA",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "CTSIUSDT",
        balas: "USDT",
        trdas: "CTSI",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "LPTUSDT",
        balas: "USDT",
        trdas: "LPT",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "ENSUSDT",
        balas: "USDT",
        trdas: "ENS",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "PEOPLEUSDT",
        balas: "USDT",
        trdas: "PEOPLE",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "ROSEUSDT",
        balas: "USDT",
        trdas: "ROSE",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "DUSKUSDT",
        balas: "USDT",
        trdas: "DUSK",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "FLOWUSDT",
        balas: "USDT",
        trdas: "FLOW",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "IMXUSDT",
        balas: "USDT",
        trdas: "IMX",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "API3USDT",
        balas: "USDT",
        trdas: "API3",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "GMTUSDT",
        balas: "USDT",
        trdas: "GMT",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "APEUSDT",
        balas: "USDT",
        trdas: "APE",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "WOOUSDT",
        balas: "USDT",
        trdas: "WOO",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "FTTUSDT",
        balas: "USDT",
        trdas: "FTT",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "JASMYUSDT",
        balas: "USDT",
        trdas: "JASMY",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "DARUSDT",
        balas: "USDT",
        trdas: "DAR",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "OPUSDT",
        balas: "USDT",
        trdas: "OP",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "INJUSDT",
        balas: "USDT",
        trdas: "INJ",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "STGUSDT",
        balas: "USDT",
        trdas: "STG",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "SPELLUSDT",
        balas: "USDT",
        trdas: "SPELL",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "1000LUNCUSDT",
        balas: "USDT",
        trdas: "1000LUNC",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "LUNA2USDT",
        balas: "USDT",
        trdas: "LUNA2",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "LDOUSDT",
        balas: "USDT",
        trdas: "LDO",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "CVXUSDT",
        balas: "USDT",
        trdas: "CVX",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "ICPUSDT",
        balas: "USDT",
        trdas: "ICP",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "APTUSDT",
        balas: "USDT",
        trdas: "APT",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "QNTUSDT",
        balas: "USDT",
        trdas: "QNT",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "FETUSDT",
        balas: "USDT",
        trdas: "FET",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "FXSUSDT",
        balas: "USDT",
        trdas: "FXS",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "HOOKUSDT",
        balas: "USDT",
        trdas: "HOOK",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "MAGICUSDT",
        balas: "USDT",
        trdas: "MAGIC",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "TUSDT",
        balas: "USDT",
        trdas: "T",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "HIGHUSDT",
        balas: "USDT",
        trdas: "HIGH",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "MINAUSDT",
        balas: "USDT",
        trdas: "MINA",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "ASTRUSDT",
        balas: "USDT",
        trdas: "ASTR",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "AGIXUSDT",
        balas: "USDT",
        trdas: "AGIX",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "PHBUSDT",
        balas: "USDT",
        trdas: "PHB",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "GMXUSDT",
        balas: "USDT",
        trdas: "GMX",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "CFXUSDT",
        balas: "USDT",
        trdas: "CFX",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "STXUSDT",
        balas: "USDT",
        trdas: "STX",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "BNXUSDT",
        balas: "USDT",
        trdas: "BNX",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "ACHUSDT",
        balas: "USDT",
        trdas: "ACH",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "SSVUSDT",
        balas: "USDT",
        trdas: "SSV",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "CKBUSDT",
        balas: "USDT",
        trdas: "CKB",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "PERPUSDT",
        balas: "USDT",
        trdas: "PERP",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "TRUUSDT",
        balas: "USDT",
        trdas: "TRU",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "LQTYUSDT",
        balas: "USDT",
        trdas: "LQTY",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "USDCUSDT",
        balas: "USDT",
        trdas: "USDC",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "IDUSDT",
        balas: "USDT",
        trdas: "ID",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "ARBUSDT",
        balas: "USDT",
        trdas: "ARB",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "JOEUSDT",
        balas: "USDT",
        trdas: "JOE",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "TLMUSDT",
        balas: "USDT",
        trdas: "TLM",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "AMBUSDT",
        balas: "USDT",
        trdas: "AMB",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "LEVERUSDT",
        balas: "USDT",
        trdas: "LEVER",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "RDNTUSDT",
        balas: "USDT",
        trdas: "RDNT",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "HFTUSDT",
        balas: "USDT",
        trdas: "HFT",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "XVSUSDT",
        balas: "USDT",
        trdas: "XVS",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "ETHBTC",
        balas: "BTC",
        trdas: "ETH",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "BLURUSDT",
        balas: "USDT",
        trdas: "BLUR",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "EDUUSDT",
        balas: "USDT",
        trdas: "EDU",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "IDEXUSDT",
        balas: "USDT",
        trdas: "IDEX",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "SUIUSDT",
        balas: "USDT",
        trdas: "SUI",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "1000PEPEUSDT",
        balas: "USDT",
        trdas: "1000PEPE",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "1000FLOKIUSDT",
        balas: "USDT",
        trdas: "1000FLOKI",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "UMAUSDT",
        balas: "USDT",
        trdas: "UMA",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "RADUSDT",
        balas: "USDT",
        trdas: "RAD",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "KEYUSDT",
        balas: "USDT",
        trdas: "KEY",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "COMBOUSDT",
        balas: "USDT",
        trdas: "COMBO",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "NMRUSDT",
        balas: "USDT",
        trdas: "NMR",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "MAVUSDT",
        balas: "USDT",
        trdas: "MAV",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "MDTUSDT",
        balas: "USDT",
        trdas: "MDT",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "XVGUSDT",
        balas: "USDT",
        trdas: "XVG",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "WLDUSDT",
        balas: "USDT",
        trdas: "WLD",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "PENDLEUSDT",
        balas: "USDT",
        trdas: "PENDLE",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "ARKMUSDT",
        balas: "USDT",
        trdas: "ARKM",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "AGLDUSDT",
        balas: "USDT",
        trdas: "AGLD",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "YGGUSDT",
        balas: "USDT",
        trdas: "YGG",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "DODOXUSDT",
        balas: "USDT",
        trdas: "DODOX",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "BNTUSDT",
        balas: "USDT",
        trdas: "BNT",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "OXTUSDT",
        balas: "USDT",
        trdas: "OXT",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "SEIUSDT",
        balas: "USDT",
        trdas: "SEI",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "CYBERUSDT",
        balas: "USDT",
        trdas: "CYBER",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "HIFIUSDT",
        balas: "USDT",
        trdas: "HIFI",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "ARKUSDT",
        balas: "USDT",
        trdas: "ARK",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "GLMRUSDT",
        balas: "USDT",
        trdas: "GLMR",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "BICOUSDT",
        balas: "USDT",
        trdas: "BICO",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "STRAXUSDT",
        balas: "USDT",
        trdas: "STRAX",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "LOOMUSDT",
        balas: "USDT",
        trdas: "LOOM",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "BIGTIMEUSDT",
        balas: "USDT",
        trdas: "BIGTIME",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "BONDUSDT",
        balas: "USDT",
        trdas: "BOND",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "ORBSUSDT",
        balas: "USDT",
        trdas: "ORBS",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "STPTUSDT",
        balas: "USDT",
        trdas: "STPT",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "WAXPUSDT",
        balas: "USDT",
        trdas: "WAXP",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "BSVUSDT",
        balas: "USDT",
        trdas: "BSV",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "RIFUSDT",
        balas: "USDT",
        trdas: "RIF",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "POLYXUSDT",
        balas: "USDT",
        trdas: "POLYX",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "GASUSDT",
        balas: "USDT",
        trdas: "GAS",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "POWRUSDT",
        balas: "USDT",
        trdas: "POWR",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "SLPUSDT",
        balas: "USDT",
        trdas: "SLP",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "TIAUSDT",
        balas: "USDT",
        trdas: "TIA",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "SNTUSDT",
        balas: "USDT",
        trdas: "SNT",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "CAKEUSDT",
        balas: "USDT",
        trdas: "CAKE",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "MEMEUSDT",
        balas: "USDT",
        trdas: "MEME",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "TWTUSDT",
        balas: "USDT",
        trdas: "TWT",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "TOKENUSDT",
        balas: "USDT",
        trdas: "TOKEN",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "ORDIUSDT",
        balas: "USDT",
        trdas: "ORDI",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "STEEMUSDT",
        balas: "USDT",
        trdas: "STEEM",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "BADGERUSDT",
        balas: "USDT",
        trdas: "BADGER",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "ILVUSDT",
        balas: "USDT",
        trdas: "ILV",
        scnt: 1,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "NTRNUSDT",
        balas: "USDT",
        trdas: "NTRN",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "KASUSDT",
        balas: "USDT",
        trdas: "KAS",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "BEAMXUSDT",
        balas: "USDT",
        trdas: "BEAMX",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "1000BONKUSDT",
        balas: "USDT",
        trdas: "1000BONK",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "PYTHUSDT",
        balas: "USDT",
        trdas: "PYTH",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "SUPERUSDT",
        balas: "USDT",
        trdas: "SUPER",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "USTCUSDT",
        balas: "USDT",
        trdas: "USTC",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "ONGUSDT",
        balas: "USDT",
        trdas: "ONG",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "ETHWUSDT",
        balas: "USDT",
        trdas: "ETHW",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "JTOUSDT",
        balas: "USDT",
        trdas: "JTO",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "1000SATSUSDT",
        balas: "USDT",
        trdas: "1000SATS",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "AUCTIONUSDT",
        balas: "USDT",
        trdas: "AUCTION",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "1000RATSUSDT",
        balas: "USDT",
        trdas: "1000RATS",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "ACEUSDT",
        balas: "USDT",
        trdas: "ACE",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "MOVRUSDT",
        balas: "USDT",
        trdas: "MOVR",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "NFPUSDT",
        balas: "USDT",
        trdas: "NFP",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "BTCUSDC",
        balas: "USDC",
        trdas: "BTC",
        scnt: 1,
        pdec: 1,
        sdec: 3
      },
      {
        tk: "ETHUSDC",
        balas: "USDC",
        trdas: "ETH",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "BNBUSDC",
        balas: "USDC",
        trdas: "BNB",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "SOLUSDC",
        balas: "USDC",
        trdas: "SOL",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "XRPUSDC",
        balas: "USDC",
        trdas: "XRP",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "AIUSDT",
        balas: "USDT",
        trdas: "AI",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "XAIUSDT",
        balas: "USDT",
        trdas: "XAI",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "DOGEUSDC",
        balas: "USDC",
        trdas: "DOGE",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "WIFUSDT",
        balas: "USDT",
        trdas: "WIF",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "MANTAUSDT",
        balas: "USDT",
        trdas: "MANTA",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "ONDOUSDT",
        balas: "USDT",
        trdas: "ONDO",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "LSKUSDT",
        balas: "USDT",
        trdas: "LSK",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "ALTUSDT",
        balas: "USDT",
        trdas: "ALT",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "JUPUSDT",
        balas: "USDT",
        trdas: "JUP",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "ZETAUSDT",
        balas: "USDT",
        trdas: "ZETA",
        scnt: 1,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "RONINUSDT",
        balas: "USDT",
        trdas: "RONIN",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "DYMUSDT",
        balas: "USDT",
        trdas: "DYM",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "SUIUSDC",
        balas: "USDC",
        trdas: "SUI",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "OMUSDT",
        balas: "USDT",
        trdas: "OM",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "LINKUSDC",
        balas: "USDC",
        trdas: "LINK",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "PIXELUSDT",
        balas: "USDT",
        trdas: "PIXEL",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "STRKUSDT",
        balas: "USDT",
        trdas: "STRK",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "MAVIAUSDT",
        balas: "USDT",
        trdas: "MAVIA",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "ORDIUSDC",
        balas: "USDC",
        trdas: "ORDI",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "GLMUSDT",
        balas: "USDT",
        trdas: "GLM",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "PORTALUSDT",
        balas: "USDT",
        trdas: "PORTAL",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "TONUSDT",
        balas: "USDT",
        trdas: "TON",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "AXLUSDT",
        balas: "USDT",
        trdas: "AXL",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "MYROUSDT",
        balas: "USDT",
        trdas: "MYRO",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "1000PEPEUSDC",
        balas: "USDC",
        trdas: "1000PEPE",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "METISUSDT",
        balas: "USDT",
        trdas: "METIS",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "AEVOUSDT",
        balas: "USDT",
        trdas: "AEVO",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "WLDUSDC",
        balas: "USDC",
        trdas: "WLD",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "VANRYUSDT",
        balas: "USDT",
        trdas: "VANRY",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "BOMEUSDT",
        balas: "USDT",
        trdas: "BOME",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "ETHFIUSDT",
        balas: "USDT",
        trdas: "ETHFI",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "AVAXUSDC",
        balas: "USDC",
        trdas: "AVAX",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "1000SHIBUSDC",
        balas: "USDC",
        trdas: "1000SHIB",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "BTCUSDT_240927",
        balas: "USDT",
        trdas: "BTC",
        scnt: 1,
        pdec: 1,
        sdec: 3
      },
      {
        tk: "ETHUSDT_240927",
        balas: "USDT",
        trdas: "ETH",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "ENAUSDT",
        balas: "USDT",
        trdas: "ENA",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "WUSDT",
        balas: "USDT",
        trdas: "W",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "WIFUSDC",
        balas: "USDC",
        trdas: "WIF",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "BCHUSDC",
        balas: "USDC",
        trdas: "BCH",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "TNSRUSDT",
        balas: "USDT",
        trdas: "TNSR",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "SAGAUSDT",
        balas: "USDT",
        trdas: "SAGA",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "LTCUSDC",
        balas: "USDC",
        trdas: "LTC",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "NEARUSDC",
        balas: "USDC",
        trdas: "NEAR",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "TAOUSDT",
        balas: "USDT",
        trdas: "TAO",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "OMNIUSDT",
        balas: "USDT",
        trdas: "OMNI",
        scnt: 1,
        pdec: 4,
        sdec: 2
      },
      {
        tk: "ARBUSDC",
        balas: "USDC",
        trdas: "ARB",
        scnt: 1,
        pdec: 6,
        sdec: 1
      },
      {
        tk: "NEOUSDC",
        balas: "USDC",
        trdas: "NEO",
        scnt: 1,
        pdec: 3,
        sdec: 2
      },
      {
        tk: "FILUSDC",
        balas: "USDC",
        trdas: "FIL",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "TIAUSDC",
        balas: "USDC",
        trdas: "TIA",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "BOMEUSDC",
        balas: "USDC",
        trdas: "BOME",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "REZUSDT",
        balas: "USDT",
        trdas: "REZ",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "ENAUSDC",
        balas: "USDC",
        trdas: "ENA",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "ETHFIUSDC",
        balas: "USDC",
        trdas: "ETHFI",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "1000BONKUSDC",
        balas: "USDC",
        trdas: "1000BONK",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "BBUSDT",
        balas: "USDT",
        trdas: "BB",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "NOTUSDT",
        balas: "USDT",
        trdas: "NOT",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "TURBOUSDT",
        balas: "USDT",
        trdas: "TURBO",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "IOUSDT",
        balas: "USDT",
        trdas: "IO",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "ZKUSDT",
        balas: "USDT",
        trdas: "ZK",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "MEWUSDT",
        balas: "USDT",
        trdas: "MEW",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "LISTAUSDT",
        balas: "USDT",
        trdas: "LISTA",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "ZROUSDT",
        balas: "USDT",
        trdas: "ZRO",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "BTCUSDT_241227",
        balas: "USDT",
        trdas: "BTC",
        scnt: 1,
        pdec: 1,
        sdec: 3
      },
      {
        tk: "ETHUSDT_241227",
        balas: "USDT",
        trdas: "ETH",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "CRVUSDC",
        balas: "USDC",
        trdas: "CRV",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "RENDERUSDT",
        balas: "USDT",
        trdas: "RENDER",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "BANANAUSDT",
        balas: "USDT",
        trdas: "BANANA",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "RAREUSDT",
        balas: "USDT",
        trdas: "RARE",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "GUSDT",
        balas: "USDT",
        trdas: "G",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "SYNUSDT",
        balas: "USDT",
        trdas: "SYN",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "SYSUSDT",
        balas: "USDT",
        trdas: "SYS",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "VOXELUSDT",
        balas: "USDT",
        trdas: "VOXEL",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "BRETTUSDT",
        balas: "USDT",
        trdas: "BRETT",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "ALPACAUSDT",
        balas: "USDT",
        trdas: "ALPACA",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "POPCATUSDT",
        balas: "USDT",
        trdas: "POPCAT",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "SUNUSDT",
        balas: "USDT",
        trdas: "SUN",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "VIDTUSDT",
        balas: "USDT",
        trdas: "VIDT",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "NULSUSDT",
        balas: "USDT",
        trdas: "NULS",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "DOGSUSDT",
        balas: "USDT",
        trdas: "DOGS",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "MBOXUSDT",
        balas: "USDT",
        trdas: "MBOX",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "CHESSUSDT",
        balas: "USDT",
        trdas: "CHESS",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "FLUXUSDT",
        balas: "USDT",
        trdas: "FLUX",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "BSWUSDT",
        balas: "USDT",
        trdas: "BSW",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "QUICKUSDT",
        balas: "USDT",
        trdas: "QUICK",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "NEIROETHUSDT",
        balas: "USDT",
        trdas: "NEIROETH",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "RPLUSDT",
        balas: "USDT",
        trdas: "RPL",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "AERGOUSDT",
        balas: "USDT",
        trdas: "AERGO",
        scnt: 1,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "POLUSDT",
        balas: "USDT",
        trdas: "POL",
        scnt: 1,
        pdec: 7,
        sdec: 0
      }
    ],
    delivery: [
      {
        tk: "BTCUSD_PERP",
        balas: "BTC",
        trdas: "USD",
        scnt: 100,
        pdec: 1,
        sdec: 0
      },
      {
        tk: "BTCUSD_240927",
        balas: "BTC",
        trdas: "USD",
        scnt: 100,
        pdec: 1,
        sdec: 0
      },
      {
        tk: "BTCUSD_241227",
        balas: "BTC",
        trdas: "USD",
        scnt: 100,
        pdec: 1,
        sdec: 0
      },
      {
        tk: "ETHUSD_PERP",
        balas: "ETH",
        trdas: "USD",
        scnt: 10,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "ETHUSD_240927",
        balas: "ETH",
        trdas: "USD",
        scnt: 10,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "ETHUSD_241227",
        balas: "ETH",
        trdas: "USD",
        scnt: 10,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "LINKUSD_PERP",
        balas: "LINK",
        trdas: "USD",
        scnt: 10,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "BNBUSD_PERP",
        balas: "BNB",
        trdas: "USD",
        scnt: 10,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "TRXUSD_PERP",
        balas: "TRX",
        trdas: "USD",
        scnt: 10,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "DOTUSD_PERP",
        balas: "DOT",
        trdas: "USD",
        scnt: 10,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "ADAUSD_PERP",
        balas: "ADA",
        trdas: "USD",
        scnt: 10,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "EOSUSD_PERP",
        balas: "EOS",
        trdas: "USD",
        scnt: 10,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "LTCUSD_PERP",
        balas: "LTC",
        trdas: "USD",
        scnt: 10,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "BCHUSD_PERP",
        balas: "BCH",
        trdas: "USD",
        scnt: 10,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "XRPUSD_PERP",
        balas: "XRP",
        trdas: "USD",
        scnt: 10,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "ETCUSD_PERP",
        balas: "ETC",
        trdas: "USD",
        scnt: 10,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "FILUSD_PERP",
        balas: "FIL",
        trdas: "USD",
        scnt: 10,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "EGLDUSD_PERP",
        balas: "EGLD",
        trdas: "USD",
        scnt: 10,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "DOGEUSD_PERP",
        balas: "DOGE",
        trdas: "USD",
        scnt: 10,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "UNIUSD_PERP",
        balas: "UNI",
        trdas: "USD",
        scnt: 10,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "THETAUSD_PERP",
        balas: "THETA",
        trdas: "USD",
        scnt: 10,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "XLMUSD_PERP",
        balas: "XLM",
        trdas: "USD",
        scnt: 10,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "SOLUSD_PERP",
        balas: "SOL",
        trdas: "USD",
        scnt: 10,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "FTMUSD_PERP",
        balas: "FTM",
        trdas: "USD",
        scnt: 10,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "SANDUSD_PERP",
        balas: "SAND",
        trdas: "USD",
        scnt: 10,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "MANAUSD_PERP",
        balas: "MANA",
        trdas: "USD",
        scnt: 10,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "AVAXUSD_PERP",
        balas: "AVAX",
        trdas: "USD",
        scnt: 10,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "GALAUSD_PERP",
        balas: "GALA",
        trdas: "USD",
        scnt: 10,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "NEARUSD_PERP",
        balas: "NEAR",
        trdas: "USD",
        scnt: 10,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "ATOMUSD_PERP",
        balas: "ATOM",
        trdas: "USD",
        scnt: 10,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "AAVEUSD_PERP",
        balas: "AAVE",
        trdas: "USD",
        scnt: 10,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "AXSUSD_PERP",
        balas: "AXS",
        trdas: "USD",
        scnt: 10,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "ROSEUSD_PERP",
        balas: "ROSE",
        trdas: "USD",
        scnt: 10,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "XTZUSD_PERP",
        balas: "XTZ",
        trdas: "USD",
        scnt: 10,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "ICXUSD_PERP",
        balas: "ICX",
        trdas: "USD",
        scnt: 10,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "ALGOUSD_PERP",
        balas: "ALGO",
        trdas: "USD",
        scnt: 10,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "RUNEUSD_PERP",
        balas: "RUNE",
        trdas: "USD",
        scnt: 10,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "APEUSD_PERP",
        balas: "APE",
        trdas: "USD",
        scnt: 10,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "VETUSD_PERP",
        balas: "VET",
        trdas: "USD",
        scnt: 10,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "ZILUSD_PERP",
        balas: "ZIL",
        trdas: "USD",
        scnt: 10,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "KNCUSD_PERP",
        balas: "KNC",
        trdas: "USD",
        scnt: 10,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "GMTUSD_PERP",
        balas: "GMT",
        trdas: "USD",
        scnt: 10,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "OPUSD_PERP",
        balas: "OP",
        trdas: "USD",
        scnt: 10,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "ENSUSD_PERP",
        balas: "ENS",
        trdas: "USD",
        scnt: 10,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "CHZUSD_PERP",
        balas: "CHZ",
        trdas: "USD",
        scnt: 10,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "APTUSD_PERP",
        balas: "APT",
        trdas: "USD",
        scnt: 10,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "ADAUSD_240927",
        balas: "ADA",
        trdas: "USD",
        scnt: 10,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "LINKUSD_240927",
        balas: "LINK",
        trdas: "USD",
        scnt: 10,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "BCHUSD_240927",
        balas: "BCH",
        trdas: "USD",
        scnt: 10,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "DOTUSD_240927",
        balas: "DOT",
        trdas: "USD",
        scnt: 10,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "XRPUSD_240927",
        balas: "XRP",
        trdas: "USD",
        scnt: 10,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "LTCUSD_240927",
        balas: "LTC",
        trdas: "USD",
        scnt: 10,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "BNBUSD_240927",
        balas: "BNB",
        trdas: "USD",
        scnt: 10,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "ADAUSD_241227",
        balas: "ADA",
        trdas: "USD",
        scnt: 10,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "LINKUSD_241227",
        balas: "LINK",
        trdas: "USD",
        scnt: 10,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "BCHUSD_241227",
        balas: "BCH",
        trdas: "USD",
        scnt: 10,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "DOTUSD_241227",
        balas: "DOT",
        trdas: "USD",
        scnt: 10,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "XRPUSD_241227",
        balas: "XRP",
        trdas: "USD",
        scnt: 10,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "LTCUSD_241227",
        balas: "LTC",
        trdas: "USD",
        scnt: 10,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "BNBUSD_241227",
        balas: "BNB",
        trdas: "USD",
        scnt: 10,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "WIFUSD_PERP",
        balas: "WIF",
        trdas: "USD",
        scnt: 10,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "SOLUSD_240927",
        balas: "SOL",
        trdas: "USD",
        scnt: 10,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "SOLUSD_241227",
        balas: "SOL",
        trdas: "USD",
        scnt: 10,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "DOGSUSD_PERP",
        balas: "DOGS",
        trdas: "USD",
        scnt: 10,
        pdec: 8,
        sdec: 0
      }
    ]
  },
  okx: {
    spot: [
      {
        tk: "BTC-AUD",
        balas: "AUD",
        trdas: "BTC",
        scnt: 1,
        pdec: 1,
        sdec: 4
      },
      {
        tk: "ETH-AUD",
        balas: "AUD",
        trdas: "ETH",
        scnt: 1,
        pdec: 1,
        sdec: 4
      },
      {
        tk: "USDT-AUD",
        balas: "AUD",
        trdas: "USDT",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "USDC-AUD",
        balas: "AUD",
        trdas: "USDC",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "BTC-BRL",
        balas: "BRL",
        trdas: "BTC",
        scnt: 1,
        pdec: 0,
        sdec: 5
      },
      {
        tk: "ETH-BRL",
        balas: "BRL",
        trdas: "ETH",
        scnt: 1,
        pdec: 1,
        sdec: 4
      },
      {
        tk: "PEPE-BRL",
        balas: "BRL",
        trdas: "PEPE",
        scnt: 1,
        pdec: 8,
        sdec: -5
      },
      {
        tk: "USDT-BRL",
        balas: "BRL",
        trdas: "USDT",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "USDC-BRL",
        balas: "BRL",
        trdas: "USDC",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "BTC-EUR",
        balas: "EUR",
        trdas: "BTC",
        scnt: 1,
        pdec: 1,
        sdec: 4
      },
      {
        tk: "ETH-EUR",
        balas: "EUR",
        trdas: "ETH",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "XRP-EUR",
        balas: "EUR",
        trdas: "XRP",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "SOL-EUR",
        balas: "EUR",
        trdas: "SOL",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "DOGE-EUR",
        balas: "EUR",
        trdas: "DOGE",
        scnt: 1,
        pdec: 5,
        sdec: -1
      },
      {
        tk: "TON-EUR",
        balas: "EUR",
        trdas: "TON",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "1INCH-EUR",
        balas: "EUR",
        trdas: "1INCH",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "AAVE-EUR",
        balas: "EUR",
        trdas: "AAVE",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "ADA-EUR",
        balas: "EUR",
        trdas: "ADA",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "ALGO-EUR",
        balas: "EUR",
        trdas: "ALGO",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "APE-EUR",
        balas: "EUR",
        trdas: "APE",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "APT-EUR",
        balas: "EUR",
        trdas: "APT",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "ARB-EUR",
        balas: "EUR",
        trdas: "ARB",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "ASTR-EUR",
        balas: "EUR",
        trdas: "ASTR",
        scnt: 1,
        pdec: 5,
        sdec: -1
      },
      {
        tk: "ATOM-EUR",
        balas: "EUR",
        trdas: "ATOM",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "AVAX-EUR",
        balas: "EUR",
        trdas: "AVAX",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "AXS-EUR",
        balas: "EUR",
        trdas: "AXS",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "BAL-EUR",
        balas: "EUR",
        trdas: "BAL",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "BAT-EUR",
        balas: "EUR",
        trdas: "BAT",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "CHZ-EUR",
        balas: "EUR",
        trdas: "CHZ",
        scnt: 1,
        pdec: 5,
        sdec: -1
      },
      {
        tk: "COMP-EUR",
        balas: "EUR",
        trdas: "COMP",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "CRO-EUR",
        balas: "EUR",
        trdas: "CRO",
        scnt: 1,
        pdec: 5,
        sdec: -1
      },
      {
        tk: "CRV-EUR",
        balas: "EUR",
        trdas: "CRV",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "DOT-EUR",
        balas: "EUR",
        trdas: "DOT",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "DYDX-EUR",
        balas: "EUR",
        trdas: "DYDX",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "EGLD-EUR",
        balas: "EUR",
        trdas: "EGLD",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "EOS-EUR",
        balas: "EUR",
        trdas: "EOS",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "FET-EUR",
        balas: "EUR",
        trdas: "FET",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "FLOW-EUR",
        balas: "EUR",
        trdas: "FLOW",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "FLR-EUR",
        balas: "EUR",
        trdas: "FLR",
        scnt: 1,
        pdec: 5,
        sdec: -2
      },
      {
        tk: "FTM-EUR",
        balas: "EUR",
        trdas: "FTM",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "FXS-EUR",
        balas: "EUR",
        trdas: "FXS",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "GRT-EUR",
        balas: "EUR",
        trdas: "GRT",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "HBAR-EUR",
        balas: "EUR",
        trdas: "HBAR",
        scnt: 1,
        pdec: 5,
        sdec: -1
      },
      {
        tk: "ICP-EUR",
        balas: "EUR",
        trdas: "ICP",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "IMX-EUR",
        balas: "EUR",
        trdas: "IMX",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "INJ-EUR",
        balas: "EUR",
        trdas: "INJ",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "JTO-EUR",
        balas: "EUR",
        trdas: "JTO",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "LDO-EUR",
        balas: "EUR",
        trdas: "LDO",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "LINK-EUR",
        balas: "EUR",
        trdas: "LINK",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "LTC-EUR",
        balas: "EUR",
        trdas: "LTC",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "LUNC-EUR",
        balas: "EUR",
        trdas: "LUNC",
        scnt: 1,
        pdec: 8,
        sdec: -4
      },
      {
        tk: "MANA-EUR",
        balas: "EUR",
        trdas: "MANA",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "MINA-EUR",
        balas: "EUR",
        trdas: "MINA",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "MKR-EUR",
        balas: "EUR",
        trdas: "MKR",
        scnt: 1,
        pdec: 1,
        sdec: 3
      },
      {
        tk: "OP-EUR",
        balas: "EUR",
        trdas: "OP",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "SAND-EUR",
        balas: "EUR",
        trdas: "SAND",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "SHIB-EUR",
        balas: "EUR",
        trdas: "SHIB",
        scnt: 5,
        pdec: 9,
        sdec: -4
      },
      {
        tk: "SNX-EUR",
        balas: "EUR",
        trdas: "SNX",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "STX-EUR",
        balas: "EUR",
        trdas: "STX",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "SUI-EUR",
        balas: "EUR",
        trdas: "SUI",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "SUSHI-EUR",
        balas: "EUR",
        trdas: "SUSHI",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "TRX-EUR",
        balas: "EUR",
        trdas: "TRX",
        scnt: 1,
        pdec: 5,
        sdec: -1
      },
      {
        tk: "UNI-EUR",
        balas: "EUR",
        trdas: "UNI",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "USDT-EUR",
        balas: "EUR",
        trdas: "USDT",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "USDC-EUR",
        balas: "EUR",
        trdas: "USDC",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "WIF-EUR",
        balas: "EUR",
        trdas: "WIF",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "WOO-EUR",
        balas: "EUR",
        trdas: "WOO",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "XLM-EUR",
        balas: "EUR",
        trdas: "XLM",
        scnt: 1,
        pdec: 5,
        sdec: -1
      },
      {
        tk: "XTZ-EUR",
        balas: "EUR",
        trdas: "XTZ",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "YGG-EUR",
        balas: "EUR",
        trdas: "YGG",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "BTC-TRY",
        balas: "TRY",
        trdas: "BTC",
        scnt: 1,
        pdec: 0,
        sdec: 5
      },
      {
        tk: "ETH-TRY",
        balas: "TRY",
        trdas: "ETH",
        scnt: 1,
        pdec: 1,
        sdec: 4
      },
      {
        tk: "USDT-TRY",
        balas: "TRY",
        trdas: "USDT",
        scnt: 1,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "BTC-USDT",
        balas: "USDT",
        trdas: "BTC",
        scnt: 1,
        pdec: 1,
        sdec: 5
      },
      {
        tk: "ETH-USDT",
        balas: "USDT",
        trdas: "ETH",
        scnt: 1,
        pdec: 2,
        sdec: 4
      },
      {
        tk: "OKB-USDT",
        balas: "USDT",
        trdas: "OKB",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "XRP-USDT",
        balas: "USDT",
        trdas: "XRP",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "SOL-USDT",
        balas: "USDT",
        trdas: "SOL",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "DOGE-USDT",
        balas: "USDT",
        trdas: "DOGE",
        scnt: 1,
        pdec: 5,
        sdec: -1
      },
      {
        tk: "POL-USDT",
        balas: "USDT",
        trdas: "POL",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "DOGS-USDT",
        balas: "USDT",
        trdas: "DOGS",
        scnt: 1,
        pdec: 6,
        sdec: -2
      },
      {
        tk: "TON-USDT",
        balas: "USDT",
        trdas: "TON",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "PEPE-USDT",
        balas: "USDT",
        trdas: "PEPE",
        scnt: 1,
        pdec: 9,
        sdec: -6
      },
      {
        tk: "SATS-USDT",
        balas: "USDT",
        trdas: "SATS",
        scnt: 1,
        pdec: 10,
        sdec: -7
      },
      {
        tk: "1INCH-USDT",
        balas: "USDT",
        trdas: "1INCH",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "AAVE-USDT",
        balas: "USDT",
        trdas: "AAVE",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "ACA-USDT",
        balas: "USDT",
        trdas: "ACA",
        scnt: 1,
        pdec: 5,
        sdec: -1
      },
      {
        tk: "ACE-USDT",
        balas: "USDT",
        trdas: "ACE",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "ACH-USDT",
        balas: "USDT",
        trdas: "ACH",
        scnt: 1,
        pdec: 5,
        sdec: -2
      },
      {
        tk: "ADA-USDT",
        balas: "USDT",
        trdas: "ADA",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "AERGO-USDT",
        balas: "USDT",
        trdas: "AERGO",
        scnt: 1,
        pdec: 5,
        sdec: -1
      },
      {
        tk: "AEVO-USDT",
        balas: "USDT",
        trdas: "AEVO",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "AGLD-USDT",
        balas: "USDT",
        trdas: "AGLD",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "AIDOGE-USDT",
        balas: "USDT",
        trdas: "AIDOGE",
        scnt: 1,
        pdec: 13,
        sdec: -10
      },
      {
        tk: "ALCX-USDT",
        balas: "USDT",
        trdas: "ALCX",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "ALGO-USDT",
        balas: "USDT",
        trdas: "ALGO",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "ALPHA-USDT",
        balas: "USDT",
        trdas: "ALPHA",
        scnt: 1,
        pdec: 5,
        sdec: -1
      },
      {
        tk: "APE-USDT",
        balas: "USDT",
        trdas: "APE",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "API3-USDT",
        balas: "USDT",
        trdas: "API3",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "APT-USDT",
        balas: "USDT",
        trdas: "APT",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "AR-USDT",
        balas: "USDT",
        trdas: "AR",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "ARB-USDT",
        balas: "USDT",
        trdas: "ARB",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "ARG-USDT",
        balas: "USDT",
        trdas: "ARG",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "ARKM-USDT",
        balas: "USDT",
        trdas: "ARKM",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "ARTY-USDT",
        balas: "USDT",
        trdas: "ARTY",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "AST-USDT",
        balas: "USDT",
        trdas: "AST",
        scnt: 1,
        pdec: 5,
        sdec: -1
      },
      {
        tk: "ASTR-USDT",
        balas: "USDT",
        trdas: "ASTR",
        scnt: 1,
        pdec: 5,
        sdec: -1
      },
      {
        tk: "ATH-USDT",
        balas: "USDT",
        trdas: "ATH",
        scnt: 1,
        pdec: 5,
        sdec: -2
      },
      {
        tk: "ATOM-USDT",
        balas: "USDT",
        trdas: "ATOM",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "AUCTION-USDT",
        balas: "USDT",
        trdas: "AUCTION",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "AVAX-USDT",
        balas: "USDT",
        trdas: "AVAX",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "AVIVE-USDT",
        balas: "USDT",
        trdas: "AVIVE",
        scnt: 1,
        pdec: 6,
        sdec: -2
      },
      {
        tk: "AXS-USDT",
        balas: "USDT",
        trdas: "AXS",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "BABYDOGE-USDT",
        balas: "USDT",
        trdas: "BABYDOGE",
        scnt: 1,
        pdec: 12,
        sdec: -9
      },
      {
        tk: "BADGER-USDT",
        balas: "USDT",
        trdas: "BADGER",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "BAL-USDT",
        balas: "USDT",
        trdas: "BAL",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "BAND-USDT",
        balas: "USDT",
        trdas: "BAND",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "BAT-USDT",
        balas: "USDT",
        trdas: "BAT",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "BCH-USDT",
        balas: "USDT",
        trdas: "BCH",
        scnt: 1,
        pdec: 1,
        sdec: 2
      },
      {
        tk: "BETH-USDT",
        balas: "USDT",
        trdas: "BETH",
        scnt: 1,
        pdec: 1,
        sdec: 3
      },
      {
        tk: "BICO-USDT",
        balas: "USDT",
        trdas: "BICO",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "BIGTIME-USDT",
        balas: "USDT",
        trdas: "BIGTIME",
        scnt: 1,
        pdec: 5,
        sdec: -1
      },
      {
        tk: "BLOCK-USDT",
        balas: "USDT",
        trdas: "BLOCK",
        scnt: 1,
        pdec: 5,
        sdec: -1
      },
      {
        tk: "BLUR-USDT",
        balas: "USDT",
        trdas: "BLUR",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "BNB-USDT",
        balas: "USDT",
        trdas: "BNB",
        scnt: 1,
        pdec: 1,
        sdec: 2
      },
      {
        tk: "BNT-USDT",
        balas: "USDT",
        trdas: "BNT",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "BOME-USDT",
        balas: "USDT",
        trdas: "BOME",
        scnt: 1,
        pdec: 6,
        sdec: -3
      },
      {
        tk: "BONE-USDT",
        balas: "USDT",
        trdas: "BONE",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "BONK-USDT",
        balas: "USDT",
        trdas: "BONK",
        scnt: 1,
        pdec: 9,
        sdec: -5
      },
      {
        tk: "BORING-USDT",
        balas: "USDT",
        trdas: "BORING",
        scnt: 1,
        pdec: 6,
        sdec: -2
      },
      {
        tk: "BORA-USDT",
        balas: "USDT",
        trdas: "BORA",
        scnt: 1,
        pdec: 5,
        sdec: -1
      },
      {
        tk: "BSV-USDT",
        balas: "USDT",
        trdas: "BSV",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "BTT-USDT",
        balas: "USDT",
        trdas: "BTT",
        scnt: 1,
        pdec: 10,
        sdec: -6
      },
      {
        tk: "BZZ-USDT",
        balas: "USDT",
        trdas: "BZZ",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "CEEK-USDT",
        balas: "USDT",
        trdas: "CEEK",
        scnt: 1,
        pdec: 5,
        sdec: -2
      },
      {
        tk: "CELO-USDT",
        balas: "USDT",
        trdas: "CELO",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "CELR-USDT",
        balas: "USDT",
        trdas: "CELR",
        scnt: 1,
        pdec: 5,
        sdec: -2
      },
      {
        tk: "CETUS-USDT",
        balas: "USDT",
        trdas: "CETUS",
        scnt: 1,
        pdec: 5,
        sdec: -1
      },
      {
        tk: "CFG-USDT",
        balas: "USDT",
        trdas: "CFG",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "CFX-USDT",
        balas: "USDT",
        trdas: "CFX",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "CHZ-USDT",
        balas: "USDT",
        trdas: "CHZ",
        scnt: 1,
        pdec: 5,
        sdec: -1
      },
      {
        tk: "CITY-USDT",
        balas: "USDT",
        trdas: "CITY",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "CLV-USDT",
        balas: "USDT",
        trdas: "CLV",
        scnt: 1,
        pdec: 5,
        sdec: -2
      },
      {
        tk: "COMP-USDT",
        balas: "USDT",
        trdas: "COMP",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "CORE-USDT",
        balas: "USDT",
        trdas: "CORE",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "CRO-USDT",
        balas: "USDT",
        trdas: "CRO",
        scnt: 1,
        pdec: 5,
        sdec: -1
      },
      {
        tk: "CRV-USDT",
        balas: "USDT",
        trdas: "CRV",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "CSPR-USDT",
        balas: "USDT",
        trdas: "CSPR",
        scnt: 1,
        pdec: 5,
        sdec: -2
      },
      {
        tk: "CTC-USDT",
        balas: "USDT",
        trdas: "CTC",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "CTXC-USDT",
        balas: "USDT",
        trdas: "CTXC",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "CVC-USDT",
        balas: "USDT",
        trdas: "CVC",
        scnt: 1,
        pdec: 5,
        sdec: -1
      },
      {
        tk: "CVX-USDT",
        balas: "USDT",
        trdas: "CVX",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "CXT-USDT",
        balas: "USDT",
        trdas: "CXT",
        scnt: 1,
        pdec: 5,
        sdec: -1
      },
      {
        tk: "DAI-USDT",
        balas: "USDT",
        trdas: "DAI",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "DAO-USDT",
        balas: "USDT",
        trdas: "DAO",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "DEGEN-USDT",
        balas: "USDT",
        trdas: "DEGEN",
        scnt: 1,
        pdec: 6,
        sdec: -3
      },
      {
        tk: "DEP-USDT",
        balas: "USDT",
        trdas: "DEP",
        scnt: 1,
        pdec: 6,
        sdec: -2
      },
      {
        tk: "DGB-USDT",
        balas: "USDT",
        trdas: "DGB",
        scnt: 1,
        pdec: 6,
        sdec: -2
      },
      {
        tk: "DIA-USDT",
        balas: "USDT",
        trdas: "DIA",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "DORA-USDT",
        balas: "USDT",
        trdas: "DORA",
        scnt: 1,
        pdec: 5,
        sdec: -1
      },
      {
        tk: "DOT-USDT",
        balas: "USDT",
        trdas: "DOT",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "DYDX-USDT",
        balas: "USDT",
        trdas: "DYDX",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "EGLD-USDT",
        balas: "USDT",
        trdas: "EGLD",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "ELF-USDT",
        balas: "USDT",
        trdas: "ELF",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "ELON-USDT",
        balas: "USDT",
        trdas: "ELON",
        scnt: 5,
        pdec: 10,
        sdec: -6
      },
      {
        tk: "ENJ-USDT",
        balas: "USDT",
        trdas: "ENJ",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "ENS-USDT",
        balas: "USDT",
        trdas: "ENS",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "EOS-USDT",
        balas: "USDT",
        trdas: "EOS",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "ERN-USDT",
        balas: "USDT",
        trdas: "ERN",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "ETC-USDT",
        balas: "USDT",
        trdas: "ETC",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "ETHW-USDT",
        balas: "USDT",
        trdas: "ETHW",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "ETHFI-USDT",
        balas: "USDT",
        trdas: "ETHFI",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "FET-USDT",
        balas: "USDT",
        trdas: "FET",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "FIL-USDT",
        balas: "USDT",
        trdas: "FIL",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "FLM-USDT",
        balas: "USDT",
        trdas: "FLM",
        scnt: 1,
        pdec: 5,
        sdec: -2
      },
      {
        tk: "FLOW-USDT",
        balas: "USDT",
        trdas: "FLOW",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "FLOKI-USDT",
        balas: "USDT",
        trdas: "FLOKI",
        scnt: 1,
        pdec: 8,
        sdec: -4
      },
      {
        tk: "FLR-USDT",
        balas: "USDT",
        trdas: "FLR",
        scnt: 1,
        pdec: 5,
        sdec: -2
      },
      {
        tk: "FORTH-USDT",
        balas: "USDT",
        trdas: "FORTH",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "FOXY-USDT",
        balas: "USDT",
        trdas: "FOXY",
        scnt: 1,
        pdec: 7,
        sdec: -2
      },
      {
        tk: "FTM-USDT",
        balas: "USDT",
        trdas: "FTM",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "FXS-USDT",
        balas: "USDT",
        trdas: "FXS",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "G-USDT",
        balas: "USDT",
        trdas: "G",
        scnt: 1,
        pdec: 5,
        sdec: -2
      },
      {
        tk: "GALA-USDT",
        balas: "USDT",
        trdas: "GALA",
        scnt: 1,
        pdec: 5,
        sdec: -2
      },
      {
        tk: "GALFT-USDT",
        balas: "USDT",
        trdas: "GALFT",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "GAS-USDT",
        balas: "USDT",
        trdas: "GAS",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "GEAR-USDT",
        balas: "USDT",
        trdas: "GEAR",
        scnt: 1,
        pdec: 6,
        sdec: -2
      },
      {
        tk: "GFT-USDT",
        balas: "USDT",
        trdas: "GFT",
        scnt: 1,
        pdec: 5,
        sdec: -2
      },
      {
        tk: "GHST-USDT",
        balas: "USDT",
        trdas: "GHST",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "GLM-USDT",
        balas: "USDT",
        trdas: "GLM",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "GLMR-USDT",
        balas: "USDT",
        trdas: "GLMR",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "GMT-USDT",
        balas: "USDT",
        trdas: "GMT",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "GMX-USDT",
        balas: "USDT",
        trdas: "GMX",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "GOAL-USDT",
        balas: "USDT",
        trdas: "GOAL",
        scnt: 1,
        pdec: 5,
        sdec: -2
      },
      {
        tk: "GODS-USDT",
        balas: "USDT",
        trdas: "GODS",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "GOG-USDT",
        balas: "USDT",
        trdas: "GOG",
        scnt: 1,
        pdec: 5,
        sdec: -1
      },
      {
        tk: "GPT-USDT",
        balas: "USDT",
        trdas: "GPT",
        scnt: 1,
        pdec: 5,
        sdec: -1
      },
      {
        tk: "GRT-USDT",
        balas: "USDT",
        trdas: "GRT",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "HBAR-USDT",
        balas: "USDT",
        trdas: "HBAR",
        scnt: 1,
        pdec: 5,
        sdec: -1
      },
      {
        tk: "ICE-USDT",
        balas: "USDT",
        trdas: "ICE",
        scnt: 1,
        pdec: 6,
        sdec: -2
      },
      {
        tk: "ICP-USDT",
        balas: "USDT",
        trdas: "ICP",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "ICX-USDT",
        balas: "USDT",
        trdas: "ICX",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "ID-USDT",
        balas: "USDT",
        trdas: "ID",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "ILV-USDT",
        balas: "USDT",
        trdas: "ILV",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "IMX-USDT",
        balas: "USDT",
        trdas: "IMX",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "INJ-USDT",
        balas: "USDT",
        trdas: "INJ",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "IOST-USDT",
        balas: "USDT",
        trdas: "IOST",
        scnt: 1,
        pdec: 6,
        sdec: -2
      },
      {
        tk: "IOTA-USDT",
        balas: "USDT",
        trdas: "IOTA",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "IQ-USDT",
        balas: "USDT",
        trdas: "IQ",
        scnt: 1,
        pdec: 6,
        sdec: -2
      },
      {
        tk: "JOE-USDT",
        balas: "USDT",
        trdas: "JOE",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "JST-USDT",
        balas: "USDT",
        trdas: "JST",
        scnt: 1,
        pdec: 5,
        sdec: -2
      },
      {
        tk: "JTO-USDT",
        balas: "USDT",
        trdas: "JTO",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "JUP-USDT",
        balas: "USDT",
        trdas: "JUP",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "KAN-USDT",
        balas: "USDT",
        trdas: "KAN",
        scnt: 1,
        pdec: 6,
        sdec: -2
      },
      {
        tk: "KDA-USDT",
        balas: "USDT",
        trdas: "KDA",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "KINE-USDT",
        balas: "USDT",
        trdas: "KINE",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "KISHU-USDT",
        balas: "USDT",
        trdas: "KISHU",
        scnt: 1,
        pdec: 12,
        sdec: -10
      },
      {
        tk: "KLAY-USDT",
        balas: "USDT",
        trdas: "KLAY",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "KNC-USDT",
        balas: "USDT",
        trdas: "KNC",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "KP3R-USDT",
        balas: "USDT",
        trdas: "KP3R",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "KSM-USDT",
        balas: "USDT",
        trdas: "KSM",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "LAMB-USDT",
        balas: "USDT",
        trdas: "LAMB",
        scnt: 1,
        pdec: 6,
        sdec: -2
      },
      {
        tk: "LAT-USDT",
        balas: "USDT",
        trdas: "LAT",
        scnt: 1,
        pdec: 6,
        sdec: -2
      },
      {
        tk: "LBR-USDT",
        balas: "USDT",
        trdas: "LBR",
        scnt: 1,
        pdec: 5,
        sdec: -1
      },
      {
        tk: "LDO-USDT",
        balas: "USDT",
        trdas: "LDO",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "LEASH-USDT",
        balas: "USDT",
        trdas: "LEASH",
        scnt: 1,
        pdec: 1,
        sdec: 2
      },
      {
        tk: "LEO-USDT",
        balas: "USDT",
        trdas: "LEO",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "LINK-USDT",
        balas: "USDT",
        trdas: "LINK",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "LON-USDT",
        balas: "USDT",
        trdas: "LON",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "LOOKS-USDT",
        balas: "USDT",
        trdas: "LOOKS",
        scnt: 1,
        pdec: 5,
        sdec: -1
      },
      {
        tk: "LPT-USDT",
        balas: "USDT",
        trdas: "LPT",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "LQTY-USDT",
        balas: "USDT",
        trdas: "LQTY",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "LRC-USDT",
        balas: "USDT",
        trdas: "LRC",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "LSK-USDT",
        balas: "USDT",
        trdas: "LSK",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "LTC-USDT",
        balas: "USDT",
        trdas: "LTC",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "LUNC-USDT",
        balas: "USDT",
        trdas: "LUNC",
        scnt: 1,
        pdec: 8,
        sdec: -4
      },
      {
        tk: "LUNA-USDT",
        balas: "USDT",
        trdas: "LUNA",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "MAGIC-USDT",
        balas: "USDT",
        trdas: "MAGIC",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "MANA-USDT",
        balas: "USDT",
        trdas: "MANA",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "MASK-USDT",
        balas: "USDT",
        trdas: "MASK",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "MAX-USDT",
        balas: "USDT",
        trdas: "MAX",
        scnt: 1,
        pdec: 5,
        sdec: -1
      },
      {
        tk: "MDT-USDT",
        balas: "USDT",
        trdas: "MDT",
        scnt: 1,
        pdec: 5,
        sdec: -2
      },
      {
        tk: "MEME-USDT",
        balas: "USDT",
        trdas: "MEME",
        scnt: 1,
        pdec: 5,
        sdec: -2
      },
      {
        tk: "MENGO-USDT",
        balas: "USDT",
        trdas: "MENGO",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "MERL-USDT",
        balas: "USDT",
        trdas: "MERL",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "METIS-USDT",
        balas: "USDT",
        trdas: "METIS",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "MEW-USDT",
        balas: "USDT",
        trdas: "MEW",
        scnt: 1,
        pdec: 6,
        sdec: -3
      },
      {
        tk: "MILO-USDT",
        balas: "USDT",
        trdas: "MILO",
        scnt: 1,
        pdec: 11,
        sdec: -8
      },
      {
        tk: "MINA-USDT",
        balas: "USDT",
        trdas: "MINA",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "MKR-USDT",
        balas: "USDT",
        trdas: "MKR",
        scnt: 1,
        pdec: 1,
        sdec: 3
      },
      {
        tk: "MLN-USDT",
        balas: "USDT",
        trdas: "MLN",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "MOVR-USDT",
        balas: "USDT",
        trdas: "MOVR",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "MXC-USDT",
        balas: "USDT",
        trdas: "MXC",
        scnt: 1,
        pdec: 6,
        sdec: -2
      },
      {
        tk: "MYRIA-USDT",
        balas: "USDT",
        trdas: "MYRIA",
        scnt: 1,
        pdec: 6,
        sdec: -3
      },
      {
        tk: "NEAR-USDT",
        balas: "USDT",
        trdas: "NEAR",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "NEO-USDT",
        balas: "USDT",
        trdas: "NEO",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "NFT-USDT",
        balas: "USDT",
        trdas: "NFT",
        scnt: 1,
        pdec: 10,
        sdec: -7
      },
      {
        tk: "NMR-USDT",
        balas: "USDT",
        trdas: "NMR",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "NOT-USDT",
        balas: "USDT",
        trdas: "NOT",
        scnt: 1,
        pdec: 6,
        sdec: -2
      },
      {
        tk: "NULS-USDT",
        balas: "USDT",
        trdas: "NULS",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "OAS-USDT",
        balas: "USDT",
        trdas: "OAS",
        scnt: 1,
        pdec: 5,
        sdec: -2
      },
      {
        tk: "OKT-USDT",
        balas: "USDT",
        trdas: "OKT",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "OM-USDT",
        balas: "USDT",
        trdas: "OM",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "OMI-USDT",
        balas: "USDT",
        trdas: "OMI",
        scnt: 1,
        pdec: 7,
        sdec: -3
      },
      {
        tk: "ONDO-USDT",
        balas: "USDT",
        trdas: "ONDO",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "ONE-USDT",
        balas: "USDT",
        trdas: "ONE",
        scnt: 1,
        pdec: 5,
        sdec: -2
      },
      {
        tk: "ONT-USDT",
        balas: "USDT",
        trdas: "ONT",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "OP-USDT",
        balas: "USDT",
        trdas: "OP",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "ORBS-USDT",
        balas: "USDT",
        trdas: "ORBS",
        scnt: 1,
        pdec: 5,
        sdec: -2
      },
      {
        tk: "ORB-USDT",
        balas: "USDT",
        trdas: "ORB",
        scnt: 1,
        pdec: 5,
        sdec: -2
      },
      {
        tk: "ORDI-USDT",
        balas: "USDT",
        trdas: "ORDI",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "OXT-USDT",
        balas: "USDT",
        trdas: "OXT",
        scnt: 1,
        pdec: 5,
        sdec: -1
      },
      {
        tk: "PEOPLE-USDT",
        balas: "USDT",
        trdas: "PEOPLE",
        scnt: 1,
        pdec: 5,
        sdec: -2
      },
      {
        tk: "PERP-USDT",
        balas: "USDT",
        trdas: "PERP",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "PHA-USDT",
        balas: "USDT",
        trdas: "PHA",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "PIXEL-USDT",
        balas: "USDT",
        trdas: "PIXEL",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "POLYDOGE-USDT",
        balas: "USDT",
        trdas: "POLYDOGE",
        scnt: 1,
        pdec: 11,
        sdec: -8
      },
      {
        tk: "POR-USDT",
        balas: "USDT",
        trdas: "POR",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "PRCL-USDT",
        balas: "USDT",
        trdas: "PRCL",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "PRQ-USDT",
        balas: "USDT",
        trdas: "PRQ",
        scnt: 1,
        pdec: 5,
        sdec: -1
      },
      {
        tk: "PSTAKE-USDT",
        balas: "USDT",
        trdas: "PSTAKE",
        scnt: 1,
        pdec: 5,
        sdec: -2
      },
      {
        tk: "PYTH-USDT",
        balas: "USDT",
        trdas: "PYTH",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "QTUM-USDT",
        balas: "USDT",
        trdas: "QTUM",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "RACA-USDT",
        balas: "USDT",
        trdas: "RACA",
        scnt: 1,
        pdec: 7,
        sdec: -3
      },
      {
        tk: "RADAR-USDT",
        balas: "USDT",
        trdas: "RADAR",
        scnt: 1,
        pdec: 6,
        sdec: -3
      },
      {
        tk: "RAY-USDT",
        balas: "USDT",
        trdas: "RAY",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "RDNT-USDT",
        balas: "USDT",
        trdas: "RDNT",
        scnt: 1,
        pdec: 5,
        sdec: -1
      },
      {
        tk: "REN-USDT",
        balas: "USDT",
        trdas: "REN",
        scnt: 1,
        pdec: 5,
        sdec: -2
      },
      {
        tk: "RENDER-USDT",
        balas: "USDT",
        trdas: "RENDER",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "RIO-USDT",
        balas: "USDT",
        trdas: "RIO",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "RON-USDT",
        balas: "USDT",
        trdas: "RON",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "RPL-USDT",
        balas: "USDT",
        trdas: "RPL",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "RSR-USDT",
        balas: "USDT",
        trdas: "RSR",
        scnt: 1,
        pdec: 6,
        sdec: -2
      },
      {
        tk: "RSS3-USDT",
        balas: "USDT",
        trdas: "RSS3",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "RUNECOIN-USDT",
        balas: "USDT",
        trdas: "RUNECOIN",
        scnt: 1,
        pdec: 6,
        sdec: -2
      },
      {
        tk: "RVN-USDT",
        balas: "USDT",
        trdas: "RVN",
        scnt: 1,
        pdec: 5,
        sdec: -2
      },
      {
        tk: "SAFE-USDT",
        balas: "USDT",
        trdas: "SAFE",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "SAMO-USDT",
        balas: "USDT",
        trdas: "SAMO",
        scnt: 1,
        pdec: 6,
        sdec: -2
      },
      {
        tk: "SAND-USDT",
        balas: "USDT",
        trdas: "SAND",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "SC-USDT",
        balas: "USDT",
        trdas: "SC",
        scnt: 1,
        pdec: 6,
        sdec: -2
      },
      {
        tk: "SD-USDT",
        balas: "USDT",
        trdas: "SD",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "SHIB-USDT",
        balas: "USDT",
        trdas: "SHIB",
        scnt: 1,
        pdec: 9,
        sdec: -5
      },
      {
        tk: "SIS-USDT",
        balas: "USDT",
        trdas: "SIS",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "SKL-USDT",
        balas: "USDT",
        trdas: "SKL",
        scnt: 1,
        pdec: 5,
        sdec: -2
      },
      {
        tk: "SLERF-USDT",
        balas: "USDT",
        trdas: "SLERF",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "SLP-USDT",
        balas: "USDT",
        trdas: "SLP",
        scnt: 1,
        pdec: 6,
        sdec: -2
      },
      {
        tk: "SNT-USDT",
        balas: "USDT",
        trdas: "SNT",
        scnt: 1,
        pdec: 5,
        sdec: -2
      },
      {
        tk: "SNX-USDT",
        balas: "USDT",
        trdas: "SNX",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "SPURS-USDT",
        balas: "USDT",
        trdas: "SPURS",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "SSV-USDT",
        balas: "USDT",
        trdas: "SSV",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "SSWP-USDT",
        balas: "USDT",
        trdas: "SSWP",
        scnt: 1,
        pdec: 7,
        sdec: -2
      },
      {
        tk: "STETH-USDT",
        balas: "USDT",
        trdas: "STETH",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "STORJ-USDT",
        balas: "USDT",
        trdas: "STORJ",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "STRK-USDT",
        balas: "USDT",
        trdas: "STRK",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "STX-USDT",
        balas: "USDT",
        trdas: "STX",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "SUI-USDT",
        balas: "USDT",
        trdas: "SUI",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "SUSHI-USDT",
        balas: "USDT",
        trdas: "SUSHI",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "SWEAT-USDT",
        balas: "USDT",
        trdas: "SWEAT",
        scnt: 1,
        pdec: 6,
        sdec: -2
      },
      {
        tk: "SWFTC-USDT",
        balas: "USDT",
        trdas: "SWFTC",
        scnt: 1,
        pdec: 6,
        sdec: -2
      },
      {
        tk: "T-USDT",
        balas: "USDT",
        trdas: "T",
        scnt: 1,
        pdec: 5,
        sdec: -2
      },
      {
        tk: "TAKI-USDT",
        balas: "USDT",
        trdas: "TAKI",
        scnt: 1,
        pdec: 5,
        sdec: -2
      },
      {
        tk: "THETA-USDT",
        balas: "USDT",
        trdas: "THETA",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "TIA-USDT",
        balas: "USDT",
        trdas: "TIA",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "TNSR-USDT",
        balas: "USDT",
        trdas: "TNSR",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "TRA-USDT",
        balas: "USDT",
        trdas: "TRA",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "TRB-USDT",
        balas: "USDT",
        trdas: "TRB",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "TRX-USDT",
        balas: "USDT",
        trdas: "TRX",
        scnt: 1,
        pdec: 5,
        sdec: -1
      },
      {
        tk: "TURBO-USDT",
        balas: "USDT",
        trdas: "TURBO",
        scnt: 1,
        pdec: 7,
        sdec: -3
      },
      {
        tk: "ULTI-USDT",
        balas: "USDT",
        trdas: "ULTI",
        scnt: 1,
        pdec: 5,
        sdec: -2
      },
      {
        tk: "UMA-USDT",
        balas: "USDT",
        trdas: "UMA",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "UNI-USDT",
        balas: "USDT",
        trdas: "UNI",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "USDC-USDT",
        balas: "USDT",
        trdas: "USDC",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "USTC-USDT",
        balas: "USDT",
        trdas: "USTC",
        scnt: 1,
        pdec: 5,
        sdec: -2
      },
      {
        tk: "UTK-USDT",
        balas: "USDT",
        trdas: "UTK",
        scnt: 1,
        pdec: 5,
        sdec: -2
      },
      {
        tk: "UXLINK-USDT",
        balas: "USDT",
        trdas: "UXLINK",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "VELO-USDT",
        balas: "USDT",
        trdas: "VELO",
        scnt: 1,
        pdec: 6,
        sdec: -2
      },
      {
        tk: "VELODROME-USDT",
        balas: "USDT",
        trdas: "VELODROME",
        scnt: 1,
        pdec: 5,
        sdec: -1
      },
      {
        tk: "VELA-USDT",
        balas: "USDT",
        trdas: "VELA",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "VENOM-USDT",
        balas: "USDT",
        trdas: "VENOM",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "VRA-USDT",
        balas: "USDT",
        trdas: "VRA",
        scnt: 1,
        pdec: 6,
        sdec: -2
      },
      {
        tk: "W-USDT",
        balas: "USDT",
        trdas: "W",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "WAXP-USDT",
        balas: "USDT",
        trdas: "WAXP",
        scnt: 1,
        pdec: 5,
        sdec: -2
      },
      {
        tk: "WBTC-USDT",
        balas: "USDT",
        trdas: "WBTC",
        scnt: 1,
        pdec: 1,
        sdec: 4
      },
      {
        tk: "WIFI-USDT",
        balas: "USDT",
        trdas: "WIFI",
        scnt: 1,
        pdec: 5,
        sdec: -1
      },
      {
        tk: "WIF-USDT",
        balas: "USDT",
        trdas: "WIF",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "WIN-USDT",
        balas: "USDT",
        trdas: "WIN",
        scnt: 1,
        pdec: 8,
        sdec: -5
      },
      {
        tk: "WLD-USDT",
        balas: "USDT",
        trdas: "WLD",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "WOO-USDT",
        balas: "USDT",
        trdas: "WOO",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "WSM-USDT",
        balas: "USDT",
        trdas: "WSM",
        scnt: 1,
        pdec: 6,
        sdec: -2
      },
      {
        tk: "WXT-USDT",
        balas: "USDT",
        trdas: "WXT",
        scnt: 1,
        pdec: 6,
        sdec: -2
      },
      {
        tk: "XAUT-USDT",
        balas: "USDT",
        trdas: "XAUT",
        scnt: 1,
        pdec: 1,
        sdec: 3
      },
      {
        tk: "XCH-USDT",
        balas: "USDT",
        trdas: "XCH",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "XLM-USDT",
        balas: "USDT",
        trdas: "XLM",
        scnt: 1,
        pdec: 5,
        sdec: -1
      },
      {
        tk: "XNO-USDT",
        balas: "USDT",
        trdas: "XNO",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "XR-USDT",
        balas: "USDT",
        trdas: "XR",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "XTZ-USDT",
        balas: "USDT",
        trdas: "XTZ",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "YFI-USDT",
        balas: "USDT",
        trdas: "YFI",
        scnt: 5,
        pdec: 0,
        sdec: 4
      },
      {
        tk: "YGG-USDT",
        balas: "USDT",
        trdas: "YGG",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "ZBCN-USDT",
        balas: "USDT",
        trdas: "ZBCN",
        scnt: 1,
        pdec: 7,
        sdec: -3
      },
      {
        tk: "ZENT-USDT",
        balas: "USDT",
        trdas: "ZENT",
        scnt: 1,
        pdec: 5,
        sdec: -2
      },
      {
        tk: "ZERO-USDT",
        balas: "USDT",
        trdas: "ZERO",
        scnt: 1,
        pdec: 7,
        sdec: -3
      },
      {
        tk: "ZETA-USDT",
        balas: "USDT",
        trdas: "ZETA",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "ZEUS-USDT",
        balas: "USDT",
        trdas: "ZEUS",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "ZIL-USDT",
        balas: "USDT",
        trdas: "ZIL",
        scnt: 1,
        pdec: 5,
        sdec: -2
      },
      {
        tk: "ZK-USDT",
        balas: "USDT",
        trdas: "ZK",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "ZKJ-USDT",
        balas: "USDT",
        trdas: "ZKJ",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "ZRO-USDT",
        balas: "USDT",
        trdas: "ZRO",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "ZRX-USDT",
        balas: "USDT",
        trdas: "ZRX",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "BTC-USDC",
        balas: "USDC",
        trdas: "BTC",
        scnt: 1,
        pdec: 1,
        sdec: 5
      },
      {
        tk: "ETH-USDC",
        balas: "USDC",
        trdas: "ETH",
        scnt: 1,
        pdec: 2,
        sdec: 4
      },
      {
        tk: "OKB-USDC",
        balas: "USDC",
        trdas: "OKB",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "XRP-USDC",
        balas: "USDC",
        trdas: "XRP",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "SOL-USDC",
        balas: "USDC",
        trdas: "SOL",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "DOGE-USDC",
        balas: "USDC",
        trdas: "DOGE",
        scnt: 1,
        pdec: 5,
        sdec: -1
      },
      {
        tk: "POL-USDC",
        balas: "USDC",
        trdas: "POL",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "TON-USDC",
        balas: "USDC",
        trdas: "TON",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "PEPE-USDC",
        balas: "USDC",
        trdas: "PEPE",
        scnt: 1,
        pdec: 9,
        sdec: -6
      },
      {
        tk: "SATS-USDC",
        balas: "USDC",
        trdas: "SATS",
        scnt: 1,
        pdec: 10,
        sdec: -7
      },
      {
        tk: "1INCH-USDC",
        balas: "USDC",
        trdas: "1INCH",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "AAVE-USDC",
        balas: "USDC",
        trdas: "AAVE",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "ACA-USDC",
        balas: "USDC",
        trdas: "ACA",
        scnt: 1,
        pdec: 5,
        sdec: -1
      },
      {
        tk: "ACE-USDC",
        balas: "USDC",
        trdas: "ACE",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "ADA-USDC",
        balas: "USDC",
        trdas: "ADA",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "AGLD-USDC",
        balas: "USDC",
        trdas: "AGLD",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "ALCX-USDC",
        balas: "USDC",
        trdas: "ALCX",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "ALGO-USDC",
        balas: "USDC",
        trdas: "ALGO",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "ALPHA-USDC",
        balas: "USDC",
        trdas: "ALPHA",
        scnt: 1,
        pdec: 5,
        sdec: -1
      },
      {
        tk: "APE-USDC",
        balas: "USDC",
        trdas: "APE",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "API3-USDC",
        balas: "USDC",
        trdas: "API3",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "APT-USDC",
        balas: "USDC",
        trdas: "APT",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "AR-USDC",
        balas: "USDC",
        trdas: "AR",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "ARB-USDC",
        balas: "USDC",
        trdas: "ARB",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "ASTR-USDC",
        balas: "USDC",
        trdas: "ASTR",
        scnt: 1,
        pdec: 5,
        sdec: -1
      },
      {
        tk: "ATOM-USDC",
        balas: "USDC",
        trdas: "ATOM",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "AUCTION-USDC",
        balas: "USDC",
        trdas: "AUCTION",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "AVAX-USDC",
        balas: "USDC",
        trdas: "AVAX",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "AXS-USDC",
        balas: "USDC",
        trdas: "AXS",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "BADGER-USDC",
        balas: "USDC",
        trdas: "BADGER",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "BAL-USDC",
        balas: "USDC",
        trdas: "BAL",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "BAND-USDC",
        balas: "USDC",
        trdas: "BAND",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "BAT-USDC",
        balas: "USDC",
        trdas: "BAT",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "BCH-USDC",
        balas: "USDC",
        trdas: "BCH",
        scnt: 1,
        pdec: 1,
        sdec: 2
      },
      {
        tk: "BICO-USDC",
        balas: "USDC",
        trdas: "BICO",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "BLUR-USDC",
        balas: "USDC",
        trdas: "BLUR",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "BNB-USDC",
        balas: "USDC",
        trdas: "BNB",
        scnt: 1,
        pdec: 1,
        sdec: 2
      },
      {
        tk: "BNT-USDC",
        balas: "USDC",
        trdas: "BNT",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "BSV-USDC",
        balas: "USDC",
        trdas: "BSV",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "CELR-USDC",
        balas: "USDC",
        trdas: "CELR",
        scnt: 1,
        pdec: 5,
        sdec: -2
      },
      {
        tk: "CETUS-USDC",
        balas: "USDC",
        trdas: "CETUS",
        scnt: 1,
        pdec: 5,
        sdec: -1
      },
      {
        tk: "CFG-USDC",
        balas: "USDC",
        trdas: "CFG",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "CFX-USDC",
        balas: "USDC",
        trdas: "CFX",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "CHZ-USDC",
        balas: "USDC",
        trdas: "CHZ",
        scnt: 1,
        pdec: 5,
        sdec: -1
      },
      {
        tk: "COMP-USDC",
        balas: "USDC",
        trdas: "COMP",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "CRO-USDC",
        balas: "USDC",
        trdas: "CRO",
        scnt: 1,
        pdec: 5,
        sdec: -1
      },
      {
        tk: "CRV-USDC",
        balas: "USDC",
        trdas: "CRV",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "CSPR-USDC",
        balas: "USDC",
        trdas: "CSPR",
        scnt: 1,
        pdec: 5,
        sdec: -2
      },
      {
        tk: "CTC-USDC",
        balas: "USDC",
        trdas: "CTC",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "CTXC-USDC",
        balas: "USDC",
        trdas: "CTXC",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "CVC-USDC",
        balas: "USDC",
        trdas: "CVC",
        scnt: 1,
        pdec: 5,
        sdec: -1
      },
      {
        tk: "CVX-USDC",
        balas: "USDC",
        trdas: "CVX",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "DAI-USDC",
        balas: "USDC",
        trdas: "DAI",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "DOT-USDC",
        balas: "USDC",
        trdas: "DOT",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "DYDX-USDC",
        balas: "USDC",
        trdas: "DYDX",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "EGLD-USDC",
        balas: "USDC",
        trdas: "EGLD",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "ENS-USDC",
        balas: "USDC",
        trdas: "ENS",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "EOS-USDC",
        balas: "USDC",
        trdas: "EOS",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "ETC-USDC",
        balas: "USDC",
        trdas: "ETC",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "ETHW-USDC",
        balas: "USDC",
        trdas: "ETHW",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "FIL-USDC",
        balas: "USDC",
        trdas: "FIL",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "FLOKI-USDC",
        balas: "USDC",
        trdas: "FLOKI",
        scnt: 1,
        pdec: 8,
        sdec: -4
      },
      {
        tk: "FLOW-USDC",
        balas: "USDC",
        trdas: "FLOW",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "FLR-USDC",
        balas: "USDC",
        trdas: "FLR",
        scnt: 1,
        pdec: 5,
        sdec: -2
      },
      {
        tk: "FORTH-USDC",
        balas: "USDC",
        trdas: "FORTH",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "FTM-USDC",
        balas: "USDC",
        trdas: "FTM",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "G-USDC",
        balas: "USDC",
        trdas: "G",
        scnt: 1,
        pdec: 5,
        sdec: -2
      },
      {
        tk: "GALA-USDC",
        balas: "USDC",
        trdas: "GALA",
        scnt: 1,
        pdec: 5,
        sdec: -2
      },
      {
        tk: "GFT-USDC",
        balas: "USDC",
        trdas: "GFT",
        scnt: 1,
        pdec: 5,
        sdec: -2
      },
      {
        tk: "GHST-USDC",
        balas: "USDC",
        trdas: "GHST",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "GLM-USDC",
        balas: "USDC",
        trdas: "GLM",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "GLMR-USDC",
        balas: "USDC",
        trdas: "GLMR",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "GMT-USDC",
        balas: "USDC",
        trdas: "GMT",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "GRT-USDC",
        balas: "USDC",
        trdas: "GRT",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "HBAR-USDC",
        balas: "USDC",
        trdas: "HBAR",
        scnt: 1,
        pdec: 5,
        sdec: -1
      },
      {
        tk: "ICP-USDC",
        balas: "USDC",
        trdas: "ICP",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "ICX-USDC",
        balas: "USDC",
        trdas: "ICX",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "IMX-USDC",
        balas: "USDC",
        trdas: "IMX",
        scnt: 1,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "IOTA-USDC",
        balas: "USDC",
        trdas: "IOTA",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "JTO-USDC",
        balas: "USDC",
        trdas: "JTO",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "KDA-USDC",
        balas: "USDC",
        trdas: "KDA",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "KLAY-USDC",
        balas: "USDC",
        trdas: "KLAY",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "KNC-USDC",
        balas: "USDC",
        trdas: "KNC",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "KP3R-USDC",
        balas: "USDC",
        trdas: "KP3R",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "KSM-USDC",
        balas: "USDC",
        trdas: "KSM",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "LDO-USDC",
        balas: "USDC",
        trdas: "LDO",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "LINK-USDC",
        balas: "USDC",
        trdas: "LINK",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "LOOKS-USDC",
        balas: "USDC",
        trdas: "LOOKS",
        scnt: 1,
        pdec: 5,
        sdec: -1
      },
      {
        tk: "LPT-USDC",
        balas: "USDC",
        trdas: "LPT",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "LQTY-USDC",
        balas: "USDC",
        trdas: "LQTY",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "LRC-USDC",
        balas: "USDC",
        trdas: "LRC",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "LSK-USDC",
        balas: "USDC",
        trdas: "LSK",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "LTC-USDC",
        balas: "USDC",
        trdas: "LTC",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "LUNC-USDC",
        balas: "USDC",
        trdas: "LUNC",
        scnt: 1,
        pdec: 8,
        sdec: -4
      },
      {
        tk: "LUNA-USDC",
        balas: "USDC",
        trdas: "LUNA",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "MAGIC-USDC",
        balas: "USDC",
        trdas: "MAGIC",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "MANA-USDC",
        balas: "USDC",
        trdas: "MANA",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "MASK-USDC",
        balas: "USDC",
        trdas: "MASK",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "MERL-USDC",
        balas: "USDC",
        trdas: "MERL",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "METIS-USDC",
        balas: "USDC",
        trdas: "METIS",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "MINA-USDC",
        balas: "USDC",
        trdas: "MINA",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "MKR-USDC",
        balas: "USDC",
        trdas: "MKR",
        scnt: 1,
        pdec: 1,
        sdec: 3
      },
      {
        tk: "MLN-USDC",
        balas: "USDC",
        trdas: "MLN",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "MOVR-USDC",
        balas: "USDC",
        trdas: "MOVR",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "NEAR-USDC",
        balas: "USDC",
        trdas: "NEAR",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "NEO-USDC",
        balas: "USDC",
        trdas: "NEO",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "NMR-USDC",
        balas: "USDC",
        trdas: "NMR",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "OKT-USDC",
        balas: "USDC",
        trdas: "OKT",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "OM-USDC",
        balas: "USDC",
        trdas: "OM",
        scnt: 1,
        pdec: 5,
        sdec: -1
      },
      {
        tk: "ONDO-USDC",
        balas: "USDC",
        trdas: "ONDO",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "OP-USDC",
        balas: "USDC",
        trdas: "OP",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "ORDI-USDC",
        balas: "USDC",
        trdas: "ORDI",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "OXT-USDC",
        balas: "USDC",
        trdas: "OXT",
        scnt: 1,
        pdec: 5,
        sdec: -1
      },
      {
        tk: "PEOPLE-USDC",
        balas: "USDC",
        trdas: "PEOPLE",
        scnt: 1,
        pdec: 5,
        sdec: -2
      },
      {
        tk: "PERP-USDC",
        balas: "USDC",
        trdas: "PERP",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "PHA-USDC",
        balas: "USDC",
        trdas: "PHA",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "PSTAKE-USDC",
        balas: "USDC",
        trdas: "PSTAKE",
        scnt: 1,
        pdec: 5,
        sdec: -2
      },
      {
        tk: "PYTH-USDC",
        balas: "USDC",
        trdas: "PYTH",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "QTUM-USDC",
        balas: "USDC",
        trdas: "QTUM",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "RDNT-USDC",
        balas: "USDC",
        trdas: "RDNT",
        scnt: 1,
        pdec: 5,
        sdec: -1
      },
      {
        tk: "REN-USDC",
        balas: "USDC",
        trdas: "REN",
        scnt: 1,
        pdec: 5,
        sdec: -2
      },
      {
        tk: "RENDER-USDC",
        balas: "USDC",
        trdas: "RENDER",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "RON-USDC",
        balas: "USDC",
        trdas: "RON",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "RPL-USDC",
        balas: "USDC",
        trdas: "RPL",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "RVN-USDC",
        balas: "USDC",
        trdas: "RVN",
        scnt: 1,
        pdec: 5,
        sdec: -2
      },
      {
        tk: "SAND-USDC",
        balas: "USDC",
        trdas: "SAND",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "SC-USDC",
        balas: "USDC",
        trdas: "SC",
        scnt: 1,
        pdec: 6,
        sdec: -2
      },
      {
        tk: "SHIB-USDC",
        balas: "USDC",
        trdas: "SHIB",
        scnt: 1,
        pdec: 9,
        sdec: -5
      },
      {
        tk: "SNX-USDC",
        balas: "USDC",
        trdas: "SNX",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "STORJ-USDC",
        balas: "USDC",
        trdas: "STORJ",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "STRK-USDC",
        balas: "USDC",
        trdas: "STRK",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "STX-USDC",
        balas: "USDC",
        trdas: "STX",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "SUI-USDC",
        balas: "USDC",
        trdas: "SUI",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "SUSHI-USDC",
        balas: "USDC",
        trdas: "SUSHI",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "SWEAT-USDC",
        balas: "USDC",
        trdas: "SWEAT",
        scnt: 1,
        pdec: 6,
        sdec: -2
      },
      {
        tk: "T-USDC",
        balas: "USDC",
        trdas: "T",
        scnt: 1,
        pdec: 5,
        sdec: -2
      },
      {
        tk: "TIA-USDC",
        balas: "USDC",
        trdas: "TIA",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "TRX-USDC",
        balas: "USDC",
        trdas: "TRX",
        scnt: 1,
        pdec: 5,
        sdec: -1
      },
      {
        tk: "UMA-USDC",
        balas: "USDC",
        trdas: "UMA",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "UNI-USDC",
        balas: "USDC",
        trdas: "UNI",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "USDT-USDC",
        balas: "USDC",
        trdas: "USDT",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "VELO-USDC",
        balas: "USDC",
        trdas: "VELO",
        scnt: 1,
        pdec: 6,
        sdec: -2
      },
      {
        tk: "VENOM-USDC",
        balas: "USDC",
        trdas: "VENOM",
        scnt: 1,
        pdec: 5,
        sdec: -1
      },
      {
        tk: "W-USDC",
        balas: "USDC",
        trdas: "W",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "WAXP-USDC",
        balas: "USDC",
        trdas: "WAXP",
        scnt: 1,
        pdec: 5,
        sdec: -2
      },
      {
        tk: "WBTC-USDC",
        balas: "USDC",
        trdas: "WBTC",
        scnt: 1,
        pdec: 1,
        sdec: 4
      },
      {
        tk: "WIF-USDC",
        balas: "USDC",
        trdas: "WIF",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "WLD-USDC",
        balas: "USDC",
        trdas: "WLD",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "XLM-USDC",
        balas: "USDC",
        trdas: "XLM",
        scnt: 1,
        pdec: 5,
        sdec: -1
      },
      {
        tk: "XNO-USDC",
        balas: "USDC",
        trdas: "XNO",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "XTZ-USDC",
        balas: "USDC",
        trdas: "XTZ",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "YFI-USDC",
        balas: "USDC",
        trdas: "YFI",
        scnt: 1,
        pdec: 0,
        sdec: 4
      },
      {
        tk: "YGG-USDC",
        balas: "USDC",
        trdas: "YGG",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "ZENT-USDC",
        balas: "USDC",
        trdas: "ZENT",
        scnt: 1,
        pdec: 5,
        sdec: -2
      },
      {
        tk: "ZERO-USDC",
        balas: "USDC",
        trdas: "ZERO",
        scnt: 1,
        pdec: 7,
        sdec: -3
      },
      {
        tk: "ZIL-USDC",
        balas: "USDC",
        trdas: "ZIL",
        scnt: 1,
        pdec: 5,
        sdec: -2
      },
      {
        tk: "ZK-USDC",
        balas: "USDC",
        trdas: "ZK",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "ZRO-USDC",
        balas: "USDC",
        trdas: "ZRO",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "ZRX-USDC",
        balas: "USDC",
        trdas: "ZRX",
        scnt: 1,
        pdec: 4,
        sdec: -1
      },
      {
        tk: "BTC-DAI",
        balas: "DAI",
        trdas: "BTC",
        scnt: 1,
        pdec: 1,
        sdec: 5
      },
      {
        tk: "ETH-DAI",
        balas: "DAI",
        trdas: "ETH",
        scnt: 1,
        pdec: 2,
        sdec: 3
      },
      {
        tk: "ETH-BTC",
        balas: "BTC",
        trdas: "ETH",
        scnt: 1,
        pdec: 5,
        sdec: 4
      },
      {
        tk: "OKB-BTC",
        balas: "BTC",
        trdas: "OKB",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "SOL-BTC",
        balas: "BTC",
        trdas: "SOL",
        scnt: 1,
        pdec: 7,
        sdec: 2
      },
      {
        tk: "BCH-BTC",
        balas: "BTC",
        trdas: "BCH",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "BSV-BTC",
        balas: "BTC",
        trdas: "BSV",
        scnt: 1,
        pdec: 7,
        sdec: 1
      },
      {
        tk: "LTC-BTC",
        balas: "BTC",
        trdas: "LTC",
        scnt: 1,
        pdec: 6,
        sdec: 2
      },
      {
        tk: "STX-BTC",
        balas: "BTC",
        trdas: "STX",
        scnt: 1,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "WBTC-BTC",
        balas: "BTC",
        trdas: "WBTC",
        scnt: 1,
        pdec: 4,
        sdec: 4
      },
      {
        tk: "SOL-ETH",
        balas: "ETH",
        trdas: "SOL",
        scnt: 1,
        pdec: 5,
        sdec: 2
      },
      {
        tk: "BETH-ETH",
        balas: "ETH",
        trdas: "BETH",
        scnt: 1,
        pdec: 4,
        sdec: 3
      },
      {
        tk: "STETH-ETH",
        balas: "ETH",
        trdas: "STETH",
        scnt: 1,
        pdec: 4,
        sdec: 3
      }
    ],
    futures: [
      {
        tk: "HMSTR-USDT-250207",
        balas: "USDT",
        trdas: "HMSTR",
        scnt: 10,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "EIGEN-USDT-250307",
        balas: "USDT",
        trdas: "EIGEN",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "CATI-USDT-250307",
        balas: "USDT",
        trdas: "CATI",
        scnt: 10,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "BTC-USD-240920",
        balas: "BTC",
        trdas: "USD",
        scnt: 100,
        pdec: 1,
        sdec: 0
      },
      {
        tk: "BTC-USD-240927",
        balas: "BTC",
        trdas: "USD",
        scnt: 100,
        pdec: 1,
        sdec: 0
      },
      {
        tk: "BTC-USD-241025",
        balas: "BTC",
        trdas: "USD",
        scnt: 100,
        pdec: 1,
        sdec: 0
      },
      {
        tk: "BTC-USD-241129",
        balas: "BTC",
        trdas: "USD",
        scnt: 100,
        pdec: 1,
        sdec: 0
      },
      {
        tk: "BTC-USD-241227",
        balas: "BTC",
        trdas: "USD",
        scnt: 100,
        pdec: 1,
        sdec: 0
      },
      {
        tk: "BTC-USD-250328",
        balas: "BTC",
        trdas: "USD",
        scnt: 100,
        pdec: 1,
        sdec: 0
      },
      {
        tk: "ETH-USD-240920",
        balas: "ETH",
        trdas: "USD",
        scnt: 10,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "ETH-USD-240927",
        balas: "ETH",
        trdas: "USD",
        scnt: 10,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "ETH-USD-241025",
        balas: "ETH",
        trdas: "USD",
        scnt: 10,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "ETH-USD-241129",
        balas: "ETH",
        trdas: "USD",
        scnt: 10,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "ETH-USD-241227",
        balas: "ETH",
        trdas: "USD",
        scnt: 10,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "ETH-USD-250328",
        balas: "ETH",
        trdas: "USD",
        scnt: 10,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "XRP-USD-240927",
        balas: "XRP",
        trdas: "USD",
        scnt: 10,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "XRP-USD-241227",
        balas: "XRP",
        trdas: "USD",
        scnt: 10,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "EOS-USD-240927",
        balas: "EOS",
        trdas: "USD",
        scnt: 10,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "ETC-USD-240927",
        balas: "ETC",
        trdas: "USD",
        scnt: 10,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "LTC-USD-240927",
        balas: "LTC",
        trdas: "USD",
        scnt: 10,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "LTC-USD-241227",
        balas: "LTC",
        trdas: "USD",
        scnt: 10,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "BTC-USDT-240920",
        balas: "USDT",
        trdas: "BTC",
        scnt: 0.01,
        pdec: 1,
        sdec: 1
      },
      {
        tk: "BTC-USDT-240927",
        balas: "USDT",
        trdas: "BTC",
        scnt: 0.01,
        pdec: 1,
        sdec: 1
      },
      {
        tk: "BTC-USDT-241025",
        balas: "USDT",
        trdas: "BTC",
        scnt: 0.01,
        pdec: 1,
        sdec: 1
      },
      {
        tk: "BTC-USDT-241129",
        balas: "USDT",
        trdas: "BTC",
        scnt: 0.01,
        pdec: 1,
        sdec: 1
      },
      {
        tk: "BTC-USDT-241227",
        balas: "USDT",
        trdas: "BTC",
        scnt: 0.01,
        pdec: 1,
        sdec: 1
      },
      {
        tk: "BTC-USDT-250328",
        balas: "USDT",
        trdas: "BTC",
        scnt: 0.01,
        pdec: 1,
        sdec: 1
      },
      {
        tk: "ETH-USDT-240920",
        balas: "USDT",
        trdas: "ETH",
        scnt: 0.1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "ETH-USDT-240927",
        balas: "USDT",
        trdas: "ETH",
        scnt: 0.1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "ETH-USDT-241227",
        balas: "USDT",
        trdas: "ETH",
        scnt: 0.1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "ETH-USDT-250328",
        balas: "USDT",
        trdas: "ETH",
        scnt: 0.1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "XRP-USDT-240927",
        balas: "USDT",
        trdas: "XRP",
        scnt: 100,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "XRP-USDT-241227",
        balas: "USDT",
        trdas: "XRP",
        scnt: 100,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "EOS-USDT-240927",
        balas: "USDT",
        trdas: "EOS",
        scnt: 10,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "ETC-USDT-240927",
        balas: "USDT",
        trdas: "ETC",
        scnt: 10,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "LTC-USDT-240927",
        balas: "USDT",
        trdas: "LTC",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "LTC-USDT-241227",
        balas: "USDT",
        trdas: "LTC",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "BTC-USDC-240920",
        balas: "USDC",
        trdas: "BTC",
        scnt: 0.0001,
        pdec: 1,
        sdec: 0
      },
      {
        tk: "BTC-USDC-240927",
        balas: "USDC",
        trdas: "BTC",
        scnt: 0.0001,
        pdec: 1,
        sdec: 0
      },
      {
        tk: "BTC-USDC-241227",
        balas: "USDC",
        trdas: "BTC",
        scnt: 0.0001,
        pdec: 1,
        sdec: 0
      },
      {
        tk: "BTC-USDC-250328",
        balas: "USDC",
        trdas: "BTC",
        scnt: 0.0001,
        pdec: 1,
        sdec: 0
      },
      {
        tk: "ETH-USDC-240920",
        balas: "USDC",
        trdas: "ETH",
        scnt: 0.001,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "ETH-USDC-240927",
        balas: "USDC",
        trdas: "ETH",
        scnt: 0.001,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "ETH-USDC-241227",
        balas: "USDC",
        trdas: "ETH",
        scnt: 0.001,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "ETH-USDC-250328",
        balas: "USDC",
        trdas: "ETH",
        scnt: 0.001,
        pdec: 2,
        sdec: 0
      }
    ],
    swap: [
      {
        tk: "BTC-USD-SWAP",
        balas: "BTC",
        trdas: "USD",
        scnt: 100,
        pdec: 1,
        sdec: 0
      },
      {
        tk: "ETH-USD-SWAP",
        balas: "ETH",
        trdas: "USD",
        scnt: 10,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "XRP-USD-SWAP",
        balas: "XRP",
        trdas: "USD",
        scnt: 10,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "SOL-USD-SWAP",
        balas: "SOL",
        trdas: "USD",
        scnt: 10,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "DOGE-USD-SWAP",
        balas: "DOGE",
        trdas: "USD",
        scnt: 10,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "1INCH-USD-SWAP",
        balas: "1INCH",
        trdas: "USD",
        scnt: 10,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "ADA-USD-SWAP",
        balas: "ADA",
        trdas: "USD",
        scnt: 10,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "ALGO-USD-SWAP",
        balas: "ALGO",
        trdas: "USD",
        scnt: 10,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "ATOM-USD-SWAP",
        balas: "ATOM",
        trdas: "USD",
        scnt: 10,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "AVAX-USD-SWAP",
        balas: "AVAX",
        trdas: "USD",
        scnt: 10,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "BCH-USD-SWAP",
        balas: "BCH",
        trdas: "USD",
        scnt: 10,
        pdec: 1,
        sdec: 0
      },
      {
        tk: "BSV-USD-SWAP",
        balas: "BSV",
        trdas: "USD",
        scnt: 10,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "CRV-USD-SWAP",
        balas: "CRV",
        trdas: "USD",
        scnt: 10,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "DOT-USD-SWAP",
        balas: "DOT",
        trdas: "USD",
        scnt: 10,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "EOS-USD-SWAP",
        balas: "EOS",
        trdas: "USD",
        scnt: 10,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "ETC-USD-SWAP",
        balas: "ETC",
        trdas: "USD",
        scnt: 10,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "FIL-USD-SWAP",
        balas: "FIL",
        trdas: "USD",
        scnt: 10,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "GRT-USD-SWAP",
        balas: "GRT",
        trdas: "USD",
        scnt: 10,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "LINK-USD-SWAP",
        balas: "LINK",
        trdas: "USD",
        scnt: 10,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "LTC-USD-SWAP",
        balas: "LTC",
        trdas: "USD",
        scnt: 10,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "MANA-USD-SWAP",
        balas: "MANA",
        trdas: "USD",
        scnt: 10,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "NEO-USD-SWAP",
        balas: "NEO",
        trdas: "USD",
        scnt: 10,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "OP-USD-SWAP",
        balas: "OP",
        trdas: "USD",
        scnt: 10,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "SAND-USD-SWAP",
        balas: "SAND",
        trdas: "USD",
        scnt: 10,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "SUSHI-USD-SWAP",
        balas: "SUSHI",
        trdas: "USD",
        scnt: 10,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "THETA-USD-SWAP",
        balas: "THETA",
        trdas: "USD",
        scnt: 10,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "TRX-USD-SWAP",
        balas: "TRX",
        trdas: "USD",
        scnt: 10,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "UNI-USD-SWAP",
        balas: "UNI",
        trdas: "USD",
        scnt: 10,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "XLM-USD-SWAP",
        balas: "XLM",
        trdas: "USD",
        scnt: 10,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "YFI-USD-SWAP",
        balas: "YFI",
        trdas: "USD",
        scnt: 10,
        pdec: 0,
        sdec: 0
      },
      {
        tk: "BTC-USDT-SWAP",
        balas: "USDT",
        trdas: "BTC",
        scnt: 0.01,
        pdec: 1,
        sdec: 1
      },
      {
        tk: "ETH-USDT-SWAP",
        balas: "USDT",
        trdas: "ETH",
        scnt: 0.1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "XRP-USDT-SWAP",
        balas: "USDT",
        trdas: "XRP",
        scnt: 100,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "SOL-USDT-SWAP",
        balas: "USDT",
        trdas: "SOL",
        scnt: 1,
        pdec: 2,
        sdec: 2
      },
      {
        tk: "DOGE-USDT-SWAP",
        balas: "USDT",
        trdas: "DOGE",
        scnt: 1000,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "DOGS-USDT-SWAP",
        balas: "USDT",
        trdas: "DOGS",
        scnt: 1000,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "TON-USDT-SWAP",
        balas: "USDT",
        trdas: "TON",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "PEPE-USDT-SWAP",
        balas: "USDT",
        trdas: "PEPE",
        scnt: 10000000,
        pdec: 9,
        sdec: 1
      },
      {
        tk: "SATS-USDT-SWAP",
        balas: "USDT",
        trdas: "SATS",
        scnt: 10000000,
        pdec: 11,
        sdec: 0
      },
      {
        tk: "1INCH-USDT-SWAP",
        balas: "USDT",
        trdas: "1INCH",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "AAVE-USDT-SWAP",
        balas: "USDT",
        trdas: "AAVE",
        scnt: 0.1,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "ACE-USDT-SWAP",
        balas: "USDT",
        trdas: "ACE",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "ACH-USDT-SWAP",
        balas: "USDT",
        trdas: "ACH",
        scnt: 100,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "ADA-USDT-SWAP",
        balas: "USDT",
        trdas: "ADA",
        scnt: 100,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "AEVO-USDT-SWAP",
        balas: "USDT",
        trdas: "AEVO",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "AGLD-USDT-SWAP",
        balas: "USDT",
        trdas: "AGLD",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "AIDOGE-USDT-SWAP",
        balas: "USDT",
        trdas: "AIDOGE",
        scnt: 10000000000,
        pdec: 13,
        sdec: 0
      },
      {
        tk: "ALGO-USDT-SWAP",
        balas: "USDT",
        trdas: "ALGO",
        scnt: 10,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "ALPHA-USDT-SWAP",
        balas: "USDT",
        trdas: "ALPHA",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "APE-USDT-SWAP",
        balas: "USDT",
        trdas: "APE",
        scnt: 0.1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "API3-USDT-SWAP",
        balas: "USDT",
        trdas: "API3",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "APT-USDT-SWAP",
        balas: "USDT",
        trdas: "APT",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "AR-USDT-SWAP",
        balas: "USDT",
        trdas: "AR",
        scnt: 0.1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "ARB-USDT-SWAP",
        balas: "USDT",
        trdas: "ARB",
        scnt: 10,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "ATH-USDT-SWAP",
        balas: "USDT",
        trdas: "ATH",
        scnt: 100,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "ATOM-USDT-SWAP",
        balas: "USDT",
        trdas: "ATOM",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "AUCTION-USDT-SWAP",
        balas: "USDT",
        trdas: "AUCTION",
        scnt: 0.1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "AVAX-USDT-SWAP",
        balas: "USDT",
        trdas: "AVAX",
        scnt: 1,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "AXS-USDT-SWAP",
        balas: "USDT",
        trdas: "AXS",
        scnt: 0.1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "BADGER-USDT-SWAP",
        balas: "USDT",
        trdas: "BADGER",
        scnt: 0.1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "BAL-USDT-SWAP",
        balas: "USDT",
        trdas: "BAL",
        scnt: 0.1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "BAND-USDT-SWAP",
        balas: "USDT",
        trdas: "BAND",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "BAT-USDT-SWAP",
        balas: "USDT",
        trdas: "BAT",
        scnt: 10,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "BCH-USDT-SWAP",
        balas: "USDT",
        trdas: "BCH",
        scnt: 0.1,
        pdec: 1,
        sdec: 1
      },
      {
        tk: "BICO-USDT-SWAP",
        balas: "USDT",
        trdas: "BICO",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "BIGTIME-USDT-SWAP",
        balas: "USDT",
        trdas: "BIGTIME",
        scnt: 10,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "BLUR-USDT-SWAP",
        balas: "USDT",
        trdas: "BLUR",
        scnt: 10,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "BNB-USDT-SWAP",
        balas: "USDT",
        trdas: "BNB",
        scnt: 0.01,
        pdec: 1,
        sdec: 0
      },
      {
        tk: "BNT-USDT-SWAP",
        balas: "USDT",
        trdas: "BNT",
        scnt: 10,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "BOME-USDT-SWAP",
        balas: "USDT",
        trdas: "BOME",
        scnt: 1000,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "BONK-USDT-SWAP",
        balas: "USDT",
        trdas: "BONK",
        scnt: 100000,
        pdec: 9,
        sdec: 0
      },
      {
        tk: "BONE-USDT-SWAP",
        balas: "USDT",
        trdas: "BONE",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "BRETT-USDT-SWAP",
        balas: "USDT",
        trdas: "BRETT",
        scnt: 100,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "BSV-USDT-SWAP",
        balas: "USDT",
        trdas: "BSV",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "CAT-USDT-SWAP",
        balas: "USDT",
        trdas: "CAT",
        scnt: 100000,
        pdec: 9,
        sdec: 0
      },
      {
        tk: "CELO-USDT-SWAP",
        balas: "USDT",
        trdas: "CELO",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "CETUS-USDT-SWAP",
        balas: "USDT",
        trdas: "CETUS",
        scnt: 10,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "CFX-USDT-SWAP",
        balas: "USDT",
        trdas: "CFX",
        scnt: 10,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "CHZ-USDT-SWAP",
        balas: "USDT",
        trdas: "CHZ",
        scnt: 10,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "COMP-USDT-SWAP",
        balas: "USDT",
        trdas: "COMP",
        scnt: 0.1,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "CORE-USDT-SWAP",
        balas: "USDT",
        trdas: "CORE",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "CRO-USDT-SWAP",
        balas: "USDT",
        trdas: "CRO",
        scnt: 10,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "CRV-USDT-SWAP",
        balas: "USDT",
        trdas: "CRV",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "CSPR-USDT-SWAP",
        balas: "USDT",
        trdas: "CSPR",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "CTC-USDT-SWAP",
        balas: "USDT",
        trdas: "CTC",
        scnt: 10,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "CVC-USDT-SWAP",
        balas: "USDT",
        trdas: "CVC",
        scnt: 100,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "CVX-USDT-SWAP",
        balas: "USDT",
        trdas: "CVX",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "DGB-USDT-SWAP",
        balas: "USDT",
        trdas: "DGB",
        scnt: 100,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "DOT-USDT-SWAP",
        balas: "USDT",
        trdas: "DOT",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "DYDX-USDT-SWAP",
        balas: "USDT",
        trdas: "DYDX",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "EGLD-USDT-SWAP",
        balas: "USDT",
        trdas: "EGLD",
        scnt: 0.1,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "ENJ-USDT-SWAP",
        balas: "USDT",
        trdas: "ENJ",
        scnt: 10,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "ENS-USDT-SWAP",
        balas: "USDT",
        trdas: "ENS",
        scnt: 0.1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "EOS-USDT-SWAP",
        balas: "USDT",
        trdas: "EOS",
        scnt: 10,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "ETC-USDT-SWAP",
        balas: "USDT",
        trdas: "ETC",
        scnt: 10,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "ETHW-USDT-SWAP",
        balas: "USDT",
        trdas: "ETHW",
        scnt: 0.1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "ETHFI-USDT-SWAP",
        balas: "USDT",
        trdas: "ETHFI",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "FIL-USDT-SWAP",
        balas: "USDT",
        trdas: "FIL",
        scnt: 0.1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "FLM-USDT-SWAP",
        balas: "USDT",
        trdas: "FLM",
        scnt: 10,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "FLOKI-USDT-SWAP",
        balas: "USDT",
        trdas: "FLOKI",
        scnt: 100000,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "FLOW-USDT-SWAP",
        balas: "USDT",
        trdas: "FLOW",
        scnt: 10,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "FLR-USDT-SWAP",
        balas: "USDT",
        trdas: "FLR",
        scnt: 100,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "FOXY-USDT-SWAP",
        balas: "USDT",
        trdas: "FOXY",
        scnt: 100,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "FTM-USDT-SWAP",
        balas: "USDT",
        trdas: "FTM",
        scnt: 10,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "FXS-USDT-SWAP",
        balas: "USDT",
        trdas: "FXS",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "GALA-USDT-SWAP",
        balas: "USDT",
        trdas: "GALA",
        scnt: 10,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "GAS-USDT-SWAP",
        balas: "USDT",
        trdas: "GAS",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "GFT-USDT-SWAP",
        balas: "USDT",
        trdas: "GFT",
        scnt: 100,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "GLM-USDT-SWAP",
        balas: "USDT",
        trdas: "GLM",
        scnt: 10,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "GMT-USDT-SWAP",
        balas: "USDT",
        trdas: "GMT",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "GMX-USDT-SWAP",
        balas: "USDT",
        trdas: "GMX",
        scnt: 0.1,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "GODS-USDT-SWAP",
        balas: "USDT",
        trdas: "GODS",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "GPT-USDT-SWAP",
        balas: "USDT",
        trdas: "GPT",
        scnt: 10,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "GRT-USDT-SWAP",
        balas: "USDT",
        trdas: "GRT",
        scnt: 10,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "HBAR-USDT-SWAP",
        balas: "USDT",
        trdas: "HBAR",
        scnt: 100,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "ICP-USDT-SWAP",
        balas: "USDT",
        trdas: "ICP",
        scnt: 0.01,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "ICX-USDT-SWAP",
        balas: "USDT",
        trdas: "ICX",
        scnt: 10,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "ID-USDT-SWAP",
        balas: "USDT",
        trdas: "ID",
        scnt: 10,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "IMX-USDT-SWAP",
        balas: "USDT",
        trdas: "IMX",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "INJ-USDT-SWAP",
        balas: "USDT",
        trdas: "INJ",
        scnt: 0.1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "IOST-USDT-SWAP",
        balas: "USDT",
        trdas: "IOST",
        scnt: 1000,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "IOTA-USDT-SWAP",
        balas: "USDT",
        trdas: "IOTA",
        scnt: 10,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "JOE-USDT-SWAP",
        balas: "USDT",
        trdas: "JOE",
        scnt: 10,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "JST-USDT-SWAP",
        balas: "USDT",
        trdas: "JST",
        scnt: 100,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "JTO-USDT-SWAP",
        balas: "USDT",
        trdas: "JTO",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "JUP-USDT-SWAP",
        balas: "USDT",
        trdas: "JUP",
        scnt: 10,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "KISHU-USDT-SWAP",
        balas: "USDT",
        trdas: "KISHU",
        scnt: 1000000000,
        pdec: 12,
        sdec: 0
      },
      {
        tk: "KLAY-USDT-SWAP",
        balas: "USDT",
        trdas: "KLAY",
        scnt: 10,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "KNC-USDT-SWAP",
        balas: "USDT",
        trdas: "KNC",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "KSM-USDT-SWAP",
        balas: "USDT",
        trdas: "KSM",
        scnt: 0.1,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "LDO-USDT-SWAP",
        balas: "USDT",
        trdas: "LDO",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "LINK-USDT-SWAP",
        balas: "USDT",
        trdas: "LINK",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "LOOKS-USDT-SWAP",
        balas: "USDT",
        trdas: "LOOKS",
        scnt: 1,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "LPT-USDT-SWAP",
        balas: "USDT",
        trdas: "LPT",
        scnt: 0.1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "LQTY-USDT-SWAP",
        balas: "USDT",
        trdas: "LQTY",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "LRC-USDT-SWAP",
        balas: "USDT",
        trdas: "LRC",
        scnt: 10,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "LSK-USDT-SWAP",
        balas: "USDT",
        trdas: "LSK",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "LTC-USDT-SWAP",
        balas: "USDT",
        trdas: "LTC",
        scnt: 1,
        pdec: 2,
        sdec: 1
      },
      {
        tk: "LUNA-USDT-SWAP",
        balas: "USDT",
        trdas: "LUNA",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "LUNC-USDT-SWAP",
        balas: "USDT",
        trdas: "LUNC",
        scnt: 10000,
        pdec: 8,
        sdec: 0
      },
      {
        tk: "MAGIC-USDT-SWAP",
        balas: "USDT",
        trdas: "MAGIC",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "MANA-USDT-SWAP",
        balas: "USDT",
        trdas: "MANA",
        scnt: 10,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "MASK-USDT-SWAP",
        balas: "USDT",
        trdas: "MASK",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "MAX-USDT-SWAP",
        balas: "USDT",
        trdas: "MAX",
        scnt: 10,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "MEME-USDT-SWAP",
        balas: "USDT",
        trdas: "MEME",
        scnt: 100,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "MERL-USDT-SWAP",
        balas: "USDT",
        trdas: "MERL",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "METIS-USDT-SWAP",
        balas: "USDT",
        trdas: "METIS",
        scnt: 0.1,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "MEW-USDT-SWAP",
        balas: "USDT",
        trdas: "MEW",
        scnt: 1000,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "MINA-USDT-SWAP",
        balas: "USDT",
        trdas: "MINA",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "MKR-USDT-SWAP",
        balas: "USDT",
        trdas: "MKR",
        scnt: 0.01,
        pdec: 1,
        sdec: 1
      },
      {
        tk: "MOVR-USDT-SWAP",
        balas: "USDT",
        trdas: "MOVR",
        scnt: 0.1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "NEAR-USDT-SWAP",
        balas: "USDT",
        trdas: "NEAR",
        scnt: 10,
        pdec: 3,
        sdec: 1
      },
      {
        tk: "NEIROETH-USDT-SWAP",
        balas: "USDT",
        trdas: "NEIROETH",
        scnt: 100,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "NEO-USDT-SWAP",
        balas: "USDT",
        trdas: "NEO",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "NFT-USDT-SWAP",
        balas: "USDT",
        trdas: "NFT",
        scnt: 1000000,
        pdec: 10,
        sdec: 0
      },
      {
        tk: "NMR-USDT-SWAP",
        balas: "USDT",
        trdas: "NMR",
        scnt: 0.1,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "NOT-USDT-SWAP",
        balas: "USDT",
        trdas: "NOT",
        scnt: 100,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "OM-USDT-SWAP",
        balas: "USDT",
        trdas: "OM",
        scnt: 10,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "ONDO-USDT-SWAP",
        balas: "USDT",
        trdas: "ONDO",
        scnt: 10,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "ONE-USDT-SWAP",
        balas: "USDT",
        trdas: "ONE",
        scnt: 100,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "ONT-USDT-SWAP",
        balas: "USDT",
        trdas: "ONT",
        scnt: 10,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "OP-USDT-SWAP",
        balas: "USDT",
        trdas: "OP",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "ORBS-USDT-SWAP",
        balas: "USDT",
        trdas: "ORBS",
        scnt: 100,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "ORDI-USDT-SWAP",
        balas: "USDT",
        trdas: "ORDI",
        scnt: 0.1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "PEOPLE-USDT-SWAP",
        balas: "USDT",
        trdas: "PEOPLE",
        scnt: 100,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "PERP-USDT-SWAP",
        balas: "USDT",
        trdas: "PERP",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "POPCAT-USDT-SWAP",
        balas: "USDT",
        trdas: "POPCAT",
        scnt: 10,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "PRCL-USDT-SWAP",
        balas: "USDT",
        trdas: "PRCL",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "PYTH-USDT-SWAP",
        balas: "USDT",
        trdas: "PYTH",
        scnt: 10,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "QTUM-USDT-SWAP",
        balas: "USDT",
        trdas: "QTUM",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "RACA-USDT-SWAP",
        balas: "USDT",
        trdas: "RACA",
        scnt: 10000,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "RAY-USDT-SWAP",
        balas: "USDT",
        trdas: "RAY",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "RDNT-USDT-SWAP",
        balas: "USDT",
        trdas: "RDNT",
        scnt: 10,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "RENDER-USDT-SWAP",
        balas: "USDT",
        trdas: "RENDER",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "RON-USDT-SWAP",
        balas: "USDT",
        trdas: "RON",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "RSR-USDT-SWAP",
        balas: "USDT",
        trdas: "RSR",
        scnt: 100,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "RVN-USDT-SWAP",
        balas: "USDT",
        trdas: "RVN",
        scnt: 10,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "SAND-USDT-SWAP",
        balas: "USDT",
        trdas: "SAND",
        scnt: 10,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "SHIB-USDT-SWAP",
        balas: "USDT",
        trdas: "SHIB",
        scnt: 1000000,
        pdec: 9,
        sdec: 1
      },
      {
        tk: "SLP-USDT-SWAP",
        balas: "USDT",
        trdas: "SLP",
        scnt: 10,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "SNX-USDT-SWAP",
        balas: "USDT",
        trdas: "SNX",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "SSV-USDT-SWAP",
        balas: "USDT",
        trdas: "SSV",
        scnt: 0.1,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "STORJ-USDT-SWAP",
        balas: "USDT",
        trdas: "STORJ",
        scnt: 10,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "STRK-USDT-SWAP",
        balas: "USDT",
        trdas: "STRK",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "STX-USDT-SWAP",
        balas: "USDT",
        trdas: "STX",
        scnt: 10,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "SUI-USDT-SWAP",
        balas: "USDT",
        trdas: "SUI",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "SUSHI-USDT-SWAP",
        balas: "USDT",
        trdas: "SUSHI",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "SWEAT-USDT-SWAP",
        balas: "USDT",
        trdas: "SWEAT",
        scnt: 100,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "T-USDT-SWAP",
        balas: "USDT",
        trdas: "T",
        scnt: 100,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "THETA-USDT-SWAP",
        balas: "USDT",
        trdas: "THETA",
        scnt: 10,
        pdec: 4,
        sdec: 1
      },
      {
        tk: "TIA-USDT-SWAP",
        balas: "USDT",
        trdas: "TIA",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "TNSR-USDT-SWAP",
        balas: "USDT",
        trdas: "TNSR",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "TRB-USDT-SWAP",
        balas: "USDT",
        trdas: "TRB",
        scnt: 0.1,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "TRX-USDT-SWAP",
        balas: "USDT",
        trdas: "TRX",
        scnt: 1000,
        pdec: 5,
        sdec: 1
      },
      {
        tk: "TURBO-USDT-SWAP",
        balas: "USDT",
        trdas: "TURBO",
        scnt: 10000,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "ULTI-USDT-SWAP",
        balas: "USDT",
        trdas: "ULTI",
        scnt: 100,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "UMA-USDT-SWAP",
        balas: "USDT",
        trdas: "UMA",
        scnt: 0.1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "UNI-USDT-SWAP",
        balas: "USDT",
        trdas: "UNI",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "USDC-USDT-SWAP",
        balas: "USDT",
        trdas: "USDC",
        scnt: 10,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "USTC-USDT-SWAP",
        balas: "USDT",
        trdas: "USTC",
        scnt: 100,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "UXLINK-USDT-SWAP",
        balas: "USDT",
        trdas: "UXLINK",
        scnt: 10,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "VELO-USDT-SWAP",
        balas: "USDT",
        trdas: "VELO",
        scnt: 1000,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "VRA-USDT-SWAP",
        balas: "USDT",
        trdas: "VRA",
        scnt: 1000,
        pdec: 6,
        sdec: 0
      },
      {
        tk: "W-USDT-SWAP",
        balas: "USDT",
        trdas: "W",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "WAXP-USDT-SWAP",
        balas: "USDT",
        trdas: "WAXP",
        scnt: 100,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "WIF-USDT-SWAP",
        balas: "USDT",
        trdas: "WIF",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "WLD-USDT-SWAP",
        balas: "USDT",
        trdas: "WLD",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "WOO-USDT-SWAP",
        balas: "USDT",
        trdas: "WOO",
        scnt: 10,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "XCH-USDT-SWAP",
        balas: "USDT",
        trdas: "XCH",
        scnt: 0.01,
        pdec: 2,
        sdec: 0
      },
      {
        tk: "XLM-USDT-SWAP",
        balas: "USDT",
        trdas: "XLM",
        scnt: 100,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "XTZ-USDT-SWAP",
        balas: "USDT",
        trdas: "XTZ",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "YFI-USDT-SWAP",
        balas: "USDT",
        trdas: "YFI",
        scnt: 0.0001,
        pdec: 0,
        sdec: 0
      },
      {
        tk: "YGG-USDT-SWAP",
        balas: "USDT",
        trdas: "YGG",
        scnt: 1,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "ZENT-USDT-SWAP",
        balas: "USDT",
        trdas: "ZENT",
        scnt: 100,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "ZERO-USDT-SWAP",
        balas: "USDT",
        trdas: "ZERO",
        scnt: 1000,
        pdec: 7,
        sdec: 0
      },
      {
        tk: "ZETA-USDT-SWAP",
        balas: "USDT",
        trdas: "ZETA",
        scnt: 10,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "ZEUS-USDT-SWAP",
        balas: "USDT",
        trdas: "ZEUS",
        scnt: 10,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "ZIL-USDT-SWAP",
        balas: "USDT",
        trdas: "ZIL",
        scnt: 100,
        pdec: 5,
        sdec: 0
      },
      {
        tk: "ZK-USDT-SWAP",
        balas: "USDT",
        trdas: "ZK",
        scnt: 10,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "ZRO-USDT-SWAP",
        balas: "USDT",
        trdas: "ZRO",
        scnt: 1,
        pdec: 3,
        sdec: 0
      },
      {
        tk: "ZRX-USDT-SWAP",
        balas: "USDT",
        trdas: "ZRX",
        scnt: 10,
        pdec: 4,
        sdec: 0
      },
      {
        tk: "BTC-USDC-SWAP",
        balas: "USDC",
        trdas: "BTC",
        scnt: 0.0001,
        pdec: 1,
        sdec: 0
      },
      {
        tk: "ETH-USDC-SWAP",
        balas: "USDC",
        trdas: "ETH",
        scnt: 0.001,
        pdec: 2,
        sdec: 0
      }
    ]
  }
}

export {
  tickers
}

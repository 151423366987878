import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import { Menu } from 'antd'
import { DashboardOutlined, TeamOutlined, RobotOutlined, TransactionOutlined, SettingOutlined } from '@ant-design/icons'

import { useAuth } from '../../contexts/AuthContextProvider'

import './Sidebar.css'

const Sidebar = () => {
  const { admin, tester } = useAuth()
  const navigate = useNavigate()
  let location = useLocation()

  if (['/admin', '/admin/'].indexOf(location.pathname) !== -1) {
    location.pathname = '/admin/dashboard'
  }

  const getItem = (label, key, icon, children, isAdmin = false, isTester = false, type) => {
    if ((isAdmin || isTester) && !((admin && isAdmin) || (tester && isTester))) {
      return null
    }
    return { key, icon, children, label, type }
  }

  const onClick = (e) => {
    if (!e?.key) {
      return
    }
    navigate(e.key)
  }

  const items = [
    getItem('Dashboard', '/admin/dashboard', <DashboardOutlined />, null, false, false),
    getItem('Users', '/admin/users', <TeamOutlined />, null, true, false),
    getItem('Bots', 'bots', <RobotOutlined />, [
      getItem('TradingView', '/admin/bots/tv', null, null, false, false),
    ], false, false),
    getItem('Billing', '/admin/billings', <TransactionOutlined />, null, false, false),
    getItem('Settings', '/admin/settings', <SettingOutlined />, null, false, false),
  ]

  return (
    <div className='sidebar'>
      <Menu
        mode='inline'
        style={{ backgroundColor: '#08979c' }}
        defaultSelectedKeys={[location.pathname]}
        defaultOpenKeys={['bots']}
        onClick={onClick}
        items={items}
      />
    </div>
  )
}

export default Sidebar

import { initializeApp } from 'firebase/app'
import { getAuth, connectAuthEmulator } from 'firebase/auth'
import { initializeFirestore, persistentLocalCache, persistentMultipleTabManager, connectFirestoreEmulator } from 'firebase/firestore'
// import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'
// import { getStorage, connectStorageEmulator } from 'firebase/storage'

const app = initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
})

const auth = getAuth(app)
const db = initializeFirestore(app, {
  localCache:
    persistentLocalCache({
      tabManager: persistentMultipleTabManager()
    })
})
// const db = getFirestore(app)
const functions = getFunctions(app, process.env.REACT_APP_FIREBASE_REGION)
// const storage = getStorage(app, 'gs://my-custom-bucket')

// eslint-disable-next-line no-restricted-globals
if (process.env.REACT_APP_USE_EMULATOR) {
  connectAuthEmulator(auth, 'http://127.0.0.1:9099')
  connectFirestoreEmulator(db, '127.0.0.1', 8080)
  connectFunctionsEmulator(functions, '127.0.0.1', 5001)
  // connectStorageEmulator(storage, '127.0.0.1', 9199)
}

export { auth, db, functions }
import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

import { Skeleton } from 'antd'

import { useAuth } from '../contexts/AuthContextProvider'

const PrivateRoute = ({ children, isAdmin = false, isTester = false }) => {
  const navigate = useNavigate()
  const { admin, tester } = useAuth()

  let redirect = false

  useEffect(() => {
    if (redirect) {
      navigate('/403')
    }
    return () => { }
  }, [redirect, navigate])

  if (!((admin && isAdmin) || (tester && isTester))) {
    redirect = true
    return <Skeleton />
  }
  return (children) ? children : <Outlet />
}

export default PrivateRoute
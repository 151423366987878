import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Modal, message, Skeleton } from 'antd'

import { GoogleAuthProvider, signInWithPopup, signOut, onAuthStateChanged } from 'firebase/auth'

import { auth, db, functions } from '../utils/firebase'

auth.useDeviceLanguage()

const provider = new GoogleAuthProvider()
provider.setCustomParameters({
  prompt: 'select_account'
})

const handleLogin = async () => {
  try {
    await signInWithPopup(auth, provider)
  } catch (error) {
    message.error(error.message)
  }
}

const handleLogout = async () => {
  try {
    await signOut(auth)
  } catch (error) {
    message.error(error.message)
  }
}

const AuthContext = React.createContext({
  auth,
  db,
  functions,
  // storage,
  firebaseLogin: handleLogin,
  firebaseLogout: handleLogout,
  uid: '',
  email: '',
  admin: false,
  tester: false,
  loading: false
})

const AuthContextProvider = ({
  children
}) => {
  const [modal, contextHolder] = Modal.useModal()
  const navigate = useNavigate()
  const [uid, setUid] = useState()
  const [email, setEmail] = useState()
  const [admin, setAdmin] = useState(false)
  const [tester, setTester] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(false)
  const contextValue = {
    auth,
    db,
    functions,
    // storage,
    firebaseLogin: handleLogin,
    firebaseLogout: handleLogout,
    uid,
    email,
    admin,
    tester,
    loading
  }

  useEffect(() => {
    if (loading) return
    const prevUrl = window.location.pathname
    onAuthStateChanged(auth, async (userAuth) => {
      if (!userAuth) {
        if (['/', '/login'].indexOf(prevUrl) === -1) {
          navigate(`/login?continue=${encodeURIComponent(prevUrl)}`)
        } else {
          navigate('/login')
        }
        setLoaded(true)
        return
      }

      let isSignup = false
      const idToken = await userAuth.getIdTokenResult()
      if (!idToken?.claims?.trader) {
        setLoading(true)
        isSignup = true
        const instance = modal.success()
        instance.update({
          title: 'You have successfully registered.',
          content: 'Need to log in again'
        })
        setTimeout(async () => {
          setLoading(false)
          await handleLogout()
          instance.destroy()
        }, 3000)
      }

      setEmail(userAuth.email)
      setUid(userAuth.uid)
      setAdmin(idToken?.claims?.admin)
      setTester(idToken?.claims?.tester)

      const url = new URL(window.location)
      const params = new URLSearchParams(url.search)
      const redirect = params.get('continue')

      if (redirect) {
        navigate(redirect)
      } else if (['/', '/login'].indexOf(window.location.pathname) !== -1) {
        if (!isSignup) {
          navigate('/admin')
        } else {
          navigate('/login')
        }
      }
      setLoaded(true)
    })
  }, [navigate, modal, loading])

  if (!loaded) return <Skeleton></Skeleton>
  return (
    <AuthContext.Provider value={contextValue}>
      {contextHolder}
      {children}
    </AuthContext.Provider>
  )
}

const useAuth = () => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('Cannot useAuth outside AuthContextProvider!')
  }
  return context
}

export default AuthContextProvider
export {
  useAuth
}

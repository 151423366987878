import React, { createContext, useContext, useState } from 'react'

const LayoutContext = createContext({})

const useLayoutContext = () => {
  const context = useContext(LayoutContext)
  if (!context) {
    throw new Error('Cannot use LayoutContext outside LayoutContextProvider!')
  }
  return context
}

const LayoutContextProvider = ({ children }) => {
  const [paginate, setPaginate] = useState({})
  const [tk, setTk] = useState({})
  const [x, setX] = useState({})

  const getPageNo = (key) => {
    return paginate[key]
  }

  const onPageChange = (key, page) => {
    setPaginate({
      ...paginate,
      [key]: page
    })
  }

  const getTK = (key) => {
    return tk[key]
  }

  const setTK = (key, value) => {
    setTk({
      ...tk,
      [key]: value
    })
  }

  const getData = (key) => {
    return x[key]
  }

  const setData = (key, value) => {
    setX({
      ...x,
      [key]: value
    })
  }

  const contextValue = {
    getPageNo,
    onPageChange,
    getTK,
    setTK,
    getData,
    setData
  }
  return (
    <LayoutContext.Provider value={contextValue}>
      {children}
    </LayoutContext.Provider>
  )
}

export default LayoutContextProvider
export {
  useLayoutContext
}

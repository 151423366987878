import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAsync } from 'react-use'

import { Form as AntdForm, Row, Col, Card, Button, Input, InputNumber, Select, Skeleton } from 'antd'
import { SaveOutlined, CloseOutlined } from '@ant-design/icons'

import { collection, doc, getDoc, addDoc, setDoc } from 'firebase/firestore'

import { useAuth } from '../../contexts/AuthContextProvider'
import { pickBy } from '../../utils/tools'
import { billingCoin } from '../../const/billing'

import './Billing.css'

const Form = () => {
  const navigate = useNavigate()
  const { db } = useAuth()
  const { bid } = useParams()
  const [btnLoading, setBtnLoading] = useState(false)
  const [networks, setNetworks] = useState([])
  const [address, setAddress] = useState()

  const [billingForm] = AntdForm.useForm()

  const addressOrd = 0

  const { value, loading } = useAsync(async () => {
    const snap = await getDoc(doc(db, 'billings', bid))
    const data = snap.data()
    setAddress(data.to)
    return {
      ...data,
      id: snap.id
    }
  }, [bid])

  const handleSubmit = async (formData) => {
    try {
      setBtnLoading(true)
      const data = pickBy({
        ...formData,
        to: address
      })
      if (bid) {
        await setDoc(doc(db, 'billings', bid), data, { merge: true })
      } else {
        await addDoc(collection(db, 'billings'), data)
      }
      setBtnLoading(false)
      navigate('/admin/billings')
      return Promise.resolve()
    } catch (error) {
      setBtnLoading(false)
      return Promise.reject(error)
    }
  }

  const onCoinChange = (value) => {
    billingForm.setFieldsValue({
      ...billingForm.getFieldsValue(),
      c: value,
      n: ''
    })
    const getNetworks = billingCoin.find((o) => o.value === value)?.networks ?? []
    setNetworks(getNetworks)
  }

  const onNetworkChange = (value) => {
    const getAddress = networks.find((o) => o.value === value)?.address[addressOrd] ?? ''
    setAddress(getAddress)
  }

  if (loading) return <Skeleton></Skeleton>
  return (
    <div className='billing-page'>
      <Row>
        <Col span={24}>
          <Card
            title='Payment'
            styles={{
              header: { fontWeight: 'bold', fontSize: '20px', lineHeight: '23px', backgroundColor: '#36cfc9', color: '#fdfdfd' },
              body: { boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)', borderRadius: '0 0 4px 4px' }
            }}
          >
            <AntdForm
              labelCol={{ span: 4 }}
              onFinish={handleSubmit}
              initialValues={value}
              form={billingForm}
              name='billingForm'
            >
              <AntdForm.Item name='c' label='Coin' rules={[{ required: true, message: 'Please select Coin!' }]}>
                <Select
                  placeholder='Select Coin'
                  onChange={(v) => onCoinChange(v)}
                >
                  {((billingCoin || []).map((o) => (
                    <Select.Option key={o.value} value={o.value}>{o.value}</Select.Option>
                  )))
                  }
                </Select>
              </AntdForm.Item>
              <AntdForm.Item name='n' label='Network' rules={[{ required: true, message: 'Please select Network!' }]}>
                <Select
                  placeholder='Select Network'
                  onChange={(v) => onNetworkChange(v)}
                >
                  {((networks || []).map((o) => (
                    <Select.Option key={o.value} value={o.value}>{o.value}</Select.Option>
                  )))
                  }
                </Select>
              </AntdForm.Item>
              {(address) && (
                <>
                  <Row>
                    <Col span={4}><span style={{ float: 'right', margin: '4px 8px 0 0', fontWeight: 'bold' }}>Send to Address:</span></Col>
                    <Col span={20}><span style={{ fontWeight: 'bold' }}>{address}</span><Button type='link' style={{ margin: '0 8px 16px' }} onClick={() => navigator.clipboard.writeText(address)}>Copy</Button></Col>
                  </Row>
                </>
              )}
              <AntdForm.Item name='from' label='Send from Address' rules={[{ required: true, message: 'Please input Send from Address!' }]}>
                <Input />
              </AntdForm.Item>
              <AntdForm.Item name='am' label='Amount' rules={[{ required: true, message: 'Please input Amount' }]}>
                <InputNumber
                  min={0}
                />
              </AntdForm.Item>
              <AntdForm.Item name='r' label='Remark'>
                <Input.TextArea />
              </AntdForm.Item>
              <div style={{ textAlign: 'center' }}>
                <Button loading={btnLoading} style={{ width: 300, borderRadius: '4px', height: 40, marginTop: 20 }} onClick={() => billingForm.submit()} type='primary'><SaveOutlined />Payment</Button>
                <Button loading={btnLoading} style={{ borderRadius: '4px', height: 40, marginTop: 20, marginLeft: 20 }} onClick={() => navigate(-1)}><CloseOutlined />Close</Button>
              </div>
            </AntdForm>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Form

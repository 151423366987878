import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { Card, Table, Button, Skeleton } from 'antd'
import { DollarOutlined } from '@ant-design/icons'

import dayjs from 'dayjs'

import { onSnapshot, collection, query, where, limit } from 'firebase/firestore'

import { useAuth } from '../../contexts/AuthContextProvider'
import { useLayoutContext } from '../../contexts/LayoutContextProvider'
import { billingStatus } from '../../const/billing'
import { dateFormat } from '../../const/misc'

import './Billing.css'

const List = () => {
  const navigate = useNavigate()
  const { db, uid } = useAuth()
  const { getPageNo, onPageChange } = useLayoutContext()

  const [value, setValue] = useState([])
  const [pendings, setPendings] = useState([])

  useEffect(() => {
    const unsub = onSnapshot(query(collection(db, 'billings'), where('uid', '==', uid), limit(20)), { includeMetadataChanges: true }, (snapshot) => {
      const p = []
      const data = snapshot.docs.map((doc) => {
        const d = doc.data()
        if (d.s !== 'success') p.push(doc.id)
        return {
          ...d,
          id: doc.id
        }
      })
      data.sort((x, y) => y.date.seconds - x.date.seconds)
      setPendings(p)
      setValue(data)
    })
    return () => unsub()
  }, [db, uid])

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      width: 100,
      render: text => dayjs(text.seconds * 1000).format(dateFormat)
    },
    {
      title: 'Address',
      dataIndex: 'from',
      width: 200
    },
    {
      title: 'Coin',
      dataIndex: 'c',
      width: 100
    },
    {
      title: 'Network',
      dataIndex: 'n',
      width: 100
    },
    {
      title: 'Amount',
      dataIndex: 'am',
      width: 150
    },
    {
      title: 'Status',
      dataIndex: 's',
      width: 130,
      render: (text) => <span style={{ color: (text === 'success') ? '#15dd15' : '#dd1515' }}>{billingStatus[text]}</span>
    },
    {
      title: 'Remark',
      dataIndex: 'r'
    },
    {
      title: 'Operation',
      dataIndex: 'Operation',
      width: 150,
      render: (_, row) => (
        <div>
          {(row.s !== 'success') && (
            <Button style={{ borderRadius: '4px', marginLeft: '16px' }} type='primary' size='middle' onClick={() => navigate(`/admin/billings/${row.id}/payment`)}><DollarOutlined /></Button>
          )}
        </div>
      )
    }
  ]

  if (!value) return <Skeleton></Skeleton>
  return (
    <div className='billing-page'>
      <Card
        title='Billing List'
        styles={{
          header: { fontWeight: 'bold', fontSize: '20px', lineHeight: '23px', backgroundColor: '#36cfc9', color: '#fdfdfd' },
          body: { boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)', borderRadius: '0 0 4px 4px' }
        }}
      >
        <Table
          bordered
          rowKey='id'
          columns={columns}
          dataSource={value}
          pagination={{
            total: value?.length ?? 0,
            current: getPageNo('billingList'),
            onChange(page) {
              onPageChange('billingList', page)
            }
          }}
          rowClassName={(record) => (pendings.indexOf(record.id) !== -1) ? 'billing-hilight' : ''
          }
        />
      </Card>
    </div>
  )
}

export default List

import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { Card, Table, Button, Tag, Popconfirm, Skeleton } from 'antd'
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'

import { onSnapshot, collection, doc, deleteDoc, query, where } from 'firebase/firestore'

import { useAuth } from '../../../contexts/AuthContextProvider'
import { useLayoutContext } from '../../../contexts/LayoutContextProvider'
import { getExLogo } from '../../../utils/tools'
import { exchanges, tickerColor, timeFrame, rate } from '../../../const/bots'

import green from '../../../assets/images/green.gif'
import red from '../../../assets/images/red.gif'

import '../Bot.css'

const apiTypes = exchanges.reduce((acc1, o) => {
  return {
    ...acc1,
    [o.value]: (o?.tv?.apiTypes ?? []).reduce((acc2, p) => {
      return {
        ...acc2,
        [p.value]: p.name
      }
    }, {})
  }
}, {})

const tf = timeFrame.tv.reduce((acc, o) => {
  acc[o.value] = o
  return acc
}, {})

const List = () => {
  const navigate = useNavigate()
  const { db, uid } = useAuth()
  const { getPageNo, onPageChange } = useLayoutContext()

  const [value, setValue] = useState([])

  useEffect(() => {
    const unsub = onSnapshot(query(collection(db, 'bots'), where('uid', '==', uid), where('bot', '==', 'tv')), { includeMetadataChanges: true }, (snapshot) => {
      const data = snapshot.docs.map((doc) => {
        const d = doc.data()
        return {
          ...d,
          id: doc.id,
          fee: (!d.active) ? '' : (d.fr * rate).toFixed(2),
          x: d.active ? 1 : 2
        }
      })
      data.sort((x, y) => (y?.date?.seconds ?? 1) * x.x - (x?.date?.seconds ?? 1) * y.x)
      setValue(data)
    })
    return () => unsub()
  }, [db, uid])

  const handleDelete = async (row) => {
    try {
      await deleteDoc(doc(db, 'bots', row.id))
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
        multiple: 1
      }
    },
    {
      title: 'Exchange',
      dataIndex: 'ex',
      width: 150,
      sorter: {
        compare: (a, b) => a.ex.localeCompare(b.ex),
        multiple: 4
      },
      render: text => getExLogo(text)
    },
    {
      title: 'Type',
      dataIndex: 'type',
      width: 200,
      sorter: {
        compare: (a, b) => a.type.localeCompare(b.type),
        multiple: 3
      },
      render: (data, row) => (apiTypes[row.ex][data] || '')
    },
    {
      title: 'Ticker',
      dataIndex: 'tk',
      width: 200,
      sorter: {
        compare: (a, b) => a.tk.localeCompare(b.tk),
        multiple: 2
      },
      render: (data, row) => <Tag color={tickerColor[row.type]} style={{ fontSize: '14px' }} key={data}>{data}</Tag>
    },
    {
      title: 'Time Frame',
      dataIndex: 'tf',
      width: 150,
      sorter: {
        compare: (a, b) => tf[a.tf]['id'] - tf[b.tf]['id'],
        multiple: 1
      },
      render: (data) => tf[data]['name']
    },
    {
      title: 'Fee (USD/Day)',
      dataIndex: 'fee',
      width: 150
    },
    {
      title: 'Active',
      dataIndex: 'active',
      width: 120,
      align: 'center',
      render: text => <img src={(text) ? green : red} width={36} alt={(text) ? 'Active' : 'Not active'} />
    },
    {
      title: 'Operation',
      dataIndex: 'Operation',
      width: 175,
      render: (_, row) => (
        <div>
          <Button style={{ borderRadius: '4px', marginLeft: '16px' }} type='primary' size='middle' onClick={() => navigate(`/admin/bots/tv/${row.id}/edit`)}><EditOutlined /></Button>
          {(!row.active) && (
            <Popconfirm
              title={`Are you sure to delete ${row.name} Bot?`}
              onConfirm={() => handleDelete(row)}
              okText='Yes'
              cancelText='No'
            >
              <Button style={{ borderRadius: '4px', marginLeft: '16px' }} type='primary' size='middle' danger><DeleteOutlined /></Button>
            </Popconfirm>
          )}
        </div>
      )
    }
  ]

  if (!value) return <Skeleton></Skeleton>
  return (
    <div className='bot-page'>
      <Card
        title='TradingView Bot List'
        styles={{
          header: { fontWeight: 'bold', fontSize: '20px', lineHeight: '23px', backgroundColor: '#36cfc9', color: '#fdfdfd' },
          body: { boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)', borderRadius: '0 0 4px 4px' }
        }}
      >
        <div style={{ textAlign: 'right', marginBottom: 20 }}>
          <Button style={{ borderRadius: '4px' }} onClick={() => navigate('/admin/bots/tv/create')} type='primary'><PlusOutlined />Add New Bot</Button>
        </div>
        <Table
          bordered
          rowKey='id'
          columns={columns}
          dataSource={value}
          pagination={{
            total: value?.length ?? 0,
            current: getPageNo('botTVList'),
            onChange(page) {
              onPageChange('botTVList', page)
            }
          }}
        />
      </Card>
    </div>
  )
}

export default List

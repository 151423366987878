import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Layout, Result, Button } from 'antd'
import { HomeOutlined } from '@ant-design/icons'

import { useAuth } from '../../contexts/AuthContextProvider'

import './NotFound.css'

const NotFoundPage = () => {
  const navigate = useNavigate()
  const { uid } = useAuth()
  return (
    <Layout className='not-found-page'>
      <Layout.Content>
        <Result
          status='404'
          title='404'
          subTitle='Sorry, the page you visited does not exist.'
          extra={
            <Button onClick={() => navigate(`${(uid) ? '/admin' : '/login'}`)} style={{ width: 300, borderRadius: '4px', height: 40, marginTop: 20 }} type='primary'><HomeOutlined />Home</Button>
          }
        />
      </Layout.Content>
    </Layout>
  )
}

export default NotFoundPage

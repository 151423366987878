const dateFormat = 'MM/DD/YYYY'

const expression = [
  { value: 'gt', name: '>' },
  { value: 'gte', name: '>=' },
  { value: 'lt', name: '<' },
  { value: 'lte', name: '<=' },
  { value: 'eq', name: '=' },
  { value: 'neq', name: '!=' },
  { value: 'c', name: 'Crossing' },
  { value: 'cu', name: 'Crossing up' },
  { value: 'cd', name: 'Crossing down' }
]

export {
  dateFormat,
  expression
}

import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAsync } from 'react-use'

import { Form as AntdForm, Card, Button, Select, Skeleton } from 'antd'
import { SaveOutlined, CloseOutlined } from '@ant-design/icons'

import { collection, doc, getDocs, setDoc, query, where } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'

import { useAuth } from '../../contexts/AuthContextProvider'
import { getExponent, getId } from '../../utils/tickers'
import { exchanges } from '../../const/bots'

import './Dashboard.css'

const Form = () => {
  const navigate = useNavigate()
  const { db, functions } = useAuth()
  const [btnLoading, setBtnLoading] = useState(false)

  const [tickerForm] = AntdForm.useForm()

  const { value, loading } = useAsync(async () => {
    return {
      ex: 'binance'
    }
  })

  const handleSubmit = async (formData) => {
    try {
      setBtnLoading(true)
      if (formData.ex === 'binance') {
        // Binance spot
        const snapBS = await getDocs(query(collection(db, 'tks'), where('ex', '==', 'binance'), where('type', '==', 'spot')))
        const bsObj = snapBS.docs.reduce((acc, o) => {
          acc[o.data().tk] = o.data()
          return acc
        }, {})
        const dataBS = await fetch('https://api.binance.com/api/v3/exchangeInfo')
        const jsonBS = await dataBS.json()
        const bsArr = jsonBS.symbols.map((o) => {
          const p = o.filters.find((q) => q.filterType === 'PRICE_FILTER')
          const l = o.filters.find((q) => q.filterType === 'LOT_SIZE')
          const n = o.filters.find((q) => q.filterType === 'NOTIONAL')
          const pdec = getExponent(p.tickSize)
          const sdec = getExponent(l.stepSize)
          return {
            tk: o.symbol,
            balas: o.quoteAsset,
            trdas: o.baseAsset,
            scnt: sdec.man,
            pdec: pdec.exp,
            sdec: sdec.exp,
            min: parseFloat(n.minNotional)
          }
        })
        for await (const o of bsArr) {
          if (
            !bsObj[o.tk] ||
            (
              o.balas !== bsObj[o.tk]['balas'] ||
              o.trdas !== bsObj[o.tk]['trdas'] ||
              o.scnt !== bsObj[o.tk]['scnt'] ||
              o.pdec !== bsObj[o.tk]['pdec'] ||
              o.sdec !== bsObj[o.tk]['sdec'] ||
              o.min !== bsObj[o.tk]['min']
            )
          ) {
            const id = getId('binance', 'spot', o.tk)
            await setDoc(doc(db, 'tks', id), {
              ex: 'binance',
              type: 'spot',
              tk: o.tk,
              balas: o.balas,
              trdas: o.trdas,
              scnt: o.scnt,
              pdec: o.pdec,
              sdec: o.sdec,
              min: o.min
            }, {
              merge: true,
            })
          }
        }

        // Binance futures
        const snapBF = await getDocs(query(collection(db, 'tks'), where('ex', '==', 'binance'), where('type', '==', 'futures')))
        const bfObj = snapBF.docs.reduce((acc, o) => {
          acc[o.data().tk] = o.data()
          return acc
        }, {})
        const dataBF = await fetch('https://fapi.binance.com/fapi/v1/exchangeInfo')
        const jsonBF = await dataBF.json()
        const bfArr = jsonBF.symbols.map((o) => {
          const p = o.filters.find((q) => q.filterType === 'PRICE_FILTER')
          const l = o.filters.find((q) => q.filterType === 'LOT_SIZE')
          const n = o.filters.find((q) => q.filterType === 'MIN_NOTIONAL')
          const pdec = getExponent(p.tickSize)
          const sdec = getExponent(l.stepSize)
          return {
            tk: o.symbol,
            balas: o.quoteAsset,
            trdas: o.baseAsset,
            scnt: sdec.man,
            pdec: pdec.exp,
            sdec: sdec.exp,
            min: parseFloat(n.notional)
          }
        })
        for await (const o of bfArr) {
          if (
            !bfObj[o.tk] ||
            (
              o.balas !== bfObj[o.tk]['balas'] ||
              o.trdas !== bfObj[o.tk]['trdas'] ||
              o.scnt !== bfObj[o.tk]['scnt'] ||
              o.pdec !== bfObj[o.tk]['pdec'] ||
              o.sdec !== bfObj[o.tk]['sdec'] ||
              o.min !== bfObj[o.tk]['min']
            )
          ) {
            const id = getId('binance', 'futures', o.tk)
            await setDoc(doc(db, 'tks', id), {
              ex: 'binance',
              type: 'futures',
              tk: o.tk,
              balas: o.balas,
              trdas: o.trdas,
              scnt: o.scnt,
              pdec: o.pdec,
              sdec: o.sdec,
              min: o.min
            }, {
              merge: true,
            })
          }
        }

        // Binance delivery
        const snapBD = await getDocs(query(collection(db, 'tks'), where('ex', '==', 'binance'), where('type', '==', 'delivery')))
        const bdObj = snapBD.docs.reduce((acc, o) => {
          acc[o.data().tk] = o.data()
          return acc
        }, {})
        const dataBD = await fetch('https://dapi.binance.com/dapi/v1/exchangeInfo')
        const jsonBD = await dataBD.json()
        const bdArr = jsonBD.symbols.map((o) => {
          const p = o.filters.find((q) => q.filterType === 'PRICE_FILTER')
          const l = o.filters.find((q) => q.filterType === 'LOT_SIZE')
          const pdec = getExponent(p.tickSize)
          const sdec = getExponent(l.stepSize)
          return {
            tk: o.symbol,
            balas: o.baseAsset,
            trdas: o.quoteAsset,
            scnt: o.contractSize,
            pdec: pdec.exp,
            sdec: sdec.exp,
            min: parseFloat(l.minQty)
          }
        })
        for await (const o of bdArr) {
          if (
            !bdObj[o.tk] ||
            (
              o.balas !== bdObj[o.tk]['balas'] ||
              o.trdas !== bdObj[o.tk]['trdas'] ||
              o.scnt !== bdObj[o.tk]['scnt'] ||
              o.pdec !== bdObj[o.tk]['pdec'] ||
              o.sdec !== bdObj[o.tk]['sdec'] ||
              o.min !== bdObj[o.tk]['min']
            )
          ) {
            const id = getId('binance', 'delivery', o.tk)
            await setDoc(doc(db, 'tks', id), {
              ex: 'binance',
              type: 'delivery',
              tk: o.tk,
              balas: o.balas,
              trdas: o.trdas,
              scnt: o.scnt,
              pdec: o.pdec,
              sdec: o.sdec,
              min: o.min
            }, {
              merge: true,
            })
          }
        }
      }

      if (formData.ex === 'okx') {
        const getTKS = httpsCallable(functions, 'getTickers-getTickers')
        // okx spot
        const snapOS = await getDocs(query(collection(db, 'tks'), where('ex', '==', 'okx'), where('type', '==', 'spot')))
        const osObj = snapOS.docs.reduce((acc, o) => {
          acc[o.data().tk] = o.data()
          return acc
        }, {})
        const osArr = await getTKS({ ex: 'okx', type: 'spot' })
        for await (const o of osArr.data) {
          if (
            !osObj[o.tk] ||
            (
              o.balas !== osObj[o.tk]['balas'] ||
              o.trdas !== osObj[o.tk]['trdas'] ||
              o.scnt !== osObj[o.tk]['scnt'] ||
              o.pdec !== osObj[o.tk]['pdec'] ||
              o.sdec !== osObj[o.tk]['sdec'] ||
              o.min !== osObj[o.tk]['min']
            )
          ) {
            const id = getId('okx', 'spot', o.tk)
            await setDoc(doc(db, 'tks', id), {
              ex: 'okx',
              type: 'spot',
              tk: o.tk,
              balas: o.balas,
              trdas: o.trdas,
              scnt: o.scnt,
              pdec: o.pdec,
              sdec: o.sdec,
              min: o.min
            }, {
              merge: true,
            })
          }
        }

        // okx futures
        const snapOF = await getDocs(query(collection(db, 'tks'), where('ex', '==', 'okx'), where('type', '==', 'futures')))
        const ofObj = snapOF.docs.reduce((acc, o) => {
          acc[o.data().tk] = o.data()
          return acc
        }, {})
        const ofArr = await getTKS({ ex: 'okx', type: 'futures' })
        for await (const o of ofArr.data) {
          if (
            !ofObj[o.tk] ||
            (
              o.balas !== ofObj[o.tk]['balas'] ||
              o.trdas !== ofObj[o.tk]['trdas'] ||
              o.scnt !== ofObj[o.tk]['scnt'] ||
              o.pdec !== ofObj[o.tk]['pdec'] ||
              o.sdec !== ofObj[o.tk]['sdec'] ||
              o.min !== ofObj[o.tk]['min']
            )
          ) {
            const id = getId('okx', 'futures', o.tk)
            await setDoc(doc(db, 'tks', id), {
              ex: 'okx',
              type: 'futures',
              tk: o.tk,
              balas: o.balas,
              trdas: o.trdas,
              scnt: o.scnt,
              pdec: o.pdec,
              sdec: o.sdec,
              min: o.min
            }, {
              merge: true,
            })
          }
        }

        // okx swap
        const snapOSW = await getDocs(query(collection(db, 'tks'), where('ex', '==', 'okx'), where('type', '==', 'swap')))
        const oswObj = snapOSW.docs.reduce((acc, o) => {
          acc[o.data().tk] = o.data()
          return acc
        }, {})
        const oswArr = await getTKS({ ex: 'okx', type: 'swap' })
        for await (const o of oswArr.data) {
          if (
            !oswObj[o.tk] ||
            (
              o.balas !== oswObj[o.tk]['balas'] ||
              o.trdas !== oswObj[o.tk]['trdas'] ||
              o.scnt !== oswObj[o.tk]['scnt'] ||
              o.pdec !== oswObj[o.tk]['pdec'] ||
              o.sdec !== oswObj[o.tk]['sdec'] ||
              o.min !== oswObj[o.tk]['min']
            )
          ) {
            const id = getId('okx', 'swap', o.tk)
            await setDoc(doc(db, 'tks', id), {
              ex: 'okx',
              type: 'swap',
              tk: o.tk,
              balas: o.balas,
              trdas: o.trdas,
              scnt: o.scnt,
              pdec: o.pdec,
              sdec: o.sdec,
              min: o.min
            }, {
              merge: true,
            })
          }
        }
      }

      // // bitkub spot
      // const dataBKS = await fetch('https://api.bitkub.com/api/market/symbols')
      // const jsonBKS = await dataBKS.json()
      // const bksTKN = jsonBKS.result.reduce((acc, o) => {
      //   const sym = o.symbol.split('_')
      //   const tk = `${sym[0]}${sym[1]}`.toLowerCase()
      //   acc[tk] = `${sym[1]}/${sym[0]}`
      //   return acc
      // }, {})
      setBtnLoading(false)
    } catch (error) {
      console.log(error)
      setBtnLoading(false)
    }
  }

  if (loading) return <Skeleton></Skeleton>
  return (
    <div className='dashboard-page'>
      <Card
        title='Generate Tickers'
        styles={{
          header: { fontWeight: 'bold', fontSize: '20px', lineHeight: '23px', backgroundColor: '#36cfc9', color: '#fdfdfd' },
          body: { boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)', borderRadius: '0 0 4px 4px' }
        }}
      >
        <AntdForm
          labelCol={{ span: 4 }}
          onFinish={handleSubmit}
          initialValues={value}
          form={tickerForm}
          name='tickerForm'
        >
          <AntdForm.Item name='ex' label='Exchange' rules={[{ required: true, message: 'Please select Exchange' }]}>
            <Select
              placeholder='Select Exchange'
            >
              {(exchanges.map((o) => (
                <Select.Option key={o.value} value={o.value}>{o.name}</Select.Option>
              )))}
            </Select>
          </AntdForm.Item>
          <div style={{ textAlign: 'center' }}>
            <Button loading={btnLoading} style={{ width: 300, borderRadius: '4px', height: 40, marginTop: 20 }} onClick={() => tickerForm.submit()} type='primary'><SaveOutlined />Generate Tickers</Button>
            <Button loading={btnLoading} style={{ borderRadius: '4px', height: 40, marginTop: 20, marginLeft: 20 }} onClick={() => navigate(-1)}><CloseOutlined />Close</Button>
          </div>
        </AntdForm>
      </Card>
    </div>
  )
}

export default Form

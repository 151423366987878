const billingCoin = [
  {
    value: 'USDT',
    networks: [
      {
        value: 'BSC',
        address: [
          'bc1q8yx582e7d57zagv8fz3dgcuckagnxcl3ckutlkbcs'
        ]
      },
      {
        value: 'Polygon',
        address: [
          'bc1q8yx582e7d57zagv8fz3dgcuckagnxcl3ckutlkpolygon'
        ]
      }
    ]
  },
  // {
  //   value: 'BUSD',
  //   networks: [
  //     {
  //       value: 'BSC',
  //       address: 'DLa1TX1h9ZUwD2Qmkw8Y7mURjzMtNJv77cbsc'
  //     },
  //     {
  //       value: 'Polygon',
  //       address: 'DLa1TX1h9ZUwD2Qmkw8Y7mURjzMtNJv77cpolygon'
  //     }
  //   ]
  // }
]

const billingStatus = {
  'pending': 'Pending',
  'reject': 'Not Acceptable',
  'success': 'Success'
}

export {
  billingCoin,
  billingStatus
}

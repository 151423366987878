import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Row, Col, Card } from 'antd'
import { ApiOutlined, AlertOutlined } from '@ant-design/icons'

import './Setting.css'

const Setting = () => {
  const navigate = useNavigate()

  return (
    <div className='setting-page'>
      <Row gutter={16}>
        <Col span={12}>
          <Card
            hoverable
            styles={{
              body: { textAlign: 'center' }
            }}
            onClick={() => navigate('/admin/settings/keys')}
          >
            <Row gutter={8}>
              <Col span={8}>
                <ApiOutlined style={{ fontSize: '96px', color: '#52c41a' }} />
              </Col>
              <Col span={16} style={{ margin: 'auto', width: '50%', fontSize: '32px', fontWeight: 'bold', color: '#73d13d' }}>
                API Keys
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={12}>
          <Card
            hoverable
            styles={{
              body: { textAlign: 'center' }
            }}
            onClick={() => navigate('/admin/settings/tokens')}
          >
            <Row gutter={8}>
              <Col span={8}>
                <AlertOutlined style={{ fontSize: '96px', color: '#1677ff' }} />
              </Col>
              <Col span={16} style={{ margin: 'auto', width: '50%', fontSize: '32px', fontWeight: 'bold', color: '#4096ff' }}>
                Alert Tokens
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Setting
